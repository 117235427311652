import ApiService from "../../core/services/ApiService";
import store from "../../store/index";

export interface INotificationStatus {
	notificationsSupported: boolean;
	notificationsBlocked: boolean;
	notificationsEnabled: boolean;
	serviceWorkerRegistation: ServiceWorkerRegistration | null;
	pushSubscription: PushSubscription | null;
}
declare let process: { env: { [key: string]: string } };

/** manages notification status in the auth store */
const NotificationHelper = {
	async setNotificationsStatus(): Promise<void> {
		//Promise<INotificationStatus>
		const notificationsSupported = !!("Notification" in window && "serviceWorker" in navigator);
		const notificationsBlocked: boolean = notificationsSupported ? Notification.permission === "denied" : false;
		let notificationsEnabled = false;
		let serviceWorkerRegistation: ServiceWorkerRegistration | null = null;
		let pushSubscription: PushSubscription | null = null;
		if (notificationsSupported && !notificationsBlocked) {
			serviceWorkerRegistation = await this.getServiceWorkerReadyPromise().catch(() => null);
			if (serviceWorkerRegistation) {
				pushSubscription = await serviceWorkerRegistation.pushManager.getSubscription().catch(() => null);
				if (pushSubscription) {
					//todo need to check with the database that this subscription exists there before we say this.
					notificationsEnabled = true;
				}
			}
		}
		const status: INotificationStatus = {
			notificationsSupported: notificationsSupported,
			notificationsBlocked: notificationsBlocked,
			notificationsEnabled: notificationsEnabled,
			serviceWorkerRegistation: serviceWorkerRegistation,
			pushSubscription: pushSubscription,
		};
		store.commit("auth/setNotificationStatus", status);
	},

	getServiceWorkerReadyPromise(): Promise<ServiceWorkerRegistration> {
		const race: Promise<ServiceWorkerRegistration> = Promise.race([
			navigator.serviceWorker.ready, // returns a promise that may never complete so put it in a race with a timer.
			new Promise<ServiceWorkerRegistration>((resolve, reject) => {
				setTimeout(() => {
					reject("Timed out");
				}, 20000);
			}),
		]);
		return race;
	},

	async toggleSubscription(): Promise<void> {
		const status: INotificationStatus = store.getters["auth/notificationStatus"];
		if (status.notificationsSupported) {
			// Find out if we need to create a subscription or delete it
			if (!status.notificationsEnabled) {
				// Ask permission and when granted, create new subscription
				const perm: NotificationPermission = await Notification.requestPermission();
				// if granted, create new subscription
				if (perm === "granted") {
					const sub: PushSubscription | null = await this.createSubscription().catch((e: any) => {
						store.commit("common/errorMsg", "Subscription attempt timed out. Please try again.");
						return null;
					});
					if (sub) {
						store.commit("auth/setPushSubscription", sub);
						// store new subscription on the server
						const resource = "/api/subscription";
						const userId: number = store.getters["auth/currentUserData"].id;
						await ApiService.post(resource, {
							subscription: store.getters["auth/notificationStatus"].pushSubscription,
							userId: userId,
						}).catch(() => store.commit("common/errorMsg", "Subscription attempt failed. Please try again."));
						this.showNotification();
						store.commit("auth/setNotificationsEnabled", true);
					}
				} else {
					store.commit("auth/setNotificationsBlocked", Notification.permission === "denied");
				}
			} else {
				// Destroy subscription
				if (status.pushSubscription) {
					// destroy on the server
					const resource = "/api/subscription/delete";
					return (
						ApiService.post(resource, {
							endpoint: status.pushSubscription.endpoint,
						})
							// unsubscribe on the client
							.then(() => status.pushSubscription!.unsubscribe())
							.then(() => {
								// update the data
								store.commit("auth/setNotificationsEnabled", false);
								store.commit("auth/setPushSubscription", null);
							})
					);
				}
			}
		}
	},

	async createSubscription(): Promise<PushSubscription> {
		const status: INotificationStatus = store.getters["auth/notificationStatus"];
		if (status.serviceWorkerRegistation === null) {
			return NotificationHelper.getServiceWorkerReadyPromise().then((swreg) => {
				store.commit("auth/setServiceWorkerRegistation", swreg);
				return this.subscribe();
			});
		} else {
			return this.subscribe();
		}
	},

	subscribe(): Promise<PushSubscription> {
		const swreg: ServiceWorkerRegistration = store.getters["auth/notificationStatus"].serviceWorkerRegistation;
		// create new subscription for this browser on this device
		const vapidPublicKey: string = process.env.VUE_APP_VAPID_PUBLIC_KEY;
		const convertedVapidPublicKey: Uint8Array = this.urlBase64ToUint8Array(vapidPublicKey);
		// return the subscription promise, we chain another then where we can send it to the server
		return swreg.pushManager.subscribe({
			userVisibleOnly: true,
			// This is for security. On the backend, we need to do something with the VAPID_PRIVATE_KEY
			// that you can find in .env to make this work in the end
			applicationServerKey: convertedVapidPublicKey,
		});
	},

	showNotification() {
		const status: INotificationStatus = store.getters["auth/notificationStatus"];
		if (status.serviceWorkerRegistation) {
			status.serviceWorkerRegistation.showNotification("Notifications granted", {
				body: "Web Push Notifications have been enabled on one of your devices",
				icon: "/img/icons/android-chrome-192x192.png",
				image: "/img/autumn-forest.png",
				vibrate: [300, 200, 300],
				badge: "/img/icons/plint-badge-96x96.png",
			});
		} else {
			store.commit("common/errorMsg", "Cant send a message, no service worker registered.");
		}
	},

	async acknowledgeNotification(notificationId: string): Promise<void> {

		// store new subscription on the server
		const resource = "/api/acknowledge/" + notificationId;
		const userId: number = store.getters["auth/currentUserData"].id;
		await ApiService.post(resource, {
			userId: userId,
		}).catch(() => store.commit("common/errorMsg", "Mark notification as read attempt failed. Please try again."));
	},

	urlBase64ToUint8Array(base64String: string): Uint8Array {
		const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
		const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
		const rawData = window.atob(base64);
		const outputArray: Uint8Array = new Uint8Array(rawData.length);
		for (let i = 0; i < rawData.length; ++i) {
			outputArray[i] = rawData.charCodeAt(i);
		}
		return outputArray;
	},
};

export { NotificationHelper };
