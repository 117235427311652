import {
    IReport,
    IReportModule,
    IReportModuleIncidentSectionData,
    ModuleCode,
    ReportType,
    IFieldDefinitionReportModule,
} from '@/core/interfaces/ReportInterface';
import ReportService from '@/core/services/ReportService';
import ReportModuleService from '@/core/services/ReportModuleService';
import store from './index';
import { IHierarchicalSelectListItem, ISelectListItem } from '@/core/interfaces/SelectListItemInterface';
import { TableNames } from '@/core/enums/LookupEnums';
import { cloneDeep, isString, startCase, uniqBy } from 'lodash';
import RiskAssessmentService from '@/modules/RiskAssessments/services/RiskAssessmentService';
import { SelectListService } from '@/core/services/SelectListService';
import { IncidentHelperService } from '@/modules/Incidents/services/IncidentModuleHelperService';
import { ICustomFieldDef, IDdValue, IFieldDefinition, ILayoutItem } from '@/core/interfaces/CustomFieldInterface';
import CustomFieldService from '@/core/services/CustomFieldService';
import { IControl } from '@/modules/RiskAssessments/interfaces/ControlInterface';
import { IRiskDefinition } from '@/modules/RiskAssessments/interfaces/RiskDefinitionsInterface';
import { IRisk } from '@/modules/RiskAssessments/interfaces/RiskInterface';
import { IIncident } from '@/modules/Incidents/interfaces/IncidentInterface';
import { IIssue } from '@/modules/Issues/interfaces/IssueInterface';
import RelatedRecordHelper from '@/core/helpers/RelatedRecordHelper';
import { IMultipleRiskAssessmentReportsData } from '@/modules/RiskAssessments/interfaces/RiskAssessmentInterface';
import EventService from '@/modules/Issues/services/EventService';
import { IMultipleEventReportsData } from '@/modules/Issues/interfaces/EventInterface';

type TReportData = {
    fields: { [key: string]: ICustomFieldDef },
    risk_assessments: { [key: string]: any; },
    risk_definitions: { [key: string]: any; },
    risks: IRisk[],
    controls: IControl[];
    events: { [key: string]: any; },
    incidents: IIncident[],
    issues: IIssue[],
    issueCriteriaOptions: IDdValue[],
};

const emptyData = {
    fields: {},
    risk_assessments: {},
    risk_definitions: {},
    risks: [],
    controls: [],
    events: {},
    incidents: [],
    issues: [],
    issueCriteriaOptions: [],
}

interface IState {
    report: IReport;
    data: TReportData;
    eventOptions: IHierarchicalSelectListItem[];
    riskAssessmentOptions: IHierarchicalSelectListItem[];
}

const state: IState = {
    report: ReportService.getEmptyReport(),
    data: emptyData,
    eventOptions: [],
    riskAssessmentOptions: [],
};

const getters = {
    report: (state: IState) => {
        return state.report;
    },
    data: (state: IState) => {
        return state.data;
    },
    risk_assessments: (state: IState) => {
        return Object.values(state.data.risk_assessments).map((value) => value.risk_assessment);
    },
    events: (state: IState) => {
        return Object.values(state.data.events).map((value) => value.event);
    },
    moduleInferred: (state: IState) =>
        (dataAttributeString: string, moduleCode: ModuleCode, moduleIndex: number, fallback: any = undefined) => {
            return getModuleInferred(dataAttributeString, moduleCode, moduleIndex, fallback);
        },
    displayFields: (state: IState) => (tableName: string, code: ModuleCode, moduleIndex: number) => {
        let filterFunction = (val: IFieldDefinition) => false;
        switch (tableName) {
            case TableNames.Risks:
                filterFunction = (val: IFieldDefinition) => {
                    const exclDisplFdNames: string[] = ['control_owners', 'update_count'];
                    return !exclDisplFdNames.includes(val.name);
                };
                break
            case TableNames.Controls:
                filterFunction = (val: IFieldDefinition) => {
                    const exclDisplFdNames: string[] = ['task_count', 'update_count'];
                    return !exclDisplFdNames.includes(val.name);
                };
                break;
            case TableNames.Issues:
                filterFunction = (val: IFieldDefinition) => {
                    const exclDisplFdNames: string[] = ['update_count', 'task_count', 'notifications', 'tasks'];
                    return !exclDisplFdNames.includes(val.name);
                };
                break;
            case TableNames.Tasks:
                filterFunction = (val: IFieldDefinition) => {
                    const exclDisplFdNames: string[] = [];
                    return !exclDisplFdNames.includes(val.name);
                };
                break;
            case TableNames.Incidents:
                filterFunction = (val: IFieldDefinition) => {
                    let exclDisplFdNames: string[] = [];
                    if (code == ModuleCode.IRDetails || code == ModuleCode.EVINDetails) {
                        exclDisplFdNames.push('description');
                    }
                    if (code == ModuleCode.IRRegister || code == ModuleCode.EVINRegister) {
                        exclDisplFdNames.push('attachments');
                    }
                    return !exclDisplFdNames.includes(val.name);
                };
                break;
            case TableNames.People:
                filterFunction = (val: IFieldDefinition) => {
                    let exclDisplFdNames: string[] = ['parent_incident', 'parent_description'];
                    if (code == ModuleCode.IRDetails || code == ModuleCode.EVINDetails) {
                        exclDisplFdNames.push('name');
                    }
                    return !exclDisplFdNames.includes(val.name);
                };
                break;
            case TableNames.CorrectiveActions:
                filterFunction = (val: IFieldDefinition) => {
                    let exclDisplFdNames: string[] = ['parent_incident', 'parent_description'];
                    if (code == ModuleCode.IRDetails || code == ModuleCode.EVINDetails) {
                        exclDisplFdNames.push('description');
                    }
                    if (code == ModuleCode.IRRegister || code == ModuleCode.EVINRegister) {
                        exclDisplFdNames.push('updates', 'attachments');
                    }
                    return !exclDisplFdNames.includes(val.name);
                };
                break;
            case TableNames.IncidentInvestigations:
                filterFunction = (val: IFieldDefinition) => {
                    let exclDisplFdNames: string[] = ['incident_id'];
                    if (code == ModuleCode.IRDetails || code == ModuleCode.EVINDetails) {
                        exclDisplFdNames.push('name');
                    }
                    if (code == ModuleCode.IRRegister || code == ModuleCode.EVINRegister) {
                        exclDisplFdNames.push('updates', 'attachments');
                    }
                    return !exclDisplFdNames.includes(val.name);
                };
                break;
        }
        const sortFunction = (a: IFieldDefinition, b: IFieldDefinition) => {
            var labelA = (a.label && isString(a.label) ? a.label : a.name && isString(a.name) ? startCase(a.name) : 'Unknown').toUpperCase();
            var labelB = (b.label && isString(b.label) ? b.label : b.name && isString(b.name) ? startCase(b.name) : 'Unknown').toUpperCase();
            if (labelA < labelB) {
                return -1;
            }
            if (labelA > labelB) {
                return 1;
            }
            return 0;
        };
        let result = state.data.fields[tableName]?.fields.filter(filterFunction).sort(sortFunction);
        return result;
    },
    /**
     * Gets the parent options for combining columns in the report
     */
    fieldParentOptions: (state: IState) => (table: TableNames, index: number, fdrm: IFieldDefinitionReportModule) => {
        let result: ISelectListItem[] = [];
        let fdrms = state.report.report_modules[index].field_definition_report_modules ? state.report.report_modules[index].field_definition_report_modules![table] : [];
        if (fdrm && fdrms && Array.isArray(fdrms)) {
            if (fdrm.label === 'Responsible Team') {
                let a = 1;
            }
            const filter: IFieldDefinitionReportModule[] = fdrms.filter(
                (f: IFieldDefinitionReportModule) => {
                    return f.selected && fdrm.lku_master_table_id === f.lku_master_table_id && f.parent_field_definition_id === null
                });
            const selfIndex = filter.indexOf(fdrm);
            if (selfIndex >= 0) {
                filter.splice(selfIndex, 1);
            }
            result = filter.map((f: IFieldDefinitionReportModule) => {
                return { value: f.field_definition_id.toString(), text: f.label ? f.label : '', display_sequence: 0 };
            });
            result.unshift({ value: null, text: 'No Parent Field', display_sequence: 0 });
        }
        return result;
    },
    fieldDefinitionReportModules: (state: IState) => (moduleIndex: number) => {
        let fdrms = cloneDeep(state.report.report_modules[moduleIndex].field_definition_report_modules);
        return fdrms != null ? fdrms : {};
    },
    eventOptions: (state: IState) => {
        return state.eventOptions;
    },
    riskAssessmentOptions: (state: IState) => {
        return state.riskAssessmentOptions;
    },
    editorInit: (state: IState) => {
        return {
            selector: '#tiny',
            height: 300,
            skin: false,
            content_css: false,
            plugins: [
                'lists table code help',
                /* 'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount', */
            ],
            toolbar:
                'undo redo | fontselect fontsizeselect formatselect | bold italic forecolor backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | table |removeformat | code help',
            menubar: 'false',
            /* 			image_title: true,
            file_picker_callback: (cb: any, value: any, meta: any) => {
                let input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');


                    //Note: In modern browsers input[type='file'] is functional without
                    //even adding it to the DOM, but that might not be the case in some older
                    //or quirky browsers like IE, so you might want to add it to the DOM
                    //just in case, and visually hide it. And do not forget do remove it
                    //once you do not need it anymore.


                input.onchange = function (event: any) {
                    //debugger;
                    let file = event.target.files[0];

                    let reader = new FileReader();
                    reader.onload = function () {

                            //Note: Now we need to register the blob in TinyMCEs image blob
                            //registry. In the next release this part hopefully won't be
                            //necessary, as we are looking to handle it internally.

                        var id = 'blobid' + new Date().getTime();
                        var blobCache = (tinymce as any).activeEditor.editorUpload.blobCache;
                        var base64 = (reader.result as string).split(',')[1];
                        var blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);

                        //call the callback and populate the Title field with the file name
                        cb(blobInfo.blobUri(), { title: file.name });
                    };
                    reader.readAsDataURL(file);
                };

                input.click();
            }, */
        };
    },
    fieldDefinitionsForCharts: (state: IState) => (index: number) => {
        let result: { [key: string]: IFieldDefinition[] } = {};
        Object.keys(state.data.fields).forEach((tableName) => {
            if (state.data.fields[tableName]?.hasOwnProperty('fields')) {
                result[tableName] = cloneDeep(state.data.fields[tableName].fields);
                if (tableName === TableNames.Risks) {
                    // need to add all categories from all risk definitions
                    let riskCriteriaField = result[tableName].find((field) => field.name === 'risk_definition_category_id');
                    if (riskCriteriaField) {
                        riskCriteriaField.dd_values = [];
                        let ds = 0;
                        (Object.values(getModuleInferred('risk_definitions', ModuleCode.RAAnalysis, index, {})) as IRiskDefinition[])
                            .forEach((riskDefinition: IRiskDefinition) => {
                                let ddValues: IDdValue[] = riskDefinition.risk_definition_categories.map((category) => {
                                    return {
                                        value: category.id,
                                        display_sequence: ds++,
                                        text: category.name,
                                    }
                                });
                                riskCriteriaField!.dd_values.push(...ddValues);
                            });
                    }
                } else if (tableName === TableNames.Issues) {
                    // need to add all risk criteria
                    let criteriaField = result[tableName].find((field) => field.name === 'risk_definition_criterion_id');
                    if (criteriaField) {
                        criteriaField.dd_values = getModuleInferred('issueCriteriaOptions', ModuleCode.EVAnalysis, index, []);
                    }
                }
            }
        });
        return result;
    }
};
const actions = {
    async setReport({ dispatch, commit }: any, report: IReport) {
        commit('setReport', report);
        if (!report.is_template) {
            switch (state.report.lku_report_type_id) {
                case ReportType.RiskAssessment:
                    return dispatch('getRiskAssessmentData', state.report.master_id != null ? [state.report.master_id] : []);
                case ReportType.MultipleRiskAssessment:
                    let risk_assessment_ids = report.master_selection ? report.master_selection.selected_ids : [];
                    return dispatch('getRiskAssessmentData', risk_assessment_ids)
                case ReportType.IncidentReport:
                    return dispatch('getIncidentData');
                case ReportType.EventReport:
                    return dispatch('getEventData', state.report.master_id != null ? [state.report.master_id] : []);
                case ReportType.MultipleEvent:
                    let event_ids = report.master_selection ? report.master_selection.selected_ids : [];
                    return dispatch('getEventData', event_ids);
            }
        } else { // is a template
            switch (state.report.lku_report_type_id) {
                case ReportType.RiskAssessment:
                case ReportType.MultipleRiskAssessment:
                    return dispatch('getRiskAssessmentData', [])
                case ReportType.IncidentReport:
                    return dispatch('getIncidentData');
                case ReportType.EventReport:
                case ReportType.MultipleEvent:
                    return dispatch('getEventData', [])
            }
        }
        return state.report;
    },
    async newReport({ dispatch, commit }: any, data: { from: IReport, isTemplate: boolean, masterId: number | null, reportType: ReportType; }) {
        let report = cloneDeep(data.from);
        report.id = -1;
        //report.name = 'New Report Layout';
        //if (data.from.id > 0) {
            //report.name = report.name + ' from ' + data.from.name;
        //}
        report.is_template = data.isTemplate;
        report.master_id = data.masterId;
        report.lku_report_type_id = data.reportType;
        return dispatch('setReport', report);
    },
    async getIncidentData({ dispatch, commit }: any, payload: { moduleIndex: number | undefined } | undefined): Promise<void> {
        store.commit('common/isWorkingStack', true);
        return Promise.all([
            CustomFieldService.getCustomFields(TableNames.Incidents, null, null),
            CustomFieldService.getCustomFields(TableNames.People, null, null),
            CustomFieldService.getCustomFields(TableNames.CorrectiveActions, null, null),
            CustomFieldService.getCustomFields(TableNames.IncidentInvestigations, null, null),
        ])
            .then((values: any[]) => {
                commit('updateData', {
                    fields: {
                        [TableNames.Incidents]: values[0],
                        [TableNames.People]: values[1],
                        [TableNames.CorrectiveActions]: values[2],
                        [TableNames.IncidentInvestigations]: values[3],
                    }
                });
                dispatch('updateAllColumns');
            })
            .catch((e: any) => {
                store.commit('common/errorMsg', 'Getting data failed, please try again');
            })
            .finally(() => {
                store.commit('common/isWorkingStack', false);
            });
    },
    getEventData({ dispatch, commit }: any, event_ids: number[]): Promise<void> {
        store.commit('common/isWorkingStack', true);
        return EventService.getEventReportsData(event_ids)
            .then((result: IMultipleEventReportsData) => {
                let data: any = {
                    fields: result.fields,
                    events: result.events
                };
                // get collated data
                data.incidents = [];
                data.issues = [];
                data.issueCriteriaOptions = [];
                for (let id in data.events) {
                    data.incidents.push(...data.events[id].incidents);
                    data.issues.push(...data.events[id].issues);
                    data.issueCriteriaOptions.push(...data.events[id].issueCriteriaOptions);
                }
                data.incidents = uniqBy(data.incidents, 'id');
                data.issues = uniqBy(data.issues, 'id');
                data.issueCriteriaOptions = uniqBy(data.issueCriteriaOptions, 'value');
                commit('updateData', data);
                dispatch('updateAllColumns');
            })
            .catch((e) => {
                store.commit('common/errorMsg', 'Getting data failed, please try again');
            })
            .finally(() => {
                store.commit('common/isWorkingStack', false);
            });
    },
    getRiskAssessmentData({ dispatch, commit }: any, risk_assessment_ids: number[]) {
        store.commit('common/isWorkingStack', true);
        return RiskAssessmentService.getRiskAssessmentReportsData(risk_assessment_ids)
            .then((result: IMultipleRiskAssessmentReportsData) => {
                let data: any = {
                    riskControlStatuses: result.riskControlStatuses,
                    profileResultOptions: result.profileResultOptions,
                    fields: result.fields
                };

                // add fetched data
                data.risk_assessments = result.risk_assessments;
                let rdfs: { [id: string]: IRiskDefinition; } = {};
                for (let id in data.risk_assessments) {
                    rdfs[id] = data.risk_assessments[id].risk_definition;
                }
                data.risk_definitions = rdfs;

                // get collated data
                data.risks = [];
                data.controls = [];
                for (let id in data.risk_assessments) {
                    data.risks.push(...data.risk_assessments[id].risks);
                    data.controls.push(...data.risk_assessments[id].controls);
                }
                data.risks = uniqBy(data.risks, 'id');
                data.controls = uniqBy(data.controls, 'id');

                commit('updateData', data);
                dispatch('updateAllColumns');
            })
            .catch(() => {
                store.commit('common/errorMsg', 'Getting data failed, please try again');
            })
            .finally(() => {
                store.commit('common/isWorkingStack', false);
            });
    },
    setMasterSelection({ dispatch, commit }: any, selection: { selected_ids: number[], filter_state: any; }) {
        commit('setReportAttribute', { attribute: 'master_selection', value: selection });
        if (state.report.lku_report_type_id == ReportType.MultipleRiskAssessment) {
            dispatch('getRiskAssessmentData', selection.selected_ids);
        } else if (state.report.lku_report_type_id == ReportType.MultipleEvent) {
            dispatch('getEventData', selection.selected_ids);
        }
    },
    addModule({ dispatch, commit }: any, payload: { report_module: IReportModule, index: number, data: any, isnew: boolean; }): void {
        const code = payload.report_module.lku_report_module_code;
        if (code.substring(0, 9) == 'template_') {
            payload.report_module.lku_report_module!.data.forEach((m: IReportModule, i: number) => {
                dispatch('addModule', { report_module: m, index: payload.index + i, isnew: false });
            });
        } else {
            let reportModule = cloneDeep(payload.report_module);
            //Only add empty detail if the module is new and not being copied from a template.  isnew property must be explicitly set to false otherwise it is true
            const isnew: boolean = (payload.hasOwnProperty("isnew") && payload.isnew === false) ? false : true;
            if(isnew) {
                switch (code) {
                    case ModuleCode.Section:
                        reportModule.data = ReportModuleService.getEmptyReportModuleSection(reportModule.id);
                        break;
                    case ModuleCode.EVIssues:
                        reportModule.report_module_issue_option = ReportModuleService.getEmptyReportModuleIssueOption(reportModule.id);
                        reportModule.field_definition_report_modules = {
                            [TableNames.Issues]: mappedAndPreselectedFields(reportModule.id, TableNames.Issues, code, payload.index)
                        };
                        break;
                    case ModuleCode.RARegister:
                        reportModule.report_module_risk_option = ReportModuleService.getEmptyReportModuleRiskOption(reportModule.id);
                        reportModule.field_definition_report_modules = {
                            [TableNames.Risks]: mappedAndPreselectedFields(reportModule.id, TableNames.Risks, code, payload.index),
                            [TableNames.Controls]: mappedAndPreselectedFields(reportModule.id, TableNames.Controls, code, payload.index)
                        };
                        break;
                    case ModuleCode.CoverPage:
                        reportModule.report_module_cover = ReportModuleService.getEmptyReportModuleCover(reportModule.id);
                        break;
                    case ModuleCode.EVAnalysis:
                    case ModuleCode.RAAnalysis:
                        reportModule.data = ReportModuleService.getEmptyReportModuleAnalysisData();
                        break;
                    case ModuleCode.Comments:
                        reportModule.report_module_description = ReportModuleService.getEmptyReportModuleDescription(reportModule.id);
                        break;
                    case ModuleCode.RPProfile:
                        reportModule.report_module_profile = ReportModuleService.getEmptyReportModuleProfile(reportModule.id);
                        break;
                    case ModuleCode.IRSection:
                        let previousData = Object.assign({}, getModuleInferredData(code, payload.index) as IReportModuleIncidentSectionData, payload.report_module.data != null ? payload.report_module.data : {});
                        reportModule.data = ReportModuleService.getEmptyReportModuleIncidentSectionData(previousData);
                        break;
                    case ModuleCode.EVINDetails:
                    case ModuleCode.EVINRegister:
                    case ModuleCode.IRDetails:
                    case ModuleCode.IRRegister:
                        let inferredData = Object.assign({}, getModuleInferredData(code, payload.index) as IReportModuleIncidentSectionData, payload.report_module.data != null ? payload.report_module.data : {});
                        reportModule.data = ReportModuleService.getEmptyReportModuleIncidentDetailData(inferredData);
                        if ([ModuleCode.EVINDetails, ModuleCode.EVINRegister].includes(code)) {
                            Object.assign(reportModule.data as any, {
                                selection: {
                                    filter_state: IncidentHelperService.getIncidentFilterStateForRecords(RelatedRecordHelper.getRelatedRecordsForEvents(Object.values(state.data.events))),
                                    selected_ids: (inferredData.incidents as IIncident[]).map(i => i.id),
                                }
                            });
                        }
                        reportModule.field_definition_report_modules = {
                            [TableNames.Incidents]: mappedAndPreselectedFields(reportModule.id, TableNames.Incidents, code, payload.index),
                            [TableNames.People]: mappedAndPreselectedFields(reportModule.id, TableNames.People, code, payload.index),
                            [TableNames.CorrectiveActions]: mappedAndPreselectedFields(reportModule.id, TableNames.CorrectiveActions, code, payload.index),
                            [TableNames.IncidentInvestigations]: mappedAndPreselectedFields(reportModule.id, TableNames.IncidentInvestigations, code, payload.index),
                        };
                        break;
                    case ModuleCode.IRAnalysis:
                    case ModuleCode.EVINAnalysis:
                        inferredData = getModuleInferredData(code, payload.index) as IReportModuleIncidentSectionData;
                        reportModule.data = ReportModuleService.getEmptyReportModuleIncidentAnalysisData(inferredData);
                        break;
                }
            }
            commit('insertModule', { report_module: reportModule, index: payload.index });
        }
    },
    changeReportModules({ dispatch, commit }: any, event: any) {
        if (event.added != null && event.added.element) {
            dispatch('addModule', { report_module: event.added.element, index: event.added.newIndex });
        }
        if (event.moved != null && event.moved.element) {
            commit('moveModule', { report_module: event.moved.element, oldIndex: event.moved.oldIndex, newIndex: event.moved.newIndex });
        }
    },
    getEventOptions({ dispatch, commit }: any) {
        if (state.eventOptions.length === 0) {
            store.commit('common/isWorkingStack', true);
            return SelectListService.getHierarchicalEvents('children')
                .then((result: IHierarchicalSelectListItem[]) => {
                    commit('eventOptions', result);
                })
                .catch(() => {
                    store.commit('common/errorMsg', 'Getting Events failed, please try again.');
                })
                .finally(() => {
                    store.commit('common/isWorkingStack', false)
                });
        } else {
            return Promise.resolve();
        }
    },
    getRiskAssessmentOptions({ dispatch, commit }: any): Promise<void> {
        if (state.riskAssessmentOptions.length === 0) {
            store.commit('common/isWorkingStack', true);
            return SelectListService.getHierarchicalRiskAssessments('children')
                .then((result: IHierarchicalSelectListItem[]) => {
                    commit('riskAssessmentOptions', result);
                })
                .catch(() => {
                    store.commit('common/errorMsg', 'Getting Risk Assessments failed, please try again.');
                })
                .finally(() => store.commit('common/isWorkingStack', false));
        } else {
            return Promise.resolve();
        }
    },
    updateAllColumns({ dispatch, commit }: any) {
        switch (state.report.lku_report_type_id) {
            case ReportType.RiskAssessment:
            case ReportType.MultipleRiskAssessment:
                dispatch('updateColumns', { module: ModuleCode.RARegister, tables: [TableNames.Risks, TableNames.Controls] });
                break;
            case ReportType.EventReport:
            case ReportType.MultipleEvent:
                dispatch('updateColumns', { module: ModuleCode.EVIssues, tables: [TableNames.Issues] });
                dispatch('updateColumns', { module: ModuleCode.EVINDetails, tables: [TableNames.Incidents, TableNames.People, TableNames.CorrectiveActions, TableNames.IncidentInvestigations] });
                dispatch('updateColumns', { module: ModuleCode.EVINRegister, tables: [TableNames.Incidents, TableNames.People, TableNames.CorrectiveActions, TableNames.IncidentInvestigations] });
                break;
            case ReportType.IncidentReport:
                dispatch('updateColumns', { module: ModuleCode.IRDetails, tables: [TableNames.Incidents, TableNames.People, TableNames.CorrectiveActions, TableNames.IncidentInvestigations] });
                dispatch('updateColumns', { module: ModuleCode.IRRegister, tables: [TableNames.Incidents, TableNames.People, TableNames.CorrectiveActions, TableNames.IncidentInvestigations] });
                break;
        }
    },
    updateColumns({ dispatch, commit }: any, params: { module: ModuleCode, tables: TableNames[] }) {
        if (state.report && state.report.report_modules && state.report.report_modules.length > 0) {
            let report_modules = cloneDeep(state.report.report_modules);
            report_modules
                .filter((rm) => rm.lku_report_module_code === params.module)
                .forEach((rReportModule, rReportModuleIndex) => {
                    if (!rReportModule.field_definition_report_modules) {
                        rReportModule.field_definition_report_modules = {};
                    }
                    params.tables.forEach((table: TableNames) => {
                        let fdrms = rReportModule.field_definition_report_modules![table] != null ? rReportModule.field_definition_report_modules![table] : [];
                        for (const field of store.getters['reports/displayFields'](table, rReportModule.lku_report_module_code, rReportModuleIndex)) {
                            const label: string = field.label && isString(field.label) ? field.label : field.name && isString(field.name) ? startCase(field.name) : 'Unknown';
                            const foundFdrmIndex: number = fdrms.findIndex((fdrm) => fdrm.field_definition_id === field.id);
                            if (foundFdrmIndex >= 0) {
                                fdrms[foundFdrmIndex].label = label;
                                fdrms[foundFdrmIndex].lku_master_table_id = field.lku_master_table_id;
                                fdrms[foundFdrmIndex].field_definition_name = field.name;
                                fdrms[foundFdrmIndex].selected = true;
                            } else {
                                fdrms.push({
                                    field_definition_id: field.id,
                                    report_module_id: rReportModule.id,
                                    lku_master_table_id: field.lku_master_table_id,
                                    field_definition_name: field.name,
                                    display_sequence: fdrms.length,
                                    label: label,
                                    selected: false,
                                    parent_field_definition_id: null,
                                });
                            }
                        }
                        rReportModule.field_definition_report_modules![table] = fdrms;
                    });
                });
            commit('setModules', report_modules);
        }
    },
}
const mutations = {
    setReport(state: IState, report: IReport) {
        state.report = report;
        state.report.report_modules = formatModules(state.report.report_modules);
    },
    setReportAttribute(state: IState, payload: { attribute: keyof IReport, value: any; }) {
        (state.report as any)[payload.attribute] = payload.value;
    },
    setModules(state: IState, report_modules: IReportModule[]) {
        state.report.report_modules = formatModules(report_modules);
    },
    updateData(state: IState, newData: TReportData) {
        let clonedData = cloneDeep(state.data);
        Object.assign(clonedData, newData);
        state.data = clonedData;
        if (state.report && state.report.report_modules) {
            state.report.report_modules.forEach((report_module) => {
                if (report_module.lku_report_module_code == 'section') {
                    if (report_module.data && report_module.data.hasOwnProperty('selection')) {
                        if ([ReportType.EventReport, ReportType.MultipleEvent].includes(state.report.lku_report_type_id)) {
                            (report_module.data as any).issues = state.data.issues.filter((issue) => {
                                return (report_module.data as any).selection?.selected_ids.includes(issue.id);
                            });
                        } else if ([ReportType.RiskAssessment, ReportType.MultipleRiskAssessment].includes(state.report.lku_report_type_id)) {
                            (report_module.data as any).risks = state.data.risks.filter((risk) => {
                                return (report_module.data as any).selection?.selected_ids.includes(risk.id);
                            });
                        }
                    }
                }
            })
        }
    },
    insertModule(state: IState, payload: { report_module: IReportModule, index: number; }): void {
        state.report.report_modules.splice(payload.index, 0, applyMappedAndPreSelectedFields(formatModule(payload.report_module, payload.index), payload.index));
    },
    moveModule(state: IState, payload: { report_module: IReportModule, oldIndex: number, newIndex: number }): void {
        const code = payload.report_module.lku_report_module_code;
        state.report.report_modules.splice(payload.oldIndex, 1);
        state.report.report_modules.splice(payload.newIndex, 0, payload.report_module);
    },
    removeModule(state: IState, index: number) {
        state.report.report_modules.splice(index, 1);
    },
    updateModule(state: IState, payload: { module: any, moduleIndex: number }) {
        const usingModuleIndex = payload.moduleIndex != null && payload.moduleIndex >= 0 && payload.moduleIndex < state.report.report_modules.length;
        if (usingModuleIndex) {
            const result = Object.assign({}, state.report.report_modules[payload.moduleIndex!], payload.module);
            state.report.report_modules.splice(payload.moduleIndex, 1, result);
        } else { // updating default data
            state.data = Object.assign({}, state.data, payload.module.data);
        }
    },
    clear(state: IState): void {
        state.report = ReportService.getEmptyReport();
        state.data = emptyData;
        state.eventOptions = [];
        state.riskAssessmentOptions = [];
    },
    eventOptions(state: IState, newOptions: IHierarchicalSelectListItem[]) {
        state.eventOptions = newOptions;
    },
    riskAssessmentOptions(state: IState, newOptions: IHierarchicalSelectListItem[]) {
        state.riskAssessmentOptions = newOptions;
    },
    applySelection(state: IState, selection: any) {
        switch (state.report.lku_report_type_id) {
            case ReportType.IncidentReport:
                state.report.report_modules.forEach(module => {
                    if (module.lku_report_module_code == ModuleCode.IRSection) {
                        module.data = Object.assign(module.data as any, selection);
                    } else if (module.data && module.data.hasOwnProperty('useOwnIncidents') && (module.data as any).useOwnIncidents == true) {
                        module.data = Object.assign(module.data as any, selection);
                    }
                });
        }
    },
};
const getModuleInferredData: (moduleCode: ModuleCode, moduleIndex: number) => any = (moduleCode: ModuleCode, moduleIndex: number) => {
    switch (state.report.lku_report_type_id) {
        case ReportType.IncidentReport:
            for (let i = moduleIndex - 1; i >= 0; i--) {
                let rm = state.report.report_modules[i];
                if (ModuleCode.IRSection == rm.lku_report_module_code && rm.data != null) {
                    return Object.assign({}, state.data, rm.data);
                }
            }
            // else if not found get defaults
            return state.data;
        case ReportType.EventReport:
        case ReportType.RiskAssessment:
        case ReportType.MultipleEvent:
        case ReportType.MultipleRiskAssessment:
            if (state.report.is_template) {
                return state.data;
            } else {
                for (let i = moduleIndex - 1; i >= 0; i--) {
                    let rm = state.report.report_modules[i];
                    if (ModuleCode.Section == rm.lku_report_module_code && rm.data != null) {
                        return Object.assign({}, state.data, rm.data);
                    }
                }
                // else if not found get defaults
                return state.data;
            }
    }
    return state.data;
}

const getModuleInferred: (dataAttributeString: string | null, moduleCode: ModuleCode, moduleIndex: number, fallback?: any) => any =
    (dataAttributeString: string | null, moduleCode: ModuleCode, moduleIndex: number, fallback: any = undefined) => {
        const dataAttributeKey = dataAttributeString == null ? [] : dataAttributeString.split('.');
        let data = getModuleInferredData(moduleCode, moduleIndex) as any;
        dataAttributeKey.some(key => {
            if (data != null && data.hasOwnProperty(key)) {
                data = data[key];
            } else {
                data = fallback;
                return true; // short circuit
            }
        });
        return data
    }

const applyMappedAndPreSelectedFields = (report_module: IReportModule, index: number, preselectedCallback: null | ((field: IFieldDefinitionReportModule) => boolean) = null) => {
    const code = report_module.lku_report_module_code;
    switch (report_module.lku_report_module_code) {
        case ModuleCode.EVIssues:
            report_module.field_definition_report_modules = { [TableNames.Issues]: mappedAndPreselectedFields(report_module.id, TableNames.Issues, code, index, preselectedCallback) };
            break;
        case ModuleCode.RARegister:
            report_module.field_definition_report_modules = {
                [TableNames.Risks]: mappedAndPreselectedFields(report_module.id, TableNames.Risks, code, index, preselectedCallback),
                [TableNames.Controls]: mappedAndPreselectedFields(report_module.id, TableNames.Controls, code, index, preselectedCallback)
            };
            break;
        case ModuleCode.EVINDetails:
        case ModuleCode.EVINRegister:
            (report_module.data as any).selection = IncidentHelperService.getInitialIncidentSelectionForEventId(getModuleInferred('event.id', code, index) as number);
            break;
    }
    return report_module;
}

const mappedFields = (reportModuleId: number, tableName: TableNames, moduleCode: ModuleCode, moduleIndex: number) => {
    let displayFields = store.getters['reports/displayFields'](tableName, moduleCode, moduleIndex);
    let mappedFields: IFieldDefinitionReportModule[] = [];
    if (displayFields && displayFields.length > 0) {
        mappedFields = displayFields.map((fd: IFieldDefinition, index: number) => {
            return {
                field_definition_id: fd.id,
                field_definition: fd,
                report_module_id: reportModuleId,
                lku_master_table_id: fd.lku_master_table_id,
                field_definition_name: fd.name,
                display_sequence: index,
                label: fieldLabel(fd),
                selected: true,
                parent_field_definition_id: null,
            };
        });
    }
    return mappedFields;
}

const mappedAndPreselectedFields = (reportModuleId: number, tableName: TableNames, moduleCode: ModuleCode, moduleIndex: number, selectedCallback: null | ((field: IFieldDefinitionReportModule) => boolean) = null) => {
    let fields = mappedFields(reportModuleId, tableName, moduleCode, moduleIndex);
    // select fields based on layouts
    let result: IFieldDefinitionReportModule[] = [];
    let layout: ILayoutItem[] = [];
    if (selectedCallback != null) {
        fields.forEach((field, index) => {
            if (selectedCallback(field)) {
                result.push(fields.splice(index, 1)[0]);
            }
        });
    } else {
        const addLayoutsToResult = (items: ILayoutItem[]) => {
            items.forEach((item: ILayoutItem) => {
                const index = fields.findIndex((field) => field.field_definition_id == item.field_definition_id);
                if (index >= 0) {
                    // push the field to result and simultaneously remove from mappedFields
                    result.push(fields.splice(index, 1)[0]);
                    if (item.children && item.children.length > 0) {
                        addLayoutsToResult(item.children);
                    }
                }
            });
        };
        switch (moduleCode) {
            case ModuleCode.IRRegister:
            case ModuleCode.EVINRegister:
            case ModuleCode.EVIssues:
            case ModuleCode.RARegister:
                layout = (getModuleInferred('fields.' + tableName + '.list', moduleCode, moduleIndex, []) as any);
                addLayoutsToResult(layout);
                break;
            case ModuleCode.IRDetails:
            case ModuleCode.EVINDetails:
                layout = (getModuleInferred('fields.' + tableName + '.detail', moduleCode, moduleIndex, []) as any);
                addLayoutsToResult(layout);
                break;
        }
    }

    // add remaining fields
    fields.forEach((field) => {
        field.selected = false;
        result.push(field);
    });
    return result;
}

const fieldLabel = (fd: IFieldDefinition) => {
    return fd.label && isString(fd.label) ? fd.label : fd.name && isString(fd.name) ? startCase(fd.name) : 'Unknown';
}

const formatModules = (report_modules: IReportModule[]) => {
    return cloneDeep(report_modules)?.map(formatModule);
};

const formatModule = (reportModule: IReportModule, reportModuleIndex: number) => {
    const code = reportModule.lku_report_module_code;
    if (code === ModuleCode.CoverPage && !reportModule.report_module_cover) {
        reportModule.report_module_cover = ReportModuleService.getEmptyReportModuleCover(reportModule.id);
    } else if (code === ModuleCode.Section && !reportModule.report_module_section) {
        reportModule.report_module_section = ReportModuleService.getEmptyReportModuleSection(reportModule.id);
    } else if (code === ModuleCode.IRSection) {
        reportModule.data = Object.assign({}, state.data, reportModule.data);
    } else if (code === ModuleCode.EVIssues) {
        if (!reportModule.field_definition_report_modules) {
            reportModule.field_definition_report_modules = { [TableNames.Issues]: mappedFields(reportModule.id, TableNames.Issues, reportModule.lku_report_module_code, reportModuleIndex) };
        }
        if (!reportModule.report_module_issue_option) {
            reportModule.report_module_issue_option = ReportModuleService.getEmptyReportModuleIssueOption(reportModule.id);
        }
    } else if (code === ModuleCode.RARegister) {
        if (!reportModule.field_definition_report_modules) {
            reportModule.field_definition_report_modules = {
                [TableNames.Risks]: mappedFields(reportModule.id, TableNames.Risks, reportModule.lku_report_module_code, reportModuleIndex),
                [TableNames.Controls]: mappedFields(reportModule.id, TableNames.Controls, reportModule.lku_report_module_code, reportModuleIndex)
            };
        }
        if (!reportModule.report_module_risk_option) {
            reportModule.report_module_risk_option = ReportModuleService.getEmptyReportModuleRiskOption(reportModule.id);
        }
    } else if (code === ModuleCode.RPProfile && !reportModule.report_module_profile) {
        reportModule.report_module_profile = ReportModuleService.getEmptyReportModuleProfile(reportModule.id);
    } else if (code === ModuleCode.Comments && !reportModule.report_module_description) {
        reportModule.report_module_description = ReportModuleService.getEmptyReportModuleDescription(reportModule.id);
    }
    return reportModule;
}

/** Check to make sure that if a module is specified then it has an appropriate module data object if applicable */

export const reports = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
