import { IIncident } from "@/modules/Incidents/interfaces/IncidentInterface";
import {
    IReportModuleAnalysisData,
    IReportModuleCover,
    IReportModuleDescription,
    IReportModuleIncidentAnalysisData,
    IReportModuleIncidentDetailsData,
    IReportModuleIncidentSectionData,
    IReportModuleIssueOption,
    IReportModuleProfile,
    IReportModuleRiskOption,
    IReportModuleSection,
    PageLayout
} from "../interfaces/ReportInterface";
import ChartService from "./ChartService";

export default abstract class ReportModuleService {
    static getEmptyReportModuleIssueOption(reportModuleId: number): IReportModuleIssueOption {
        let result: IReportModuleIssueOption = {
            id: -new Date().valueOf(),
            report_module_id: reportModuleId,
            sort_by: null,
            sort_direction: "asc",
            sort_by_1: null,
            sort_direction_1: "asc",
            show_criteria: [],
        };
        return result;
    }

    static getEmptyReportModuleRiskOption(reportModuleId: number): IReportModuleRiskOption {
        let result: IReportModuleRiskOption = {
            id: -new Date().valueOf(),
            report_module_id: reportModuleId,
            user_id: null,
            external_party_id: null,
            sort_by: null,
            sort_direction: "asc",
            sort_by_1: null,
            sort_direction_1: "asc",
            show_criteria: [],
        };
        return result;
    }

    static getEmptyReportModuleSection(reportModuleId: number): IReportModuleSection {
        let result: IReportModuleSection = {
            id: -new Date().valueOf(),
            report_module_id: reportModuleId,
            heading: "",
            introduction: "",
            selection: {
                filter_state: {
                    filter: {},
                    responsibleOptions: [],
                },
                selected_ids: []
            },
            issues: [],
        };
        return result;
    }

    static getEmptyReportModuleCover(reportModuleId: number): IReportModuleCover {
        let result: IReportModuleCover = {
            id: -new Date().valueOf(),
            report_module_id: reportModuleId,
            content: "", //<div style="border:1px solid gray; position: absolute; width:210mm; height:297mm;"></div>
            background_img_file_id: null,
        };
        return result;
    }

    static getEmptyReportModuleDescription(reportModuleId: number): IReportModuleDescription {
        let result: IReportModuleDescription = {
            id: -new Date().valueOf(),
            report_module_id: reportModuleId,
            description: "",
        };
        return result;
    }

    static getEmptyReportModuleIncidentSectionData(data: any = {}): IReportModuleIncidentSectionData {
        return Object.assign(
            {
                heading: "",
                introduction: "",
                orientation: "portrait",
                incidents: [] as IIncident[],
                selection: null,
                field_definitions: {},
                layouts: {},
            },
            data
        );
    }

    static getEmptyReportModuleIncidentDetailData(data: any = {}): IReportModuleIncidentDetailsData {
        return Object.assign(
            {
                useOwnIncidents: false,
                incidents: [] as IIncident[],
                selection: null,
                pageBreakAfterEach: true,
                fontSize: 8,
                attachOnSepPage: true,
                fields: {},
            },
            data
        );
    }

    static getEmptyReportModuleIncidentAnalysisData(data: any = undefined): IReportModuleIncidentAnalysisData {
        return {
            useOwnIncidents: !(data && data.incidents && data.incidents.length > 0),
            incidents: data.incidents as IIncident[],
            selection: null,
            charts: [ChartService.getEmptyChart(1)],
            lku_page_layout_id: PageLayout.Portrait1,
            fields: {},
        };
    }

    static getEmptyReportModuleAnalysisData(): IReportModuleAnalysisData {
        return {
            charts: [ChartService.getEmptyChart(1)],
            lku_page_layout_id: PageLayout.Portrait1,
        };
    }

    static getEmptyReportModuleProfile(reportModuleId: number): IReportModuleProfile {
        let result: IReportModuleProfile = {
            id: -new Date().valueOf(),
            report_module_id: reportModuleId,
            profile_id: -new Date().valueOf(),
        };
        return result;
    }
}
