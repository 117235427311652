import { AxiosResponse } from "axios";
import { IDirectoryItem } from "../interfaces/DirectoryItemInterface";
import ApiService from "./ApiService";

export default abstract class DirectoryService {
    /**
     * Get folder data from database
     * @param folderTypes
     */
    static async getDirectory(folderType: string, licenseLevel: string): Promise<IDirectoryItem[]> {
        const resource: string = "/api/directory/" + folderType;
        try {
            const response: AxiosResponse<any> = await ApiService.get(resource);
            if (response.data) {
                return response.data;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            let msg = "Get folders failed.";
            if (e.response) {
                msg = e.response.data.message;
            } else if (e.message) {
                msg = e.message;
            }
            throw new Error(msg);
        }
    }
}
