import { AxiosRequestConfig, AxiosResponse } from "axios";
import { Route } from "vue-router";
import store from "../../store";
import { Modules } from "../enums/LookupEnums";
import { IModule, IUserLookup, IUser, IUserData, IUserTeam, PermissionCode } from "../interfaces/UserDataInterface";
import ApiService from "./ApiService";
import router from "../../router";


class AuthenticationError extends Error {
	errorCode: any;
	constructor(errorCode: any, message: string) {
		super(message);
		this.name = this.constructor.name;
		this.message = message;
		this.errorCode = errorCode;
	}
}

const UserService = {
	loggingIn: false,
	loggingOut: false,
	/**
	 * Checks if the system thinks the usr is logged in by checking for user data which is stored in memory.
	 * If the user data is not present the sytem will attempt to retreive it (to cover for cases where we are moving to a new tab or the browser was closed)
	 * If the request comes back unauthorised then the user is logged out.
	 */
	loggedIn: async function (to: Route): Promise<void> {
		try {
			if (to.name === "EventStaffLogin") {
				const token = to.params.token;
				if(token) {
					//Event workforce login
					//make sure the user is logged out if they happened to be logged in as a normal user or workforce user.
					await this.logout();
					//now attach the token to future headers
					ApiService.setToken(token);
					//try get user data
					const resource = "/api/user";
					const userResponse: AxiosResponse<IUserData> = await ApiService.get(resource); // im not sure why it's wrapped in an extra data:
					if (userResponse.data && userResponse.data.id && userResponse.data.name && userResponse.data.email && userResponse.data.extra.current_license_id) {
						store.commit("auth/token", token);
						store.commit("auth/setUserData", userResponse.data);
						localStorage.setItem("ewfToken", token);
						store.commit("auth/setLoggedIn", true);
						router.push("/event-staff/home");
						return;
					}
				} else {
					//trying to login without a token in the url
					router.push("/event-staff-login/failed");
				}
			} else if (to.name === "EventStaffHome") {
				//check for an authorisation header
				const header = ApiService.getToken();
				//if theres no header try and find a token in local storage and add it
				if (!header) {
					const token = localStorage.getItem("ewfToken");
					if (token) {
					    //now attach the token to future headers
						ApiService.setToken(token);
						//try get user data
						const resource = "/api/user";
                        const userResponse: AxiosResponse<IUserData> = await ApiService.get(resource);
                        if (userResponse.data && userResponse.data.id && userResponse.data.name && userResponse.data.email && userResponse.data.extra.current_license_id) {
                            store.commit("auth/token", token);
							store.commit("auth/setUserData", userResponse.data);
							store.commit("auth/setLoggedIn", true);
							return;
						}
					} else {
						//no header or token so show failure
						router.push("/event-staff-login/failed");
					}
				}
			} else {
				//normal login
				//if were in the process of logging in then just return true to avoid unnecessary api calls
				//when loading the user data
				if (this.loggingIn || this.loggingOut) {
					return;
				}
				if (store.getters["auth/loggedIn"]) {
					//make sure we have the user data if not attempt to fetch it
					if (store.getters["auth/currentUserData"]) {
						return;
					} else {
						const resource = "/api/user";
						const userResponse: AxiosResponse<IUserData> = await ApiService.get(resource);
						if (userResponse.data && userResponse.data.id && userResponse.data.name && userResponse.data.email && userResponse.data.extra.current_license_id) {
							store.commit("auth/setUserData", userResponse.data);
							return;
						}
					}
				}
			}

		} catch (e: any) {
			//something went wrong logout the user and return false
			store.commit("common/errorMsg", "Unknown authentication error. Please try again.");
		}
		//if we got here then not logged in so set logged in as false and return false.
		store.commit("auth/setLoggedIn", false);
	},

    /**
     * Get a csrf token and set the axios XSRF header
     *
     */
    getCsrfCookie: async function (): Promise<void> {
        await ApiService.get('/sanctum/csrf-cookie');
        ApiService.setHeader();
    },

	/**
	 *
	 * @param email
	 * @param password
	 * @param lastLoginId the last lilicese id
	 */
	login: async function (email: string, password: string): Promise<IUserData> {
		try {
			//clear any remaining error messages
			store.commit("common/errorMsg", "");
			//set the logging in flag to override logged in check
			this.loggingIn = true;
            await this.getCsrfCookie();
            //login with user credentials - this will set the session cookie
			const loginResource = "/auth/login";
			const loginData = { email: email, password: password };
			await ApiService.post(loginResource, loginData);
			//get user data
			let userResource = "/api/user";
			const lastLicenseId: string | null = localStorage.getItem('lastLicenseId');
			if (lastLicenseId) userResource += '?lli=' + lastLicenseId;
            const userResponse: AxiosResponse<IUserData> = await ApiService.get(userResource);
			if (
                userResponse.data &&
                userResponse.data.id !== null &&
                userResponse.data.name &&
                userResponse.data.email &&
                (userResponse.data.extra.current_license_id || userResponse.data.extra.is_sys_admin)
			) {
                store.commit("auth/setUserData", userResponse.data);
				store.commit("auth/setLoggedIn", true);
			} else {
				throw new AuthenticationError(0, "Invalid User Data Response");
			}
			this.loggingIn = false;
            return userResponse.data;
		} catch (e: any) {
			this.loggingIn = false;
			throw new AuthenticationError(0, "Authentication Failed");
		}
	},

	/**
	 * Logout the current user by logging out from the server, removing user data from the store and resetting the axios headers.
	 *
	 *
	 **/
	async logout() {
		//set the logging out flag to override logged in check
		this.loggingOut = true;
		//Log out of the server by revoking the token which will invalidate the authentication cookie
		const resource = "/auth/logout";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
		} catch (e: any) { }
		this.logoutLocal();
		this.loggingOut = false;
	},
	/**
	 * logs out locally without sending anything to the server
	 */
	logoutLocal() {
		//Delete user data and reset the login status in the store
		store.commit("auth/setAuthStart");
		//reset axios headers
		ApiService.removeHeader();
		//remove the Authorisation header
		ApiService.removeToken();
		//remove token from local storage
		localStorage.removeItem("ewfToken");
		//Set the logged in flag to false, definately loggend out for this sessionStorage.
		store.commit("auth/setLoggedIn", false);
	},
	recoverPassword: async function (email: string): Promise<any> {
        // need to get csrf cookie incase user tries to recover password without trying to login
        await this.getCsrfCookie();
        await ApiService.post('/auth/password/recover', {'email': email});
	},
    resetPassword: async function(token: string, email: string, newPassword: string, confirmPassword: string) {
        const data = {
            email: email,
            token: token,
            password: newPassword,
            password_confirmation: confirmPassword
        };
		await ApiService.post('/auth/password/reset', data);
	},
    changePassword: async function (oldPassword: string, newPassword: string, confirmPassword: string) {
		const data = {
			password_old: oldPassword,
            password: newPassword,
            password_confirmation: confirmPassword,
		};
		await ApiService.post('/auth/password/change', data);
	},
	sendVerifyEmail: async function (userId: string): Promise<any> {
		const recoverRequestConfig: AxiosRequestConfig = {
			method: "get",
			url: "/api/invite_user/" + userId.toString(),
		};
		await ApiService.customRequest(recoverRequestConfig);
	},
	updateCurrentLicense: async function (licenseId: number) {
		const updateRequestConfig: AxiosRequestConfig = {
			method: "put",
			url: "/api/user/license/" + licenseId.toString(),
		};
		try {
			//returns a user response after the changes have been made
            const userResponse: AxiosResponse<IUserData> = await ApiService.customRequest(updateRequestConfig);
            store.commit("auth/setUserData", userResponse.data);
		} catch (e: any) {
			throw e;
		}
	},
    async saveUser(user: IUser): Promise<IUser> {
        let resource = '/api/license/user';
        let response: AxiosResponse<any> | null = null;
        if (user.id > 0) { // existing user
		    response = await ApiService.put(resource, user);
        } else { // new user
            response = await ApiService.post(resource, user);
        }
        if (response?.data?.data) {
            return response.data.data;
        } else {
            throw new Error('Invalid Response saving user');
        }
    },

    /**
	 * Search the system for a user with the given email
     * If they exist it responds with their id and licenses
     * If not it returns null
	 * @param email
	 */
	async lookupEmail (email: string): Promise<IUserLookup | undefined> {
		const resource: string = '/api/user/email/' + email.toString();
		const response: AxiosResponse<any> = await ApiService.get(resource);
		return response?.data?.data;
	},

    async updateUserProfile(timezone: string, mobile: string): Promise<any> {
		const resource = "/api/user/profile";
        const data = {
            timezone: timezone,
            mobile: mobile,
        }
		return ApiService.put(resource, data);
	},

	// ----------------------------  Below are calls from license user admin -----------------------

	async getUsersForCurLicense(includeInactive: boolean = false): Promise<IUser[]> {
		const resource: string = "/api/users?inactive=" + includeInactive;
        const response: AxiosResponse<any> = await ApiService.get(resource);
        if (response.data?.data) {
            return response.data.data;
        } else {
            throw new Error("Invalid Response getting users");
        }
	},
    // System admin call
    async getUsersForLicense(licenseId: number, includeInactive: boolean = false): Promise<IUser[]> {
		const resource: string = "/api/users/" + licenseId + "?inactive=" + includeInactive;
        const response: AxiosResponse<any> = await ApiService.get(resource);
        if (response.data?.data) {
            return response.data.data;
        } else {
            throw new Error("Invalid Response getting users");
        }
	},
	async getUser(userId: number): Promise<IUser> {
		const resource: string = "/api/user/" + userId.toString();
        const response: AxiosResponse<any> = await ApiService.get(resource);
        if (response.data?.data) {
            return response.data.data;
        } else {
            throw new Error("Invalid Response getting users");
        }
	},

	async updateUserData(): Promise<void> {
		const resource1 = "/api/user";
        const userResponse: AxiosResponse<IUserData> = await ApiService.get(resource1);
        if (userResponse.data && userResponse.data.id && userResponse.data.name && userResponse.data.email && userResponse.data.extra.current_license_id) {
            store.commit("auth/setUserData", userResponse.data);
		}
	},
};

const PermissionService = {
	get userData(): IUserData {
		return store.getters["auth/currentUserData"];
	},

	get licenseName(): string {
		if (this.userData && this.userData.extra && this.userData.extra.current_license_name) {
			return this.userData.extra.current_license_name;
		} else return "";
	},

	get modules(): IModule[] {
		if (this.userData && this.userData.extra && this.userData.extra.modules) {
			return this.userData.extra.modules;
		} else return [];
	},

	get permissions(): PermissionCode[] {
		if (this.userData && this.userData.extra && this.userData.extra.permissions) {
			return this.userData.extra.permissions;
		} else return [];
	},

	get isSysAdmin(): boolean {
		return !!(this.userData && this.userData.extra && this.userData.extra.is_sys_admin);
	},

	get isLicenseAdmin(): boolean {
		return !!(this.userData && this.userData.extra && this.userData.extra.is_license_admin);
	},

	get userId(): number | undefined {
		return this.userData?.id;
	},

	isInTeam(teamId: number): boolean {
		return !!(
			this.userData &&
			this.userData.extra &&
			this.userData.extra.teams &&
			this.userData.extra.teams &&
			this.userData.extra.teams.some((team: IUserTeam) => {
				return team.id === teamId;
			})
		);
	},

	hasModule(code: Modules): boolean {
		return this.modules.some((mod: IModule) => mod.id === code);
	},

	hasPermission(code: PermissionCode): boolean {
		return this.permissions.includes(code);
	},
};

export default UserService;
export { PermissionService, UserService, AuthenticationError };

