import { IHierarchicalSelectListItem, ISelectListItem } from "../interfaces/SelectListItemInterface";
import { FieldTypes } from "../enums/LookupEnums";
import { IDdValue, IFieldDefinition, ILayoutItem, ILayoutItemConditionGroup } from "../interfaces/CustomFieldInterface";
import { ChartFieldType } from "../enums/ChartEnum";
import { HierarchicalSelectListHelper } from "./HierarchicalSelectListHelper";
import { cloneDeep } from "lodash";

/**
 *
 * @param type the field type
 * @returns all possible operators for the field type
 */
const operators: (type: FieldTypes) => ISelectListItem[] = (type: string) => {
    let displaySeq = 0;

    //get the appropriate operators for the field type
    let result: ISelectListItem[] = [];
    switch (type) {
        case FieldTypes.Boolean:
            result = [{
                        value:'=',
                        text:"is",
                        display_sequence: displaySeq++,
            },
                    {
                        value:'!=',
                        text:"isn't",
                        display_sequence: displaySeq++
                    }];
            break;

        case FieldTypes.Date:
            //fall through
        case FieldTypes.DateTime:
            //fall through
        case FieldTypes.Decimal:
            //fall through
        case FieldTypes.Currency:
            //fall through
        case FieldTypes.Integer:
            result = [{
                value: '=',
                        text:'is',
                        display_sequence: displaySeq++,
            },
                    {
                        value:'!=',
                        text:'isn\'t',
                        display_sequence: displaySeq++
                    },
                    {
                        value:'<',
                        text:'less than',
                        display_sequence: displaySeq++
                    },
                    {
                        value:'<=',
                        text:'less than or equal to',
                        display_sequence: displaySeq++
                    },
                    {
                        value:'>',
                        text:'greater than',
                        display_sequence: displaySeq++
                    },
                    {
                        value:'>=',
                        text:'greater than or equal to',
                        display_sequence: displaySeq++
                    }];
            break;

        case FieldTypes.String:
            //fall through
        case FieldTypes.Text:
            result = [{
                        value:'=',
                        text:"is",
                        display_sequence: displaySeq++,
            },
                    {
                        value:'!=',
                        text:"isn't",
                        display_sequence: displaySeq++
                    }];
            break;

        case FieldTypes.DropdownFd:
            //fall through
        case FieldTypes.DropdownSys:
            //fall through
        case FieldTypes.HierarchicalDdFd:
            //fall through
        case FieldTypes.HierarchicalDdSys:
            //fall through
        case FieldTypes.HierarchicalDdMultiFd:
            //fall through
        case FieldTypes.HierarchicalDdMultiSys:
            result = [{
                        value:'=',
                        text:"is",
                        display_sequence: displaySeq++,
            },
                    {
                        value:'!=',
                        text:"isn't",
                        display_sequence: displaySeq++
                    }];
            break;
    }

    result.push({
                    value:'!null',
                    text:"is set.",
                    display_sequence: displaySeq++,
    },
                {
                    value:'null',
                    text:"isn't set.",
                    display_sequence: displaySeq++
                });

    // otherwise
    return result;
};

export { operators };

/**
 *
 * @param modelValue the left-hand side of the comparison (from the object being displayed)
 * @param conditionValue the right-hand side of the comparison (from the conditions)
 * @param operator
 * @returns whether the condition is true
 */
const compare = (modelValue: any, conditionValue: any, operator: string | null) => {
    // let allOperators =  ['=',
    //                     '!=',
    //                     '<',
    //                     '<=',
    //                     '>',
    //                     '>=',
    //                     '!null',
    //                     'null'];

    switch (operator) {
        case '!null':
            return modelValue != null;
        case 'null':
            return modelValue == null;
        case '=':
            return modelValue == conditionValue;
        case '!=':
            return modelValue != conditionValue;
        case '<':
            return modelValue < conditionValue;
        case '<=':
            return modelValue <= conditionValue;
        case '>':
            return modelValue > conditionValue;
        case '>=':
            return modelValue >= conditionValue;
    }
    // the operator is unknown
    return false;
};

/**
 *
 * @param model the object being displayed
 * @param conditions the conditions for a field for the objects field definitions
 * @returns the result (show/hide) from the conditions
 */
const isFieldVisibleFromConditions = (model: any, conditionGroups: ILayoutItemConditionGroup[] | null | undefined, hiddenByDefault: boolean, layout_items: ILayoutItem[]) => {

    if (conditionGroups == null || conditionGroups.length == 0) {
        // no conditions
        return !hiddenByDefault;
    }

    // OR the groups, exit on first group to be true
    groupLoop: for (let group of conditionGroups) {
        // AND the conditions, jump to next group on first false condition
        for (let condition of group.conditions) {
            if (condition.condition_on_id) {
                const layout_item = findLayoutItemByFieldDefinitionId(condition.condition_on_id, layout_items);
                if (layout_item) {
                    let value = getFieldValue(model, layout_item.field_definition);
                    if (!compare(value, condition.value, condition.operator)) {
                        continue groupLoop;
                    }
                }
            }
            else {
                // there's been a bad condition, this code should never execute
                continue groupLoop;
            }
        }
        // if here then this group has passed all conditions
        return hiddenByDefault;
    }
    // none of the group's conditions succeeded, use default
    return !hiddenByDefault;
};

export { isFieldVisibleFromConditions };


/**
 *
 * @param field
 * @returns the data type (discrete, continuous) for use in charts
 */
const getChartFieldType = (field: IFieldDefinition) => {
    switch(field.lku_field_type_id) {
        case FieldTypes.Boolean:
        case FieldTypes.DropdownFd:
        case FieldTypes.DropdownSys:
        case FieldTypes.HierarchicalDdFd:
        case FieldTypes.HierarchicalDdMultiFd:
        case FieldTypes.HierarchicalDdSys:
        case FieldTypes.HierarchicalDdMultiSys:
            return ChartFieldType.Categorical;
        case FieldTypes.Date:
        case FieldTypes.DateTime:
            //return ChartFieldType.DateTime;
        case FieldTypes.Decimal:
        case FieldTypes.Integer:
        case FieldTypes.Currency:
            return ChartFieldType.Numeric;
        case FieldTypes.Custom:
            return ChartFieldType.Custom;
        case FieldTypes.String:
        case FieldTypes.Text:
        case FieldTypes.Table:
        default:
            return ChartFieldType.None;
    }
};

export { getChartFieldType };

const getFieldValue = (record: any, field: IFieldDefinition | null | undefined, force_result: boolean = false) => {
    if (field == null || !field.name || field.id < 0) {
        return null;
    }
    let key = field.name;
    if (field.lku_field_category_id == 'custom')
    {
        key = ("@" + field.id);
    }
    let rawValue = record[key];
    if ( force_result == false) {
        return rawValue;
    }

    // else provide a default value
    // not implemented
}

export { getFieldValue };

/**
 * Determines whether a field type is a dropdown type
 * @param lkuFieldTypeId string the field type code
 * @returns boolean
 */
const fieldTypeIsDropdown = (lkuFieldTypeId: string): boolean => {
    return ["dd_fd", "dd_sys", "hdd_sys", "dda_fd", "ddm_fd", "ddm_sys", "ddma_fd", "hdd_fd", "shdd_sys", "hdda_fd", "hddm_fd", "hddm_sys", "hddma_fd"].includes(lkuFieldTypeId);
}

export { fieldTypeIsDropdown };

type ISelected = (string | number | boolean)[] | string | number | undefined | null | boolean;
type IFound = IHierarchicalSelectListItem | ISelectListItem | IDdValue | undefined;
export const formatDropdown = (selected: ISelected, options: IHierarchicalSelectListItem[] | ISelectListItem[], onlyAncestors: boolean = false, placeholder: string = 'None'): string => {
    selected = cloneDeep(selected);
    if (selected != null && !Array.isArray(selected)) {
        selected = [selected as string | number | boolean];
    } else if (selected == null) {
        selected = [];
    }
    if (onlyAncestors) {
        // reduce to only top selected items
        for (let value of selected) {
            const foundItem: IFound = findDropdown(value, options);
            if (foundItem && HierarchicalSelectListHelper.itemHasChildren(foundItem)) {
                let childrenValues = HierarchicalSelectListHelper.flattenedChildrenValues(foundItem as IHierarchicalSelectListItem);
                selected.filter((x) => !childrenValues.some((y) => x == y || Number(x) == Number(y)));
            }
        }
    }
    let result: string[] = [];
    for (let value of selected) {
        const foundItem: IFound = findDropdown(value, options);
        if (foundItem) {
            result.push(foundItem.text);
        }
    }
    return result.length == 0 ? placeholder : result.join(', ');
}

const findDropdown = (value: number | string | boolean, options: IHierarchicalSelectListItem[] | ISelectListItem[]) => {
    return HierarchicalSelectListHelper.findItem(
        (item: IHierarchicalSelectListItem | ISelectListItem | IDdValue) => {
            return item.value != null ? item.value.toString() === value.toString() : false;
        },
        options
    );
}

export const formatCurrency = (value: string | null): string | null => {
    let number = Number(value);
    if (isNaN(number)) {
        return null;
    }
    return '$' + (Math.round(number * 100) / 100).toFixed(2);
}
/**
 * Finds the first layout item in a hierarchical array of layout items including descendants where id matches the field definition id. 
 * @param id 
 * @param layoutItems 
 * @returns 
 */
const findLayoutItemByFieldDefinitionId = (id: number, layoutItems: ILayoutItem[]): ILayoutItem | undefined => {
    let result: ILayoutItem | undefined = undefined;
    const findInItems: (id: number, items: ILayoutItem[]) => void = (id: number, items: ILayoutItem[]) => {
        result = items.find((item) => item.field_definition_id == id);
        if (!result) {
            for(let item1 of items ) {
                if (item1.children && Array.isArray(item1.children) && item1.children.length > 0) {
                    findInItems(id, item1.children);
                };
                if (result) {
                    break;
                }
            };
        }
    }
    findInItems(id, layoutItems);
    return result;
}

export { findLayoutItemByFieldDefinitionId };
