import { BvTableField, BvTableFieldArray } from "bootstrap-vue";
import { FieldCategories, FieldTypes, LayoutItemTypes, TableNames } from "../core/enums/LookupEnums";
import { ICustomFieldDef, IDdValue, IFieldDefinition, ILayoutItem } from "../core/interfaces/CustomFieldInterface";
import { IHierarchicalSelectListItem, ISelectListItem } from "@/core/interfaces/SelectListItemInterface";

import CustomFieldService from "../core/services/CustomFieldService";
import { HierarchicalSelectListHelper } from "../core/helpers/HierarchicalSelectListHelper";
import Vue from "vue";
import dayjs from "dayjs";
import { startCase } from "../core/helpers/StringHelpers";
import { isEqual } from "lodash";
import { formatCurrency, formatDropdown } from "@/core/helpers/FieldHelper";

interface IState {
    customFields: { [key: string]: ICustomFieldDef };
    awaitingCustomFields: { [key: string]: { params: any, promise: Promise<void> } };
    columnsRiskAssessment: BvTableFieldArray;
    columnsIssue: BvTableFieldArray;
    columnsRisk: BvTableFieldArray;
    columnsControl: BvTableFieldArray;
    columnsIncident: BvTableFieldArray;
    columnsCorrectiveActions: BvTableFieldArray;
    columnsPeople: BvTableFieldArray;
    columnsIncidentInvestigations: BvTableFieldArray;
    columnsTask: BvTableFieldArray;
    columnsEvent: BvTableFieldArray;

    fieldsRiskAssessment: ILayoutItem[];
    fieldsIncident: ILayoutItem[];
    fieldsCorrectiveActions: ILayoutItem[];
    fieldsPeople: ILayoutItem[];
    fieldsIncidentInvestigations: ILayoutItem[];
    fieldsIssue: ILayoutItem[];
    fieldsRisk: ILayoutItem[];
    fieldsControl: ILayoutItem[];
    fieldsTask: ILayoutItem[];
    fieldsEvent: ILayoutItem[];

    customFieldsErrorMessage: string;
    customFieldsInfoMessage: string;
}

const getColumns = function (state: IState, tableName: TableNames): BvTableFieldArray {
    switch (tableName) {
        case TableNames.RiskAssessments:
            return state.columnsRiskAssessment as BvTableFieldArray;
            break;
        case TableNames.Risks:
            return state.columnsRisk as BvTableFieldArray;
            break;
        case TableNames.Controls:
            return state.columnsControl as BvTableFieldArray;
            break;
        case TableNames.Incidents:
            return state.columnsIncident as BvTableFieldArray;
            break;
        case TableNames.CorrectiveActions:
            return state.columnsCorrectiveActions as BvTableFieldArray;
            break;
        case TableNames.People:
            return state.columnsPeople as BvTableFieldArray;
            break;
        case TableNames.IncidentInvestigations:
            return state.columnsIncidentInvestigations as BvTableFieldArray;
            break;
        case TableNames.Issues:
            return state.columnsIssue as BvTableFieldArray;
            break;
        case TableNames.Tasks:
            return state.columnsTask as BvTableFieldArray;
            break;
        case TableNames.Events:
            return state.columnsEvent as BvTableFieldArray;
            break;
    }
    return [];
};

const getFields = function (state: IState, tableName: TableNames): ILayoutItem[] {
    switch (tableName) {
        case TableNames.RiskAssessments:
            return state.fieldsRiskAssessment;
            break;
        case TableNames.Risks:
            return state.fieldsRisk;
            break;
        case TableNames.Controls:
            return state.fieldsControl;
            break;
        case TableNames.Incidents:
            return state.fieldsIncident;
            break;
        case TableNames.CorrectiveActions:
            return state.fieldsCorrectiveActions;
            break;
        case TableNames.People:
            return state.fieldsPeople;
            break;
        case TableNames.IncidentInvestigations:
            return state.fieldsIncidentInvestigations;
            break;
        case TableNames.Issues:
            return state.fieldsIssue;
            break;
        case TableNames.Tasks:
            return state.fieldsTask;
            break;
        case TableNames.Events:
            return state.fieldsEvent;
            break;
    }
    return [];
};

const state: IState = {
    //custom fields is the raw data from the database stored in an object keyed by the table name each containing an array of ICustomFieldDef
    //columns and fields store data for use in AppTabularList and AppRecordEditor components respectively
    //data for each type is kept in a separate array to ensure that Vue's reactivity system responds when they are changed
    //See https://vuejs.org/v2/guide/reactivity.html
    //customField arrays are arrays of ICustomField
    //columns are Array<string | ({ key: string } & BvTableField & { fieldDef: IFieldDefinition })> (BvTableFieldArrays with the field definition added
    customFields: {},
    awaitingCustomFields: {},
    columnsRiskAssessment: [],
    columnsIssue: [],
    columnsRisk: [],
    columnsControl: [],
    columnsIncident: [],
    columnsCorrectiveActions: [],
    columnsPeople: [],
    columnsIncidentInvestigations: [],
    columnsTask: [],
    columnsEvent: [],

    fieldsRiskAssessment: [],
    fieldsIncident: [],
    fieldsCorrectiveActions: [],
    fieldsPeople: [],
    fieldsIncidentInvestigations: [],
    fieldsIssue: [],
    fieldsRisk: [],
    fieldsControl: [],
    fieldsTask: [],
    fieldsEvent: [],

    customFieldsErrorMessage: "",
    customFieldsInfoMessage: "",
};
const getters = {
    //custom fields is a ICustomFieldDef object
    customFields: (state: IState) =>
        function (tableName: TableNames): ICustomFieldDef {
            return state.customFields[tableName];
        },
    /**
     * Returns a BvTableFieldArray based on the customFields filtered and sorted for use in AppTabularList components
     */
    columns: (state: IState) =>
        function (tableName: TableNames): BvTableFieldArray {
            return getColumns(state, tableName);
        },
    /**
  //Returns an array of ICustomField filtered and sorted for use in AppRecordEditor components
  */
    fields: (state: IState) =>
        function (tableName: TableNames): ILayoutItem[] {
            return getFields(state, tableName);
        },
};
/**
 * queryParams is an object containing key value pairs of query string items to add to the url
 */
const actions = {
    getCustomFields: async function (
        context: any,
        params: { tableName: string; layoutTemplateId: number | null | undefined; queryParams: { tableName: string; queryParams: any } | null | undefined }
    ): Promise<ICustomFieldDef> {
        return CustomFieldService.getCustomFields(params.tableName, params.layoutTemplateId, params.queryParams);
    },

    getColumns: async function ({ rootState }: any, params: { customFields: ICustomFieldDef | null | undefined }): Promise<BvTableFieldArray> {
        const columns: Array<string | ({ key: string } & BvTableField & { fieldDef: IFieldDefinition })> = [];
        // @todo Greg 5th July - all needs to be revised
        // We need date and time formats from auth store modeul
        let dateFormat = "";
        let timeFormat = "";
        let midnightFormat = "";
        let dateTimeFormat = "";
        // @todo use getters rayther than direct access !
        const settings: any = rootState.auth.userData.extra.settings;
        for (let i = 0; i < settings.length; i++) {
            if (settings[i].id == "LIC_DATE_FMT") dateFormat = settings[i].value;
            if (settings[i].id == "LIC_TIME_FMT") timeFormat = settings[i].value;
            if (settings[i].id == "LIC_MIDNT_FMT") midnightFormat = settings[i].value;
        }
        // @todo we receive values like default, us ampm , and need to convert them to actual formats
        // see LookupEnum.ts that does this
        if (dateFormat == "default") dateFormat = "D MMM YYYY";
        if (dateFormat == "us") dateFormat = "MMM D YYYY";
        if (timeFormat == "default") timeFormat = "HH:mm";
        if (timeFormat == "ampm") timeFormat = "h:mm a";
        dateTimeFormat = dateFormat + " " + timeFormat;
        if (params.customFields && Object.prototype.hasOwnProperty.call(params.customFields, "fields")) {
            const fields: IFieldDefinition[] = params.customFields.fields;
            //columns is the array used to define the list columns to display and their sequence in an AppTabularList component

            let listItems: ILayoutItem[] | null = null;
            if (params.customFields && Object.prototype.hasOwnProperty.call(params.customFields, "list")) {
                listItems = params.customFields.list;
            }
            //List items for list
            //Sort the array by listSequence (should already be sorted by the database)
            //filteredCustomFields.sort((a,b) => (a.list_sequence > b.list_sequence) ? 1 : -1);
            if (listItems && listItems.length > 0) {
                listItems.forEach((layoutItem: ILayoutItem) => {
                    let fieldDefinition: IFieldDefinition | undefined;
                    let lkuFieldTypeId: FieldTypes;
                    let lkuFieldCategoryId: FieldCategories;
                    if (layoutItem.lku_layout_item_type_id == LayoutItemTypes.Field) {
                        //these must all be fields
                        //now lookup the field type in the field definition
                        lkuFieldTypeId = FieldTypes.String;
                        fieldDefinition = fields.find((value: IFieldDefinition) => {
                            return value.id === layoutItem.field_definition_id;
                        });
                        if (fieldDefinition) {
                            lkuFieldTypeId = fieldDefinition.lku_field_type_id;
                            lkuFieldCategoryId = fieldDefinition.lku_field_category_id;
                            const key: string = lkuFieldCategoryId === FieldCategories.Custom ? "@" + fieldDefinition.id : fieldDefinition.name;
                            const label: string | undefined = fieldDefinition.label ? fieldDefinition.label : fieldDefinition.name ? startCase(fieldDefinition.name) : undefined;
                            /*//attach the field definition to the layoutItem
                                    layoutItem.field_definition = fieldDefinition; */
                            switch (lkuFieldTypeId) {
                                case FieldTypes.String:
                                case FieldTypes.Text:
                                    columns.push({
                                        key: key,
                                        label: label,
                                        tdClass: layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk",
                                        thClass: [layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk", "align-middle"],
                                        sortable: true,
                                        fieldDef: fieldDefinition,
                                    });
                                    break;
                                case FieldTypes.Integer:
                                    //TODO format this as an integer
                                    columns.push({
                                        key: key,
                                        label: label,
                                        tdClass: layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk",
                                        thClass: [layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk", "align-middle"],
                                        sortable: true,
                                        fieldDef: fieldDefinition,
                                    });
                                    break;
                                case FieldTypes.Decimal:
                                    //TODO format this to 2 decimal places
                                    columns.push({
                                        key: key,
                                        label: label,
                                        tdClass: layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk",
                                        thClass: [layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk", "align-middle"],
                                        sortable: true,
                                        fieldDef: fieldDefinition,
                                    });
                                    break;
                                case FieldTypes.Boolean:
                                    columns.push({
                                        key: key,
                                        label: label,
                                        tdClass: layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk",
                                        thClass: [layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk", "align-middle"],
                                        sortable: true,
                                        fieldDef: fieldDefinition,
                                        formatter: (value: boolean | null) => {
                                            if (value === true) {
                                                return 'Yes';
                                            } else if (value === false) {
                                                return 'No';
                                            } else {
                                                return null;
                                            }
                                        },
                                        sortByFormatted: false,
                                    });
                                    break;
                                case FieldTypes.Date:
                                    columns.push({
                                        key: key,
                                        label: label,
                                        tdClass: layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk",
                                        thClass: [layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk", "align-middle"],
                                        sortable: true,
                                        formatter: (value: any) => {
                                            if (value) return dayjs(value).format(dateFormat);
                                        },
                                        sortByFormatted: (value: any) => {
                                            return dayjs(value).valueOf();
                                        },
                                        filterByFormatted: true,
                                        fieldDef: fieldDefinition,
                                    });
                                    break;
                                case FieldTypes.DateTime:
                                    columns.push({
                                        key: key,
                                        label: label,
                                        tdClass: layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk",
                                        thClass: [layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk", "align-middle"],
                                        sortable: true,
                                        formatter: (value: any) => {
                                            if (value) return dayjs(value).format(dateTimeFormat);
                                        },
                                        sortByFormatted: (value: any) => {
                                            return dayjs(value).valueOf();
                                        },
                                        filterByFormatted: true,
                                        fieldDef: fieldDefinition,
                                    });
                                    break;
                                        case FieldTypes.DropdownSys:
                                        case FieldTypes.DropdownMultiSys:
                                        case FieldTypes.DropdownFd:
                                        case FieldTypes.DropdownMultiFd:
                                        case FieldTypes.DropdownAddFd:
                                        case FieldTypes.DropdownMultiAddFd:
                                        case FieldTypes.HierarchicalDdSys:
                                        case FieldTypes.HierarchicalDdMultiSys:
                                        case FieldTypes.HierarchicalDdFd:
                                        case FieldTypes.HierarchicalDdMultiFd:
                                        case FieldTypes.HierarchicalDdAddFd:
                                        case FieldTypes.HierarchicalDdMultiAddFd:
                                            columns.push({
                                                key: key,
                                                label: label,
                                                tdClass: layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk",
                                                thClass: [layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk", "align-middle"],
                                                sortable: true,
                                                formatter: (value: any) => formatDropdown(value, fieldDefinition!.dd_values as IHierarchicalSelectListItem[] | ISelectListItem[]),
                                                sortByFormatted: true,
                                                filterByFormatted: true,
                                                fieldDef: fieldDefinition,
                                            });
                                            break;
                                case FieldTypes.Currency:
                                    columns.push({
                                        key: key,
                                        label: label,
                                        tdClass: layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk",
                                        thClass: [layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk", "align-middle"],
                                        sortable: true,
                                        formatter: formatCurrency,
                                        sortByFormatted: false,
                                        filterByFormatted: true,
                                        fieldDef: fieldDefinition,
                                    });
                                    break;
                                default:
                                    columns.push({
                                        key: key,
                                        label: label,
                                        tdClass: layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk",
                                        thClass: [layoutItem.mobile_view ? "rm-view-both" : "rm-view-desk", "align-middle"],
                                        sortable: true,
                                        fieldDef: fieldDefinition,
                                    });
                                    break;
                            }
                        }
                    }
                });
            }
        }
        return columns;
    },

    getDetailItems: async function (context: any, params: { customFields: ICustomFieldDef | null | undefined }): Promise<ILayoutItem[]> {
        let detailItems: ILayoutItem[] = [];
        let fields: IFieldDefinition[] = [];
        //ListItems for Detail
        if (params.customFields && Object.prototype.hasOwnProperty.call(params.customFields, "detail")) {
            detailItems = params.customFields.detail;
            fields = params.customFields.fields;
        }
        //Recursively attach definitions to layout items.
        const attachDef = (li: ILayoutItem) => {
            const fieldDefinition: IFieldDefinition | undefined = fields.find((value: IFieldDefinition) => {
                return value.id === li.field_definition_id;
            });
            if (fieldDefinition) {
                //attach the field definition to the layoutItem
                li.field_definition = fieldDefinition;
            }
            if (li.children && li.children.length > 0) {
                for (const cli of li.children) {
                    attachDef(cli);
                }
            }
        }
        if (detailItems && detailItems.length > 0 && fields && fields.length > 0) {
            detailItems.forEach((layoutItem: ILayoutItem) => {
                attachDef(layoutItem);
            });
        }
        return detailItems;
    },

    getAllFields: async function ({ dispatch, commit }: any, params: { tableName: string; layoutTemplateId: number | null | undefined; queryParams: any | null | undefined }): Promise<void> {
        if (state.awaitingCustomFields.hasOwnProperty(params.tableName) && isEqual(state.awaitingCustomFields[params.tableName].params, params)) {
            return state.awaitingCustomFields[params.tableName].promise;
        } else {
            let promise: Promise<void> =
                dispatch("getCustomFields", { tableName: params.tableName, layoutTemplateId: params.layoutTemplateId, queryParams: params.queryParams })
                    .then(async (customFields: ICustomFieldDef) => {
                        commit("gotCustomFields", {
                            tableName: params.tableName,
                            customFields: customFields,
                        });
                        if (customFields && customFields.hasOwnProperty("fields")) {
                            //columns is the array used to define the list columns to display and their sequence in an AppTabularList component
                            const columns = await dispatch("getColumns", { customFields: customFields });
                            commit("gotColumns", { tableName: params.tableName, columns: columns });
                            const detailItems = await dispatch("getDetailItems", { customFields: customFields });
                            commit("gotFields", { tableName: params.tableName, fields: detailItems });
                        }
                    })
                    .finally(() => {
                        delete state.awaitingCustomFields[params.tableName]
                    });
            state.awaitingCustomFields[params.tableName] = { params: params, promise: promise }
            return promise;
        }
    },
};
const mutations = {
    gotCustomFields(state: IState, args: any): void {
        state.customFields[args.tableName] = args.customFields;
    },
    gotColumns(state: IState, args: any): void {
        switch (args.tableName) {
            case TableNames.RiskAssessments:
                state.columnsRiskAssessment = args.columns;
                break;
            case TableNames.Risks:
                state.columnsRisk = args.columns;
                break;
            case TableNames.Controls:
                state.columnsControl = args.columns;
                break;
            case TableNames.Incidents:
                state.columnsIncident = args.columns;
                break;
            case TableNames.CorrectiveActions:
                state.columnsCorrectiveActions = args.columns;
                break;
            case TableNames.People:
                state.columnsPeople = args.columns;
                break;
            case TableNames.IncidentInvestigations:
                state.columnsIncidentInvestigations = args.columns;
                break;
            case TableNames.Issues:
                state.columnsIssue = args.columns;
                break;
            case TableNames.Tasks:
                state.columnsTask = args.columns;
                break;
            case TableNames.Events:
                state.columnsEvent = args.columns;
                break;
        }
    },
    gotFields(state: IState, args: { tableName: TableNames; fields: ILayoutItem[] }): void {
        switch (args.tableName) {
            case TableNames.RiskAssessments:
                state.fieldsRiskAssessment = args.fields;
                break;
            case TableNames.Risks:
                state.fieldsRisk = args.fields;
                break;
            case TableNames.Controls:
                state.fieldsControl = args.fields;
                break;
            case TableNames.Incidents:
                state.fieldsIncident = args.fields;
                break;
            case TableNames.CorrectiveActions:
                state.fieldsCorrectiveActions = args.fields;
                break;
            case TableNames.People:
                state.fieldsPeople = args.fields;
                break;
            case TableNames.IncidentInvestigations:
                state.fieldsIncidentInvestigations = args.fields;
                break;
            case TableNames.Issues:
                state.fieldsIssue = args.fields;
                break;
            case TableNames.Tasks:
                state.fieldsTask = args.fields;
                break;
            case TableNames.Events:
                state.fieldsEvent = args.fields;
                break;
        }
    },
    setErrorMessage(state: IState, errorMessage: string): void {
        state.customFieldsErrorMessage = errorMessage;
    },
    clearErrorMessage(state: IState): void {
        state.customFieldsErrorMessage = "";
    },
    setInfoMessage(state: IState, infoMessage: string): void {
        state.customFieldsInfoMessage = infoMessage;
    },
    clearInfoMessage(state: IState): void {
        state.customFieldsInfoMessage = "";
    },
    updateColumnKey(state: IState, args: { tableName: TableNames; oldKey: string; newKey: string }): void {
        const cols: BvTableFieldArray = getColumns(state, args.tableName);
        const foundCol: string | ({ key: string } & BvTableField) | undefined = cols.find((c) => (c as { key: string } & BvTableField).key === args.oldKey);
        if (foundCol && Object.prototype.hasOwnProperty.call(foundCol, "key")) {
            (foundCol as { key: string } & BvTableField).key = args.newKey;
        }
    },
    updateColumnFormatter(state: IState, args: { tableName: TableNames; key: string; formatter: (value: any, key: any, item: any) => any }): void {
        const cols: BvTableFieldArray = getColumns(state, args.tableName);
        const foundCol: string | ({ key: string } & BvTableField) | undefined = cols.find((c) => (c as { key: string } & BvTableField).key === args.key);
        if (foundCol) {
            if (Object.prototype.hasOwnProperty.call(foundCol, "formatter")) {
                (foundCol as { key: string } & BvTableField).formatter = args.formatter;
            } else {
                Vue.set(foundCol as { key: string } & BvTableField, "formatter", args.formatter);
            }
        }
    },
    updateColumnSortByFormatted(state: IState, args: { tableName: TableNames; key: string; sortByFormatted: (value: any, key: any, item: any) => any | boolean }): void {
        const cols: BvTableFieldArray = getColumns(state, args.tableName);
        const foundCol: string | ({ key: string } & BvTableField) | undefined = cols.find((c) => (c as { key: string } & BvTableField).key === args.key);
        if (foundCol) {
            if (Object.prototype.hasOwnProperty.call(foundCol, "sortByFormatted")) {
                (foundCol as { key: string } & BvTableField).sortByFormatted = args.sortByFormatted;
            } else {
                Vue.set(foundCol as { key: string } & BvTableField, "sortByFormatted", args.sortByFormatted);
            }
        }
    },
    updateColumnFilterByFormatted(state: IState, args: { tableName: TableNames; key: string; filterByFormatted: (value: any, key: any, item: any) => any | boolean }): void {
        const cols: BvTableFieldArray = getColumns(state, args.tableName);
        const foundCol: string | ({ key: string } & BvTableField) | undefined = cols.find((c) => (c as { key: string } & BvTableField).key === args.key);
        if (foundCol) {
            if (Object.prototype.hasOwnProperty.call(foundCol, "filterByFormatted")) {
                (foundCol as { key: string } & BvTableField).filterByFormatted = args.filterByFormatted;
            } else {
                Vue.set(foundCol as { key: string } & BvTableField, "filterByFormatted", args.filterByFormatted);
            }
        }
    },
    updateFieldName(state: IState, args: { tableName: TableNames; oldFieldName: string; newFieldName: string }): void {
        const fields: ILayoutItem[] = getFields(state, args.tableName);
        const foundField: ILayoutItem | undefined = fields.find((li) => li.field_name === args.oldFieldName);
        if (foundField) {
            foundField.field_name = args.newFieldName;
        }
    },
    removeField(state: IState, args: { tableName: TableNames; fieldName: string }): void {
        const fields: ILayoutItem[] = getFields(state, args.tableName);
        const foundField: ILayoutItem | undefined = fields.find((li) => li.field_name === args.fieldName);
        if (foundField) {
            fields.splice(fields.indexOf(foundField), 1);
        }
    },
    replaceField(state: IState, args: { tableName: TableNames; fieldName: string; newField: any }): void {
        const fields: ILayoutItem[] = getFields(state, args.tableName);
        const foundField: ILayoutItem | undefined = fields.find((li) => li.field_name === args.fieldName);
        if (foundField) {
            fields.splice(fields.indexOf(foundField), 1, args.newField);
        }
    },
    updateColumnAttr(state: IState, args: { tableName: TableNames; key: string; attr: {[key: string]: string} }): void {
        const cols: BvTableFieldArray = getColumns(state, args.tableName);
        const foundCol: string | ({ key: string } & BvTableField) | undefined = cols.find((c) => (c as { key: string } & BvTableField).key === args.key);
        if (foundCol) {
            if (Object.prototype.hasOwnProperty.call(foundCol, "tdAttr")) {
                (foundCol as { key: string } & BvTableField).tdAttr = args.attr;
            } else {
                Vue.set(foundCol as { key: string } & BvTableField, "tdAttr", args.attr);
            }
        }
    },
    clearCustomFields(state: IState, tableName: TableNames): void {
        if (state.customFields.hasOwnProperty(tableName)) delete state.customFields[tableName];
        switch (tableName) {
            case TableNames.RiskAssessments:
                state.columnsRiskAssessment = [];
                state.fieldsRiskAssessment = [];
                break;
            case TableNames.Risks:
                state.columnsRisk = [];
                state.fieldsRisk = [];
                break;
            case TableNames.Controls:
                state.columnsControl = [];
                state.fieldsControl = [];
                break;
            case TableNames.Incidents:
                state.columnsIncident = [];
                state.fieldsIncident = [];
                break;
            case TableNames.CorrectiveActions:
                state.columnsCorrectiveActions = [];
                state.fieldsCorrectiveActions = [];
                break;
            case TableNames.People:
                state.columnsPeople = [];
                state.fieldsPeople = [];
                break;
            case TableNames.IncidentInvestigations:
                state.columnsIncidentInvestigations = [];
                state.fieldsIncidentInvestigations = [];
                break;
            case TableNames.Issues:
                state.columnsIssue = [];
                state.fieldsIssue = [];
                break;
            case TableNames.Tasks:
                state.columnsTask = [];
                state.fieldsTask = [];
                break;
            case TableNames.Events:
                state.columnsEvent = [];
                state.fieldsEvent = [];
                break;
        }
    }
};

export const customFields = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
