<template>
	<b-alert class="position-fixed fixed-top m-0 rounded-0" style="z-index: 2000" v-model="hasError" variant="danger" dismissible fade>
        <div  @click="hasError = false">
            <template v-if="Array.isArray(syncedErrorMsg)">
                Please correct the following errors:
                <ul class="py-0 my-0">
                    <li v-for="(item, index) of syncedErrorMsg" :key="index">{{ item }}</li>
                </ul>
            </template>
            <template v-else-if="isError">{{ syncedErrorMsg.message }}</template>
            <template v-else >{{ syncedErrorMsg }}</template>
        </div>
		<template v-slot:dismiss>X</template>
	</b-alert>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";

@Component({ name: "app-error-display" })
export default class AppErrorDisplay extends Vue {
	@PropSync("errorMsg", { type: [Array, String, Error], default: null }) syncedErrorMsg?: string[] | string | Error | null;

	get hasError(): boolean {
		return !!this.syncedErrorMsg;
	}

	set hasError(value: boolean) {
		if (!value) {
			this.syncedErrorMsg = null;
		}
	}

	get isError(): boolean {
		return this.syncedErrorMsg instanceof Error;
	}
}
</script>
