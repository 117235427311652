export enum EventStatus {
	Planning = 0,
	Operational = 1,
	"Non-Event" = 2,
	Closed = 99,
}

export enum EventPeremissions {
	CreateEvent = "IM-EV-CR",
	EditEvent = "IM-EV-ED",
}
