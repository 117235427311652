import ApiService from "@/core/services//ApiService";
import { AxiosResponse } from "axios";
import IVenueType from "../interfaces/VenueTypeInterface";
import { ISelectListItem } from "@/core/interfaces/SelectListItemInterface";

export abstract class SystemService {
	static async getTimezones(): Promise<{ text: string; value: string }[]> {
		const resource = "/api/timezones";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data) {
				return response.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get timezones failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async getCountries(): Promise<{ text: string; value: number }[]> {
		const resource = "/api/countries";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data) {
				return response.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get countries failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async getStates(): Promise<{ country_id: number; text: string; value: number }[]> {
		const resource = "/api/states";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data) {
				return response.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get states failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	//  ------  Those for Venue Types ---------------------------------------------

	static async getVenueTypes(): Promise<IVenueType[]> {
		const resource = "/api/venuetypes";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data) {
				return response.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get venue types failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async saveNewVenueType(vt: IVenueType): Promise<IVenueType> {
		const resource = "/api/system/venuetypes";
		try {
			const response: AxiosResponse<any> = await ApiService.post(resource, vt);
			return response.data;
		} catch (e: any) {
			throw e;
		}
	}

	static async updateVenueType(vt: IVenueType): Promise<IVenueType> {
		const resource: string = "/api/system/venuetypes/" + vt.id?.toString();
		try {
			const response: AxiosResponse<any> = await ApiService.put(resource, vt);
            return response.data;
		} catch (e: any) {
			throw e;
		}
	}

	static async deleteVenueType(vt: IVenueType): Promise<void> {
		const resource: string = "/api/system/venuetypes/" + vt.toString();
		try {
			const response: AxiosResponse<any> = await ApiService.delete(resource);
		} catch (e: any) {
			throw e;
		}
	}

    static async getLicenseManagerOptions(): Promise<ISelectListItem[]> {
		const resource = "/api/license_managers";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data) {
				return response.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Getting license manager options failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}
}
