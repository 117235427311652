import { IRiskDefinition, IRiskDefinitionCategory, IRiskDefinitionCriterion, IRiskDefinitionTemplate } from "../interfaces/RiskDefinitionsInterface";

import ApiService from "../../../core/services/ApiService";
import { AxiosResponse } from "axios";

export default abstract class RiskDefinitionService {
    static async getRiskDefById(id: number): Promise<IRiskDefinition> {
        const resource: string = "/api/risk_definition/" + id.toString();
        try {
            const response: AxiosResponse<any> = await ApiService.get(resource);
            if (response.data && response.data.data) {
                //response.data should a single object representing a set of risk definitions.
                return response.data.data;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            let msg = "Get risk definitions failed.";
            if (e.response) {
                msg = e.response.data.message;
            } else if (e.message) {
                msg = e.message;
            }
            throw new Error(msg);
        }
    }

    static async getRiskDefByRiskAssessmentId(id: number): Promise<IRiskDefinition> {
        const resource: string = "/api/risk_definition/risk_assessment/" + id.toString();
        try {
            const response: AxiosResponse<any> = await ApiService.get(resource);
            if (response.data && response.data.data) {
                //response.data should a single object representing a set of risk definitions.
                return response.data.data;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            let msg = "Get risk definitions failed.";
            if (e.response) {
                msg = e.response.data.message;
            } else if (e.message) {
                msg = e.message;
            }
            throw new Error(msg);
        }
    }

    static async getDefaultRiskDef(): Promise<IRiskDefinition> {
        const resource = "/api/risk_definition/default";
        try {
            const response: AxiosResponse<any> = await ApiService.get(resource);
            if (response.data && response.data.data) {
                //response.data should a single object representing a set of risk definitions.
                return response.data.data;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            let msg = "Get risk definitions failed.";
            if (e.response) {
                msg = e.response.data.message;
            } else if (e.message) {
                msg = e.message;
            }
            throw new Error(msg);
        }
    }

    static async saveRiskDef(riskDef: IRiskDefinition, riskAssessmentId: number | null): Promise<IRiskDefinition> {
        let resource = "";
        let response: AxiosResponse<any>;
        try {
            if (riskDef.id > 0) {
                //update
                resource = "/api/risk_definition/" + riskDef.id.toString() + (riskAssessmentId ? "?raid=" + (riskAssessmentId as number).toString() : "");
                response = await ApiService.put(resource, riskDef);
            } else {
                //add new
                resource = "/api/risk_definition" + (riskAssessmentId ? "?raid=" + (riskAssessmentId as number).toString() : "");
                response = await ApiService.post(resource, riskDef);
            }

            if (response.data && response.data.data) {
                //response.data should a single object representing a set of risk definitions.
                return response.data.data;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            throw e;
        }
    }

    static async deleteRiskDef(id: number): Promise<void> {
        const resource: string = "/api/risk_definition/" + id.toString();
        try {
            await ApiService.delete(resource);
        } catch (e: any) {
            let msg = "Save risk definitions failed.";
            if (e.response) {
                msg = e.response.data.message;
            } else if (e.message) {
                msg = e.message;
            }
            throw new Error(msg);
        }
    }

    /**
     * Gets a list of templates for the current user license
     */
    static async getRiskDefTemplates(): Promise<IRiskDefinitionTemplate[]> {
        const resource = "/api/risk_definitions";
        try {
            const response: AxiosResponse<any> = await ApiService.get(resource);
            if (response.data && response.data.data) {
                //response.data should an array of objects representing risk definition templates.
                return response.data.data;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            let msg = "Get definition templates failed.";
            if (e.response) {
                msg = e.response.data.message;
            } else if (e.message) {
                msg = e.message;
            }
            throw new Error(msg);
        }
    }

    static async getRiskDefCategories(riskDefId: number): Promise<IRiskDefinitionCategory[]> {
        const resource: string = "/api/risk_definition_categories/" + riskDefId.toString();
        try {
            const response: AxiosResponse<any> = await ApiService.get(resource);
            if (response.data) {
                //response.data should an array of objects representing risk definition categories.
                return response.data;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            let msg = "Get risk categories failed.";
            if (e.response) {
                msg = e.response.data.message;
            } else if (e.message) {
                msg = e.message;
            }
            throw new Error(msg);
        }
    }

    static async getRiskDefCriteria(riskDefId: number): Promise<IRiskDefinitionCriterion[]> {
        const resource: string = "/api/risk_definition_criteria/" + riskDefId.toString();
        try {
            const response: AxiosResponse<any> = await ApiService.get(resource);
            if (response.data) {
                //response.data should an array of objects representing risk definition citeria.
                return response.data;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            let msg = "Get definition criteria failed.";
            if (e.response) {
                msg = e.response.data.message;
            } else if (e.message) {
                msg = e.message;
            }
            throw new Error(msg);
        }
    }
}
