import { cloneDeep } from "lodash";
import { ISelectListItem } from "../../../core/interfaces/SelectListItemInterface";
import { IIssue } from "../../Issues/interfaces/IssueInterface";
import { FormulaElementType } from "../enums/RiskDefinitionsEnums";
import {
	IRiskDefinition,
	IRiskDefinitionComponent,
	IRiskDefinitionCriterion,
	IRiskDefinitionFormulaElement,
	IRiskDefinitionFormulaVariableElement,
	IRiskDefinitionMatrixItem
} from "../interfaces/RiskDefinitionsInterface";
import { IRisk, IRiskScore } from "../interfaces/RiskInterface";


export enum RiskScoreType {
	Inherent = 0,
	Residual = 1,
	Issue = 2,
}

const FormulaService = {
	/**
	 * returns all possible combinations of all numbers
	 * @param arg
	 */
	cartesian(arg: number[][]): number[][] {
		const result: number[][] = [],
			max = arg.length - 1;
		function helper(arr: number[], i: number) {
			for (let j = 0, l: number = arg[i].length; j < l; j++) {
				const a: number[] = arr.slice(0); // clone arr
				a.push(arg[i][j]);
				if (i == max) result.push(a);
				else helper(a, i + 1);
			}
		}
		if (arg.length > 0) {
			helper([], 0);
		} else {
			return [[0]];
		}
		return result;
	},

	/**
	 * REtruns all possible combinations of all objects based on the object id
	 * @param arg
	 */
	cartesian1(arg: { id: number; factor: number }[][]): { id: number; factor: number }[][] {
		const result: { id: number; factor: number }[][] = [],
			max = arg.length - 1;
		function helper(arr: { id: number; factor: number }[], i: number) {
			for (let j = 0, l: number = arg[i].length; j < l; j++) {
				const a: { id: number; factor: number }[] = arr.slice(0); // clone arr
				a.push(arg[i][j]);
				if (i == max) result.push(a);
				else helper(a, i + 1);
			}
		}
		if (arg.length > 0) {
			helper([], 0);
		} else {
			return [[{ id: 0, factor: 0 }]];
		}
		return result;
	},

	getRiskScore(object: IRisk | IIssue, riskDefinition: IRiskDefinition, riskScoreType: RiskScoreType): IRiskScore | null {
		let component_item_ids: boolean | number[] = [];
		if (riskScoreType === RiskScoreType.Inherent) {
			component_item_ids = (object as IRisk).inherent_component_item_ids;
		} else if (riskScoreType === RiskScoreType.Residual) {
			component_item_ids = (object as IRisk).residual_component_item_ids;
		}
		return this.getRiskScoreFromComponentIds(component_item_ids, riskDefinition);
	},

	getRiskScoreFromComponentIds(component_item_ids: boolean | number[], riskDefinition: IRiskDefinition): IRiskScore | null {
		let result: IRiskScore | null = null;
		let matrixItemFound: boolean = false;
		try {
			if (Array.isArray(component_item_ids) && (component_item_ids as number[]).length > 0) {
				//first check if these items correspond to a record in risk_definition_matrix_items.  If yes then get the result from there otherwise simply calfulate the result from the formula.
				const visualYComponent: IRiskDefinitionComponent | null = riskDefinition.visual_y_component || null;
				const visualXComponent: IRiskDefinitionComponent | null = riskDefinition.visual_x_component || null;
				if (visualYComponent && visualYComponent.risk_definition_component_items && visualXComponent && visualXComponent.risk_definition_component_items) {
					//The component ids to find in risk_definition_matrix_items
					let yComponentItemId: number | null = null;
					let xComponentItemId: number | null = null;
					//try and find one fo the component_item_ids in the visual_y_compnent
					const foundYComponentItem = visualYComponent.risk_definition_component_items.find((item) => component_item_ids.includes(item.id))
					if (foundYComponentItem) {
						yComponentItemId = foundYComponentItem.id;
					} else if (riskDefinition.y_formula_elements) {
						//need to calculate the component item using the y formula
						let values: number[] = [];
						for (const formulaElement of riskDefinition.y_formula_elements) {
							let value: number = 0;
							if (formulaElement.type === FormulaElementType.Variable) {
								const componentId = (formulaElement as IRiskDefinitionFormulaVariableElement).component_id;
								const foundComponent = riskDefinition.risk_definition_components.find((c) => c.id === componentId);
								if (foundComponent) {
									const foundComponentItem = foundComponent.risk_definition_component_items.find((item) => component_item_ids.includes(item.id));
									if (foundComponentItem) {
										value = foundComponentItem.factor;
									}
								}
							}
							values.push(value)
						}
						const calcResult: number = this.calculate(riskDefinition.y_formula_elements, values);
						//find the score in the visual y component items
						const componentItems = cloneDeep(visualYComponent.risk_definition_component_items).sort((a, b) => b.factor - a.factor);
						if (componentItems && componentItems.length > 0) {
							yComponentItemId = componentItems[0].id;
							for (const componentItem of componentItems) {
								if (calcResult < componentItem.factor) {
									yComponentItemId = componentItem.id;
								}
							}
						}
					}
					const foundXComponentItem = visualXComponent.risk_definition_component_items.find((item) => component_item_ids.includes(item.id))
					if (foundXComponentItem) {
						xComponentItemId = foundXComponentItem.id;
					} else if (riskDefinition.x_formula_elements) {
						//need to calculate the component item using the x formula
						let values: number[] = [];
						for (const formulaElement of riskDefinition.x_formula_elements) {
							let value: number = 0;
							if (formulaElement.type === FormulaElementType.Variable) {
								const componentId = (formulaElement as IRiskDefinitionFormulaVariableElement).component_id;
								const foundComponent = riskDefinition.risk_definition_components.find((c) => c.id === componentId);
								if (foundComponent) {
									const foundComponentItem = foundComponent.risk_definition_component_items.find((item) => component_item_ids.includes(item.id));
									if (foundComponentItem) {
										value = foundComponentItem.factor;
									}
								}
							}
							values.push(value)
						}
						const calcResult: number = this.calculate(riskDefinition.x_formula_elements, values);
						//find the score in the visual x component items
						const componentItems = cloneDeep(visualXComponent.risk_definition_component_items).sort((a, b) => b.factor - a.factor);
						if (componentItems && componentItems.length > 0) {
							xComponentItemId = componentItems[0].id;
							for (const componentItem of componentItems) {
								if (calcResult < componentItem.factor) {
									xComponentItemId = componentItem.id;
								}
							}
						}
					}
					if (yComponentItemId && xComponentItemId) {
						//see if the item exist in risk_definition_matrix_items
						const matrixItemScore = this.getRiskScoreMatrixItems(xComponentItemId, yComponentItemId, riskDefinition);
						if (matrixItemScore) {
							matrixItemFound = true;
							result = matrixItemScore;
						}
					}
				}
				if (!matrixItemFound) {
					let formula = "";
					const combElements: (IRiskDefinitionFormulaElement | IRiskDefinitionFormulaVariableElement)[] | null = this.combineAllElements(riskDefinition);
					let found = false;
					if (combElements && component_item_ids && (component_item_ids as number[]).length > 0) {
						//Substitute all the variableComponentItems with their equivalent values
						//If all the variableComponentItems are not found in the risk scores then return null
						for (const combElement of combElements) {
							found = false;
							if (combElement.type === FormulaElementType.Variable) {
								//get the component from the definitions
								try {
									const component = riskDefinition.risk_definition_components.find((c) => c.id === (combElement as IRiskDefinitionFormulaVariableElement).component_id);
									//find its value in the IRisk.inherent_component_item_ids
									if (component) {
										for (const componentItem of component.risk_definition_component_items) {
											if ((component_item_ids as number[]).includes(componentItem.id)) {
												//validate that its a number
												formula += isNaN(componentItem.factor) ? 0 : componentItem.factor;												
												found = true;
												break;
											}
										}
									}
								} catch (e: any) {
									return null;
								}
								if (!found) {
									return null;
								}
							} else if (combElement.type === FormulaElementType.Number) {
								//Check it is a number
								formula += isNaN(Number(combElement.value)) ? 0 : combElement.value;
							} else if(combElement.type === FormulaElementType.Operator) {
								//Check its a valid operator
								if(["(",")","*", "+", "-", "/"].indexOf(combElement.value.trim()) >= 0) {
									formula += combElement.value;
								} else {
									return null;
								}
							}
						}
						const value = eval(formula);
						//now find it in the criteria
						if (riskDefinition.risk_definition_criteria && riskDefinition.risk_definition_criteria.length > 0) {
							result = this.getRiskScoreFromValue(value, riskDefinition);
						}
					}
				}
			}
		} catch (e: any) {
			return null;
		}
		return result;
	},

	getRiskScoreFromValue(value: number, riskDefinition: IRiskDefinition): IRiskScore {
		let name: string = "Unknown";
		let color: string = "#cccccc";
		let criteriaId: number | undefined = undefined;
		const criteria: IRiskDefinitionCriterion[] = cloneDeep(riskDefinition.risk_definition_criteria).sort((a, b) => b.score_max - a.score_max);
		if (criteria && criteria.length > 0) {
			const firstCriterion = criteria[0];
			name = firstCriterion.name;
			color = firstCriterion.color;
			criteriaId = firstCriterion.id;
			for (const criterion of criteria) {
				if (value < criterion.score_max) {
					name = criterion.name;
					color = criterion.color;
					criteriaId = criterion.id;
				}
			}
		}
		return {
			name: name,
			color: color,
			value: value,
			criteriaId: criteriaId,
			modified: false,
		};
	},


	/**
	 * Returns a score result by looking up the value in risk_definition_matrix_items or null if not found.
	 *
	 * @param integer $yComponentItemId
	 * @param integer $xComponentItemId
	 * @param RiskDefinition $riskDefinition
	 * @return void
	 */
	getRiskScoreMatrixItems(xComponentItemId: number | null, yComponentItemId: number | null, riskDefinition: IRiskDefinition): IRiskScore | null {
		let result = null;
		if (yComponentItemId && xComponentItemId) {
			//see if the item exist in risk_definition_matrix_items
			if (riskDefinition.risk_definition_matrix_items) {
				const matrixItem: IRiskDefinitionMatrixItem | undefined = riskDefinition.risk_definition_matrix_items.find((value) => {
					return value.risk_definition_y_component_item_id === yComponentItemId && value.risk_definition_x_component_item_id === xComponentItemId;
				});
				if (matrixItem) {
					if (riskDefinition.risk_definition_criteria && riskDefinition.risk_definition_criteria.length > 0) {
						let criterion: IRiskDefinitionCriterion | undefined | null = undefined;
						let avgScore = null;
						if (matrixItem.score) {
							criterion = this.findCriterion(riskDefinition.risk_definition_criteria, matrixItem.score);
						} else if (matrixItem.risk_definition_criterion_id) {
							//get two adjacent criteria records to calculate the midpoint score
							const riskDefCriterionId = matrixItem.risk_definition_criterion_id;
							criterion = riskDefinition.risk_definition_criteria.find((value) => { return value.id === riskDefCriterionId; });
							if (criterion) {
								const criteria = riskDefinition.risk_definition_criteria.filter((value) => {
									return +value.score_max <= +criterion!.score_max!;
								}).sort((a, b) => +b.score_max - a.score_max);
								try {
									if (criteria.length >= 2) {
										const scoreMax0 = +criteria[0].score_max;
										const scoreMax1 = +criteria[1].score_max;
										avgScore = (scoreMax0 + scoreMax1) / 2;
									} else if (criteria.length === 1) {
										const scoreMax = +criteria[0].score_max;
										avgScore = scoreMax / 2;
									}
								} catch (e) {
									// fail silently
								}
							}
						}
						if (criterion) {
							result = {
								name: criterion.name,
								color: criterion.color,
								value: matrixItem.score ? matrixItem.score : (avgScore ? avgScore : 0),
								criteriaId: criterion.id,
								modified: true,
							};
						}
					}
				}
			}
		}
		return result;
	},




	findCriterion(criteria: IRiskDefinitionCriterion[], value: number | string): IRiskDefinitionCriterion | null {
		let result: IRiskDefinitionCriterion | null = null;
		const criteriaClone: IRiskDefinitionCriterion[] = cloneDeep(criteria).sort((a, b) => b.score_max - a.score_max);
		if (criteriaClone && criteriaClone.length > 0) {
			let result = criteriaClone[0];
			value = Number(value);
			if (!isNaN(value)) {
				for (const criterion of criteriaClone) {
					const csm = Number(criterion.score_max);
					if (!isNaN(csm) && value < csm) {
						result = criterion;
					}
				}
			}
			return result;
		} else {
			return null;
		}
	},

	calculate(elements: IRiskDefinitionFormulaElement[], values: number[]): number {
		try {
			let varNum = 0;
			let formula = "";
			let result = 0;
			for (const formulaElement of elements) {
				if (formulaElement.type === FormulaElementType.Variable) {
					formula += isNaN(Number(values[varNum])) ? "0" : values[varNum].toString();
					varNum++;
				} else if (formulaElement.type === FormulaElementType.Number) {
					formula += isNaN(Number(formulaElement.value)) ? 0 : formulaElement.value;
				} else if (formulaElement.type === FormulaElementType.Operator) {
					if(["(",")","*", "+", "-", "/"].indexOf(formulaElement.value.trim()) >= 0) {
						formula += formulaElement.value;
					} else {
						return 0;
					}
				}
			}
			result = eval(formula);
			return result;
		} catch {
			return 0;
		}
	},

	combineAllElements(riskDefinition: IRiskDefinition): (IRiskDefinitionFormulaElement | IRiskDefinitionFormulaVariableElement)[] | null {
		let elements: (IRiskDefinitionFormulaElement | IRiskDefinitionFormulaVariableElement)[] | null;
		const xElements: (IRiskDefinitionFormulaElement | IRiskDefinitionFormulaVariableElement)[] | null = riskDefinition.x_formula_elements;
		const yElements: (IRiskDefinitionFormulaElement | IRiskDefinitionFormulaVariableElement)[] | null = riskDefinition.y_formula_elements;
		const operator: IRiskDefinitionFormulaElement | null = riskDefinition.formula_operator;
		if (yElements && yElements.length > 0) {
			if (xElements && xElements.length > 0 && operator) {
				//wrap it in brackets
				const yElementsWrap: (IRiskDefinitionFormulaElement | IRiskDefinitionFormulaVariableElement)[] = [
					{ type: FormulaElementType.Operator, value: "(" },
				].concat(yElements, [{ type: FormulaElementType.Operator, value: ")" }]);
				const xElementsWrap: (IRiskDefinitionFormulaElement | IRiskDefinitionFormulaVariableElement)[] = [
					{ type: FormulaElementType.Operator, value: "(" },
				].concat(xElements, [{ type: FormulaElementType.Operator, value: ")" }]);
				elements = yElementsWrap.concat([operator as IRiskDefinitionFormulaElement], xElementsWrap);
			} else {
				elements = yElements as (IRiskDefinitionFormulaElement | IRiskDefinitionFormulaVariableElement)[];
			}
		} else if (xElements && xElements.length > 0) {
			elements = xElements as (IRiskDefinitionFormulaElement | IRiskDefinitionFormulaVariableElement)[];
		} else {
			elements = null;
		}
		return elements;
	},

	validateAll(riskDefinition: IRiskDefinition): { result: boolean; message: string; element: IRiskDefinitionFormulaElement | null } {
		//check for existence of components
		let elements: IRiskDefinitionFormulaElement[] | null;
		const xElements: IRiskDefinitionFormulaElement[] | null = riskDefinition.x_formula_elements;
		const yElements: IRiskDefinitionFormulaElement[] | null = riskDefinition.y_formula_elements;
		const operator: IRiskDefinitionFormulaElement | null = riskDefinition.formula_operator;

		if (yElements && yElements.length > 0) {
			if (xElements && xElements.length > 0) {
				if (operator) {
					//wrap it in brackets
					const yElementsWrap: IRiskDefinitionFormulaElement[] = [{ type: FormulaElementType.Operator, value: "(" }].concat(yElements, [
						{ type: FormulaElementType.Operator, value: ")" },
					]);
					const xElementsWrap: IRiskDefinitionFormulaElement[] = [{ type: FormulaElementType.Operator, value: "(" }].concat(xElements, [
						{ type: FormulaElementType.Operator, value: ")" },
					]);
					elements = yElementsWrap.concat([operator as IRiskDefinitionFormulaElement], xElementsWrap);
				} else {
					return {
						result: false,
						message: "Operator between vertical and horizontal components is missing",
						element: operator,
					};
				}
			} else {
				elements = yElements as IRiskDefinitionFormulaElement[];
			}
		} else if (xElements && xElements.length > 0) {
			elements = xElements as IRiskDefinitionFormulaElement[];
		} else {
			elements = null;
		}
		if (elements) {
			const valResult: { result: boolean; message: string; element: IRiskDefinitionFormulaElement | null } = FormulaService.validate(elements);
			if (valResult.result && FormulaService.calculateTest(elements)) {
				return valResult;
			} else if (valResult.result) {
				return {
					result: false,
					message: "Unknown error",
					element: null,
				};
			} else {
				return valResult;
			}
		} else {
			return {
				result: true,
				message: "",
				element: null,
			};
		}
	},

	validate(elements: IRiskDefinitionFormulaElement[]): { result: boolean; message: string; element: IRiskDefinitionFormulaElement | null } {
		let openParCount = 0,
			closeParCount = 0,
			varCount = 0;
		try {
			for (let i = 0; i < elements.length; ++i) {
				const formulaElement = elements[i];
				const nextFormulaElement: IRiskDefinitionFormulaElement | null = i + 1 < elements.length ? elements[i + 1] : null;
				//check that the number of opening and closing parentheses are the same
				if (formulaElement.type === FormulaElementType.Operator && formulaElement.value === "(") {
					//Open parnthesis
					//What follows an open parenthesis must be a variable or another open parentheses
					if (
						nextFormulaElement === null ||
						!(
							(nextFormulaElement.type === FormulaElementType.Operator && nextFormulaElement.value === "(") ||
							(nextFormulaElement.type === FormulaElementType.Variable && nextFormulaElement.value !== "") ||
							(nextFormulaElement.type === FormulaElementType.Number && nextFormulaElement.value !== "")
						)
					) {
						return {
							result: false,
							message: "Open parenthesis must be followed by another open parenthesis a variable or a number",
							element: formulaElement,
						};
					}
					openParCount++;
				} else if (formulaElement.type === FormulaElementType.Operator && formulaElement.value === ")") {
					//close parenthesis
					//What follows a close parenthesis must be the end or another close parenthesis or an operator
					if (!(nextFormulaElement === null || (nextFormulaElement.type === FormulaElementType.Operator && nextFormulaElement.value !== "("))) {
						return {
							result: false,
							message: "Close parenthesis must be followed by another close parenthesis or an operator or the end of the formula",
							element: formulaElement,
						};
					}

					closeParCount++;
				} else if (formulaElement.type === FormulaElementType.Operator) {
					//Any other operator
					//What follows an operator other than parentheses must be an open parenthesis or a variable
					if (
						nextFormulaElement === null ||
						!(
							(nextFormulaElement.type === FormulaElementType.Operator && nextFormulaElement.value === "(") ||
							(nextFormulaElement.type === FormulaElementType.Variable && nextFormulaElement.value !== "") ||
							(nextFormulaElement.type === FormulaElementType.Number && nextFormulaElement.value !== "")
						)
					) {
						return {
							result: false,
							message: "Operators must be followed by an open parenthesis a variable or a number",
							element: formulaElement,
						};
					}
				} else if (
					(formulaElement.type === FormulaElementType.Variable && formulaElement.value !== "") ||
					(formulaElement.type === FormulaElementType.Number && formulaElement.value !== "")
				) {
					//Variable
					//Variables / numbers must be separated by an operator or parentheses
					if (nextFormulaElement !== null && (nextFormulaElement.type === FormulaElementType.Variable || nextFormulaElement.type === FormulaElementType.Number)) {
						return {
							result: false,
							message: "Variables and numbers must be separated by an operator or parenthesis",
							element: formulaElement,
						};
					}
					varCount++;
				}
			}
			if (openParCount !== closeParCount) {
				return {
					result: false,
					message: "Unequal parentheses count",
					element: null,
				};
			}
			if (varCount === 0) {
				return {
					result: false,
					message: "Must contain at least one variable",
					element: null,
				};
			}
			return {
				result: true,
				message: "",
				element: null,
			};
		} catch {
			return {
				result: false,
				message: "Unknown error",
				element: null,
			};
		}
	},

	calculateTest(elements: IRiskDefinitionFormulaElement[]): boolean {
		try {
			let i = 1;
			let formula = "";
			for (const formulaElement of elements) {
				if (formulaElement.type === FormulaElementType.Variable) {
					formula += i.toString(); //substitiute the variable with a value, need to lookup a real value here
					i++;
				} else if (formulaElement.type === FormulaElementType.Number) {
					formula += isNaN(Number(formulaElement.value)) ? 0 : formulaElement.value;
				} else if (formulaElement.type === FormulaElementType.Operator) {
					if(["(",")","*", "+", "-", "/"].indexOf(formulaElement.value.trim()) >= 0) {
						formula += formulaElement.value;
					} else {
						return false;
					}
				}
			}
			const validateVal = eval(formula);
			return true;
		} catch {
			return false;
		}
	},

	minMaxScores(elements: (IRiskDefinitionFormulaElement | IRiskDefinitionFormulaVariableElement)[], components: IRiskDefinitionComponent[]): { min: number; max: number } {
		const answers: number[] = [];
		const cartesianArg: number[][] = [];
		if (elements.length === 0 || components.length === 0 || !this.validate(elements).result) {
			const result: { min: number; max: number } = { min: 0, max: 0 };
			return result;
		}
		for (const formulaElement of elements) {
			if (formulaElement.type === FormulaElementType.Variable) {
				const component: IRiskDefinitionComponent | undefined = components.find(
					(c: IRiskDefinitionComponent) => c.id === (formulaElement as IRiskDefinitionFormulaVariableElement).component_id
				);
				if (component && component.risk_definition_component_items) {
					const factors: (number | null)[] = component.risk_definition_component_items.map((item) => item.factor);
					cartesianArg.push(factors as number[]);
				}
			}
		}
		const combos: number[][] = FormulaService.cartesian(cartesianArg);
		for (const combo of combos) {
			answers.push(FormulaService.calculate(elements, combo));
		}

		const result: { min: number; max: number } = { min: Math.min(...answers), max: Math.max(...answers) };
		return result;
	},

	getVisualComponentName(elements: IRiskDefinitionFormulaElement[]): string {
		let name = "";
		for (const element of elements) {
			name += element.value;
		}
		return name;
	},

	getCriteriaComboOld(criteriaId: string, riskDefinition: IRiskDefinition, components: { id: number; name: string; items: ISelectListItem[] }[]): number[] {
		//get all possible combinations of components
		if (riskDefinition && components) {
			const answers: { ids: number[]; score: IRiskScore }[] = [];
			const cartesianArgIds: number[][] = [];
			const elements: (IRiskDefinitionFormulaElement | IRiskDefinitionFormulaVariableElement)[] | null = FormulaService.combineAllElements(riskDefinition);
			for (const component of components) {
				const ids: (number | null)[] = component.items.map((item) => parseInt(item.value as string));
				cartesianArgIds.push(ids as number[]);
			}
			const combosIds: number[][] = FormulaService.cartesian(cartesianArgIds);
			for (let i = 0; i < combosIds.length; i++) {
				const score: IRiskScore | null = FormulaService.getRiskScoreFromComponentIds(combosIds[i], riskDefinition);
				if (score && score.criteriaId && score.criteriaId.toString() === criteriaId) {
					answers.push({ ids: combosIds[i], score: score });
				}
			}
			//Now we have the filtered combinaitions that will result in the criteriaId score
			//so pick the middle one as the result
			const selected = answers[Math.floor(answers.length / 2)];
			//console.log(answers);
			return selected.ids;
		} else {
			return [];
		}
	},

	getCriteriaCombo(criteriaId: string, riskDefinition: IRiskDefinition, components: { id: number; name: string; items: ISelectListItem[] }[]): number[] {
		//get all possible combinations of components
		if (riskDefinition && components) {
			let answers: { ids: { id: number; factor: number }[]; score: IRiskScore }[] = [];
			const cartesianArg: { id: number; factor: number }[][] = [];
			const elements: (IRiskDefinitionFormulaElement | IRiskDefinitionFormulaVariableElement)[] | null = FormulaService.combineAllElements(riskDefinition);
			for (const component of components) {
				const ids: ({ id: number; factor: number } | null)[] = component.items.map((item) => {
					return { id: parseInt(item.value as string), factor: parseInt(item.data) };
				});
				cartesianArg.push(ids as { id: number; factor: number }[]);
			}
			const combosResult: { id: number; factor: number }[][] = FormulaService.cartesian1(cartesianArg);
			for (let i = 0; i < combosResult.length; i++) {
				const score: IRiskScore | null = FormulaService.getRiskScoreFromComponentIds(
					combosResult[i].map((r) => r.id),
					riskDefinition
				);
				if (score && score.criteriaId && score.criteriaId.toString() === criteriaId) {
					answers.push({ ids: combosResult[i], score: score });
				}
				const temp = combosResult[i].map((r) => r.id);
			}
			//Now we have the filtered combinations that will result in the criteriaId score
			//for each component pick the middle of the range in terms of factor
			const componentCount: number = answers[0].ids.length;
			for (let i = 0; i < componentCount; i++) {
				const factors = answers.map((a) => a.ids[i].factor);
				factors.sort();
				const factor = factors[Math.floor(factors.length / 2)];
				answers = answers.filter((a) => a.ids[i].factor === factor);
			}

			const selected = answers[Math.floor(answers.length / 2)];
			return selected.ids.map((i) => i.id);
		} else {
			return [];
		}
	},

	getNextCriteriaId(selectedId: string, steps: number, criteriaOptions: { text: string; value: string }[]): string {
		const foundIndex = criteriaOptions.findIndex((o) => o.value === selectedId);
		if (foundIndex >= 0) {
			if (foundIndex + steps > 0 && criteriaOptions.length >= foundIndex + steps + 1) {
				return criteriaOptions[foundIndex + steps].value as string;
			} else {
				return selectedId;
			}
		} else {
			return selectedId;
		}
	},

	getCriteriaOptions(riskDefinition: IRiskDefinition): { text: string; value: string }[] {
		const result: { text: string; value: string }[] = [];
		if (riskDefinition && riskDefinition.risk_definition_criteria) {
			result.push({ text: "Select an initial value", value: "" });
			for (const criterion of riskDefinition.risk_definition_criteria) {
				result.push({ text: criterion.name, value: criterion.id.toString() });
			}
		}
		return result;
	},
};

export { FormulaService };

