<template>
	<div>
		<div v-if="notificationStatus && notificationStatus.notificationsSupported">
			<div v-if="notificationStatus.notificationsBlocked">
				<p>Web Push Notifications are currently blocked by your browser. We recommend enabling Web Push Notifications to get the most form this app.</p>
				<p>
					<a href="#">View instructions for enabling Web Push Notifications for your operating system. (Not yet implemented)</a>
				</p>
				<!-- <p>
					<a href="#">View our privacy policy</a>
				</p> -->
				<b-button @click="onRefreshClick">Refresh</b-button>
			</div>
			<div v-else>
				<div v-if="!notificationStatus.notificationsEnabled">
					<p>Web Push Notifications are currently disabled on this device. We recommend enabling Web Push Notifications to get the most from this app.</p>
				</div>
				<b-button class="my-3 mr-3" @click="onToggleSubscriptionClick" :disabled="buttonDisabled">
					{{ notificationStatus.notificationsEnabled ? "Disable" : "Enable" }} Notifications
				</b-button>
				<div v-if="notificationStatus.notificationsEnabled">
					<b-form-group id="rm-mn-tst-grp" label="Send yourself a test message" label-for="rm-mn-tst-inpt" class="font-weight-bold">
						<b-form-input id="input-1" v-model="message" trim></b-form-input>
					</b-form-group>
					<b-button @click="onTestMessageSendClick">Send</b-button>
				</div>
			</div>
		</div>
		<div v-else>Web Push Notifications are not currently supported on this device.</div>
	</div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { INotificationStatus, NotificationHelper } from "../../../core/helpers/NotificationHelper";
import ApiService from "../../services/ApiService";

@Component({
	name: "notification-manager",
})
/**
 * Note that the notification status must be set elsewhere in auth store by calling setNotificationStatus in the Notification Helper.
 */
export default class NotificationManager extends Vue {
	//data properties
	buttonDisabled = false;
	message = "Test Message";

	//computed properties
	get notificationStatus(): INotificationStatus {
		return this.$store.getters["auth/notificationStatus"];
	}

	//lifecycle hooks
	created(): void {}

	//methods
	onToggleSubscriptionClick(): any {
		this.buttonDisabled = true;
		NotificationHelper.toggleSubscription().finally(() => (this.buttonDisabled = false));
	}

	onRefreshClick(event: Event): void {
		window.location.reload();
	}

	onTestMessageSendClick() {
		// get the textarea content and send it to the server.
		// The server will create a notifications that will be pushed
		const resource = "/api/testpushnotify";
		const userName: string = this.$store.getters["auth/currentUserData"].email;
		return ApiService.post(resource, {
			username: userName,
			message: this.message,
		}).then((response) => {
			this.message = "";
		});
	}
	//handlers
}
</script>
