import ApiService from "../../../core/services/ApiService";
import { AxiosResponse } from "axios";
import { IControl } from "../interfaces/ControlInterface";
import { IFile } from "../../../core/interfaces/FileInterface";


export default abstract class ControlService {
    static async getControlsForAssessment(riskAssessmentId: number | null | undefined): Promise<IControl[]> {
        if (riskAssessmentId) {
            const resource: string = "/api/controls/assessment/" + riskAssessmentId.toString();
            try {
                const response: AxiosResponse<any> = await ApiService.get(resource);
                if (response.data && response.data.data) {
                    //response.data should an array of objects representing risks templates.
                    return response.data.data;
                } else {
                    throw new Error("Invalid Response");
                }
            } catch (e: any) {
                let msg = "Get controls failed.";
                if (e.response) {
                    msg = e.response.data.message;
                } else if (e.message) {
                    msg = e.message;
                }
                throw new Error(msg);
            }
        } else {
            throw new Error("Risk assessment not specified");
        }
    }

    static async getControlsForRisk(riskId: number | null | undefined): Promise<IControl[]> {
        if (riskId) {
            const resource: string = "/api/controls/risk/" + riskId.toString();
            try {
                const response: AxiosResponse<any> = await ApiService.get(resource);
                if (response.data && response.data) {
                    //response.data should an array of objects representing risks templates.
                    return response.data;
                } else {
                    throw new Error("Invalid Response");
                }
            } catch (e: any) {
                let msg = "Get controls failed.";
                if (e.response) {
                    msg = e.response.data.message;
                } else if (e.message) {
                    msg = e.message;
                }
                throw new Error(msg);
            }
        } else {
            throw new Error("Risk not specified");
        }
    }

    static async getControlById(id: number): Promise<IControl> {
        const resource: string = "/api/control/" + id.toString();
        try {
            const response: AxiosResponse<any> = await ApiService.get(resource);
            if (response.data && response.data.data) {
                //response.data should a single object representing a single risk.
                return response.data.data;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            let msg = "Get control failed.";
            if (e.response) {
                msg = e.response.data.message;
            } else if (e.message) {
                msg = e.message;
            }
            throw new Error(msg);
        }
    }

    static async saveControl(control: IControl, riskAssessmentId: number | null, riskId: number | null): Promise<IControl> {
        let resource = "";
        let response: AxiosResponse<any>;
        try {
            if (control.id > 0) {
                //update
                resource =
                    "/api/control/" +
                    control.id.toString() +
                    (riskAssessmentId ? "?raid=" + (riskAssessmentId as number).toString() : "") +
                    (riskId ? "?rid=" + (riskId as number).toString() : "");
                response = await ApiService.put(resource, control);
            } else {
                //add new
                resource =
                    "/api/control" +
                    (riskAssessmentId ? "?raid=" + (riskAssessmentId as number).toString() : "") +
                    (riskId ? (riskAssessmentId ? "&rid=" : "?rid=") + (riskId as number).toString() : "");
                response = await ApiService.post(resource, control);
            }

            if (response.data && response.data.data) {
                //response.data should a single object representing a control.
                return response.data.data;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            throw e;
        }
    }

    static async addNewControls(controls: IControl[], riskAssessmentId: number | null, riskId: number | null): Promise<IControl[]> {
        const resource: string =
        "/api/multiple_controls" +
        (riskAssessmentId ? "?raid=" + (riskAssessmentId as number).toString() : "") +
        (riskId ? (riskAssessmentId ? "&rid=" : "?rid=") + (riskId as number).toString() : "");
        const response = await ApiService.post(resource, controls);
        if (response.data && response.data.data) {
            //response.data should an array of control objects representing a control.
            return response.data.data;
        } else {
            throw new Error("Invalid Response");
        }
    }

    static async saveControlRisks(controlRiskXref: { controlId: number; riskIds: number[]; }[]): Promise<void> {
        let resource = "";
        let response: AxiosResponse<any>;
        try {
            resource = "/api/control_risks";
            response = await ApiService.put(resource, controlRiskXref);
        } catch (e: any) {
            let msg = "Save control failed.";
            if (e.response) {
                msg = e.response.data.message;
            } else if (e.message) {
                msg = e.message;
            }
            throw new Error(msg);
        }
    }

    static async deleteControl(controlId: number): Promise<void> {
        try {
            const resource = "/api/control/" + controlId.toString();
            await ApiService.delete(resource);
        } catch (e: any) {
            let msg = "Delete control failed.";
            if (e.response) {
                msg = e.response.data.message;
            } else if (e.message) {
                msg = e.message;
            }
            throw new Error(msg);
        }
    }

    static async setControlStatus(controlId: number, statusId: number, updateComment: string): Promise<IControl> {
        let response: AxiosResponse<any>;
        let toReturn: IControl | null = null;

        if (controlId != null && controlId > 0 && !(statusId === undefined || statusId === null)) {
            //issue and status exist, issue is not new
            const resource: string = "/api/control/status/" + controlId.toString();
            const payload: { statusId: string; updateComment: string; } = { statusId: statusId.toString(), updateComment: updateComment };
            response = await ApiService.put(resource, payload);
            toReturn = response.data.data; //get a fresh copy from the database as it contains related records
        } else {
            throw new Error("Issue or Status not specified");
        }
        if (toReturn) {
            return toReturn;
        } else {
            throw new Error("Invalid Response");
        }
    }

    static async addFile(controlId: number, fileId: number): Promise<IFile> {
        if (controlId && fileId) {
            const resource: string = "/api/controls/addfile/" + controlId.toString() + "/" + fileId.toString() + "?url=true";

            const response: AxiosResponse<any> = await ApiService.get(resource);
            if (response.data && response.data.data) {
                //response.data should an IFile object.
                return response.data.data;
            } else {
                throw new Error("Invalid Response");
            }
        } else {
            throw new Error("controls or File not specified");
        }
    }
}
