const controlErrorMessages: any = {
	ERR_GET_CONTROLS_FAILED: "Failed to get controls. Please try again.",
	ERR_SAVE_CONTROL_FAILED: "Failed to save control. Please try again.",
};

const controlInfoMessages: any = {};

export const riskDefinitionConstants = {
	controlErrorMessages,
	controlInfoMessages,
};

export { controlErrorMessages };
export { controlInfoMessages };
