const riskDefinitionErrorMessages: any = {
	ERR_GET_RISK_DEFINITIONT_FAILED: "Failed to get risk definition. Please try again.",
	ERR_SAVE_RISK_DEFINITION_FAILED: "Failed to save risk definition. Please try again.",
};

const riskDefinitionInfoMessages: any = {};

export const riskDefinitionConstants = {
	riskDefinitionErrorMessages,
	riskDefinitionInfoMessages,
};

export { riskDefinitionErrorMessages };
export { riskDefinitionInfoMessages };
