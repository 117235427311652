<template>
    <b-modal lazy scrollable :id='id' hide-footer hide-header body-class='p-0' size='md'>
        <b-card class='mx-auto w-100' no-body>
            <b-card-header header-bg-variant="light">
                <i class="rm-menu-h-icon">
                    <font-awesome-icon icon="user-lock"></font-awesome-icon>
                </i>
                <span class="px-2">Change Password</span>
            </b-card-header>
            <b-card-body>
                <div v-if='errorMsg' class='alert alert-danger'>{{ errorMsg }}</div>
                <div v-if='infoMsg' class='alert alert-info'>{{ infoMsg }}</div>
                <b-form class='mb-3'>
                    <div class='form-group row'>
                        <label for='password-input' class='col-md-4 col-form-label text-md-right'>Current Password</label>
                        <div class='col-md-6'>
                            <b-form-input autofocus id='password-input' v-model='password' type='password'></b-form-input>
                        </div>
                    </div>
                    <div class='form-group row'>
                        <label for='new-password-input' class='col-md-4 col-form-label text-md-right'>New Password</label>
                        <div class='col-md-6'>
                            <b-form-input id='new-password-input' v-model='newPassword' type='password' autocomplete='off'></b-form-input>
                        </div>
                    </div>
                    <div class='form-group row'>
                        <label for='confirm-password-input' class='col-md-4 col-form-label text-md-right'>Confirm Password</label>
                        <div class='col-md-6'>
                            <b-form-input id='confirm-password-input' v-model='confirmPassword' type='password' autocomplete='off'></b-form-input>
                        </div>
                    </div>
                    <div class='form-group row mb-0'>
                        <div class='col-md-8 offset-md-4'>
                            <div>
                                <b-button ref='rmResetPasswordBtn' type='button' variant='primary' @click='resetClick'>Change Password</b-button>
                                <b-button variant='link' @click='closeClicked'>Cancel</b-button>
                            </div>
                        </div>
                    </div>
                </b-form>
            </b-card-body>
        </b-card>
    </b-modal>
</template>

<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ChangePassword extends Vue {
    @Prop({ type: String, default: 'change-password-modal'}) readonly id!: string;

	//Lifecycle hooks************************************************
	created() {
        this.$store.commit('auth/clearLoginModel');
	}

	//Computed Properties*************************************************
    get isWorking(): boolean {
		return this.$store.getters['common/isWorking'];
	}
	get errorMsg() {
		return this.$store.getters['auth/errorMessage'];
	}
	get infoMsg() {
		return this.$store.getters['auth/infoMessage'];
	}
	get password() {
		return this.$store.getters['auth/password'];
	}
	set password(value) {
        this.clearMessages();
		this.$store.commit('auth/password', value);
	}
	get newPassword() {
		return this.$store.getters['auth/newPassword'];
	}
	set newPassword(value) {
        this.clearMessages();
		this.$store.commit('auth/newPassword', value);
	}
	get confirmPassword() {
		return this.$store.getters['auth/confirmPassword'];
	}
	set confirmPassword(value) {
        this.clearMessages();
		this.$store.commit('auth/confirmPassword', value);
	}
	//Methods
	resetClick(event: Event): void {
        event.preventDefault();
		this.$store.dispatch('auth/changePassword')
            .then((result: boolean) => {
                if (result) {
                    this.$bvModal.hide(this.id);
                }
            });
	}
    clearMessages() {
        this.$store.commit('auth/clearErrorMessage');
		this.$store.commit('auth/clearInfoMessage');
        this.$store.commit('common/errorMsg', null);
		this.$store.commit('common/infoMsg', null);
    }
    closeClicked(): void {
        this.clearMessages();
		this.$bvModal.hide(this.id);
	}
}
</script>
