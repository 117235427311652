const CookieHelper = {
	setCookie(cname: string, cvalue: string, exminutes: number) {
		const d: Date = new Date();
		d.setTime(d.getTime() + exminutes * 60 * 1000);
		const expires: string = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	},
	getCookie(cname: string): string {
		const name: string = cname + "=";
		const ca: string[] = document.cookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == " ") {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	},
	cookieExists(cname: string): boolean {
		return this.getCookie(cname) != "";
	},
};

export { CookieHelper };
