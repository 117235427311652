const riskErrorMessages: any = {
	ERR_GET_RISKS_FAILED: "Failed to get risk. Please try again.",
	ERR_SAVE_RISK_FAILED: "Failed to save risk. Please try again.",
};

const riskInfoMessages: any = {};

export const riskDefinitionConstants = {
	riskErrorMessages,
	riskInfoMessages,
};

export { riskErrorMessages };
export { riskInfoMessages };
