import ApiService from "../../../core/services/ApiService";
import { AxiosResponse } from "axios";

import { IPerson } from "../interfaces/PersonInterface";
import { IIncidentFilter } from "../interfaces/IncidentFilterInterface";
import { IUserViewFilter } from "@/core/interfaces/UserViewInterface";

export default abstract class PersonService {
	private static serialize(obj: any, prefix?: string): string {
		let str: string[] = [],
			p: string;
		for (p in obj) {
			if (obj.hasOwnProperty(p)) {
				const k = prefix ? prefix + "[" + p + "]" : p;
				const v = obj[p];
				str.push(v !== null && typeof v === "object" ? this.serialize(v, k) : encodeURIComponent(k) + "=" + encodeURIComponent(v));
			}
		}
		return str.join("&");
	}

	static async getPersonById(id: number): Promise<IPerson> {
		if (id && id > 0) {
			const resource: string = "/api/person/" + id.toString();
			try {
				const response: AxiosResponse<any> = await ApiService.get(resource);
				if (response.data && response.data.data) {
					//response.data should an objects representing a person.
					return response.data.data;
				} else {
					throw new Error("Invalid Response");
				}
			} catch (e: any) {
				let msg = "Get person failed.";
				if (e.response) {
					msg = e.response.data.message;
				} else if (e.message) {
					msg = e.message;
				}
				throw new Error(msg);
			}
		} else {
			throw new Error("Id not specified");
		}
	}

	static async getPeopleByIncidentId(id: number): Promise<IPerson[]> {
		if (id && id > 0) {
			const resource: string = "/api/person/incident" + id.toString();
			try {
				const response: AxiosResponse<any> = await ApiService.get(resource);
				if (response.data && response.data.data) {
					//response.data should an objects representing an task.
					return response.data.data;
				} else {
					throw new Error("Invalid Response");
				}
			} catch (e: any) {
				let msg = "Get people data failed.";
				if (e.response) {
					msg = e.response.data.message;
				} else if (e.message) {
					msg = e.message;
				}
				throw new Error(msg);
			}
		} else {
			throw new Error("Id not specified");
		}
	}

	// gets all people visible to this user
	static async getPeople(filter: IIncidentFilter | IUserViewFilter | null = null): Promise<IPerson[]> {
		const resource: string = "/api/people" + (filter ? "?" + this.serialize(filter) : "");
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data && response.data.data) {
				//response.data should an objects representing an task.
				return response.data.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get people data failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async savePerson(person: IPerson): Promise<IPerson> {
		let resource = "";
		let response: AxiosResponse<any>;
		let toReturn: IPerson | null = null;
		try {
			if (person.id != null && person.id > 0) {
				//--- update ---
				resource = "/api/person/" + person.id.toString();
				response = await ApiService.put(resource, person);
				toReturn = response.data.data; // we return the original as it has all the updated details
			} else {
				// --- add new ---
				resource = "/api/person";
				response = await ApiService.post(resource, person);
				toReturn = response.data.data; // when creating a new record we get the full object back
			}

			if (toReturn) {
				return toReturn;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Failed to save person data.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async deletePerson(id: number): Promise<void> {
		try {
			const resource = "/api/person/" + id.toString();
			await ApiService.delete(resource);
		} catch (e: any) {
			let msg = "Failed to delete person.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}
}
