import { IEvent, IEventDefinition } from "../interfaces/EventInterface";

import { EventStatus } from "../enums/EventEnum";

const EventHelperService = {
    /**
     * returns an empty risk object
     */
    getNewEvent(folderId: number | string | null): IEvent {
        const newEventDefinition = this.getNewEventDefinition(false);
        return {
            id: -new Date().valueOf(),
            folder_id: folderId,
            description: "New Event",
            start_date_time: "",
            end_date_time: "",
            timezone: null,
            effective_timezone: null,
            venue_type: '',
            event_type: '',
            lku_event_type_id: null,
            lku_event_status_id: EventStatus.Planning,
            event_definition_id: newEventDefinition.id,
            event_definition: newEventDefinition,
        };
    },

    getBlankEvent(isTemplate: boolean): IEvent {
        return {
            id: -1,
            folder_id: -1,
            description: "",
            start_date_time: "",
            end_date_time: "",
            timezone: null,
            effective_timezone: null,
            venue_type: '',
            event_type: '',
            lku_event_type_id: null,
            lku_event_status_id: EventStatus.Planning,
            event_definition_id: -1,
            event_definition: {
                id: -1,
                name: "",
                description: null,
                //event_type_id: -1,
                risk_assessment_id: null,
                risk_definition_id: null,
                is_template: isTemplate,
                is_default: false,
                event_definition_task_priorities: [],
                event_definition_issue_types: [],
                event_definition_access_ids: [],
                event_definition_location_ids: [],
                incident_notifiables: [],
                incident_notifiables_select_list: [],
            },
        };
    },

    getNewEventDefinition(isTemplate: boolean): IEventDefinition {
        return {
            id: -new Date().valueOf(),
            name: "New Event Definition",
            description: null,
            //event_type_id: null,
            risk_assessment_id: null,
            risk_definition_id: null,
            is_template: isTemplate,
            is_default: false,
            event_definition_task_priorities: [],
            event_definition_issue_types: [],
            event_definition_access_ids: [],
            event_definition_location_ids: [],
            incident_notifiables: [],
            incident_notifiables_select_list: [],
        };
    },
};

export { EventHelperService };
