import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IControlOwnerSubstitutions, IMultipleRiskAssessmentDashboardData, IMultipleRiskAssessmentReportsData, IRiskAssessment } from "../interfaces/RiskAssessmentInterface";

import ApiService from "../../../core/services/ApiService";
import { IRiskAssessmentFilter } from "../interfaces/RiskAssessmentFilterInterface";
import { serialize } from "@/core/helpers/SerializeHelper";

export default abstract class RiskAssessmentService {
    static async getRiskAssessments(folderId: number | string): Promise<any[]> {
        const resource = "/api/risk_assessments/" + folderId.toString();
        const response: AxiosResponse<any> = await ApiService.get(resource);
        if (response.data && response.data.data) {
            //response.data.data should an array of objects representing each record with fieldname/value properties.
            return response.data.data;
        } else {
            throw new Error("Invalid Response");
        }
    }

    static async getRiskAssessmentsForFilter(filter: IRiskAssessmentFilter): Promise<IRiskAssessment[]> {
        const resource = "/api/risk_assessments/filter" + "?" + serialize(filter);
        const response: AxiosResponse<any> = await ApiService.get(resource);
        if (response.data) {
            return response.data;
        } else {
            throw new Error("Invalid Response");
        }
    }

    static async getRiskAssessmentDashboardData(riskAssessmentIds: number[]): Promise<IMultipleRiskAssessmentDashboardData> {
        const resource = '/api/risk_assessments/data/dashboard?' + serialize(riskAssessmentIds);
        const response: AxiosResponse<any> = await ApiService.get(resource);
        if (response.data) {
            return response.data;
        } else {
            throw new Error("Invalid Response");
        }
    }

    static async getRiskAssessmentReportsData(riskAssessmentIds: number[]): Promise<IMultipleRiskAssessmentReportsData> {
        const resource = '/api/risk_assessments/data/reports?' + serialize(riskAssessmentIds);
        const response: AxiosResponse<any> = await ApiService.get(resource);
        if (response.data) {
            return response.data;
        } else {
            throw new Error("Invalid Response");
        }
    }

    static async saveRiskAssessment(riskAssessment: IRiskAssessment): Promise<any> {
        let resource = "";
        let response: AxiosResponse<any>;
        if (riskAssessment.id > 0) {
            //update
            resource = "/api/risk_assessment/" + riskAssessment.id.toString();
            response = await ApiService.put(resource, riskAssessment);
        } else {
            //add new
            resource = "/api/risk_assessment";
            response = await ApiService.post(resource, riskAssessment);
        }
        if (response.data && response.data.data) {
            //response.data should a single object representing a risk assessment.
            return response.data.data;
        } else {
            throw new Error("Invalid Response");
        }
    }

    static async copyRiskAssessment(riskAssessment: IRiskAssessment, copyFromId: number) {
        const resource: string = "/api/risk_assessment/copy_from/" + copyFromId.toString();
        const response = await ApiService.post(resource, riskAssessment);
        if (response.data && response.data.data) {
            //response.data should a single object representing a risk assessment.
            return response.data.data;
        } else {
            throw new Error("Invalid Response");
        }
    }

    static async copyRisks(copyFromId: number, copyToId: number, controlOwnerSubstitutions: IControlOwnerSubstitutions, riskIds: number[]): Promise<void> {
        const resource: string = "/api/risk_assessment/copy_risks/" + copyFromId.toString() + "/" + copyToId.toString();
        //doesnt return anything
        await ApiService.post(resource, { control_owner_substitutions: controlOwnerSubstitutions, risk_ids: riskIds });
    }
    // --------- Download risk assessment as Excel ------------------

    static async downloadRiskAssessment(raId: number): Promise<any> {
        const resource: string = "/api/export/risk_assessments/" + raId.toString();
        // options to handle binary
        const config: AxiosRequestConfig = {
            responseType: "blob",
        };
        const response: AxiosResponse<any> = await ApiService.get(resource, config);
        if (response.data) {
            const data = response.data;
            return data;
        } else {
            throw new Error("Invalid Response");
        }
    }

    /**
     * Use this when adding risks and controls from their descriptions when using the risk data search component
     * @param controlOwnerSubstitutions
     * @param copyFromId
     * @param copyToId
     * @returns
     */
    static async addRisksControls(copyToId: number, risks: { risk: string; controls: string[] }[]) {
        const resource: string = "/api/risk_assessment/add_risks/" + copyToId.toString();
        //response is an empty response.
        await ApiService.post(resource, risks);
    }

    static async getRiskAssessmentById(id: number): Promise<IRiskAssessment> {
        const resource: string = "/api/risk_assessment/" + id.toString();
        const response: AxiosResponse<any> = await ApiService.get(resource);
        if (response.data && response.data.data) {
            //response.data should a single object representing a set of risk definitions.
            return response.data.data;
        } else {
            throw new Error("Invalid Response");
        }
    }

    static async deleteRiskAssessment(id: number): Promise<void> {
        const resource: string = "/api/risk_assessment/" + id.toString();
        await ApiService.delete(resource);
    }

    /**
     * Allocates risk assessments to a folder
     * @param eventIds
     * @param folderId
     *
     * @return number of risk assessments moved
     */
    static async setRiskAssessmentsFolder(riskAssessmentIds: number[], folderId: number | string): Promise<number> {
        if (riskAssessmentIds && riskAssessmentIds.length > 0) {
            const resource = "/api/risk_assessments/folder/" + folderId.toString();
            const response: AxiosResponse<number> = await ApiService.put(resource, riskAssessmentIds);
            return response.data; //should return the number of items moved.
        } else {
            throw new Error("No Risk Assessment specified.");
        }
    }

    static async shareRiskAssessment(riskAssessmentId: number, userIds: number[]): Promise<void> {
        const resource: string = "/api/risk_assessment/share/" + riskAssessmentId.toString();
        await ApiService.put(resource, { userIds: userIds });
    }
}
