import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IFile } from "../../../core/interfaces/FileInterface";
import ApiService from "../../../core/services/ApiService";
import { IRisk } from "../interfaces/RiskInterface";
import { LanguageHelper } from "@/core/helpers/LanguageHelper";

export default abstract class RiskService {
	static async getRisks(riskAssessmentId: number | null | undefined): Promise<IRisk[]> {
		if (riskAssessmentId) {
			const resource: string = "/api/risks/" + riskAssessmentId.toString();
			try {
				const response: AxiosResponse<any> = await ApiService.get(resource);
				if (response.data && response.data.data) {
					//response.data should an array of objects representing risks templates.
					return response.data.data;
				} else {
					throw new Error("Invalid Response");
				}
			} catch (e: any) {
				let msg = "Get risks failed.";
				if (e.response) {
					msg = e.response.data.message;
				} else if (e.message) {
					msg = e.message;
				}
				throw new Error(msg);
			}
		} else {
			throw new Error("Risk assessment not specified");
		}
	}

	static async getRiskById(id: number): Promise<IRisk> {
		const resource: string = "/api/risk/" + id.toString();
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data && response.data.data) {
				//response.data should a single object representing a single risk.
				return response.data.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get risk failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async saveRisk(risk: IRisk, riskAssessmentId: number | null): Promise<IRisk> {
		let resource = "";
		let response: AxiosResponse<any>;
		try {
			if (risk.id > 0) {
				//update
				resource = "/api/risk/" + risk.id.toString() + (riskAssessmentId ? "?raid=" + (riskAssessmentId as number).toString() : "");
				response = await ApiService.put(resource, risk);
			} else {
				//add new
				resource = "/api/risk" + (riskAssessmentId ? "?raid=" + (riskAssessmentId as number).toString() : "");
				response = await ApiService.post(resource, risk);
			}

			if (response.data && response.data.data) {
				//response.data should a single object representing a risk.
				return response.data.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			throw e;
		}
	}

	static async addNewRisks(risks: IRisk[], riskAssessmentId: number | null): Promise<IRisk[]> {
        const resource: string =
        "/api/multiple_risks" + (riskAssessmentId ? "?raid=" + (riskAssessmentId as number).toString() : "");
        const response = await ApiService.post(resource, risks);
        if (response.data && response.data.data) {
            //response.data should an array of risk objects representing a control.
            return response.data.data;
        } else {
            throw new Error("Invalid Response");
        }
    }

	static async saveRiskControls(riskControlXref: { riskId: number; controlIds: number[] }[]): Promise<void> {
		let resource = "";
		let response: AxiosResponse<any>;
		try {
			resource = "/api/risk_controls";
			response = await ApiService.put(resource, riskControlXref);
		} catch (e: any) {
			let msg = "Save risk failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async deleteRisk(riskId: number): Promise<void> {
		try {
			const resource = "/api/risk/" + riskId.toString();
			await ApiService.delete(resource);
		} catch (e: any) {
			let msg = "Delete risk failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async uploadRiskRegisterFile(file: File, riskAssessmentId: number) {
		const formData: FormData = new FormData();
		formData.append("csv_file", file);
		formData.append("risk_assessment_id", riskAssessmentId.toString());
		formData.append("lang", LanguageHelper.getLang());
		const axiosData: AxiosRequestConfig = {
			url: "/api/risk/import",
			method: "POST",
			data: formData,
			headers: { "Content-Type": "multipart/form-data" },
		};
		await ApiService.customRequest(axiosData);
	}

	static async addFile(riskId: number, fileId: number): Promise<IFile> {
		if (riskId && fileId) {
			const resource: string = "/api/risk/addfile/" + riskId.toString() + "/" + fileId.toString() + "?url=true";

			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data && response.data.data) {
				//response.data should an IFile object.
				return response.data.data;
			} else {
				throw new Error("Invalid Response");
			}
		} else {
			throw new Error("Issue or File not specified");
		}
	}
}
