import { IIncident } from "../interfaces/IncidentInterface";
import { ICorrectiveAction} from "../interfaces/CorrectiveActionInterface";
import { IPerson } from "../interfaces/PersonInterface";
import { IncidentStatus } from "../enums/IncidentEnum";

import dayjs from "dayjs";
import { IIncidentInvestigation } from "../interfaces/IncidentInvestigationInterface";
import { IIncidentFilterState, IIncidentSelection } from "../interfaces/IncidentFilterInterface";
import { IIssue } from "@/modules/Issues/interfaces/IssueInterface";
import { IRelatedRecords } from "@/core/interfaces/RelatedRecordInterface";
import RelatedRecordHelper from "@/core/helpers/RelatedRecordHelper";
import { IEvent } from "@/modules/Issues/interfaces/EventInterface";

const IncidentHelperService = {
	/**
	 * returns an empty incident object
	 */
	getEmptyIncident(): IIncident {
		return {
			id: -new Date().valueOf(),
			status_id: IncidentStatus.Draft,
            status: {
				name: "Draft",
				color: "#035efc"
			},
			description: "",
			date_time: dayjs().format("YYYY-MM-DD HH:mm:ss"),
			incident_type_id: 0,
			updates: [],
            update_count: 0,
			people: [],
            people_count: 0,
			corrective_actions: [],
            corrective_action_count: 0,
			incident_investigations: [],
			incident_investigation_count: 0,
			reported_by_id: -1, //set to dummy value, easier if it non-optional, is set to current user when storing in backend
			access_permissions: [],
			related_records: RelatedRecordHelper.getEmptyRelatedRecords(),
            attachment_count: 0
		};
	},

	/**
	 * returns a incident filter state that will fetch all incidents for the given related records
	 */
	getIncidentFilterStateForRecords(records: IRelatedRecords): IIncidentFilterState {
		return {
			incidents: null,
			filteredIncidents:  null,
			filter: {},
			selectedDateRangeDays: null,
			selectedDateRangeStart: null,
			selectedDateRangeEnd: null,
			related_records: records,
			archived: ["dont_include"],
			people: [],
            licenseOptions: [],
		};
	},

    getIncidentFilterStateForEventId(eventId: number) {
        return this.getIncidentFilterStateForRecords({
            events: [{id: eventId, text: "Parent Event", issues: []}],
            risk_assessments: [],
            incidents: [],
        });
    },

	/**
	 * returns a incident filter state that will fetch all incidents for the last 100 days
	 */
	getIncidentFilterStateForLast100Days(): IIncidentFilterState {
		return {
			incidents: null,
			filteredIncidents:  null,
			filter: {},
			selectedDateRangeDays: 100,
			selectedDateRangeStart: dayjs().subtract(100, 'days').format("YYYY-MM-DD HH:mm:ss"),
			selectedDateRangeEnd: dayjs().format("YYYY-MM-DD HH:mm:ss"),
			related_records: RelatedRecordHelper.getEmptyRelatedRecords(),
			archived: ["dont_include"],
			people: [],
            licenseOptions: [],
		};
	},

	/**
	 * returns an empty incident object with certain fields preset by an issue
	 */
	createIncidentFromIssue(issue: IIssue): IIncident {
		return {
			id: -new Date().valueOf(),
			status_id: IncidentStatus.Draft,
			status: {
				name: "Draft",
				color: "#035efc"
			},
			description: issue.description,
			details: issue.details as string | undefined,
			risk_definition_criterion_id: issue.risk_definition_criterion_id,
			risk_definition_criterion: issue.risk_definition_criterion,
			primary_location_id: issue.primary_location_id as number | undefined,
			location: issue.location_data,
			exact_location: issue.exact_location as string | undefined,
			lat: issue.lat,
			long: issue.long,
			accuracy: issue.accuracy,
			date_time: issue.issue_date_time,
			incident_type_id: 0,
			updates: [],
            update_count: 0,
			people: [],
            people_count: 0,
			corrective_actions: [],
            corrective_action_count: 0,
			incident_investigations: [],
			incident_investigation_count: 0,
			reported_by_id: -1,
			access_permissions: null,
			related_records: RelatedRecordHelper.getRelatedRecordsForIssue(issue, null),
			fileIds: issue.fileIds,
            attachment_count: issue.fileIds ? issue.fileIds.length : 0,
		};
	},

    createIncidentFromEventId(eventId: number): IIncident {
        let incident = this.getEmptyIncident();
        incident.related_records = RelatedRecordHelper.getRelatedRecordsForEventId(eventId);
        return incident;
    },

    createIncidentFromEvent(event: IEvent): IIncident {
        let incident = this.getEmptyIncident();
        incident.related_records = RelatedRecordHelper.getRelatedRecordsForEvent(event);
        return incident;
    },

	getInitialIncidentSelectionForEventId(id: number): IIncidentSelection {
		return  {
            filter_state: this.getIncidentFilterStateForRecords(RelatedRecordHelper.getRelatedRecordsForEvent({ id: id, description: "Parent Event" } as IEvent)),
            selected_ids: [],
		}
	}

};

const CorrectiveActionHelperService = {
	/**
	 * returns an empty corrective action object
	 */
	getEmptyCorrectiveAction(incidentId: number): ICorrectiveAction {
		return {
			id: -new Date().valueOf(),
			incident_id: incidentId,
			status_id: 1,
			status_name: "Open",
			priority_id: null,
			description: "",
			details: "",
			when_completed: null,
			allocated: [],
			users: [],
			due_date_time: dayjs().format("YYYY-MM-DD HH:mm:ss"),
			is_overdue: false,
			is_neardue: false,
			updates: [],
			tasks: [],
		};
	},
};

const PersonHelperService = {
	/**
	 * returns and empty person object
	 */
	 getEmptyPerson(incidentId: number): IPerson {
		return {
			id: -new Date().valueOf(),
			incident_id: incidentId,
			type_id: null,
			involvement_type_id: null,
			name: "",
			email: "",
			statement: "",
			notes: "",
			phone_number: ""
		};
	}
}

const IncidentInvestigationHelperService = {
	getEmptyIncidentInvestigation(incident: IIncident | null | undefined, incident_id: number | null | undefined): IIncidentInvestigation {
		return {
			id: -new Date().valueOf(),
			incident_id: incident ? incident.id : incident_id ? incident_id : -new Date().valueOf(),
			incident: {
				generated_id: incident ? incident.generated_id : undefined,
				description: incident ? incident.description : "",
				access: incident ? incident.user_access : undefined,
			},
			name: "",
			description: "",
			updates: [],
			update_count: 0,
			fileIds: [],
			attachment_count: 0,
            team: [],
            due_at: "",
            incident_description: "",
            incident_date: "",
            reportable: false,
            causes: [],
            cause_corrective_actions: []
		};
	}
}

export {
	IncidentHelperService,
	CorrectiveActionHelperService,
	PersonHelperService,
	IncidentInvestigationHelperService,
};
