import ApiService from "./ApiService";
import { AxiosResponse } from "axios";
import ITeam from "../interfaces/TeamInterface";
//import TeamModel from "../../core/models/TeamModel";

export abstract class TeamService {
	static getEmptyTeam(): ITeam {
		return {
			id: -new Date().valueOf(),
			name: "New Team",
			description: "",
			active: true,
			users: [],
		};
	}

	static async getTeam(teamId: number): Promise<ITeam> {
		//licenseId: number,
		const resource: string = "/api/team/" + teamId.toString(); //+ licenseId.toString() + "/"

		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data) {
				return response.data;
			} else {
				throw new Error("Invalid Response getting team");
			}
		} catch (e: any) {
			let msg = "Get team failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	// This method is used in teams mtce, slightly different API call
	static async getTeams(): Promise<ITeam[]> {
		const resource = "/api/teams"; // get teams for current license only?
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data) {
				//response.data should an array of ITeam objects representing each record with fieldname/value properties.
				return response.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get teams failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	// This one is for teams when editing a user - slightly different api call to that above
	static async getAllTeams(): Promise<ITeam[]> {
		const resource = "/api/select/teams/0"; // get teams for current license only?
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data) {
				//response.data should an array of ITeam objects representing each record with fieldname/value properties.
				return response.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get teams failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async saveNewTeam(team: any): Promise<ITeam> {
		const resource = "/api/team";
		try {
			const response: AxiosResponse<any> = await ApiService.post(resource, team);
			if (response.data) {
				//response.data should a single ITeam object with fieldname/value properties representing the new team.
				return response.data.id;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			throw e;
		}
	}

	static async saveExistingTeam(team: any): Promise<void> {
		const resource: string = "/api/team/" + team.id.toString();
		try {
			await ApiService.put(resource, team);
		} catch (e: any) {
			throw e;
		}
	}

	static async saveTeam(team: ITeam): Promise<ITeam> {
		let resource = "";
		let response: AxiosResponse<any>;
		try {
			if (team.id > 0) {
				//update
				resource = "/api/team/" + team.id.toString();
				response = await ApiService.put(resource, team);
			} else {
				//add new
				resource = "/api/team";
				response = await ApiService.post(resource, team);
			}
			if (response.data) {
				//response.data should a single object representing a control.
				return response.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			throw e;
		}
	}
}
