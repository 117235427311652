import { ChartType, SeriesType, ChartFieldType } from "../enums/ChartEnum";
import { IFieldDefinition } from "../interfaces/CustomFieldInterface";
import { getChartFieldType } from "./FieldHelper";

export default abstract class ChartHelper {
    static getSeriesTypeForChartType = (type: ChartType | null) => {
        switch (type) {
            case ChartType.Pie:
            case ChartType.Donut:
            case ChartType.RadialBar:
                return SeriesType.Categorical_None;
            case ChartType.HeatMap:
                return SeriesType.Categorical_Categorical;
            case ChartType.Bar:
            case ChartType.Radar:
                return SeriesType.Categorical_Numeric;
            case ChartType.Histogram:
            case ChartType.Line:
            case ChartType.Area:
                return SeriesType.DiscreteNumeric_Numeric;
            case ChartType.Scatter:
                return SeriesType.Numeric_Numeric;
            default:
                return SeriesType.Other;
        }
    }

    static getChartFieldTypeForSeriesType = (seriesType: SeriesType, axis: "primary" | "secondary" | "grouping") => {
        switch(axis) {
            case "primary":
                switch (seriesType) {
                    case SeriesType.Categorical_None:
                        return ChartFieldType.None
                        //return ChartFieldType.Categorical;
                    case SeriesType.Categorical_Categorical:
                        return ChartFieldType.Categorical;
                    case SeriesType.Categorical_Numeric:
                        return ChartFieldType.Categorical;
                    case SeriesType.Numeric_Numeric:
                    case SeriesType.DiscreteNumeric_Numeric:
                        return ChartFieldType.Numeric;
                    case SeriesType.None:
                        return ChartFieldType.None;
                }
            case "secondary":
                switch (seriesType) {
                    case SeriesType.Categorical_None:
                        return ChartFieldType.None;
                    case SeriesType.Categorical_Categorical:
                        return ChartFieldType.Categorical;
                    case SeriesType.Categorical_Numeric:
                        return ChartFieldType.Numeric;
                    case SeriesType.Numeric_Numeric:
                    case SeriesType.DiscreteNumeric_Numeric:
                        return ChartFieldType.Numeric;
                    case SeriesType.None:
                        return ChartFieldType.None;
                }
            case "grouping":
                return ChartFieldType.Categorical;
        }

    }

    static getChartFieldTypeForChartType = (type: ChartType, for_axis: "primary" | "secondary" | "grouping") => {
        return ChartHelper.getChartFieldTypeForSeriesType(ChartHelper.getSeriesTypeForChartType(type), for_axis);
    }

    static fieldIsType = (field: IFieldDefinition, type: ChartFieldType) => {
        const fieldType =  getChartFieldType(field);
        return fieldType == type;
    }
}
