<template>
	<div id="app-layout" class="container-fluid d-flex flex-column h-100">
		<div v-if="isWorking" class="rm-modal-backdrop">
			<b-spinner label="Working..."></b-spinner>
		</div>
		<app-error-display :errorMsg.sync="errorMsg"></app-error-display>
		<b-alert class="fixed-top m-0 rounded-0" style="z-index: 2000" v-model="hasInfo" variant="success" dismissible fade>{{ infoMsg }}</b-alert>
		<nav ref="rmHorMenu" class="row bg-mid text-dark rm-menu-h">
			<button class="rm-menu-button" @click="menuHClick">
				<i class="rm-menu-v-icon">
					<font-awesome-icon icon="bars"></font-awesome-icon>
				</i>
			</button>
			<div @click="goToUrl('/event-staff/home')" class="rm-menu-h-brand rm-cursor-pointer mr-auto">
				<img
					v-if="brandImage && brandImage.mimeType && brandImage.base64"
					:src="'data:' + brandImage.mimeType + ';base64,' + brandImage.base64"
					:alt="brandImage.name ? brandImage.name : 'logo'"
					class="d-inline-block align-top rm-logo mr-3"
				/>
				<img v-else src="~/../../../assets/logocolblack.png" alt="logo" class="d-inline-block align-top rm-logo mr-3" />
				<span class="d-none d-sm-inline">{{ mxAppName }}</span>
			</div>
			<b-button size="md" type="button" variant="outline-secondary" @click="refresh" aria-label="Refresh" title="Refresh">
				<font-awesome-icon icon="sync-alt"></font-awesome-icon>
			</b-button>
		</nav>
		<section class="row flex-grow-1 overflow-hidden position-relative rm-body">
			<nav ref="rmVertMenuSmall" class="overflow-auto bg-dark text-light rm-menu-v rm-menu-v-small collapsed rm-dark-bg-grad">
				<div class="h-100 flex-grow-1 rm-menu-container">
                    <div class="h-100 d-flex flex-column justify-content-between">
					    <ul class="rm-menu-v-list" @click="closeVertMenuSmall">
                            <li class="rm-menu-item border-bottom">
                                <a class="text-light rm-menu-v-link">
                                    <i class="rm-menu-v-icon pt-1">
                                        <font-awesome-icon icon="bars"></font-awesome-icon>
                                    </i>
                                </a>
                            </li>
                            <li class="rm-menu-item" v-if="userName != null">
                                <a class="text-light rm-menu-v-link disabled">
                                    <i class="rm-menu-v-icon">
                                        <font-awesome-icon icon="user-circle"></font-awesome-icon>
                                    </i>
                                    <span class="rm-menu-v-text px-3">{{ userName }}</span>
                                </a>
                            </li>
                            <li class="rm-menu-item" v-if="eventName != null">
                                <a class="text-light rm-menu-v-link disabled">
                                    <i class="rm-menu-v-icon">
                                        <font-awesome-icon icon="clipboard-list-check"></font-awesome-icon>
                                    </i>
                                    <span class="rm-menu-v-text px-3">{{ eventName }}</span>
                                </a>
                            </li>
                            <li class="rm-menu-item">
                                <a class="text-light rm-menu-v-link" @click="logoutClick">
                                    <i class="rm-menu-v-icon">
                                        <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
                                    </i>
                                    <span class="rm-menu-v-text px-3">Sign Out</span>
                                </a>
                            </li>
                        </ul>
                        <img src="~/../../../assets/logocolwhite.png" alt="logo" style="width: 11rem; margin: auto 0 0.5rem 1rem" /> 
                    </div>
                </div>
			</nav>
			<article ref="rmMainContent" @click="onMainContentClick" class="col overflow-auto bg-light text-dark rm-main-article">
				<event-listener id="rm-layout-parent" ref="rm-layout-parent" @setNavMenu="setNavMenu">
					<slot />
				</event-listener>
			</article>
		</section>
		<footer class="row rm-footer bg-mid">
			&copy; Momentus Technologies {{ new Date().getFullYear() }} v{{ version }}
			<div class="ml-auto mr-3">
				<b-link class="text-dark" to="/changeProfile">Timezone {{ currentUserData ? currentUserData.timezone : "unknown" }}</b-link>
			</div>
		</footer>
	</div>
</template>

<script lang="ts">
import { isFunction } from "lodash";
import { Component, Mixins, Vue } from "vue-property-decorator";
import { Permissions, Routes, Settings } from "../../../mixins/mixins";
import { Modules } from "../../enums/LookupEnums";
import EventListener from "../../helpers/EventListener";
import { INotificationStatus, NotificationHelper } from "../../helpers/NotificationHelper";
import { IMenuItem } from "../../interfaces/MenuItemInterface";
import { ILogo, IUserData } from "../../interfaces/UserDataInterface";
import AppErrorDisplay from "../AppErrorDisplay.vue";
import NotificationManager from "../user/NotificationManager.vue";

//declare var process: { env: { [key: string]: string } };

@Component({
	name: "app-layout-event-staff",
	components: {
		EventListener,
		AppErrorDisplay,
		NotificationManager,
	},
})
export default class AppLayoutEventStaff extends Mixins(Settings, Permissions, Routes) {
	/*****Properties*************************************************/
	menuVCollapsed = true;
	navMenuItems: IMenuItem[] = [];

	brandImage: ILogo = {} as ILogo;

	//notificationStatus: INotificationStatus;
	showNotificationMessage = false;

	/*****Computed properties*****************************/
	// Error messages and busy indicator
	get isWorking(): boolean {
		return this.$store.getters["common/isWorking"];
	}

	get errorMsg(): string[] | string | null {
		return this.$store.getters["common/errorMsg"];
	}

	set errorMsg(value: string[] | string | null) {
		this.$store.commit("common/errorMsg", value);
	}

	get infoMsg(): string | null {
		return this.$store.getters["common/infoMsg"];
	}

	set infoMsg(value: string | null) {
		this.$store.commit("common/infoMsg", value);
	}

	get currentUserData(): IUserData {
		return this.$store.getters["auth/currentUserData"];
	}

    get userName(): string | undefined {
        return this.currentUserData?.name;
    }

    get eventName(): string | undefined {
        return this.currentUserData?.event_staff_extra?.event?.description;
    }

	get hasInfo(): number | boolean {
		if (this.infoMsg) return 6;
		else return false;
	}

	set hasInfo(value: number | boolean) {
		if (!value) {
			this.infoMsg = null;
		}
	}

	/* get showNotificationModal(): boolean {
		return this.$store.getters["auth/showNotificationMessage"];
	}

	set showNotificationModal(value: boolean) {
		this.$store.commit("auth/showNotificationMessage", value);
	} */

	get notificationStatus(): INotificationStatus {
		return this.$store.getters["auth/notificationStatus"];
	}

	get disableNotificationMessage(): string {
		let result: string | null = localStorage.getItem("disableNotificationMessage");
		if (result !== "true") result = "false";
		return result;
	}

	set disableNotificationMessage(value: string) {
		if (value === "true") {
			localStorage.setItem("disableNotificationMessage", "true");
		} else {
			localStorage.setItem("disableNotificationMessage", "");
		}
	}

	// These are settings values ----
	get appName(): string {
		return this.$store.getters["auth/settingsAppName"];
	}
	get dateFormat(): string {
		return this.$store.getters["auth/settingsDateFormat"];
	}
	get timeFormat(): string {
		return this.$store.getters["auth/settingsTimeFormat"];
	}
	get colourScheme(): string {
		return this.$store.getters["auth/settingsColourScheme"];
	}
	get sessionTime(): string {
		return this.$store.getters["auth/settingsSessionTime"];
	}
	get hasWebPush(): boolean {
		return this.$store.getters["auth/settingsHasWebPush"];
	}
	get version() {
		return (process.env.VUE_APP_VERSION ? process.env.VUE_APP_VERSION : "") + (process.env.VUE_APP_VER ? process.env.VUE_APP_VER : "");
	}

	/*****Lifecycle hooks*************************************************/
	created(): void {
		this.brandImage = this.$store.getters["auth/settingsBrandImage"];
	}

	/*****Event handlers**************************************************/

	menuHClick(): void {
		const vertMenuSmall: any = this.$refs.rmVertMenuSmall;
		vertMenuSmall.classList.toggle("collapsed");
	}

	menuVClick(): void {
		const vertMenuLarge: any = this.$refs.rmVertMenuLarge;
		vertMenuLarge.classList.toggle("collapsed");
		if (vertMenuLarge.classList.contains("collapsed")) {
			this.menuVCollapsed = true;
		} else {
			this.menuVCollapsed = false;
		}
	}

	profileClick(): void {
		alert("Not Implemented");
	}

	logoutClick(): void {
		this.$store.dispatch("auth/logout");
	}

	navMenuItemClick(menuItem: IMenuItem): void {
		this.navMenuItems.forEach((mi: IMenuItem) => {
			mi.selected = false;
		});
		Vue.set(menuItem, "selected", true);
		if (isFunction(menuItem.action)) {
			menuItem.action();
		}
	}

	/*****Public methods********************************************************/

	setNavMenu(menuItems: IMenuItem[]): void {
		this.clearNavMenu();
		this.navMenuItems = menuItems;
	}

	clearNavMenu(): void {
		this.navMenuItems = [];
	}

	closeVertMenuSmall(): void {
		//close the main menu
		const vertMenuSmall: any = this.$refs.rmVertMenuSmall;
		if (vertMenuSmall && !vertMenuSmall.classList.contains("collapsed")) {
			vertMenuSmall.classList.add("collapsed");
		}
	}
	refresh(): void {
		this.$router.go(0);
	}

	onMainContentClick(): void {
		this.closeVertMenuSmall();
	}
}
</script>

<style lang="scss" scoped>
.rm-footer {
	height: 30px;
	line-height: 30px;
	padding-left: 0.25em;
}
</style>
