import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IIncidentType } from "../interfaces/IncidentTypeInterface";
import { ReportType } from "../interfaces/ReportInterface";
import { IHierarchicalSelectListItem, ISelectListItem } from "../interfaces/SelectListItemInterface";
import ApiService from "./ApiService";


const SelectListService = {
    /**
     * getLicensesForUserName.
     *
     * @returns array of select list objects with License ID and License Name
     * @throws Error
     **/
    //Available in the store from auth/licenses
    /* getLicensesForUserName: async function() {
        return [new SelectListItemModel("1", "Reliance Risk"), new SelectListItemModel("2", "Suncorp Stadium")];
    } */

    /**
     * Returns an array of ISelectListItem containing users. The include parameter specifies which ancestors or child licenses to include.
     * 0 = Current License Only, 1 = current license and ancestors, 2 = current license and children, 3 = current license and ancestors and children
     * 5 inlcuded siblings
     * @param include
     * @param optional, license ID
     */
    getUsers: async function (include: number, licenseId?: number | null): Promise<ISelectListItem[]> {
        // conditionally may have license ID
        let url = "/api/select/users/" + include.toString();
        if (licenseId != null) url = url + "/" + licenseId.toString();

        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: url,
        };
        const userResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);

        //console.log("getUsers = " + userResponse);

        return userResponse.data;
    },

    /**
     * Returns an array of ISelectListItem containing teams. The include parameter specifies which ancestors or child licenses to include.
     * 0 = Current License Only, 1 = current license and ancestors, 2 = current license and children, 3 = current license and ancestors and children
     * @param include
     */
    getTeams: async function (include: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/teams/" + include.toString(),
        };
        const teamResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return teamResponse.data;
    },

    /**
     * Returns an array of ISelectListItem containing groups for current license

     */
    getGroups: async function (): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/groups",
        };
        const groupResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return groupResponse.data;
    },

    /**
     * Returns an array of ISelectListItem containing external parties. The include parameter specifies which ancestors or child licenses to include.
     * 0 = Current License Only, 1 = current license and ancestors, 2 = current license and children, 3 = current license and ancestors and children
     * @param include
     */
    getExternalParties: async function (include: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/externalparties/" + include.toString(),
        };
        const epResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return epResponse.data;
    },

    //Includes users in teams
    getAllUsersForAssessment: async function (assessmentId: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/allusers/assessment/" + assessmentId.toString(),
        };
        const userResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return userResponse.data;
    },

    //Excludes users in teams
    getUsersForAssessment: async function (assessmentId: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/users/assessment/" + assessmentId.toString(),
        };
        const userResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return userResponse.data;
    },

    getTeamsForAssessment: async function (assessmentId: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/teams/assessment/" + assessmentId.toString(),
        };
        const teamResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return teamResponse.data;
    },

    getAllExternalPartiesForAssessment: async function (assessmentId: number): Promise<ISelectListItem[]> {
		const requestConfig: AxiosRequestConfig = {
			method: "get",
			url: "/api/select/allexternalparties/assessment/" + assessmentId.toString(),
		};
		const epResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
		return epResponse.data;
	},

    getExternalPartiesForAssessment: async function (assessmentId: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/externalparties/assessment/" + assessmentId.toString(),
        };
        const epResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return epResponse.data;
    },

    getRolesForAssessment: async function (assessmentId: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/roles/assessment/" + assessmentId.toString(),
        };
        const rlResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return rlResponse.data;
    },

    getRoleablesForAssessment: async function (assessmentId: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/roleables/assessment/" + assessmentId.toString(),
        };
        const rlblResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return rlblResponse.data;
    },

    /**
     * Returns an array of ISelectListItem containing control owners associated with a risk assessment.
     * @param assessmentId
     */
    getControlOwnersForAssessment: async function (assessmentId: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/controlowners/assessment/" + assessmentId.toString(),
        };
        const coResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return coResponse.data;
    },

    /**
     * Returns an array of ISelectListItem containing control owners allocated to controls in a risk assessment
     * Contains users and external parties but on teams.
     * @param assessmentId
     */
    getAllocatedControlOwnersForAssessment: async function (assessmentId: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/controlowners/allocated/" + assessmentId.toString(),
        };
        const coResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return coResponse.data;
    },

    /**
     * Returns an array of ISelectListItem containing control owners associated with a risk.
     * @param riskId
     */
    getControlOwnersForRisk: async function (riskId: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/controlowners/risk/" + riskId.toString(),
        };
        const coResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return coResponse.data;
    },

    /**
     * Returns an array of ISelectListItem containing control owners associated with a control.
     * @param controlId
     */
    getControlOwnersForControl: async function (controlId: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/controlowners/control/" + controlId.toString(),
        };
        const coResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return coResponse.data;
    },

    //Includes users in teams
    getAllUsersForEvent: async function (eventId: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/allusers/event/" + eventId.toString(),
        };
        const userResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return userResponse.data;
    },

    //Excludes users in teams
    getUsersForEvent: async function (eventId: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/users/event/" + eventId.toString(),
        };
        const userResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return userResponse.data;
    },

    getTeamsForEvent: async function (eventId: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/teams/event/" + eventId.toString(),
        };
        const teamResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return teamResponse.data;
    },

    getRolesForEvent: async function (eventId: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/roles/event/" + eventId.toString(),
        };
        const rlResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return rlResponse.data;
    },

    getRoleablesForEvent: async function (eventId: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/roleables/event/" + eventId.toString(),
        };
        const rlblResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return rlblResponse.data;
    },

    getIssueTypesForEvent: async function (eventId: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/issuetypes/event/" + eventId.toString(),
        };
        const issueTypeResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return issueTypeResponse.data;
    },

    getRiskDefinitionCriteriaForEvent: async function (eventId: number): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/riskdefinitionicriteria/event/" + eventId.toString(),
        };
        const rdcResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return rdcResponse.data;
    },

    getRiskControlStatuses: async function (): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/riskcontrolstatuses",
        };
        const rcsResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return rcsResponse.data;
    },

    /**
     * Returns an array of ISelectListItem containing child licenses for the currently logged in user.
     */
    getChildLicenses: async function (): Promise<IHierarchicalSelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/licenses/children",
        };
        const coResponse: AxiosResponse<IHierarchicalSelectListItem[]> = await ApiService.customRequest(requestConfig);
        return coResponse.data;
    },

    /**
     * Returns an array of ISelectListItem containing ancestor licenses for the currently logged in user.
     */
    getAncestorLicenses: async function (): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/licenses/ancestors",
        };
        const coResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return coResponse.data;
    },

    /**
     * Returns an array of ISelectListItem containing all related licenses for the currently logged in user.
     */
    getRelatedLicenses: async function (): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/licenses/all",
        };
        const coResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return coResponse.data;
    },

    /**
     * Returns an array of ISelectListItem containing event types.
     */
    getEventTypes: async function (): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/eventtypes",
        };
        const etResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return etResponse.data;
    },

    /**
     * Returns an array of IHierarchicalSelectListItem containing risk assessments for the current user's license and child licenses.
     * The risk assessments are arranged in their folders.
     */
    getHierarchicalRiskAssessments: async function (level: "own" | "children", riskAssessmentIcon = "minus"): Promise<IHierarchicalSelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/hierarchicalriskassessments/" + level,
        };
        const hraResponse: AxiosResponse<IHierarchicalSelectListItem[]> = await ApiService.customRequest(requestConfig);
        let result = hraResponse.data;
        //add icons
        const fn = (items: IHierarchicalSelectListItem[]) => {
            for (let item of items) {
                if (item.value.charAt(0) === "f") {
                    if (item.data) {
                        item.data.icon = "folder";
                    } else {
                        item.data = { icon: "folder" };
                    }
                } else if (item.value.charAt(0) === "l") {
                    if (item.data) {
                        item.data.icon = "id-card";
                    } else {
                        item.data = { icon: "id-card" };
                    }
                } else {
                    if (item.data) {
                        item.data.icon = riskAssessmentIcon;
                    } else {
                        item.data = { icon: riskAssessmentIcon };
                    }
                }
                if (item.children && item.children.length > 0) {
                    fn(item.children);
                }
            }
        };
        fn(result);
        return result;
    },

    /**
     * Returns an array of ISelectListItem containing risk definitions.
     */
    getRiskDefinitions: async function (): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/riskdefinitions",
        };
        const rdResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return rdResponse.data;
    },

    /**
     * Returns an array of ISelectListItem containing risk definitions.
     */
    getHierarchicalLocations: async function (): Promise<IHierarchicalSelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/hierarchicallocations",
        };
        const hlResponse: AxiosResponse<IHierarchicalSelectListItem[]> = await ApiService.customRequest(requestConfig);
        return hlResponse.data;
    },

    /**
     * Returns an array of ISelectListItem containing issue types current license
     */
    getHierarchicalIssueTypes: async function (): Promise<IHierarchicalSelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/hierarchicalissuetypes",
        };
        const hitResponse: AxiosResponse<IHierarchicalSelectListItem[]> = await ApiService.customRequest(requestConfig);
        return hitResponse.data;
    },

    /**
     * Returns an array of ISelectListItem containing issue types current license
     */
    getHierarchicalFolders: async function (folderType: string): Promise<IHierarchicalSelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/hierarchicalfolders/" + folderType.trim().toLowerCase(),
        };
        const hfResponse: AxiosResponse<IHierarchicalSelectListItem[]> = await ApiService.customRequest(requestConfig);
        return hfResponse.data;
    },

    /**
     * Returns an array of ISelectListItem containing event definition templates.
     */
    getEventDefinitionTemplates: async function (): Promise<ISelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/eventdefinitiontemplates",
        };
        const rdResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return rdResponse.data;
    },

    /**
     * Returns an array of IHierarchicalSelectListItem containing events from this and children lcienses with definitionids in the data property.
     */
    getHierarchicalEvents: async function (level: "own" | "children", eventIcon: string = "minus"): Promise<IHierarchicalSelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/hierarchicalevents/" + level,
        };
        const hedResponse: AxiosResponse<IHierarchicalSelectListItem[]> = await ApiService.customRequest(requestConfig);
        let events = hedResponse.data;
        //add icons
        const fn = (items: IHierarchicalSelectListItem[]) => {
            for (let item of items) {
                if (item.value.charAt(0) === "f") {
                    if (item.data) {
                        item.data.icon = "folder";
                    } else {
                        item.data = { icon: "folder" };
                    }
                } else if (item.value.charAt(0) === "l") {
                    if (item.data) {
                        item.data.icon = "id-card";
                    } else {
                        item.data = { icon: "id-card" };
                    }
                } else {
                    if (item.data) {
                        item.data.icon = eventIcon;
                    } else {
                        item.data = { icon: eventIcon };
                    }
                }
                if (item.children && item.children.length > 0) {
                    fn(item.children);
                }
            }
        };
        fn(events);
        return events;
    },

    /**
     * Returns an array of IHierarchicalSelectListItem containing relataed records @todo backend is incomplete.
     */
    getRelatedRecords: async function (): Promise<IHierarchicalSelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/relatedrecords",
        };
        const hedResponse: AxiosResponse<IHierarchicalSelectListItem[]> = await ApiService.customRequest(requestConfig);
        return hedResponse.data;
    },

    /**Rreturn an array of ISelectListItem containing task priorities */
    getTaskPriorities: async function (queryStr?: string): Promise<ISelectListItem[]> {
        let url = "/api/select/taskpriorities";
        if (queryStr) {
            url += "?" + queryStr;
        }
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: url,
        };
        const tpResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return tpResponse.data;
    },

    getReportTemplates: async function (reportType: ReportType): Promise<ISelectListItem[]> {
        const url = "/api/select/reporttemplates/" + reportType;
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: url,
        };
        const rtResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return rtResponse.data;
    },

    getLayoutTemplates: async function (): Promise<ISelectListItem[]> {
        const url = "/api/select/layouttemplates";
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: url,
        };
        const ltResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return ltResponse.data;
    },

    getRoles: async function (): Promise<ISelectListItem[]> {
        const url = "/api/select/roles";
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: url,
        };
        const rlResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return rlResponse.data;
    },

    getLocationCategories: async function (): Promise<ISelectListItem[]> {
        const url = "/api/select/locationcategories";
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: url,
        };
        const rlResponse: AxiosResponse<ISelectListItem[]> = await ApiService.customRequest(requestConfig);
        return rlResponse.data;
    },
    getTableFields: async function (table: string): Promise<ISelectListItem[]> {
        const resource = "/api/select/fields_table/" + table;
        try {
            const response: AxiosResponse<any> = await ApiService.get(resource);
            if (response.data) {
                return response.data;
            } else {
                throw new Error("Invalid Response getting fields");
            }
        } catch (e: any) {
            let msg = "Get layout failed.";
            if (e.response) {
                msg = e.response.data.message;
            } else if (e.message) {
                msg = e.message;
            }
            throw new Error(msg);
        }
    },
    getIncidentTypesForLicense: async function (): Promise<IIncidentType[]> {
        const resource = "/api/select/incident_types";
        try {
            const response: AxiosResponse<any> = await ApiService.get(resource);
            if (response && response.data && response.data.data) {
                return response.data.data;
            } else {
                throw new Error("Invalid Response getting IncidentTypes");
            }
        } catch (e: any) {
            let msg = "Get Incident Types failed.";
            if (e.response) {
                msg = e.response.data.message;
            } else if (e.message) {
                msg = e.message;
            }
            throw new Error(msg);
        }
    },
    getOptionsForRelatedRecords: async function (table: string): Promise<IHierarchicalSelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/related_records/" + table,
        };
        const hedResponse: AxiosResponse<IHierarchicalSelectListItem[]> = await ApiService.customRequest(requestConfig);
        return hedResponse.data;
    },
    getHierarchicalProfileResults: async function (): Promise<IHierarchicalSelectListItem[]> {
        const requestConfig: AxiosRequestConfig = {
            method: "get",
            url: "/api/select/profile_results",
        };
        const prResponse: AxiosResponse<IHierarchicalSelectListItem[]> = await ApiService.customRequest(requestConfig);
        return prResponse.data;
    }
};

export { SelectListService };

