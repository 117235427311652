// Make sure to register before importing any components
import "./classComponentHooks";
// Transpiler for older browsers
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./core/directives/ClickOutside";
import "./custom.scss";
import "./register401Interceptor";
import "./registerServiceWorker";

import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";
import {
    faAnalytics,
    faAngleDoubleLeft,
    faArrows,
    faArrowsH,
    faArrowsV,
    faBan,
    faBadgeCheck,
    faBolt,
    faBookOpen,
    faBroom,
    faCaretDown,
    faCaretUp,
    faChevronDoubleDown,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronDoubleUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faClipboardListCheck,
    faClipboardUser,
    faColumns,
    faExpandArrows,
    faExternalLink,
    faFireSmoke,
    faFirstAid,
    faFileSpreadsheet,
    faFolderTree,
    faGripLinesVertical,
    faLevelUp,
    faLineColumns,
    faMapMarked,
    faMegaphone,
    faMinusCircle,
    faNewspaper,
    faPlusCircle,
    faRabbitFast,
    faSearchPlus,
    faShareAll,
    faShieldCheck,
    faSlidersVSquare,
    faTable,
    faTachometer,
    faTachometerSlow,
    faTasksAlt,
    faTurtle,
    faUserHardHat,
    faUserShield,
    faUserCheck,
    faVials,
    faVirus,
    faBooks,
    faIcons,
    faSort,
    faSortUp,
    faSortDown,
    faClipboard,
    faPaste,
} from "@fortawesome/pro-solid-svg-icons";
import {
    faArchive,
    faArrowDown,
    faArrowUp,
    faAsterisk,
    faBars,
    faBomb,
    faBriefcaseMedical,
    faCalendar,
    faCalendarAlt,
    faCalendarWeek,
    faCaretRight,
    faChartArea,
    faChartBar,
    faChartLine,
    faChartPie,
    faCheck,
    faChild,
    faCircle,
    faClipboardList,
    faClock,
    faCog,
    faCogs,
    faComment,
    faCompressArrowsAlt,
    faCopy,
    faCube,
    faDownload,
    faEdit,
    faEllipsisV,
    faExclamation,
    faExclamationTriangle,
    faEye,
    faFileContract,
    faFileImport,
    faFilePdf,
    faFileWord,
    faFillDrip,
    faFilter,
    faFlag,
    faFolder,
    faFolderMinus,
    faFolderOpen,
    faFolderPlus,
    faGlobe,
    faHandPaper,
    faHome,
    faIdCard,
    faImage,
    faIndustry,
    faKey,
    faLink,
    faLandmark,
    faLayerGroup,
    faMinus,
    faNotesMedical,
    faPaperclip,
    faPlus,
    faPortrait,
    faQuestion,
    faRuler,
    faSave,
    faSearch,
    faShare,
    faSignOutAlt,
    faSpinner,
    faSquare,
    faSuitcase,
    faSync,
    faSyncAlt,
    faTachometerAlt,
    faTasks,
    faThLarge,
    faThList,
    faThumbsUp,
    faTimes,
    faTimesCircle,
    faTools,
    faTrash,
    faUndo,
    faUser,
    faUserCircle,
    faUserLock,
    faUserTag,
    faUsers,
    faUsersCog,
    faWineBottle,
    faBell,
    faVolumeUp,
    faFilm,
    faLock,
    faProjectDiagram,
    faUserSlash,
    faTruck,
    faCheckDouble    
} from "@fortawesome/free-solid-svg-icons";
import {
    faCalendarCheck,
    faCalendar as farCalendar,
    faAddressCard as farAddressCard,
    faUserCircle as farUserCircle,
    faFileArchive,
    faFile,
} from "@fortawesome/free-regular-svg-icons";
import {
    faChartArea as faChartAreaLight,
    faChartBar as faChartBarLight,
    faChartLine as faChartLineLight,
    faChartPie as faChartPieLight,
    faRectangleLandscape,
    faRectanglePortrait,
    faIdCard as falIdCard,
} from "@fortawesome/pro-light-svg-icons";
import {
    faExclamationSquare,
    faUserCheck as farUserCheck,
    faFileExcel,
} from "@fortawesome/pro-regular-svg-icons";

import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import Vue from "vue";
import VueEcho from "vue-echo";
import VueMq from "vue-mq";
import VueWorker from 'vue-worker';
import axios from "axios";
import { library } from "@fortawesome/fontawesome-svg-core";
import router from "./router";
import store from "./store/";

//This is needed by the Echo component when setting up web socket connections
declare global {
    interface Window {
        Pusher: any;
    }
}
window.Pusher = require("pusher-js");
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueMq, {
    breakpoints: {
        // default breakpoints - customize this
        xxs: 450,
        xs: 576,
        sm: 768,
        md: 992,
        lg: 1200,
        xl: Infinity,
    },
    defaultBreakpoint: "lg", // customize this for SSR
});
Vue.use(VueEcho, {
    broadcaster: "pusher",
    key: process.env.VUE_APP_PUSHER_KEY,
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    forceTLS: process.env.VUE_APP_PUSHER_TLS === "true" ? true : false,
    disableStats: true,
    wsHost: window.location.hostname,
    wsPort: process.env.VUE_APP_PUSHER_PORT,
    wssPort: process.env.VUE_APP_PUSHER_PORT,
    authorizer: (channel: { name: any }, options: any) => {
        return {
            authorize: (socketId: any, callback: (arg0: boolean, arg1: any) => void) => {
                axios
                    .post("/api/broadcasting/auth", {
                        socket_id: socketId,
                        channel_name: channel.name,
                    })
                    .then((response) => {
                        callback(false, response.data);
                    })
                    .catch((error) => {
                        callback(true, error);
                    });
            },
        };
    },
});
Vue.use(VueWorker);
library.add(
    faAngleDoubleLeft,
    faHome,
    faCog,
    faFireSmoke,
    faChartLine,
    faNotesMedical,
    faClipboardListCheck,
    faUserHardHat,
    faBars,
    faUserCircle,
    faQuestion,
    faRuler,
    faIndustry,
    faIdCard,
    faSignOutAlt,
    faUserLock,
    faFilter,
    faEllipsisV,
    faTimes,
    faEdit,
    faFolder,
    faFolderOpen,
    faFolderMinus,
    faFolderPlus,
    faPlus,
    faMinus,
    faShieldCheck,
    faCopy,
    faDownload,
    faFlag,
    faThumbsUp,
    faSyncAlt,
    faArrowUp,
    faArrowDown,
    faLevelUp,
    faArrows,
    faUser,
    faUsers,
    faClipboardUser,
    faCaretRight,
    faLineColumns,
    faUsersCog,
    faChevronRight,
    faChevronLeft,
    faChevronUp,
    faChevronDown,
    faChevronDoubleDown,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronDoubleUp,
    faNewspaper,
    faSync,
    faSpinner,
    faCircle,
    faPlusCircle,
    faMinusCircle,
    faCheck,
    faCaretUp,
    faCaretDown,
    faArrowsH,
    faArrowsV,
    faEye,
    faTachometer,
    faExclamation,
    faUndo,
    faSearchPlus,
    faSearch,
    faColumns,
    faFolderTree,
    faTrash,
    faFilePdf,
    faFileWord,
    faTimesCircle,
    faClock,
    faComment,
    faTools,
    faVials,
    faTasks,
    faPortrait,
    faImage,
    faSlidersVSquare,
    faExpandArrows,
    faCompressArrowsAlt,
    faChartBar,
    faChartPie,
    faBan,
    faClipboardList,
    faAnalytics,
    faThLarge,
    faRectangleLandscape,
    faRectanglePortrait,
    faChartLineLight,
    faChartBarLight,
    faChartAreaLight,
    faChartPieLight,
    faChartArea,
    faPaperclip,
    faMegaphone,
    faBookOpen,
    faCompressArrowsAlt,
    faExpandArrows,
    faTachometerSlow,
    faArrows,
    faFileImport,
    faShare,
    faAsterisk,
    faCalendarWeek,
    faCalendarAlt,
    faSquare,
    faHandPaper,
    faTachometerAlt,
    faExclamationTriangle,
    faFirstAid,
    faBriefcaseMedical,
    faFillDrip,
    faTasksAlt,
    faBroom,
    faVirus,
    faUserShield,
    faBomb,
    faChild,
    faSuitcase,
    faBolt,
    faShareAll,
    faKey,
    faWineBottle,
    faUserTag,
    faThList,
    faGlobe,
    faMinus,
    farUserCircle,
    farAddressCard,
    faFileContract,
    faMapMarked,
    faTable,
    faCalendarCheck,
    faUserCheck,
    faUserSlash,
    farUserCheck,
    faGripLinesVertical,
    faBadgeCheck,
    faExternalLink,
    faLink,
    faCalendar,
    farCalendar,
    faArchive,
    faBell,
    faFile,
    faFileArchive,
    faVolumeUp,
    faFilm,
    faFileSpreadsheet,
    faLock,
    faProjectDiagram,
    faCogs,
    faSave,
    faLandmark,
    faLayerGroup,
    faCube,
    faUserSlash,
    faRabbitFast,
    faTurtle,
    faTruck,
    faBooks,
    faCircle,
    falIdCard,
    faCheckDouble,
    faIcons,
    faSort,
    faSortUp,
    faSortDown,
    faClipboard,
    faClipboardListCheck,
    faPaste
);
//Register a vue component to use for icons.
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
