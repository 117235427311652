import { IRiskDefinition } from "../modules/RiskAssessments/interfaces/RiskDefinitionsInterface";

import { Commit, Dispatch } from "vuex";
import RiskDefinitionService from "../modules/RiskAssessments/services/RiskDefinitionService";
import { IEvent, IEventDefinition } from "@/modules/Issues/interfaces/EventInterface";
import EventService from "@/modules/Issues/services/EventService";
import { EventHelperService } from "@/modules/Issues/services/EventHelperService";
import { IRiskAssessment } from "@/modules/RiskAssessments/interfaces/RiskAssessmentInterface";
import RiskAssessmentService from "@/modules/RiskAssessments/services/RiskAssessmentService";
import EventDefinitionService from "@/modules/Issues/services/EventDefinitionService";

interface IState {
	event: IEvent;
    event_definition: IEventDefinition | null;
    risk_definition: IRiskDefinition | null; // risk definition from event_definition.risk_definition_id or event_defintion.risk_assessment.risk_definition_id
}

const state: IState = {
	event: EventHelperService.getBlankEvent(false),
    event_definition: null,
    risk_definition: null
};
const getters = {
	event: (state: IState) => {
		return state.event;
	},
	eventId: (state: IState): number => {
		return state.event.id;
	},
	eventDescription: (state: IState): string => {
		return state.event.description;
	},
    riskDefinition: (state: IState): IRiskDefinition | null => {
        return state.risk_definition;
    },
    riskDefinitionId: (state: IState): number | null => {
		return state.risk_definition ? state.risk_definition.id : null;
	},
};
const actions = {
	async getEvent({ commit, dispatch,  state }: { commit: Commit; dispatch: Dispatch, state: IState }, id: number, tryExisting: boolean = true): Promise<IEvent> {
		if (tryExisting && state.event.id == id) {
            return new Promise((resolve, reject) => resolve(state.event));
        } else {
            return EventService.getEventById(id)
                .then((event: IEvent) => {
                    commit("setEvent", event);
                    return event;
                })
                .catch((e: any) =>{
                    commit("common/errorMsg", "Failed to get event. Please try again.", { root: true });
                    throw e;
                });
        }
	},
	async saveEvent({ commit, state }: { commit: Commit; state: IState }): Promise<IEvent> {
        return EventService.saveEvent(state.event)
			.then((event: IEvent) => {
				commit("setEvent", event);
				return event;
			})
            .catch((e: any) =>{
                commit("common/errorMsg", "Failed to save event. Please try again.", { root: true });
                throw e;
            });
	},
    async getEventDefinition({ state, commit, dispatch }: { commit: Commit; dispatch: Dispatch, state: IState}, id: number, tryExisting: boolean = true): Promise<IEventDefinition> {
        if (tryExisting && state.event_definition && state.event_definition.id == id) {
            return new Promise((resolve, reject) => resolve(state.event_definition as IEventDefinition));
        } else {
            return EventDefinitionService.getEventDefById(state.event.event_definition_id)
                .then((eventDefinition: IEventDefinition) => {
                    commit("setEventDefinition", eventDefinition);
                    return eventDefinition;
                })
                .catch((e: any) =>{
                    commit("common/errorMsg", "Failed to get event definition. Please try again.", { root: true });
                    throw e;
                });
        }
    },
    async getRiskDefinitionId({ state, commit, dispatch }: { state: IState, commit: Commit; dispatch: Dispatch }, tryExisting: boolean = true): Promise<number | null> {
        if (state.event_definition && state.event_definition.risk_assessment_id) {
            return RiskAssessmentService.getRiskAssessmentById(state.event_definition.risk_assessment_id)
                .then((riskAssessment: IRiskAssessment) => {
                    return riskAssessment.risk_definition_id;
                })
                .catch((e: any) =>{
                    commit("common/errorMsg", "Failed to get event risk definition. Please try again.", { root: true });
                    throw e;
                });
        } else if (state.event_definition && state.event_definition.risk_definition_id) {
            return new Promise((resolve, reject) => resolve(state.event_definition!.risk_definition_id as number));
        } else {
            return null;
        }
    },
    async getRiskDefinition({ state, commit, dispatch }: { state: IState, commit: Commit; dispatch: Dispatch }, tryExisting: boolean = true ): Promise<IRiskDefinition> {
        return dispatch("getRiskDefinitionId")
                .then((id: number) => {
                    if (tryExisting && state.risk_definition && state.risk_definition.id == id) {
                        return new Promise((resolve, reject) => resolve(state.risk_definition as IRiskDefinition));
                    } else {
                        return RiskDefinitionService.getRiskDefById(id)
                            .then((risk_definition: IRiskDefinition) => {
                                commit("setRiskDefinition", risk_definition);
                                return risk_definition;
                            })
                            .catch((e: any) =>{
                                commit("common/errorMsg", "Failed to get event risk definition. Please try again.", { root: true });
                                throw e;
                            })
                    }
                });
    }
}
const mutations = {
	setEvent(state: IState, event: IEvent) {
		state.event = event;
	},
    setEventDefinition(state: IState, event_definition: IEventDefinition) {
        state.event_definition = event_definition;
    },
    setRiskDefinition(state: IState, value: IRiskDefinition | null) {
        state.risk_definition = value;
    },
	clear(state: IState): void {
		state.event = EventHelperService.getBlankEvent(false);
        state.risk_definition = null;
	}
};

export const events = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
