import { IIncidentSelection } from "@/modules/Incidents/interfaces/IncidentFilterInterface";

interface IState {
	states: { [key: string]: IIncidentSelection; };
}

const state: IState = {
	states: {},
};

const getters = {
    allStates: (state: IState) => {
		return state.states;
	},
	state: (state: IState) => (key: string) => {
		return state.states[key];
	},
};

const actions = {

};

const mutations = {
	setState(state: IState, payload: { state: IIncidentSelection, key: string; }) {
		state.states[payload.key] = payload.state;
	},
	clearState(state: IState, key: string) {
		delete state.states[key];
	},
};

export const incidentSelector = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
