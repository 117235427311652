import ControlService from "../modules/RiskAssessments/services/ControlService";
import { IControl, DetailView } from "../modules/RiskAssessments/interfaces/ControlInterface";
import { IRisk } from "../modules/RiskAssessments/interfaces/RiskInterface";
import Vue from "vue";
import { controlErrorMessages } from "./controlConstants";
import { formatError } from "../core/helpers/ErrorHelper";
import { ControlStatus } from "@/modules/RiskAssessments/enums/ControlEnums";

interface IState {
	controlList: IControl[];
}

const state: IState = {
	controlList: [],
};

const getters = {
	controlList: (state: IState): IControl[] => {
		return state.controlList;
	},
	controlListForRisk: (state: IState) => (riskId: number) => {
		return state.controlList.filter((ctl) => ctl.risk_ids.includes(riskId));
	},
};

const actions = {
	async getControlList({ commit, state }: any, riskAssessmentId: number): Promise<IControl[]> {
		try {
			const controlList: IControl[] = await ControlService.getControlsForAssessment(riskAssessmentId);
			commit("gotControlList", controlList);
			return controlList;
		} catch (e: any) {
			commit("common/errorMsg", controlErrorMessages.ERR_GET_CONTROLS_FAILED, { root: true });
			throw e;
		}
	},
	async saveControl({ commit, state }: any, params: { control: IControl; riskAssessmentId: number; riskId: number | null }): Promise<IControl> {
		try {
			const savedControl: IControl = await ControlService.saveControl(params.control, params.riskAssessmentId, params.riskId);
			commit("controlSaved", savedControl);
			commit("risk/syncControlRisks", savedControl, { root: true });
			return savedControl;
		} catch (e: any) {
			commit("common/errorMsg", formatError(e, controlErrorMessages.ERR_SAVE_CONTROL_FAILED), { root: true });
			throw e;
		}
	},
	async addNewControls({ commit, state }: any, params: { controls: IControl[]; riskAssessmentId: number; riskId: number | null }): Promise<IControl[]> {
		try {
			const savedControls: IControl[] = await ControlService.addNewControls(params.controls, params.riskAssessmentId, params.riskId);
			commit("newControlsSaved", savedControls);
			commit("risk/syncMultipleControlRisks", savedControls, { root: true });
			return savedControls;
		} catch (e: any) {
			commit("common/errorMsg", formatError(e, controlErrorMessages.ERR_SAVE_CONTROL_FAILED), { root: true });
			throw e;
		}
	},
	async saveCurrentControl({ commit, state }: any, controlId: number): Promise<IControl> {
		try {
			//find the control in the controls array
			const foundControl: IControl | undefined = state.controlList.find((r: IControl) => r.id === controlId);
			if (foundControl) {
				const savedControl: IControl = await ControlService.saveControl(foundControl, foundControl.risk_assessment_id, null);
				commit("controlSaved", savedControl);
				commit("risk/syncControlRisks", savedControl, { root: true });
				return savedControl;
			} else {
				throw "Control not found.";
			}
		} catch (e: any) {
			commit("common/errorMsg", formatError(e, controlErrorMessages.ERR_SAVE_CONTROL_FAILED), { root: true });
			throw e;
		}
	},

	async saveControlRiskAllocation({ commit, state }: any, control: IControl): Promise<void> {
		try {
			const controlRiskXref: { controlId: number; riskIds: number[] }[] = [{ controlId: control.id, riskIds: control.risk_ids }];
			await ControlService.saveControlRisks(controlRiskXref);
			commit("risk/syncControlRisks", control, { root: true });
		} catch (e: any) {
			commit("common/errorMsg", formatError(e, controlErrorMessages.ERR_SAVE_CONTROL_FAILED), { root: true });
			throw e;
		}
	},

	async updateControlStatus({ commit, state }: any, params: { controlId: number; status: ControlStatus; updateComment: string }): Promise<IControl> {
		try {
			const savedControl: IControl = await ControlService.setControlStatus(params.controlId, params.status, params.updateComment)
			commit("controlSaved", savedControl);
			return savedControl;
		} catch (e: any) {
			commit("common/errorMsg", formatError(e, controlErrorMessages.ERR_SAVE_CONTROL_FAILED), { root: true });
			throw e;
		}
	}
};

const mutations = {
	gotControlList(state: IState, controlList: IControl[]) {
		state.controlList = controlList;
	},
	controlSaved(state: IState, control: IControl) {
		//find the control in the control list and update it or if not found add it to the array.
		const foundControl: IControl | undefined = state.controlList.find((r: IControl) => r.id === control.id);
		if (foundControl) {
			//update the found control
			Object.assign(foundControl, control);
		} else {
			//add it to the array
			state.controlList.push(control);
		}
	},
	newControlsSaved(state: IState, controls: IControl[]) {
		state.controlList.push(...controls);
	},
	setControlField(state: IState, params: { control: IControl; fieldKey: string; value: any }) {
		const foundControl: IControl | undefined = state.controlList.find((r: IControl) => r.id === params.control.id);
		if (foundControl) {
			(foundControl as any)[params.fieldKey] = params.value;
		}
	},
	addRisksToControl(state: IState, params: { control: IControl; risks: IRisk[] }) {
		const foundIndex: number = state.controlList.findIndex((c: IControl) => c.id === params.control.id);
		if (foundIndex >= 0) {
			(state.controlList[foundIndex].risk_ids as number[]) = params.risks.map((r) => r.id);
		}
	},
	removeControl(state: IState, controlId: number) {
		const foundIndex: number = state.controlList.findIndex((c: IControl) => c.id === controlId);
		if (foundIndex >= 0) {
			state.controlList.splice(foundIndex, 1);
		}
	},
	/**
	 * Ensures the risk id is in the control.risk_ids field of all the risk.control_ids in the risk */
	syncRiskControls(state: IState, risk: IRisk) {
		Vue.set(risk, "controls", []);
		if (risk.control_ids.length > 0) {
			for (const controlId of risk.control_ids) {
				const foundControl: IControl | undefined = state.controlList.find((c: IControl) => c.id === controlId);
				if (foundControl) {
					const riskIds = foundControl.risk_ids;
					if (riskIds && !riskIds.includes(risk.id)) {
						foundControl.risk_ids.push(risk.id);
					}
					//make sure the control objects are all correctly attached to the risk.
					if (risk.controls) risk.controls.push(foundControl);
				}
			}
		}
	},
	syncMultipleRiskControls(state: IState, risks: IRisk[]) {
		for (const risk of risks) {
			Vue.set(risk, "controls", []);
			if (risk.control_ids.length > 0) {
				for (const controlId of risk.control_ids) {
					const foundControl: IControl | undefined = state.controlList.find((c: IControl) => c.id === controlId);
					if (foundControl) {
						const riskIds = foundControl.risk_ids;
						if (riskIds && !riskIds.includes(risk.id)) {
							foundControl.risk_ids.push(risk.id);
						}
						//make sure the control objects are all correctly attached to the risk.
						if (risk.controls) risk.controls.push(foundControl);
					}
				}
			}
		}
	},
	clearDetails(state: IState) {
		for (const ctl of state.controlList) {
			if (Boolean(ctl._showDetails)) {
				ctl._showDetails = false; //Vue.set(ctl, "_showDetails", false); //
			}
		}
	},
	setDetails(state: IState, params: { control: IControl, view: DetailView }) {
		const set = Boolean(params.control._showDetails);
		if (!set) {
			params.control._detailView = params.view;
			Vue.set(params.control, "_showDetails", true);
		}
	},
	clearControlList(state: IState) {
		state.controlList = [];
	},
	setUpdateComment(state: IState, params: { control: IControl, comment: string }) {
		params.control.update_comment = params.comment;
	},
};

export const control = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
