import Vue from "vue";
import Vuex from "vuex";
import { common } from "./commonModule";
import { auth } from "./authModule";
import { customFields } from "./customFieldsModule";
import { riskDefinition } from "./riskDefinitionModule";
import { risk } from "./riskModule";
import { control } from "./controlModule";
import { location } from "./locationModule";
import { incidentSelector } from "./incidentSelectorModule";
import { incidents } from "./incidentModule";
import { events } from "./eventModule";
import { reports } from "./reportModule";
import { directories } from "./directoryModule";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
    modules: {
        common,
        auth,
        customFields,
        riskDefinition,
        risk,
        control,
        location,
        incidentSelector,
        incidents,
        events,
        reports,
        directories
    },
    strict: debug,
});
