import ApiService from "../../core/services/ApiService";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IEventType } from "../../core/interfaces/EventTypeInterface";
import { IIssueType } from "../../core/interfaces/IssueTypeInterface";
import { ILocation } from "../interfaces/LocationInterface";
import { IRole } from "../../core/interfaces/RoleInterface";
import { ITaskPriority } from "@/modules/Tasks/interfaces/TaskPriorityInterface";
import { IIncidentType } from "../interfaces/IncidentTypeInterface";
import { Modules } from "../../core/enums/LookupEnums";
import { ILocationCategory } from "../interfaces/LocationCategoryInterface";
import { IPersonDropdown } from "@/modules/Incidents/interfaces/PersonInterface";
import { ICauseSection } from "@/modules/Incidents/interfaces/IncidentInvestigationInterface";
import ITeam from "../interfaces/TeamInterface";
import { ISelectedModule } from "@/modules/SystemAdmin/interfaces/SelectedModuleInterface";
import IVenueType from "@/modules/SystemAdmin/interfaces/VenueTypeInterface";
import { ILicense } from "../interfaces/LicenseInterface";
import IExternalPartyGroup from "../interfaces/ExternalPartyGroupInterface";

export default abstract class LicenseService {
	static async getLicenses(): Promise<any[]> {
		const resource: string = "/api/system/licenses";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data.data) {
				return response.data.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get licenses failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async getLicense(licenseId: number): Promise<ILicense> {
		const resource: string = "/api/system/license/" + licenseId.toString();
		const response: AxiosResponse<any> = await ApiService.get(resource);
		if (response.data.data) {
			return response.data.data;
		} else {
			throw new Error("Invalid Response");
		}
	}

    static async saveLicense(license: ILicense): Promise<ILicense> {
        if (!license.hasOwnProperty("id") || license.id <= 0) {
            return LicenseService.saveNewLicense(license);
        } else {
            return LicenseService.updateLicense(license);
        }
    }

    static async saveNewLicense(license: any): Promise<ILicense> {
		const resource = "/api/system/license";
		const response: AxiosResponse<any> = await ApiService.post(resource, license);
		if (response.data.data) {
			return response.data.data;
		} else {
			throw new Error("Invalid Response");
		}
	}

	static async updateLicense(license: ILicense): Promise<ILicense> {
		const resource: string = "/api/system/license/" + license.id;
		const response: AxiosResponse<any> = await ApiService.put(resource, license);
        if (response.data.data) {
			return response.data.data;
		} else {
			throw new Error("Invalid Response");
		}
	}

	static async updateLicenseOwner(licenseId: number, userId: number): Promise<void> {
		const resource: string = "/api/system/license/" + licenseId.toString() + "/" + userId.toString();
		await ApiService.put(resource, licenseId);
	}

	static async getLicenseModules(licenseId: number): Promise<ISelectedModule[]> {
		const resource: string = "/api/system/license/modules/" + licenseId.toString();
		const response: AxiosResponse<any> = await ApiService.get(resource);
        return response.data;
	}

    static async deleteLicense(licenseId: number): Promise<void> {
		const resource: string = "/api/system/license/" + licenseId.toString();
		await ApiService.delete(resource);
	}

	static async shareLicense(licenseId: number, module: Modules, userIds: number[]): Promise<void> {
		const resource: string = "/api/license/share/" + licenseId.toString();
		try {
			await ApiService.put(resource, { userIds: userIds, moduleId: module });
		} catch (e: any) {
			let msg = "Delete folder failed.";
			if (e.response) {
				msg = e.response.data.message;
			}
			throw new Error(msg);
		}
	}

	//  ------  Those for License   Issue Types ---------------------------------------------

	static async getIssueTypes(): Promise<IIssueType[]> {
		const resource = "/api/licenses/issuetypes";

		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data && response.data.data) {
				return response.data.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get license issue types failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

    static async saveIssueType(licenseId: number, it: IIssueType): Promise<IIssueType> {
        if (!it.hasOwnProperty("id") || it.id == undefined || it.id <= 0) {
            return LicenseService.saveNewIssueType(licenseId, it);
        } else {
            return LicenseService.updateIssueType(licenseId, it);
        }
    }

	// licenses/issuetypes/{license_id}
	static async saveNewIssueType(licenseId: number, it: IIssueType): Promise<IIssueType> {
		const resource: string = "/api/licenses/issuetypes/" + licenseId.toString();
		try {
			const response: AxiosResponse<any> = await ApiService.post(resource, it);
			return response.data.data;
		} catch (e: any) {
			throw e;
		}
	}

	// licenses/issuetypes/{license_id}/{issue_type_id}
	static async updateIssueType(licenseId: number, it: IIssueType): Promise<IIssueType> {
		const resource: string = "/api/licenses/issuetypes/" + licenseId.toString() + "/" + it.id?.toString();
		try {
			const response: AxiosResponse<any> = await ApiService.put(resource, it);
            return response.data.data;
		} catch (e: any) {
			throw e;
		}
	}
	// licenses/issuetypes/{license_id}/{issue_type_id}  DELETE
	static async deleteIssueType(licenseId: number, it: IIssueType): Promise<void> {
		const resource: string = "/api/licenses/issuetypes/" + licenseId.toString() + "/" + it.toString();
		const response: AxiosResponse<any> = await ApiService.delete(resource);
	}

	//  ------  Those for License  IncidentTypes ---------------------------------------------

	static async getIncidentTypes(): Promise<any[]> {
		const resource = "/api/licenses/incidenttypes";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data) {
				return response.data.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get license incident types failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async saveIncidentType(licenseId: number, incidentType: IIncidentType): Promise<IIncidentType> {
		if (incidentType.id == null || incidentType.id <= 0) {
			// new incident type
			const resource: string = "/api/licenses/incidenttypes/" + licenseId.toString();
			try {
				const response: AxiosResponse<any> = await ApiService.post(resource, incidentType);
				return response.data.data;
			} catch (e: any) {
				throw e;
			}
		} else {
			// existing incident type
			const resource: string = "/api/licenses/incidenttypes/" + licenseId.toString() + "/" + incidentType.id?.toString();
			try {
				const response: AxiosResponse<any> = await ApiService.put(resource, incidentType);
				return response.data.data;
			} catch (e: any) {
				throw e;
			}
		}

	}

	// licenses/incidenttypes/{license_id}/{incident_type_id}  DELETE
	static async deleteIncidentType(licenseId: number, et: IIncidentType): Promise<void> {
		const resource: string = "/api/licenses/incidenttypes/" + licenseId.toString() + "/" + et.toString();
		const response: AxiosResponse<any> = await ApiService.delete(resource);
	}

    // people types
    static async getPeopleTypes(): Promise<any[]> {
		const resource = "/api/licenses/peopletypes";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
            return response.data;
		} catch (e: any) {
			let msg = "Get license people types failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

    // people involvement types
	static async savePeopleTypes(peopleTypes: IPersonDropdown[]): Promise<IPersonDropdown[]> {
        const resource: string = "/api/licenses/peopletypes";
        try {
            const response: AxiosResponse<any> = await ApiService.put(resource, peopleTypes);
            return response.data;
        } catch (e: any) {
            throw e;
        }
    }

    // involvement types
    static async getInvolvementTypes(): Promise<any[]> {
        const resource = "/api/licenses/involvementtypes";
        try {
            const response: AxiosResponse<any> = await ApiService.get(resource);
            return response.data;
        } catch (e: any) {
            let msg = "Get license involvement types failed.";
            if (e.response) {
                msg = e.response.data.message;
            } else if (e.message) {
                msg = e.message;
            }
            throw new Error(msg);
        }
    }

    // people involvement types
    static async saveInvolvementTypes(involvementTypes: IPersonDropdown[]): Promise<IPersonDropdown[]> {
        const resource: string = "/api/licenses/involvementtypes";
        try {
            const response: AxiosResponse<any> = await ApiService.put(resource, involvementTypes);
            return response.data;
        } catch (e: any) {
            throw e;
        }
    }

    // incident investigation cause sections
    static async getCauseSections(): Promise<ICauseSection[]> {
		const resource = "/api/licenses/cause_sections";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data) {
				return response.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get cause sections failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

    static async saveCauseSections(causeSections: ICauseSection[]): Promise<ICauseSection[]> {
        const resource: string = "/api/licenses/cause_sections";
        try {
            const response: AxiosResponse<any> = await ApiService.put(resource, causeSections);
            return response.data;
        } catch (e: any) {
            throw e;
        }
    }

	//  ------  Those for License   EventTypes ---------------------------------------------

	static async getLicenseEventTypes(): Promise<IEventType[]> {
		const resource = "/api/licenses/eventtypes";
		const response: AxiosResponse<any> = await ApiService.get(resource);
		if (response.data) {
			return response.data;
		} else {
			throw new Error("Invalid Response");
		}
	}

	static async updateLicenseEventType(eventTypeId: number, active: boolean): Promise<IEventType> {
		const resource = "/api/licenses/eventtype/" + eventTypeId.toString();
		const response: AxiosResponse<any> = await ApiService.put(resource, { active: active });
		if (response.data) {
			return response.data;
		} else {
			throw new Error("Invalid Response");
		}
	}

	static async getEventTypes(): Promise<string[]> {
		const resource = "/api/select/eventtypes";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data) {
				//response.data should an array of objects representing each record with fieldname/value properties.
				const len = response.data.length;
				const eventTypes: string[] = [];
				for (let i = 0; i < len; i++) {
					const temp = response.data[i];
					eventTypes.push(temp);
				}
				return eventTypes;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get license event types failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	// licenses/eventtypes/{license_id}
	static async saveNewEventType(licenseId: number, et: IEventType): Promise<number> {
		const resource: string = "/api/licenses/eventtypes/" + licenseId.toString();
		const response: AxiosResponse<any> = await ApiService.post(resource, et);
		return response.data.id;
	}

	// licenses/eventtypes/{license_id}/{issue_type_id}
	static async updateEventType(licenseId: number, et: IEventType): Promise<void> {
		const resource: string = "/api/licenses/eventtypes/" + licenseId.toString() + "/" + et.id?.toString();
		const response: AxiosResponse<any> = await ApiService.put(resource, et);
	}
	// licenses/eventtypes/{license_id}/{event_type_id}  DELETE
	static async deleteEventType(licenseId: number, et: IEventType): Promise<void> {
		const resource: string = "/api/licenses/eventtypes/" + licenseId.toString() + "/" + et.toString();
		const response: AxiosResponse<any> = await ApiService.delete(resource);
	}

	//   ------------ Task Priorities ----------------------

	static async getTaskPriorities(): Promise<any[]> {
		const resource = "/api/licenses/taskpriorities";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data.data) {
				return response.data.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get license task priorities failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

    static async saveTaskPriority(licenseId: number, tp: ITaskPriority): Promise<ITaskPriority> {
        if (!tp.hasOwnProperty("id") || tp.id == undefined || tp.id <= 0) {
            return LicenseService.saveNewTaskPriority(licenseId, tp);
		} else {
            return LicenseService.updateTaskPriority(licenseId, tp);
        }
	}

	// Create
	static async saveNewTaskPriority(licenseId: number, tp: ITaskPriority): Promise<ITaskPriority> {
		const resource: string = "/api/licenses/taskpriorities/" + licenseId.toString();
		const response: AxiosResponse<any> = await ApiService.post(resource, tp);
		if (response.data) {
			return response.data.data;
		} else {
			throw new Error("Invalid Response");
		}
	}
	// Update
	static async updateTaskPriority(licenseId: number, tp: ITaskPriority): Promise<ITaskPriority> {
		const resource: string = "/api/licenses/taskpriorities/" + licenseId.toString() + "/" + tp.id?.toString();
		const response: AxiosResponse<any> = await ApiService.put(resource, tp);
        return response.data.data;
	}
	// Delete
	static async deleteTaskPriority(licenseId: number, tpId: number): Promise<void> {
		const resource: string = "/api/licenses/taskpriorities/" + licenseId.toString() + "/" + tpId.toString();
		const response: AxiosResponse<any> = await ApiService.delete(resource);
	}

	//   ------------ Roles ----------------------

	static async getRoles(): Promise<IRole[]> {
		const resource = "/api/licenses/roles";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data.data) {
				return response.data.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get license roles failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async getRoleById(roleId: number): Promise<IRole> {
		const resource = "/api/licenses/role/" + roleId.toString();
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data.data) {
				return response.data.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get role failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async saveRole(licenseId: number, role: IRole): Promise<IRole> {
        if (role.id <= 0) {
            //new record
            return LicenseService.saveNewRole(licenseId, role);
        } else {
            //existing record
            return LicenseService.updateRole(licenseId, role);
        }
    }
	static async saveNewRole(licenseId: number, role: IRole): Promise<IRole> {
		const resource: string = "/api/licenses/roles/" + licenseId.toString();
		const response: AxiosResponse<any> = await ApiService.post(resource, role);
		return response.data.data;
	}
	static async updateRole(licenseId: number, role: IRole): Promise<IRole> {
		const resource: string = "/api/licenses/roles/" + licenseId.toString() + "/" + role.id?.toString();
		const response: AxiosResponse<any> = await ApiService.put(resource, role);
		return response.data.data;
	}
	static async deleteRole(licenseId: number, roleId: number): Promise<void> {
		const resource: string = "/api/licenses/roles/" + licenseId.toString() + "/" + roleId.toString();
		await ApiService.delete(resource);
	}

	//  -----------------  Those for License   Locationss ---------------------------------------------

	static async getLocations(): Promise<ILocation[]> {
		const resource = "/api/locations";
		const response: AxiosResponse<{ data: ILocation[] }> = await ApiService.get(resource);
		if (response.data && response.data.data) {
			return response.data.data;
		} else {
			throw new Error("Invalid Response");
		}
	}

	static async saveNewLocation(location: ILocation): Promise<ILocation> {
		const resource = "/api/location";
		const response: AxiosResponse<any> = await ApiService.post(resource, location);
		if (response.data && response.data.data) {
			return response.data.data;
		} else {
			throw new Error("Invalid Response");
		}
	}

	static async updateLocation(location: ILocation): Promise<ILocation> {
		const resource: string = "/api/location/" + location.id.toString();
		//remove the selectedLocation property to avoid cyclical references also its not needed in the request.
		const payload: string = JSON.stringify(location, (key, value) => {
			if (key === "selectedLocation") {
				return undefined;
			}
			return value;
		})
		const config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json;charset=UTF-8' } };
		const response: AxiosResponse<any> = await ApiService.put(resource, payload, config);
		if (response.data && response.data.data) {
			return response.data.data;
		} else {
			throw new Error("Invalid Response");
		}
	}

	static async getLocation(id: number): Promise<ILocation> {
		const resource: string = "/api/location/" + id.toString();
		const response: AxiosResponse<{ data: ILocation }> = await ApiService.get(resource);
		if (response.data && response.data.data) {
			return response.data.data;
		} else {
			throw new Error("Invalid Response");
		}
	}

	static async deleteLocation(id: number | string): Promise<void> {
		const resource: string = "/api/location/" + id.toString();
		const response: AxiosResponse<any> = await ApiService.delete(resource);
	}

	//----------------- License Locations Categories -----------------------------------

	static async getLocationCategories(): Promise<ILocationCategory[]> {
		const resource = "/api/licenses/locationcategories";
		const response: AxiosResponse<ILocationCategory[]> = await ApiService.get(resource);
		if (response.data) {
			return response.data;
		} else {
			throw new Error("Invalid Response");
		}
	}

	static async saveNewLocationCategory(locationCategory: ILocationCategory): Promise<ILocationCategory> {
		const resource = "/api/licenses/locationcategories";
		const response: AxiosResponse<any> = await ApiService.post(resource, locationCategory);
		if (response.data) {
			return response.data;
		} else {
			throw new Error("Invalid Response");
		}
	}

	static async updateLocationCategory(locationCategory: ILocationCategory): Promise<ILocationCategory> {
		const resource: string = "/api/licenses/locationcategories/" + locationCategory.id.toString();
		const response: AxiosResponse<any> = await ApiService.put(resource, locationCategory);
		if (response.data) {
			return response.data;
		} else {
			throw new Error("Invalid Response");
		}
	}

	static async getLocationCategory(id: number): Promise<ILocationCategory> {
		const resource: string = "/api/licenses/locationcategories/" + id.toString();
		const response: AxiosResponse<ILocationCategory> = await ApiService.get(resource);
		if (response.data) {
			return response.data;
		} else {
			throw new Error("Invalid Response");
		}
	}

	static async deleteLocationCategory(id: number): Promise<void> {
		const resource: string = "/api/licenses/locationcategories/" + id.toString();
		const response: AxiosResponse<any> = await ApiService.delete(resource);
	}

	static async uploadLocationDataFile(
		parentId: number | null,
		parentType: string | null,
		parentName: string | null,
		file: File,
		progressObject: { progress: number }
	): Promise<ILocation> {
		const resource: string = "/api/location/import";
		const formData: FormData = new FormData();
		formData.append("parent_id", parentId ? parentId.toString() : "");
		formData.append("parent_type", parentType ? parentType : "");
		formData.append("parent_name", parentName ? parentName : "");
		formData.append("upload_file", file);
		const response: AxiosResponse<{ data: ILocation }> = await ApiService.post(resource, formData, {
			headers: { "Content-Type": "multipart/form-data" },
			onUploadProgress: (progressEvent) => {
				progressObject.progress = progressEvent.loaded / (progressEvent.total ?? 1);
			},
		});
		if (response.data && response.data.data) {
			return response.data.data;
		} else {
			throw new Error("Invalid Response");
		}
	}

	static async updateLocationFile(locationId: number, fileId: number | null): Promise<void> {
		const resource: string = "/api/location/syncfile/" + locationId.toString();
		await ApiService.put(resource, { location_id: locationId, file_id: fileId });
	}

	static async updateLocationAlignment(alignmentData: {
		location_id: number;
		file_id: number;
		south: number | null;
		west: number | null;
		north: number | null;
		east: number | null;
		rotation: number | null;
		style: number | null;
	}): Promise<void> {
		const resource: string = "/api/location/syncalign/" + alignmentData.location_id.toString();
		await ApiService.put(resource, alignmentData);
	}
	// ----- Teams -----------------

	// api/team/{license_id}/{team_id}  DELETE
	static async deleteTeam(licenseId: number, team: ITeam): Promise<void> {
		const resource: string = "/api/team/" + licenseId.toString() + "/" + team.id.toString();
		const response: AxiosResponse<any> = await ApiService.delete(resource);
	}

		// ----- External Party Groups -----------------

	// api/external_party_group/{licenseId}/{id}  DELETE
	static async deleteExternalPartyGroup(licenseId: number, externalPartyGroup: IExternalPartyGroup): Promise<void> {
		const resource: string = "/api/external_party_group/" + licenseId.toString() + "/" + externalPartyGroup.id.toString();
		const response: AxiosResponse<any> = await ApiService.delete(resource);
	}

	static async getTimezones(): Promise<string[]> {
		const resource = "/api/timezones";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data) {
				return response.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get timezones failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async getCountries(): Promise<string[]> {
		const resource = "/api/countries";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data) {
				return response.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get countries failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async getStates(): Promise<string[]> {
		const resource = "/api/states";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data) {
				return response.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get states failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async getVenueTypes(): Promise<IVenueType[]> {
		const resource = "/api/venuetypes";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data) {
				return response.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get venue types failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}
}
