<template>
	<div id="app-layout" class="container-fluid d-flex flex-column h-100">
		<div v-if="isWorking" class="rm-modal-backdrop">
			<b-spinner label="Working..."></b-spinner>
		</div>
		<app-error-display :errorMsg.sync="errorMsg"></app-error-display>
		<b-alert class="fixed-top m-0 rounded-0" style="z-index: 2000" v-model="hasInfo" variant="success" dismissible fade>{{ infoMsg }}</b-alert>
		<nav ref="rmHorMenu" class="row bg-mid text-dark rm-menu-h">
			<button class="rm-menu-button rm-menu-h-toggler" @click="menuHClick">
				<i class="rm-menu-v-icon">
					<font-awesome-icon icon="bars"></font-awesome-icon>
				</i>
			</button>
			<div @click="goToUrl('/home')" class="rm-menu-h-brand rm-cursor-pointer">
				<img
					v-if="brandImage && brandImage.mimeType && brandImage.base64"
					:src="'data:' + brandImage.mimeType + ';base64,' + brandImage.base64"
					:alt="brandImage.name ? brandImage.name : 'logo'"
					class="d-inline-block align-top rm-logo mr-3"
				/>
				<img v-else src="~/../../../assets/logocolblack.png" alt="logo" class="d-inline-block align-top rm-logo mr-3" />
				<span class="d-none d-sm-inline">{{ mxAppName }}</span>
			</div>

			<div class="rm-menu-h-collapse">
				<ul class="rm-menu-h-list rm-menu-tabs">
					<template v-for="menuItem in navMenuItems">
						<router-link :key="menuItem.id" v-if="menuItem.route" :to="menuItem.route" v-slot="{ navigate, isActive, isExactActive }" custom exact>
							<li class="px-2 rm-menu-item" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']" @click="navigate">
								<div class="rm-menu-h-link">
									<i class="rm-menu-h-icon">
										<font-awesome-icon :icon="menuItem.icon"></font-awesome-icon>
									</i>
									<span class="rm-menu-h-text pl-1 pr-3">{{ menuItem.name }}</span>
								</div>
							</li>
						</router-link>
						<li :key="menuItem.id" v-else class="px-2 rm-menu-item" :class="{ selected: menuItem.selected }">
							<div class="rm-menu-h-div" @click="navMenuItemClick(menuItem)">
								<i class="rm-menu-h-icon">
									<font-awesome-icon :icon="menuItem.icon"></font-awesome-icon>
								</i>
								<span class="rm-menu-h-text pl-1 pr-3">{{ menuItem.name }}</span>
							</div>
						</li>
					</template>
				</ul>
				<ul class="rm-menu-h-list ml-auto">
					<!-- <li class="pr-2 d-inline rm-menu-item">
						<i class="rm-menu-h-icon">
							<font-awesome-icon icon="question"></font-awesome-icon>
						</i>
						<span class="rm-menu-h-text pl-1 pr-3">Help</span>
					</li> -->
					<b-nav-item-dropdown right toggle-class="pr-2 pl-0 d-inline" class="rm-menu-item">
						<template slot="button-content">
							<i class="rm-menu-h-icon">
								<font-awesome-icon icon="user-circle"></font-awesome-icon>
							</i>
							<span class="rm-menu-h-text pl-1 pr-1">{{ currentUserData ? currentUserData.name : "" }}</span>
						</template>
                        <b-dropdown-item-button v-b-modal.change-profile-modal>
							<i class="rm-menu-h-icon"> <font-awesome-icon icon="user"></font-awesome-icon> </i>User Settings
                            <change-profile id="change-profile-modal"></change-profile>
                        </b-dropdown-item-button>
						<b-dropdown-item-button v-b-modal.change-password-modal>
							<i class="rm-menu-h-icon"> <font-awesome-icon icon="user-lock"></font-awesome-icon> </i>Change Password
                            <change-password id="change-password-modal"></change-password>
                        </b-dropdown-item-button>
						<b-dropdown-item to="/manageNotifications" v-if="hasWebPush">
							<i class="rm-menu-h-icon"> <font-awesome-icon icon="comment"></font-awesome-icon> </i>Manage Notifications
						</b-dropdown-item>
						<b-dropdown-item-button @click="logoutClick">
							<i class="rm-menu-h-icon"> <font-awesome-icon icon="sign-out-alt"></font-awesome-icon> </i>Sign Out
						</b-dropdown-item-button>
						<b-dropdown-item-button @click="switchLicenseClick" v-if="$store.getters['auth/licensesList']?.length > 1">
							<i class="rm-menu-h-icon"> <font-awesome-icon icon="newspaper"></font-awesome-icon> </i>Switch License
						</b-dropdown-item-button>
					</b-nav-item-dropdown>
				</ul>
			</div>
			<b-button class="ml-auto" size="md" type="button" variant="outline-secondary" @click="refresh" aria-label="Refresh" title="Refresh">
				<font-awesome-icon icon="sync-alt"></font-awesome-icon>
			</b-button>
		</nav>
		<section class="row flex-grow-1 overflow-hidden position-relative rm-body">
			<!-- This is the menu layoput when the screen is small -->
			<nav ref="rmVertMenuSmall" class="pt-3 overflow-auto bg-dark text-light rm-menu-v rm-menu-v-small collapsed rm-dark-bg-grad">
				<div class="h-100 flex-grow-1 rm-menu-container rm-block-content-small">
					<b-tabs active-nav-item-class="text-light bg-secondary" class="h-100 d-flex flex-column" content-class="flex-grow-1">
						<b-tab title="Main" active title-link-class="text-light">
                            <div class="h-100 d-flex flex-column justify-content-between">
                                <ul class="rm-menu-v-list" @click="closeVertMenuSmall">
                                    <li :title="isFunction(menuItem.name) ? menuItem.name(menuItem.param) : menuItem.name" v-for="menuItem in moduleMenuItems" :key="menuItem.id" class="rm-menu-item">
                                        <router-link :to="menuItem.route" class="text-light rm-menu-v-link">
                                            <i class="rm-menu-v-icon">
                                                <font-awesome-icon :icon="menuItem.icon"></font-awesome-icon>
                                            </i>
                                            <span class="rm-menu-v-text px-3">{{ isFunction(menuItem.name) ? menuItem.name(menuItem.param) : menuItem.name }}</span>
                                        </router-link>
                                    </li>
                                </ul>
                                <img src="~/../../../assets/logocolwhite.png" alt="logo" style="width: 11rem; margin: auto 0 0.5rem 1rem" />
                            </div>
						</b-tab>
						<b-tab v-if="navMenuItems && navMenuItems.length > 0" title="Context" title-link-class="text-light">
							<ul class="rm-menu-v-list" @click="closeVertMenuSmall">
								<li :title="isFunction(menuItem.name) ? menuItem.name(menuItem.param) : menuItem.name" v-for="menuItem in navMenuItems" :key="menuItem.id" class="rm-menu-item">
									<router-link v-if="menuItem.route" :to="menuItem.route" class="text-light rm-menu-v-link">
										<i class="rm-menu-v-icon">
											<font-awesome-icon :icon="menuItem.icon"></font-awesome-icon>
										</i>
										<span class="rm-menu-v-text px-3">{{ isFunction(menuItem.name) ? menuItem.name(menuItem.param) : menuItem.name }}</span>
									</router-link>
									<div v-else @click="navMenuItemClick(menuItem)" class="text-light rm-menu-v-link">
										<i class="rm-menu-v-icon">
											<font-awesome-icon :icon="menuItem.icon"></font-awesome-icon>
										</i>
										<span class="rm-menu-v-text px-3">{{ isFunction(menuItem.name) ? menuItem.name(menuItem.param) : menuItem.name }}</span>
									</div>
								</li>
							</ul>
						</b-tab>
						<b-tab title="User" title-link-class="text-light">
                            <div class="h-100 d-flex flex-column justify-content-between">
                                <ul class="rm-menu-v-list" @click="closeVertMenuSmall">
                                    <!-- <li class="rm-menu-item">
                                        <a class="text-light rm-menu-v-link">
                                            <i class="rm-menu-v-icon">
                                                <font-awesome-icon icon="question"></font-awesome-icon>
                                            </i>
                                            <span class="rm-menu-v-text px-3">Help</span>
                                        </a>
                                    </li> -->
                                    <li class="rm-menu-item">
                                        <router-link to="/changeProfile" class="text-light rm-menu-v-link">
                                            <i class="rm-menu-v-icon">
                                                <font-awesome-icon icon="id-card"></font-awesome-icon>
                                            </i>
                                            <span class="rm-menu-v-text px-3">Profile</span>
                                        </router-link>
                                    </li>
                                    <li class="rm-menu-item">
                                        <router-link to="/resetPassword" class="text-light rm-menu-v-link">
                                            <i class="rm-menu-v-icon">
                                                <font-awesome-icon icon="user-lock"></font-awesome-icon>
                                            </i>
                                            <span class="rm-menu-v-text px-3">Reset Password</span>
                                        </router-link>
                                    </li>
                                    <li class="rm-menu-item" v-if="hasWebPush">
                                        <router-link to="/manageNotifications" class="text-light rm-menu-v-link">
                                            <i class="rm-menu-v-icon">
                                                <font-awesome-icon icon="comment"></font-awesome-icon>
                                            </i>
                                            <span class="rm-menu-v-text px-3">Manage Notifications</span>
                                        </router-link>
                                    </li>
                                    <li class="rm-menu-item">
                                        <a class="text-light rm-menu-v-link" @click="logoutClick">
                                            <i class="rm-menu-v-icon">
                                                <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
                                            </i>
                                            <span class="rm-menu-v-text px-3">Sign Out</span>
                                        </a>
                                    </li>
                                    <li class="rm-menu-item">
                                        <a class="text-light rm-menu-v-link" @click="switchLicenseClick" v-if="$store.getters['auth/licensesList']?.length > 1">
                                            <i class="rm-menu-v-icon">
                                                <font-awesome-icon icon="newspaper"></font-awesome-icon>
                                            </i>
                                            <span class="rm-menu-v-text px-3">Switch License</span>
                                        </a>
                                    </li>
                                </ul>
                                <img src="~/../../../assets/logocolwhite.png" alt="logo" style="width: 11rem; margin: 0 0 0.5rem 1rem" />
                            </div>
						</b-tab>
					</b-tabs>
				</div>
			</nav>
			<!-- This is the menu layoput when the screen is large -->
			<nav ref="rmVertMenuLarge" class="pt-3 overflow-auto text-light rm-menu-v rm-menu-v-large collapsed rm-block-content-large rm-dark-bg-grad">
				<div class="d-flex flex-column justify-content-between h-100 flex-grow-1 overflow-hidden">
					<div class="d-flex flex-column overflow-hidden">
						<div class="px-1 py-2">
							<button class="text-light rm-menu-button" @click="menuVClick">
								<i class="rm-menu-v-icon">
									<font-awesome-icon :icon="menuVCollapsed ? 'chevron-right' : 'chevron-left'"></font-awesome-icon>
								</i>
							</button>
						</div>
						<div class="rm-menu-container">
							<ul class="rm-menu-v-list">
								<li :title="isFunction(menuItem.name) ? menuItem.name(menuItem.param) : menuItem.name" v-for="menuItem in moduleMenuItems" :key="menuItem.id" class="rm-menu-item">
									<router-link :to="menuItem.route" :data-cy-a="slug(isFunction(menuItem.name) ? menuItem.name(menuItem.param) : menuItem.name)" class="text-light rm-menu-v-link">
										<i class="rm-menu-v-icon">
											<font-awesome-icon :icon="menuItem.icon"></font-awesome-icon>
										</i>
										<span class="rm-menu-v-text pl-4">{{ isFunction(menuItem.name) ? menuItem.name(menuItem.param) : menuItem.name }}</span>
									</router-link>
								</li>
							</ul>
						</div>
					</div>
					<img v-if="menuVCollapsed" src="~/../../../assets/momentuslogo.png" alt="logo" style="width: 1.58rem; margin-left: 1.2rem; margin-bottom: 1.3rem" />
					<img v-else src="~/../../../assets/logocolwhite.png" alt="logo" style="width: 11rem; margin-left: 1rem; margin-bottom: 0.5rem" />
				</div>
			</nav>
			<article ref="rmMainContent" @click="onMainContentClick" class="col overflow-auto bg-light text-dark rm-main-article">
				<event-listener id="rm-layout-parent" ref="rm-layout-parent" @setNavMenu="setNavMenu">
					<slot />
				</event-listener>
			</article>
		</section>
		<footer class="row rm-footer bg-mid">
			&copy; Momentus Technologies {{ new Date().getFullYear() }} v{{ version }}
			<div class="ml-auto mr-3">
				<b-link class="text-dark" to="/changeProfile">Timezone {{ currentUserData ? currentUserData.timezone : "unknown" }}</b-link>
			</div>
		</footer>
		<b-modal v-if="hasWebPush" v-model="showNotificationMessage" ok-only ok-title="Close" title="System Notifications">
			<notification-manager></notification-manager>
			<div class="my-3">
				<p>You can manage your notifications at any time by selecting 'Manage Notifications' from the user menu.</p>
				<p>
					<b-form-checkbox id="checkbox-1" v-model="disableNotificationMessage" name="checkbox-1" value="true" unchecked-value="false"
						>Don't show this message again.</b-form-checkbox
					>
				</p>
			</div>
		</b-modal>
	</div>
</template>

<script lang="ts">
import { isFunction } from "lodash";
import { Component, Mixins, Vue } from "vue-property-decorator";
import { Permissions, Routes, Settings } from "../../../mixins/mixins";
import { Modules } from "../../enums/LookupEnums";
import EventListener from "../../helpers/EventListener";
import { INotificationStatus, NotificationHelper } from "../../helpers/NotificationHelper";
import { IMenuItem } from "../../interfaces/MenuItemInterface";
import { ILogo, IUserData } from "../../interfaces/UserDataInterface";
import AppErrorDisplay from "../AppErrorDisplay.vue";
import NotificationManager from "../user/NotificationManager.vue";
import ChangePassword from "@/core/views/user/ChangePassword.vue";
import ChangeProfile from "@/core/views/user/ChangeProfile.vue";

//declare var process: { env: { [key: string]: string } };

@Component({
	name: "app-layout",
	components: {
		EventListener,
		AppErrorDisplay,
		NotificationManager,
        ChangePassword,
        ChangeProfile,
	},
})
export default class AppLayout extends Mixins(Settings, Permissions, Routes) {
	/*****Properties*************************************************/
	menuVCollapsed = true;
	moduleMenuItems: IMenuItem[] = [];
	navMenuItems: IMenuItem[] = [];
	isFunction = isFunction;
	brandImage: ILogo = {} as ILogo;

	//notificationStatus: INotificationStatus;
	showNotificationMessage = false;

	/*****Computed properties*****************************/
	// Error messages and busy indicator
	get isWorking(): boolean {
		return this.$store.getters["common/isWorking"];
	}

	get errorMsg(): string[] | string | null {
		return this.$store.getters["common/errorMsg"];
	}

	set errorMsg(value: string[] | string | null) {
		this.$store.commit("common/errorMsg", value);
	}

	get infoMsg(): string | null {
		return this.$store.getters["common/infoMsg"];
	}

	set infoMsg(value: string | null) {
		this.$store.commit("common/infoMsg", value);
	}

	get currentUserData(): IUserData {
		return this.$store.getters["auth/currentUserData"];
	}

	get hasInfo(): number | boolean {
		if (this.infoMsg) return 10;
		else return false;
	}

	set hasInfo(value: number | boolean) {
		if (!value) {
			this.infoMsg = null;
		}
	}

	/* get showNotificationModal(): boolean {
		return this.$store.getters["auth/showNotificationMessage"];
	}

	set showNotificationModal(value: boolean) {
		this.$store.commit("auth/showNotificationMessage", value);
	} */

	get notificationStatus(): INotificationStatus {
		return this.$store.getters["auth/notificationStatus"];
	}

	get disableNotificationMessage(): string {
		let result: string | null = localStorage.getItem("disableNotificationMessage");
		if (result !== "true") result = "false";
		return result;
	}

	set disableNotificationMessage(value: string) {
		if (value === "true") {
			localStorage.setItem("disableNotificationMessage", "true");
		} else {
			localStorage.setItem("disableNotificationMessage", "");
		}
	}

	// These are settings values ----
	get appName(): string {
		return this.$store.getters["auth/settingsAppName"];
	}
	get dateFormat(): string {
		return this.$store.getters["auth/settingsDateFormat"];
	}
	get timeFormat(): string {
		return this.$store.getters["auth/settingsTimeFormat"];
	}
	get colourScheme(): string {
		return this.$store.getters["auth/settingsColourScheme"];
	}
	get sessionTime(): string {
		return this.$store.getters["auth/settingsSessionTime"];
	}
	get hasWebPush(): boolean {
		return this.$store.getters["auth/settingsHasWebPush"];
	}

	get version() {
		return (process.env.VUE_APP_VERSION ? process.env.VUE_APP_VERSION : "") + (process.env.VUE_APP_VER ? process.env.VUE_APP_VER : "");
	}

	/*****Lifecycle hooks*************************************************/
	created(): void {
		this.getModuleMenuItems();
		this.brandImage = this.$store.getters["auth/settingsBrandImage"];
		//check notification status.
		if (this.disableNotificationMessage !== "true") {
			NotificationHelper.setNotificationsStatus()
				.then(() => {
					const status: INotificationStatus = this.$store.getters["auth/notificationStatus"];
					if (status?.notificationsSupported) {
						if (status?.notificationsBlocked || !status?.notificationsEnabled) {
							this.showNotificationMessage = true;
						}
					}
				})
				.catch(() => {
					this.$store.commit("auth/resetNotificationStatus", null);
					this.showNotificationMessage = false;
				});
		}
	}

	/*****Event handlers**************************************************/

	menuHClick(): void {
		const vertMenuSmall: any = this.$refs.rmVertMenuSmall;
		vertMenuSmall.classList.toggle("collapsed");
	}

	menuVClick(): void {
		const vertMenuLarge: any = this.$refs.rmVertMenuLarge;
		vertMenuLarge.classList.toggle("collapsed");
		if (vertMenuLarge.classList.contains("collapsed")) {
			this.menuVCollapsed = true;
		} else {
			this.menuVCollapsed = false;
		}
	}

	profileClick(): void {
		alert("Not Implemented");
	}

	logoutClick(): void {
		this.$store.dispatch("auth/logout");
	}

	switchLicenseClick(): void {
		this.$store.dispatch("auth/switchLicense");
	}

	navMenuItemClick(menuItem: IMenuItem): void {
		this.navMenuItems.forEach((mi: IMenuItem) => {
			mi.selected = false;
		});
		Vue.set(menuItem, "selected", true);
		if (isFunction(menuItem.action)) {
			menuItem.action();
		}
	}

	/*****Public methods********************************************************/
	getModuleMenuItems() {
		let result: IMenuItem[] = [];
		result.push({
			id: 1,
			name: "Home",
			icon: "home",
			route: "/home",
			action: null,
			subMenus: null,
		});
		/* if (this.mxHasModule(Modules.Dashboard) && this.mxHasPermission("DA-DA-VIEW")) {
			result.push({
				id: 2,
				name: "Dashboard",
				icon: "tachometer-alt",
				route: "/dashboard",
				//route: "/",
				action: null,
				subMenus: null,
			});
		} */

		// sys admin dont have these license based modules
		if (this.mxHasModule(Modules.RiskProfiling) && (this.mxHasPermission("RP-MGR") || this.mxHasPermission("RP-USER"))) {
			result.push({
				id: 4,
				name: "Risk Profiler",
				icon: "chart-line",
				route: "/rs101/rp_profile",
				action: null,
				subMenus: null,
			});
		}
		if (this.mxHasModule(Modules.RiskAssessment) && this.mxHasPermission("RA-RA-VIEW")) {
			result.push({
				id: 3,
				name: "Risk Assessment",
				icon: "exclamation-triangle",
				route: "/risk-assessment",
				action: null,
				subMenus: null,
			});
		}
		if (this.mxHasModule(Modules.Issues) && this.mxHasPermission("IS-EV-VIEW")) {
			result.push({
				id: 5,
				name: "Issue Manager",
				icon: "clipboard-list-check",
				route: "/issue-manager",
				action: null,
				subMenus: null,
			});
		}
		if (this.mxHasModule(Modules.Incidents) && this.mxHasPermission("IN-IN-VIEW")) {
			result.push({
				id: 6,
				name: "Incident Reporting",
				icon: "notes-medical",
				route: "/incident-reporting",
				action: null,
				subMenus: null,
			});
		}
		if (this.mxHasModule(Modules.ContractorManagement) && (this.mxHasPermission("CM-MGR") || this.mxHasPermission("CM-USER"))) {
			result.push({
				id: 7,
				name: "Contractor Document Manager",
				icon: "user-hard-hat",
				route: "/rs101/cm_matrix",
				action: null,
				subMenus: null,
			});
		}
		if (this.mxHasModule(Modules.TaskManagement) && this.mxHasPermission("TK-TM-VIEW")) {
			result.push({
				id: 8,
				name: "Task Manager",
				icon: "tasks",
				route: "/task-manager",
				action: null,
				subMenus: null,
			});
		}
		if (this.mxHasModule(Modules.SystemAdmin) && this.mxIsSysAdmin && this.mxLicenseId === 1) {
			result.push({
				id: 10,
				name: "System Admin",
				icon: "tools",
				route: "/system-admin",
				action: null,
				subMenus: null,
			});
		}
		if (this.mxHasModule(Modules.LicenseAdmin) && this.mxIsLicenseAdmin) {
			result.push({
				id: 9,
				name: "License Settings",
				icon: "cog",
				route: "/license-settings",
				action: null,
				subMenus: null,
			});
		}
		this.moduleMenuItems = result;
	}

	setNavMenu(menuItems: IMenuItem[]): void {
		this.clearNavMenu();
		this.navMenuItems = menuItems;
	}

	clearNavMenu(): void {
		this.navMenuItems = [];
	}

	closeVertMenuSmall(): void {
		//close the main menu
		const vertMenuSmall: any = this.$refs.rmVertMenuSmall;
		if (vertMenuSmall && !vertMenuSmall.classList.contains("collapsed")) {
			vertMenuSmall.classList.add("collapsed");
		}
	}
	refresh(): void {
		this.$router.go(0);
	}

	onMainContentClick(): void {
		this.closeVertMenuSmall();
	}
}
</script>

<style lang="scss" scoped>
/// Styles chosen menu item
.router-link-active {
	background-color: #aaa;
}
.router-link-exact-active {
	background-color: #aaa;
}
.rm-block-content-large {
	display: none;
}
.rm-block-content-small {
	display: block;
}
.rm-footer {
	height: 30px;
	line-height: 30px;
	padding-left: 0.25em;
}
.tab-pane {
  height: 100%;
}
</style>
