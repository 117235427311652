const authErrorMessages = {
	ERR_INVALID_EMAIL: "Please enter a valid email address.",
	ERR_UNRECOG_EMAIL: "We do not recognise that email address, please try again.",
	ERR_PWDS_DIFFER: "Password and confirmation do not match.",
	ERR_INVALID_LIC: "Please select an account from the list.",
	ERR_INVALID_CREDENTIALS: "Invalid credentials, please try again.",
	ERR_INVALID_PWD: "Invalid password, please try again.",
	ERR_LOGIN_FAIL: "Login failed, please try again. If the problem persists please contact support",
};

const authInfoMessages = {
	INFO_PASSWORD_CHANGED: "Your password was successfully saved.",
};

const recoverPasswordInfoMessages = {
    EMAIL_SENT: (email: string) => 'An email has been sent to ' + email + '. Follow the link in the email to reset your password.',
    EMAIL_NOT_FOUND: (email: string) => 'No account associated with ' + email + '.',
}

enum authLoginSteps {
	STEP_START = 0,
	STEP_SEL_LIC = 1,
    STEP_NO_LIC = 5,
	STEP_CHANGE_PWD = 2,
	STEP_RECOVER_PWD = 3,
	STEP_LOGGED_IN = 4,
}

export const authConstants = {
	authErrorMessages,
	authInfoMessages,
    recoverPasswordInfoMessages,
	authLoginSteps,
};

export { authErrorMessages };
export { authInfoMessages };
export { recoverPasswordInfoMessages };
export { authLoginSteps };
