import { ILevel, ILocation, IVenue } from "../interfaces/LocationInterface";

export function findLocationById(location: IVenue | ILevel | ILocation, locId: number): ILocation | undefined {
	let result: ILocation | undefined = undefined;
	if (location.id.toString() === locId.toString()) {
		result = location;
	} else if ((location as IVenue).levels && (location as IVenue).levels!.length > 0) {
		for (const level of (location as IVenue).levels!) {
			result = findLocationById(level, locId);
			if (result) break;
		}
	} else if ((location as ILevel).spaces && (location as ILevel).spaces!.length > 0) {
		for (const space of (location as ILevel).spaces!) {
			result = findLocationById(space, locId);
			if (result) break;
		}
	}
	return result;
}

export function findLocationByCode(location: IVenue | ILevel | ILocation, locCode: string): ILocation | undefined {
	let result: ILocation | undefined = undefined;
	if (location.code === locCode) {
		result = location;
	} else if ((location as IVenue).levels && (location as IVenue).levels!.length > 0) {
		for (const level of (location as IVenue).levels!) {
			result = findLocationByCode(level, locCode);
			if (result) break;
		}
	} else if ((location as ILevel).spaces && (location as ILevel).spaces!.length > 0) {
		for (const space of (location as ILevel).spaces!) {
			result = findLocationByCode(space, locCode);
			if (result) break;
		}
	}
	return result;
}


/**
 * Gets the venue and level of an incident if it is in an overlay
 */
export function getVenLvlSpc(model: any, venues: IVenue[]): { venue: IVenue | null; level: ILevel | null; space: ILocation | null } | null {
		let result: { venue: IVenue | null; level: ILevel | null; space: ILocation | null } = { venue: null, level: null, space: null };
		let found: boolean = false;
		if (model.primary_location_id) {
			for (const venue of venues) {
				if (findVenLvlSpcInVenues(venue, model.primary_location_id, result)) {
					found = true;
					break;
				}
			}
		}
		if (found) {
			return result;
		} else {
			return null;
		}
	}

	/**
	 * finds a location by id in a venue retaining the venue, level and location as appropriate
	 */
export function findVenLvlSpcInVenues(location: IVenue | ILevel | ILocation, locId: number, result: { venue: IVenue | null; level: ILevel | null; space: ILocation | null }): boolean {
		let out: boolean = false;
		switch (location.lku_location_type_id) {
			case "VEN":
				result.venue = location;
				break;
			case "LVL":
				result.level = location;
				break;
			case "SPC":
				result.space = location;
				break;
		}
		if (location.id.toString() === locId.toString()) {
			out = true;
		} else if (location.lku_location_type_id === "VEN" && (location as IVenue).levels && (location as IVenue).levels!.length > 0) {
			for (const level of (location as IVenue).levels!) {
				out = findVenLvlSpcInVenues(level, locId, result);
				if (out) break;
			}
		} else if (location.lku_location_type_id === "LVL" && (location as ILevel).spaces && (location as ILevel).spaces!.length > 0) {
			for (const space of (location as ILevel).spaces!) {
				out = findVenLvlSpcInVenues(space, locId, result);
				if (out) break;
			}
		}
		return out;
	}

/**
* a and b are google world coordinate points obtained from map.projection.fromLatLngToPoint
* pixel Size is a number obtained like so: Math.pow(2, -map.getZoom())
* see https://developers.google.com/maps/documentation/javascript/coordinates
*/
export function getDistance(a: google.maps.Point, b: google.maps.Point, pixelSize: number): number {
   const dx: number = a.x - b.x;
   const dy: number = a.y - b.y;
   return Math.sqrt(dx * dx + dy * dy) / pixelSize;
}
