import { IControl } from "../modules/RiskAssessments/interfaces/ControlInterface";
import { IRisk, DetailView} from "../modules/RiskAssessments/interfaces/RiskInterface";
import RiskService from "../modules/RiskAssessments/services/RiskService";
import Vue from "vue";
import { riskErrorMessages } from "./riskConstants";
import { formatError } from "../core/helpers/ErrorHelper";

interface IState {
	riskList: IRisk[];
}

const state: IState = {
	riskList: [],
};

const getters = {
	riskList: (state: IState): IRisk[] => {
		return state.riskList;
	},
};

const actions = {
	async getRiskList({ commit, state }: any, riskAssessmentId: number): Promise<IRisk[]> {
		try {
			const riskList: IRisk[] = await RiskService.getRisks(riskAssessmentId);
			commit("gotRiskList", riskList);
			return riskList;
		} catch (e: any) {
			commit("common/errorMsg", riskErrorMessages.ERR_GET_RISKS_FAILED, { root: true });
			throw e;
		}
	},
	async saveRisk({ commit, state }: any, params: { risk: IRisk; riskAssessmentId: number }): Promise<IRisk> {
		try {
			const savedRisk: IRisk = await RiskService.saveRisk(params.risk, params.riskAssessmentId);
			commit("riskSaved", savedRisk);
			commit("control/syncRiskControls", savedRisk, { root: true });
			return savedRisk;
		} catch (e: any) {
			commit("common/errorMsg", formatError(e, riskErrorMessages.ERR_SAVE_RISK_FAILED), { root: true });
			throw e;
		}
	},
	async addNewRisks({ commit, state }: any, params: { risks: IRisk[]; riskAssessmentId: number; }): Promise<IRisk[]> {
		try {
			const savedRisks: IRisk[] = await RiskService.addNewRisks(params.risks, params.riskAssessmentId);
			commit("newRisksSaved", savedRisks);
			commit("control/syncMultipleRiskControls", savedRisks, { root: true });
			return savedRisks;
		} catch (e: any) {
			commit("common/errorMsg", formatError(e, riskErrorMessages.ERR_SAVE_CONTROL_FAILED), { root: true });
			throw e;
		}
	},
	async saveCurrentRisk({ commit, state }: any, riskId: number): Promise<IRisk> {
		try {
			//find the risk in the risks array
			const foundRisk: IRisk | undefined = state.riskList.find((r: IRisk) => r.id === riskId);
			if (foundRisk) {
				const savedRisk: IRisk = await RiskService.saveRisk(foundRisk, foundRisk.risk_assessment_id);
				commit("riskSaved", savedRisk);
				commit("control/syncRiskControls", savedRisk, { root: true });
				return savedRisk;
			} else {
				throw "Risk not found.";
			}
		} catch (e: any) {
			commit("common/errorMsg", formatError(e, riskErrorMessages.ERR_SAVE_RISK_FAILED), { root: true });
			throw e;
		}
	},

	async saveRiskControlAllocation({ commit, state }: any, risk: IRisk): Promise<void> {
		try {
			const riskControlXref: { riskId: number; controlIds: number[] }[] = [{ riskId: risk.id, controlIds: risk.control_ids }];
			await RiskService.saveRiskControls(riskControlXref);
			commit("control/syncRiskControls", risk, { root: true });
		} catch (e: any) {
			commit("common/errorMsg", formatError(e, riskErrorMessages.ERR_SAVE_RISK_FAILED), { root: true });
			throw e;
		}
	},
};

const mutations = {
	gotRiskList(state: IState, riskList: IRisk[]) {
		state.riskList = riskList;
	},
	riskSaved(state: IState, risk: IRisk) {
		//find the risk in the risk list and update it or if not found add it to the array.
		const foundRisk: IRisk | undefined = state.riskList.find((r: IRisk) => r.id === risk.id);
		if (foundRisk) {
			//update the found risk
			Object.assign(foundRisk, risk);
		} else {
			//add it to the array
			state.riskList.push(risk);
		}
	},
	newRisksSaved(state: IState, risks: IRisk[]) {
		state.riskList.push(...risks);	
	},
	setRiskField(state: IState, params: { risk: IRisk; fieldKey: string; value: any }) {
		const foundRisk: IRisk | undefined = state.riskList.find((r: IRisk) => r.id === params.risk.id);
		if (foundRisk) {
			(foundRisk as any)[params.fieldKey] = params.value;
		}
	},
	setInherentRiskItem(state: IState, params: { risk: IRisk; idx: number; val: number }) {
		const foundIndex: number = state.riskList.findIndex((r: IRisk) => r.id === params.risk.id);
		if (foundIndex >= 0) {
			(state.riskList[foundIndex].inherent_component_item_ids as number[])[params.idx] = params.val;
		}
	},
	setResidualRiskItem(state: IState, params: { risk: IRisk; idx: number; val: number }) {
		const foundIndex: number = state.riskList.findIndex((r: IRisk) => r.id === params.risk.id);
		if (foundIndex >= 0) {
			(state.riskList[foundIndex].residual_component_item_ids as number[])[params.idx] = params.val;
		}
	},
	setInherentRiskItems(state: IState, params: { risk: IRisk; vals: number[] }) {
		const foundIndex: number = state.riskList.findIndex((r: IRisk) => r.id === params.risk.id);
		if (foundIndex >= 0) {
			(state.riskList[foundIndex].inherent_component_item_ids as number[]) = params.vals;
		}
	},
	setResidualRiskItems(state: IState, params: { risk: IRisk; vals: number[] }) {
		const foundIndex: number = state.riskList.findIndex((r: IRisk) => r.id === params.risk.id);
		if (foundIndex >= 0) {
			(state.riskList[foundIndex].residual_component_item_ids as number[]) = params.vals;
		}
	},
	addControlsToRisk(state: IState, params: { risk: IRisk; controls: IControl[] }) {
		const foundIndex: number = state.riskList.findIndex((r: IRisk) => r.id === params.risk.id);
		if (foundIndex >= 0) {
			(state.riskList[foundIndex].control_ids as number[]) = params.controls.map((c) => c.id);
		}
	},
	/**
	 * Ensures the control id is in the risk.control_ids field of all the control.risk_ids in the control
	 */
	syncControlRisks(state: IState, control: IControl) {
		if (control.risk_ids.length > 0) {
			for (const riskId of control.risk_ids) {
				const foundRisk: IRisk | undefined = state.riskList.find((r: IRisk) => r.id === riskId);
				if (foundRisk) {
					const controlIds: number[] = foundRisk.control_ids;
					if (controlIds && !controlIds.includes(control.id)) {
						foundRisk.control_ids.push(control.id);
					}
					let controls: IControl[] | undefined = foundRisk.controls;
					if (!controls) {
						controls = [];
						foundRisk.controls = controls;
					}
					if (controls && !controls.some((c: IControl) => c.id === control.id)) {
						foundRisk.controls!.push(control);
					}
				}
			}
		}
	},
	/**
	 * Ensures the control id is in the risk.control_ids field of all the control.risk_ids in multiple controls
	 */
	syncMultipleControlRisks(state: IState, controls: IControl[]) {
		for (const control of controls) {
			if (control.risk_ids.length > 0) {
				for (const riskId of control.risk_ids) {
					const foundRisk: IRisk | undefined = state.riskList.find((r: IRisk) => r.id === riskId);
					if (foundRisk) {
						const controlIds: number[] = foundRisk.control_ids;
						if (controlIds && !controlIds.includes(control.id)) {
							foundRisk.control_ids.push(control.id);
						}
						let controls: IControl[] | undefined = foundRisk.controls;
						if (!controls) {
							controls = [];
							foundRisk.controls = controls;
						}
						if (controls && !controls.some((c: IControl) => c.id === control.id)) {
							foundRisk.controls!.push(control);
						}
					}
				}
			}
		}
	},
	/**
	 * Syncs all the control objects into the controls property of all risks.
	 * controls are the full list of all controls
	 */
	syncAllRiskControls(state: IState, controls: IControl[]) {
		for (let risk of state.riskList) {
			Vue.set(risk, "controls", []);
			for (const controlId of risk.control_ids) {
				const foundControl: IControl | undefined = controls.find((c: IControl) => c.id === controlId);
				if (foundControl && risk.controls) {
					risk.controls.push(foundControl);
				}
			}
		}
	},
	toggleDetails(state: IState, risk: IRisk) {
		const set = Boolean(risk._showDetails);
		for (const risk of state.riskList) {
			if (Boolean(risk._showDetails)) risk._showDetails = false;
		}
		if (!set) {
			risk._showDetails = true;
		}
	},
	clearRiskList(state: IState) {
		state.riskList = [];
	},
	clearDetails(state: IState) {
		for (const risk of state.riskList) {
			if (Boolean(risk._showDetails)) {
				risk._showDetails = false; //Vue.set(ctl, "_showDetails", false); //
			}
		}
	},
	setDetails(state: IState, params: { risk: IRisk, view: DetailView }) {
		const set = Boolean(params.risk._showDetails);
		if (!set) {
			params.risk._detailView = params.view;
			Vue.set(params.risk, "_showDetails", true);
		}
	},
};

export const risk = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
