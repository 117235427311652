import axios, { AxiosHeaderValue, AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios";

import { CookieHelper } from "../helpers/CookieHelper";

export default abstract class ApiService {
	static setHeader(): void {
		axios.defaults.withCredentials = true;
		axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
		const xsrfToken = CookieHelper.getCookie("XSRF-TOKEN");
		if (xsrfToken) {
			axios.defaults.headers.common["X-XSRF-TOKEN"] = xsrfToken;
		}
	}

	static isHeaderSet(): boolean {
		return !!(axios.defaults.headers.common["X-Requested-With"] && axios.defaults.headers.common["X-XSRF-TOKEN"]);
	}

	static removeHeader() {
		axios.defaults.withCredentials = false;
		delete axios.defaults.headers.common["X-Requested-With"];
		delete axios.defaults.headers.common["X-XSRF-TOKEN"];
	}

	static setToken(token: string) {
		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	}

	static getToken(): string | undefined {
		const authHeader: AxiosHeaderValue | undefined = axios.defaults.headers.common["Authorization"];
		if (authHeader && (authHeader as string).substring(0, 6) === "Bearer") {
			return (authHeader as string).substring(7);
		}
	}

	static removeToken() {
		const authHeader: AxiosHeaderValue | undefined = axios.defaults.headers.common["Authorization"];
		if (authHeader && (authHeader as string).substring(0, 6) === "Bearer") {
			delete axios.defaults.headers.common["Authorization"];
		}
	}

	static get(resource: string, config?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
		if (config) {
			//example config = { headers: {responseType: 'arraybuffer' }}
			return axios.get(resource, config);
		} else {
			return axios.get(resource);
		}
	}

	static post(resource: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
		if (config) {
			//example config = { headers: { "Content-Type": "multipart/form-data" }}
			return axios.post(resource, data, config);
		} else {
			return axios.post(resource, data);
		}
	}

	static put(resource: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
		if (config) {
			return axios.put(resource, data, config);
		} else {
			return axios.put(resource, data);
		}
	}

	static delete(resource: string, config?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
		if (config) {
			return axios.delete(resource, config);
		} else {
			return axios.delete(resource);
		}
	}

	/**
	 * Perform a custom Axios request.
	 *
	 * data is an object containing the following properties:
	 *  - method
	 *  - url
	 *  - data ... request payload
	 *  - auth (optional)
	 *    - username
	 *    - password
	 **/
	static customRequest(data: AxiosRequestConfig): AxiosPromise<any> {
		return axios(data);
	}
}
