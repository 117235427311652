import { ChartType, SeriesType, ChartFieldType } from "../enums/ChartEnum";
import { FieldCategories, FieldTypes, LayoutItemTypes } from "../enums/LookupEnums";
import { IDdValue, ILayoutItem, ILayoutItemConditionGroup } from "../interfaces/CustomFieldInterface";
import ChartHelper from "../helpers/ChartHelper";

export default abstract class ChartConstants {
    static record_count_field = {
        id: -1,
        lku_field_category_id: FieldCategories.Custom,
        lku_field_type_id: FieldTypes.Integer,
        name: 'record_count',
        label: "Count",
        active: true,
        mandatory: false,
        dd_values: [],
    }

    static none_grouping_field = {
        id: -1,
        lku_field_category_id: FieldCategories.Custom,
        lku_field_type_id: FieldTypes.DropdownFd,
        name: 'no_grouping',
        label: "None",
        active: true,
        mandatory: false,
        dd_values: [{display_sequence: 0, text: "", value: "no_grouping"}],
    }

    // these are colur palettes defined by apex charts
    static ApexPalettes = {
        "palette1": ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"],
        "palette2":	["#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D", "#FF9800"],
        "palette3":	["#33B2DF", "#546E7A", "#D4526E", "#13D8AA", "#A5978B"],
        "palette4":	["#4ECDC4", "#C7F464", "#81D4FA", "#546E7A", "#FD6A6A"],
        "palette5":	["#2B908F", "#F9A3A4", "#90EE7E", "#FA4443", "#69D2E7"],
        "palette6":	["#449DD1", "#F86624", "#EA3546", "#662E9B", "#C5D86D"],
        "palette7":	["#D7263D", "#1B998B", "#2E294E", "#F46036", "#E2C044"],
        "palette8":	["#662E9B", "#F86624", "#F9C80E", "#EA3546", "#43BCCD"],
        "palette9":	["#5C4742", "#A5978B", "#8D5B4C", "#5A2A27", "#C4BBAF"],
        "palette10": ["#A300D6", "#7D02EB", "#5653FE", "#2983FF", "#00B1F2"]
    };

    static getApexPaletteSelectList = (palettes: {[key: string]: string[]}) => {
        let display_sequence_counter = 0;
        let result: IDdValue[] = [];
        for (let key in palettes) {
            result.push({
                display_sequence: display_sequence_counter++,
                value: key,
                text: "Palette " + key.substring(7),
                html:  ChartConstants.genColourPaletteHtml("Palette " + (key.substring(7)).padStart(20, " "), palettes[key]),
                selectable: true,
                selected: display_sequence_counter == 0 ? true : false,
            });
        }
        return result;
    }

    static genColourPaletteHtml = (text: String, colours: string[]) => {
        let htmlString = '<span class="d-flex flex-direction-row"><span style="min-width: 5em">' + text + '</span><div class="row ml-auto pl-3 ml-3">';
        colours.forEach((colour: string) => htmlString = (htmlString + '<div class="col" style="background: ' + colour + '; height: 1em; margin: 2px;"></div>'));
        htmlString = (htmlString + '</div></span>');
        return htmlString;
    }

    static chartTypeOptions: IDdValue[] = [
        {
            value: ChartType.Pie,
            text: 'Pie',
            display_sequence: 0,
        },
        {
            value: ChartType.Donut,
            text: 'Donut',
            display_sequence: 1,
        },
        // {
        //     value: ChartType.RadialBar,
        //     text: 'RadialBar',
        //     display_sequence: 2,
        // },
        {
            value: ChartType.HeatMap,
            text: 'Heat Map',
            display_sequence: 3,
        },
        {
            value: ChartType.Bar,
            text: 'Bar',
            display_sequence: 4,
        },
        // {
        //     value: ChartType.Radar,
        //     text: 'Radar',
        //     display_sequence: 5,
        // },
        // {
        //     value: ChartType.Bubble,
        //     text: 'Bubble',
        //     display_sequence: 6,
        // },
        // {
        //     value: ChartType.CandleStick,
        //     text: 'CandleStick',
        //     display_sequence: 7,
        // },
        {
            value: ChartType.Histogram,
            text: 'Histogram',
            display_sequence: 8,
        },
        {
            value: ChartType.Scatter,
            text: 'Scatter',
            display_sequence: 9,
        },
        {
            value: ChartType.Line,
            text: 'Line',
            display_sequence: 10,
        },
        {
            value: ChartType.Area,
            text: 'Area',
            display_sequence: 11,
        },
    ].sort((optA, optB) => {
        return optA.text.toLowerCase().localeCompare(optB.text.toLowerCase());
    });





    // -----  Chart Fields   -----

    static record_type_key = 0;
    static chart_type_key = 2;
    static primary_axis_field_id_key = 3;
    static secondary_axis_field_id_key = 4;
    static grouping_field_id_key = 5;
    static bucket_size_field_key = 6;


    /**
     * Helper method for chartFields, builds conditions to show/hide a field based on the series type
     * @param seriesType
     * @returns
     */
    static ifSeriesTypeIs = (seriesTypes: SeriesType[]) => {
        let result: ILayoutItemConditionGroup[] = [];
        ChartConstants.chartTypeOptions.forEach((chartType)=>{
            seriesTypes.forEach((seriesType) => {
                if (ChartHelper.getSeriesTypeForChartType(chartType.value as ChartType) == seriesType) {
                    result.push({ conditions: [
                                        {
                                            condition_on_id: ChartConstants.chart_type_key,
                                            condition_on_name: "type",
                                            operator: '=',
                                            value: chartType.value as ChartType,
                                            type: FieldTypes.DropdownFd,
                                        },
                                ]},);
                }
            });
        });
        return result;
    }

    static ifSeriesTypeIsnt = (seriesType: SeriesType) => {
        let result: ILayoutItemConditionGroup[] = [{conditions: []}];
        ChartConstants.chartTypeOptions.forEach((chartType)=>{
            if (ChartHelper.getSeriesTypeForChartType(chartType.value as ChartType) == seriesType) {
                result[0].conditions.push({
                                        condition_on_id: ChartConstants.chart_type_key,
                                        condition_on_name: "type",
                                        operator: '!=',
                                        value: chartType.value as ChartType,
                                        type: FieldTypes.DropdownFd,
                                    });
            }
        });
        result[0].conditions.push({
            condition_on_id: ChartConstants.chart_type_key,
            condition_on_name: "type",
            operator: '!=',
            value: null,
            type: FieldTypes.DropdownFd,
        });
        return result;
    }


    static chartFields: ILayoutItem[] = [
        {
            id: 0,
            layout_id: -1,
            field_definition_id: 0,
            lku_layout_item_type_id: LayoutItemTypes.Field,
            heading: "Record Type",
            heading_detail: null,
            display_sequence: 0,
            field_name: "lku_master_table_id",
            mandatory: true,
            field_definition: {
                id: 0,
                lku_field_category_id: FieldCategories.Mandatory,
                lku_field_type_id: FieldTypes.DropdownFd,
                name: "lku_master_table_id",
                label: "Record Type",
                active: true,
                mandatory: true,
                dd_values: [],
            },
            mobile_view: true,
            advanced_field: false,
            conditional: false,
            hidden_by_default: false,
        },
        {
            id: 1,
            layout_id: -1,
            field_definition_id: 1,
            lku_layout_item_type_id: LayoutItemTypes.Field,
            heading: "Heading",
            heading_detail: null,
            display_sequence: 1,
            field_name: "heading",
            mandatory: true,
            field_definition: {
                id: 1,
                lku_field_category_id: FieldCategories.Mandatory,
                lku_field_type_id: FieldTypes.String,
                name: "heading",
                label: "Heading",
                active: true,
                mandatory: true,
                dd_values: [],
            },
            mobile_view: true,
            advanced_field: false,
            conditional: false,
            hidden_by_default: false,
        },
        {
            id: 2,
            layout_id: -1,
            field_definition_id: 2,
            lku_layout_item_type_id: LayoutItemTypes.Field,
            heading: "Chart Type",
            heading_detail: null,
            display_sequence: 1,
            field_name: "type",
            mandatory: true,
            field_definition: {
                id: 2,
                lku_field_category_id: FieldCategories.Mandatory,
                lku_field_type_id: FieldTypes.DropdownFd,
                name: "type",
                label: "Chart Type",
                active: true,
                mandatory: true,
                dd_values: ChartConstants.chartTypeOptions,
            },
            mobile_view: true,
            advanced_field: false,
            conditional: false,
            hidden_by_default: false,
        },
        {
            id: 3,
            layout_id: -1,
            field_definition_id: 3,
            lku_layout_item_type_id: LayoutItemTypes.Field,
            heading: "Horizontal Axis",
            heading_detail: null,
            display_sequence: 2,
            field_name: "primary_axis_field_id",
            mandatory: false,
            field_definition: {
                id: 3,
                lku_field_category_id: FieldCategories.Mandatory,
                lku_field_type_id: FieldTypes.DropdownFd,
                name: "primary_axis_field_id",
                label: "Horizontal Axis",
                active: true,
                mandatory: true,
                dd_values: [],
            },
            mobile_view: true,
            advanced_field: false,
            conditional: true,
            conditions: ChartConstants.ifSeriesTypeIs([
                SeriesType.Categorical_Categorical,
                SeriesType.Categorical_Numeric,
                SeriesType.Numeric_Numeric,
                SeriesType.DiscreteNumeric_Numeric,
            ]),
            hidden_by_default: true,
        },
        {
            id: 4,
            layout_id: -1,
            field_definition_id: 4,
            lku_layout_item_type_id: LayoutItemTypes.Field,
            heading: "Vertical Axis",
            heading_detail: null,
            display_sequence: 4,
            field_name: "secondary_axis_field_id",
            mandatory: false,
            field_definition: {
                id: 4,
                lku_field_category_id: FieldCategories.Mandatory,
                lku_field_type_id: FieldTypes.DropdownFd,
                name: "secondary_axis_field_id",
                label: "Vertical Axis",
                active: true,
                mandatory: true,
                dd_values: [],
            },
            mobile_view: true,
            advanced_field: false,
            conditional: true,
            conditions: ChartConstants.ifSeriesTypeIs([
                SeriesType.Categorical_Categorical,
                SeriesType.Categorical_Numeric,
                SeriesType.Numeric_Numeric,
                SeriesType.DiscreteNumeric_Numeric,
            ]),
            hidden_by_default: true,
        },
        {
            id: 5,
            layout_id: -1,
            field_definition_id: 5,
            lku_layout_item_type_id: LayoutItemTypes.Field,
            heading: "Group By",
            heading_detail: null,
            display_sequence: 2,
            field_name: "grouping_field_id",
            mandatory: false,
            field_definition: {
                id: 5,
                lku_field_category_id: FieldCategories.Mandatory,
                lku_field_type_id: FieldTypes.DropdownFd,
                name: "grouping_field_id",
                label: "Group By",
                active: true,
                mandatory: true,
                dd_values: [],
            },
            mobile_view: true,
            advanced_field: false,
            conditional: true,
            conditions: ChartConstants.ifSeriesTypeIs([
                            SeriesType.Categorical_None,
                            SeriesType.Categorical_Numeric,
                            SeriesType.Numeric_Numeric,
                            SeriesType.DiscreteNumeric_Numeric,
                        ]).concat({ conditions: [
                            {
                                condition_on_id: ChartConstants.chart_type_key,
                                condition_on_name: "type",
                                operator: '=',
                                value: ChartType.Map,
                                type: FieldTypes.DropdownFd,
                            }
                        ]}).map((item) => {
                                item.conditions.push({
                                    condition_on_id: ChartConstants.chart_type_key,
                                    condition_on_name: "type",
                                    operator: '!=',
                                    value: ChartType.Histogram,
                                    type: FieldTypes.DropdownFd,
                                });
                                return item;
                        }),
            hidden_by_default: true,
        },
        {
            id: 6,
            layout_id: -1,
            field_definition_id: 6,
            lku_layout_item_type_id: LayoutItemTypes.Field,
            heading: "Bucket Size",
            heading_detail: null,
            display_sequence: 2,
            field_name: "bucket_size",
            mandatory: false,
            field_definition: {
                id: 6,
                lku_field_category_id: FieldCategories.Mandatory,
                lku_field_type_id: FieldTypes.DropdownFd,
                name: "bucket_size",
                label: "Bucket Size",
                active: true,
                mandatory: true,
                dd_values: [],
            },
            mobile_view: true,
            advanced_field: false,
            conditional: true,
            conditions: ChartConstants.ifSeriesTypeIs([SeriesType.DiscreteNumeric_Numeric]),
            hidden_by_default: true,
        },
        {
            id: 7,
            layout_id: -1,
            field_definition_id: 7,
            lku_layout_item_type_id: LayoutItemTypes.Field,
            heading: "Layout",
            heading_detail: null,
            display_sequence: 2,
            field_name: "grouping_layout",
            mandatory: false,
            field_definition: {
                id: 7,
                lku_field_category_id: FieldCategories.Mandatory,
                lku_field_type_id: FieldTypes.DropdownFd,
                name: "grouping_layout",
                label: "Layout",
                active: true,
                mandatory: true,
                dd_values: [{value: "cluster", text: "Cluster", display_sequence: 0},
                            {value: "stacked", text: "Stacked", display_sequence: 1}],
            },
            mobile_view: true,
            advanced_field: false,
            conditional: true,
            conditions: [{ conditions: [
                                {
                                    condition_on_id: ChartConstants.chart_type_key,
                                    condition_on_name: "type",
                                    operator: '=',
                                    value: ChartType.Bar,
                                    type: FieldTypes.DropdownFd,
                                },
                                {
                                    condition_on_id: ChartConstants.grouping_field_id_key,
                                    condition_on_name: "grouping_field_id",
                                    operator: '!=',
                                    value: null,
                                    type: FieldTypes.DropdownFd,
                                },
                                {
                                    condition_on_id: ChartConstants.grouping_field_id_key,
                                    condition_on_name: "grouping_field_id",
                                    operator: '!=',
                                    value: -1,
                                    type: FieldTypes.DropdownFd,
                                },
                        ]},],
            hidden_by_default: true,
        },
        {
            id: 8,
            layout_id: -1,
            field_definition_id: 8,
            lku_layout_item_type_id: LayoutItemTypes.Field,
            heading: "Layout",
            heading_detail: null,
            display_sequence: 8,
            field_name: "grouping_layout",
            mandatory: false,
            field_definition: {
                id: 8,
                lku_field_category_id: FieldCategories.Optional,
                lku_field_type_id: FieldTypes.DropdownFd,
                name: "horizontal",
                label: "Orientation",
                active: true,
                mandatory: false,
                dd_values: [{value: true, text: "Horizontal", display_sequence: 0},
                            {value: false, text: "Vertical", display_sequence: 1}],
            },
            mobile_view: true,
            advanced_field: false,
            conditional: true,
            conditions: [{ conditions: [
                                {
                                    condition_on_id: ChartConstants.chart_type_key,
                                    condition_on_name: "type",
                                    operator: '=',
                                    value: ChartType.Bar,
                                    type: FieldTypes.DropdownFd,
                                },
                        ]},],
            hidden_by_default: true,
        },
        {
            id: 9,
            layout_id: -1,
            field_definition_id: 9,
            lku_layout_item_type_id: LayoutItemTypes.Field,
            heading: "Palette",
            heading_detail: null,
            display_sequence: 2,
            field_name: "palette",
            mandatory: false,
            field_definition: {
                id: 9,
                lku_field_category_id: FieldCategories.Mandatory,
                lku_field_type_id: FieldTypes.HierarchicalDdFd,
                name: "palette",
                label: "Palette",
                active: true,
                mandatory: true,
                dd_values: ChartConstants.getApexPaletteSelectList(ChartConstants.ApexPalettes),
            },
            mobile_view: true,
            advanced_field: false,
            conditional: true,
            conditions: [{ conditions: [
                                {
                                    condition_on_id: ChartConstants.chart_type_key,
                                    condition_on_name: "type",
                                    operator: '=',
                                    value: 'inherent_matrix',
                                    type: FieldTypes.DropdownFd,
                                }]
                            },
                         { conditions: [
                                {
                                    condition_on_id: ChartConstants.chart_type_key,
                                    condition_on_name: "type",
                                    operator: '=',
                                    value: 'residual_matrix',
                                    type: FieldTypes.DropdownFd,
                                }]
                         }],
            hidden_by_default: false,
        },
        {
            id: 10,
            layout_id: -1,
            field_definition_id: 10,
            lku_layout_item_type_id: LayoutItemTypes.Field,
            heading: "Width",
            heading_detail: null,
            display_sequence: 10,
            field_name: "width",
            mandatory: false,
            field_definition: {
                id: 10,
                lku_field_category_id: FieldCategories.Mandatory,
                lku_field_type_id: FieldTypes.Integer,
                name: "width",
                label: "Width",
                active: true,
                mandatory: true,
                dd_values: [],
            },
            mobile_view: true,
            advanced_field: false,
            conditional: false,
            hidden_by_default: false,
        },
        {
            id: 11,
            layout_id: -1,
            field_definition_id: 11,
            lku_layout_item_type_id: LayoutItemTypes.Field,
            heading: "Height",
            heading_detail: null,
            display_sequence: 11,
            field_name: "height",
            mandatory: false,
            field_definition: {
                id: 11,
                lku_field_category_id: FieldCategories.Mandatory,
                lku_field_type_id: FieldTypes.Integer,
                name: "height",
                label: "Height",
                active: true,
                mandatory: true,
                dd_values: [],
            },
            mobile_view: true,
            advanced_field: false,
            conditional: false,
            hidden_by_default: false,
        },
        {
            id: 12,
            layout_id: -1,
            field_definition_id: 12,
            lku_layout_item_type_id: LayoutItemTypes.Field,
            heading: "Description",
            heading_detail: null,
            display_sequence: 1,
            field_name: "description",
            mandatory: true,
            field_definition: {
                id: 12,
                lku_field_category_id: FieldCategories.Mandatory,
                lku_field_type_id: FieldTypes.Text,
                name: "description",
                label: "Description",
                active: true,
                mandatory: true,
                dd_values: [],
            },
            mobile_view: true,
            advanced_field: false,
            conditional: false,
            hidden_by_default: false,
        },
    ];
}
