interface ICommon {
	errorMsg: string[] | string | null;
	infoMsg: string | null;
	isWorking: boolean;
	isWorkingStack: number;
}

const state: ICommon = {
	errorMsg: null,
	infoMsg: null,
	isWorking: false,
	isWorkingStack: 0,
};

const getters = {
	errorMsg: (state: ICommon): string[] | string | null => {
		return state.errorMsg;
	},
	infoMsg: (state: ICommon): string | null => {
		return state.infoMsg;
	},
	isWorking: (state: ICommon): boolean => {
		return state.isWorking;
	},
	isWorkingStack: (state: ICommon): number => {
		return state.isWorkingStack;
	},
};

const actions = {};

const mutations = {
	errorMsg(state: ICommon, value: string[] | string | null) {
		state.errorMsg = value;
	},
	infoMsg(state: ICommon, value: string | null) {
		state.infoMsg = value;
	},
	isWorking(state: ICommon, value: boolean) {
		state.isWorking = value;
	},
	isWorkingStack(state: ICommon, value: boolean) {
		if (value) {
			state.isWorkingStack++;
		} else if (!value && state.isWorkingStack > 0) {
			state.isWorkingStack--;
		} //else isWorkingStack = 0
		state.isWorking = state.isWorkingStack != 0;
	},
};

export const common = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
