import {
    IRiskDefinition,
    IRiskDefinitionCategory,
    IRiskDefinitionComponent,
    IRiskDefinitionComponentItem,
    IRiskDefinitionConsequence,
    IRiskDefinitionCriterion,
    IRiskDefinitionFormulaElement,
    IRiskDefinitionMatrixItem,
} from "../modules/RiskAssessments/interfaces/RiskDefinitionsInterface";

import { Commit } from "vuex";
import { FormulaElementType } from "../modules/RiskAssessments/enums/RiskDefinitionsEnums";
import { FormulaService } from "../modules/RiskAssessments/services/FormulaService";
import { IRole } from "@/core/interfaces/RoleInterface";
import RiskDefinitionService from "../modules/RiskAssessments/services/RiskDefinitionService";
import { SortDirection } from "@/core/enums/SortDirectionEnum";
import Vue from "vue";
import { cloneDeep } from "lodash";
import { formatError } from "../core/helpers/ErrorHelper";
import { riskDefinitionErrorMessages } from "./riskDefinitionConstants";

interface IState {
    riskDefinition: IRiskDefinition;
    selectedComponentItem: IRiskDefinitionComponentItem | null;
    selectedCriterion: IRiskDefinitionCriterion | null;
    selectedCategory: IRiskDefinitionCategory | null;
    selectedCategoryConsequence: IRiskDefinitionConsequence | null;
    selectedMatrixItem: IRiskDefinitionMatrixItem | null;
    xGraduations: number | null;
    yGraduations: number | null;
}

const state: IState = {
    riskDefinition: {
        id: -new Date().valueOf(),
        name: "",
        description: null,
        license_id: null,
        is_template: false,
        is_default: false,
        incl_categories: false,
        layout_template_id: null,
        x_formula_elements: null,
        y_formula_elements: null,
        formula_operator: null,
        visual_x_component: null,
        visual_y_component: null,
        risk_definition_categories: [],
        risk_definition_components: [],
        risk_definition_matrix_items: [],
        risk_definition_criteria: [],
        risk_definition_roles: [],
    },
    //selectedComponent: null,
    selectedComponentItem: null,
    selectedCriterion: null,
    selectedCategory: null,
    selectedCategoryConsequence: null,
    selectedMatrixItem: null,
    xGraduations: null,
    yGraduations: null,
};
const getters = {
    riskDefinition: (state: IState) => {
        return state.riskDefinition;
    },
    riskDefId: (state: IState): number => {
        return state.riskDefinition.id;
    },
    riskDefName: (state: IState): string => {
        return state.riskDefinition.name;
    },
    riskDefDescription: (state: IState): string | null => {
        return state.riskDefinition.description;
    },
    riskDefInclCat: (state: IState): boolean => {
        return state.riskDefinition.incl_categories;
    },
    riskDefLayoutId: (state: IState): number | null => {
        return state.riskDefinition.layout_template_id;
    },
    riskDefIsTemplate: (state: IState): boolean => {
        return state.riskDefinition.is_template;
    },
    riskDefIsDefault: (state: IState): boolean => {
        return state.riskDefinition.is_default;
    },
    selectedCategory: (state: IState): IRiskDefinitionCategory | null => {
        return state.selectedCategory;
    },
    selectedCategoryConsequence: (state: IState): IRiskDefinitionConsequence | null => {
        return state.selectedCategoryConsequence;
    },
    selectedComoponentItem: (state: IState): IRiskDefinitionComponentItem | null => {
        return state.selectedComponentItem;
    },
    selectedCriterion: (state: IState): IRiskDefinitionCriterion | null => {
        return state.selectedCriterion;
    },
    selectedMatrixItem: (state: IState): IRiskDefinitionMatrixItem | null => {
        return state.selectedMatrixItem;
    },
    xGraduations: (state: IState): number => {
        let result = 5;
        if (state.xGraduations !== null) {
            result = state.xGraduations;
        } else {
            const temp: number | undefined = state.riskDefinition.visual_x_component?.risk_definition_component_items.length;
            if (temp) {
                result = temp;
            }
        }
        return result;
    },
    yGraduations: (state: IState): number => {
        let result = 5;
        if (state.yGraduations !== null) {
            result = state.yGraduations;
        } else {
            const temp: number | undefined = state.riskDefinition.visual_y_component?.risk_definition_component_items.length;
            if (temp) {
                result = temp;
            }
        }
        return result;
    },
    minMaxComponentItemScores: (state: IState) => (axis: "x" | "y"): { min: number; max: number } => {
        let componentFormulaElements;
        if (axis === "x") {
            componentFormulaElements = state.riskDefinition.x_formula_elements;
        } else {
            componentFormulaElements = state.riskDefinition.y_formula_elements;
        }
        const minMaxScores: { min: number; max: number } = FormulaService.minMaxScores(
            componentFormulaElements as IRiskDefinitionFormulaElement[],
            state.riskDefinition.risk_definition_components
        );
        return minMaxScores;
    },
};
const actions = {
    async getRiskDefinition({ commit, state }: { commit: Commit; state: IState }, riskDefinitionId: number): Promise<IRiskDefinition> {
        try {
            const riskDef: IRiskDefinition = await RiskDefinitionService.getRiskDefById(riskDefinitionId);
            commit("gotRiskDefinition", riskDef);
            return riskDef;
        } catch (e: any) {
            commit("common/errorMsg", riskDefinitionErrorMessages.ERR_GET_RISK_DEFINITION_FAILED, { root: true });
            throw e;
        }
    },
    async getRiskDefinitionForRiskAssessment({ commit, state }: { commit: Commit; state: IState }, riskAsssessmentId: number): Promise<IRiskDefinition> {
        try {
            const riskDef: IRiskDefinition = await RiskDefinitionService.getRiskDefByRiskAssessmentId(riskAsssessmentId);
            commit("gotRiskDefinition", riskDef);
            return riskDef;
        } catch (e: any) {
            commit("common/errorMsg", riskDefinitionErrorMessages.ERR_GET_RISK_DEFINITION_FAILED, { root: true });
            throw e;
        }
    },
    async getDefaultRiskDefinition({ commit, state }: { commit: Commit; state: IState }): Promise<IRiskDefinition> {
        try {
            const riskDef: IRiskDefinition = await RiskDefinitionService.getDefaultRiskDef();
            commit("gotRiskDefinition", riskDef);
            return riskDef;
        } catch (e: any) {
            commit("common/errorMsg", riskDefinitionErrorMessages.ERR_GET_RISK_DEFINITION_FAILED, { root: true });
            throw e;
        }
    },
    async saveRiskDefinition({ commit, state }: { commit: Commit; state: IState }, riskAssessmentId: number | null): Promise<IRiskDefinition> {
        return RiskDefinitionService.saveRiskDef(state.riskDefinition, riskAssessmentId)
            .then((riskDef: IRiskDefinition) => {
                commit("gotRiskDefinition", riskDef);
                return riskDef;
            })
            .catch((e) => {
                commit("common/errorMsg", formatError(e, riskDefinitionErrorMessages.ERR_SAVE_RISK_DEFINITION_FAILED), { root: true });
                throw e;
            });
    },
};
const mutations = {
    /*************************Risk Definition***********************/
    gotRiskDefinition(state: IState, riskDef: IRiskDefinition) {
        //sort components
        riskDef.risk_definition_components.sort((a: IRiskDefinitionComponent, b: IRiskDefinitionComponent) => a.seq - b.seq);
        //sort component items
        for (const component of riskDef.risk_definition_components) {
            const direction: SortDirection = (component as IRiskDefinitionComponent).sort;
            const items: IRiskDefinitionComponentItem[] = (component as IRiskDefinitionComponent).risk_definition_component_items;
            if (items) {
                if (direction === SortDirection.Ascending) {
                    items.sort((a, b) => (a.factor || 0) - (b.factor || 0));
                } else if (direction === SortDirection.Descending) {
                    items.sort((a, b) => (b.factor || 0) - (a.factor || 0));
                }
            }
        }
        //replace visual_x_component and visual_y_component with the same object from risk_definition_components
        if (riskDef.visual_x_component) {
            // find the item in risk_definition_components by id
            const foundComponent = riskDef.risk_definition_components.find((comp) => comp.id === (riskDef.visual_x_component as IRiskDefinitionComponent).id);
            if (foundComponent) {
                riskDef.visual_x_component = foundComponent;
            }
        }
        if (riskDef.visual_y_component) {
            const foundComponent = riskDef.risk_definition_components.find((comp) => comp.id === (riskDef.visual_y_component as IRiskDefinitionComponent).id);
            if (foundComponent) {
                riskDef.visual_y_component = foundComponent;
            }
        }
        //populate components in matrix items with the appropriate objects in the components - component items arrays
        if (riskDef.risk_definition_matrix_items && Array.isArray(riskDef.risk_definition_matrix_items) && riskDef.risk_definition_matrix_items.length > 0) {
            //get a single array containing all the component items
            let componentItems: IRiskDefinitionComponentItem[] | null = null;
            if (riskDef.risk_definition_components && Array.isArray(riskDef.risk_definition_components) && riskDef.risk_definition_components.length > 0) {
                const initialVal: IRiskDefinitionComponentItem[] = [];
                componentItems = riskDef.risk_definition_components.reduce(
                    (acc: IRiskDefinitionComponentItem[], val: IRiskDefinitionComponent) => acc.concat(val.risk_definition_component_items),
                    initialVal
                );
            }
            for (const matrixItem of riskDef.risk_definition_matrix_items) {
                if (matrixItem.risk_definition_x_component_item_id && componentItems) {
                    //find the object in the components - component items arrays
                    const foundComponentItem: IRiskDefinitionComponentItem | undefined = componentItems.find(
                        (ci: IRiskDefinitionComponentItem) => ci.id === matrixItem.risk_definition_x_component_item_id
                    );
                    if (foundComponentItem) {
                        Vue.set(matrixItem, "risk_definition_x_component_item", foundComponentItem);
                    }
                }
                if (matrixItem.risk_definition_x_component_item_id && componentItems) {
                    //find the object in the components - component items arrays
                    const foundComponentItem: IRiskDefinitionComponentItem | undefined = componentItems.find(
                        (ci: IRiskDefinitionComponentItem) => ci.id === matrixItem.risk_definition_y_component_item_id
                    );
                    if (foundComponentItem) {
                        Vue.set(matrixItem, "risk_definition_y_component_item", foundComponentItem);
                    }
                }
                if (
                    matrixItem.risk_definition_criterion_id &&
                    riskDef.risk_definition_criteria &&
                    Array.isArray(riskDef.risk_definition_criteria) &&
                    riskDef.risk_definition_criteria.length > 0
                ) {
                    //find the object in the criteria array
                    const foundCriterion: IRiskDefinitionCriterion | undefined = riskDef.risk_definition_criteria.find(
                        (cr: IRiskDefinitionCriterion) => cr.id === matrixItem.risk_definition_criterion_id
                    );
                    if (foundCriterion) {
                        Vue.set(matrixItem, "risk_definition_criterion", foundCriterion);
                    }
                }
            }
        }
        state.riskDefinition = riskDef;
    },
    setRiskDefId(state: IState, value: number) {
        state.riskDefinition.id = value;
    },
    setRiskDefName(state: IState, value: string) {
        state.riskDefinition.name = value;
    },
    setRiskDefDescription(state: IState, value: string | null) {
        state.riskDefinition.description = value;
    },
    setRiskDefInclCat(state: IState, value: boolean) {
        state.riskDefinition.incl_categories = value;
    },
    setRiskDefLayoutId(state: IState, value: number | null) {
        state.riskDefinition.layout_template_id = value;
    },
    setRiskDefIsTemplate(state: IState, value: boolean) {
        state.riskDefinition.is_template = value;
    },
    setRiskDefIsDefault(state: IState, value: boolean) {
        state.riskDefinition.is_default = value;
    },
    /*************************Category Editor***********************/
    setSelectedCategory(state: IState, category: IRiskDefinitionCategory) {
        //set the selected item
        state.selectedCategory = category;
    },
    addNewCategory(state: IState, workingCategory: IRiskDefinitionCategory) {
        state.riskDefinition.risk_definition_categories.push(cloneDeep(workingCategory)); //Object.assign({}, workingCategory)
    },
    updateSelectedCategory(state: IState, workingCategory: IRiskDefinitionCategory) {
        //copy the working item into the selected item
        Object.assign(state.selectedCategory!, workingCategory);
    },
    deleteSelectedCategory(state: IState) {
        if (state.selectedCategory) {
            const index = state.riskDefinition.risk_definition_categories.indexOf(state.selectedCategory);
            if (index > -1) {
                state.riskDefinition.risk_definition_categories.splice(index, 1);
            }
        }
    },

    /*************************Component Editor***********************/
    /* setSelectedComponent(state: IState, component: IRiskDefinitionComponent | null) {
        state.selectedComponent = component;
    }, */
    addNewComponent(state: IState, newRiskDefComponent: IRiskDefinitionComponent) {
        const newComponent = cloneDeep(newRiskDefComponent); //Object.assign({}, newRiskDefComponent)
        state.riskDefinition.risk_definition_components.push(newComponent);
    },
    updateComponentName(state: IState, params: { component: IRiskDefinitionComponent; name: string }) {
        params.component.name = params.name;
    },
    updateComponentIsConsequence(state: IState, params: { component: IRiskDefinitionComponent; isConsequence: boolean }) {
        if (params.isConsequence) {
            //only one component may be marked as is_consequence so set all others to false.
            for (let comp of state.riskDefinition.risk_definition_components) {
                if (comp.is_consequence) comp.is_consequence = false;
            }
        }
        params.component.is_consequence = params.isConsequence;
    },
    /* updateSelectedComponent(state: IState, workingComponent: IRiskDefinitionComponent) {
        Object.assign(state.selectedComponent, workingComponent);
    }, */
    deleteComponent(state: IState, component: IRiskDefinitionComponent) {
        if (component) {
            const index = state.riskDefinition.risk_definition_components.indexOf(component);
            if (index > -1) {
                state.riskDefinition.risk_definition_components.splice(index, 1);
            }
            //update x_formula_elements, y_formula_elements and visual_x_component, visual_y_component as necessary
            //x_formula_elements
            if (state.riskDefinition.x_formula_elements && state.riskDefinition.x_formula_elements.some((f: any) => f.value === component!.name)) {
                state.riskDefinition.x_formula_elements = null;
                state.riskDefinition.formula_operator = null;
                state.riskDefinition.visual_x_component = null;
            }
            //y_formula_elements
            if (state.riskDefinition.y_formula_elements && state.riskDefinition.y_formula_elements.some((f: any) => f.value === component!.name)) {
                state.riskDefinition.y_formula_elements = null;
                state.riskDefinition.formula_operator = null;
                state.riskDefinition.visual_y_component = null;
            }
            //visual_x_component
            if (
                state.riskDefinition.visual_x_component &&
                (state.riskDefinition.visual_x_component.id === component.id || state.riskDefinition.visual_x_component.name === component.name)
            ) {
                state.riskDefinition.visual_x_component = null;
                state.riskDefinition.x_formula_elements = null;
                state.riskDefinition.formula_operator = null;
            }
            //visual_y_component
            if (
                state.riskDefinition.visual_y_component &&
                (state.riskDefinition.visual_y_component.id === component.id || state.riskDefinition.visual_y_component.name === component.name)
            ) {
                state.riskDefinition.visual_y_component = null;
                state.riskDefinition.y_formula_elements = null;
                state.riskDefinition.formula_operator = null;
            }
        }
    },
    /* toggleSelectedComponentSort(state: IState) {
        let component: IRiskDefinitionComponent = state.selectedComponent as IRiskDefinitionComponent;
        let items: IRiskDefinitionComponentItem[] = component.risk_definition_component_items;
        if (component.sort === SortDirection.Ascending) {
            component.sort = SortDirection.Descending;
            items.sort((a, b) => (b.factor || 0) - (a.factor || 0));
        } else {
            component.sort = SortDirection.Ascending;
            items.sort((a, b) => (a.factor || 0) - (b.factor || 0));
        }
    }, */
    toggleComponentSort(state: IState, component: IRiskDefinitionComponent | null) {
        if (component) {
            const items: IRiskDefinitionComponentItem[] = component.risk_definition_component_items;
            if (component.sort === SortDirection.Ascending) {
                component.sort = SortDirection.Descending;
                items.sort((a, b) => (b.factor || 0) - (a.factor || 0));
            } else {
                component.sort = SortDirection.Ascending;
                items.sort((a, b) => (a.factor || 0) - (b.factor || 0));
            }
        }
    },
    moveSelectedComponentToIndex(state: IState, params: { component: IRiskDefinitionComponent; newIndex: number }) {
        if (params.component) {
            const components: IRiskDefinitionComponent[] = state.riskDefinition.risk_definition_components;
            const selectedIndex: number = components.indexOf(params.component);
            //rearrange the array
            components.splice(params.newIndex, 0, components.splice(selectedIndex, 1)[0]);
            //update the seq fields
            for (let i = 0; i < components.length; i++) {
                components[i].seq = i;
            }
        }
    },
    setSelectedComponentItem(state: IState, componentItem: IRiskDefinitionComponentItem | null) {
        state.selectedComponentItem = componentItem;
    },
    addNewComponentItem(state: IState, params: { component: IRiskDefinitionComponent; newComponentItem: IRiskDefinitionComponentItem }) {
        if (params.component) {
            params.component.risk_definition_component_items.push(cloneDeep(params.newComponentItem));
        }
    },
    updateSelectedComponentItem(state: IState, workingItem: IRiskDefinitionComponentItem) {
        Object.assign(state.selectedComponentItem!, workingItem);
    },
    deleteSelectedComponentItem(state: IState, component: IRiskDefinitionComponent) {
        if (component && component.risk_definition_component_items && state.selectedComponentItem) {
            const index = (component as IRiskDefinitionComponent).risk_definition_component_items.indexOf(state.selectedComponentItem);
            if (index > -1) {
                (component as IRiskDefinitionComponent).risk_definition_component_items.splice(index, 1);
            }
        }
    },
    setSelectedCategoryConsequence(state: IState, categoryConsequence: IRiskDefinitionConsequence | null) {
        state.selectedCategoryConsequence = categoryConsequence;
    },
    updateSelectedCategoryConsequenceDescription(state: any, description: string) {
        state.selectedCategoryConsequence.description = description;
    },
    addNewCategoryConsequence(state: IState, params: { description: string; categoryId: number }) {
        if (state.selectedComponentItem) {
            const newCategoryConsequence: IRiskDefinitionConsequence = {
                id: -new Date().valueOf(),
                description: params.description,
                risk_definition_component_item_id: state.selectedComponentItem.id,
                risk_definition_category_id: params.categoryId,
            };
            state.selectedComponentItem.risk_definition_consequences.push(newCategoryConsequence);
        }
    },
    /*************************Formula Editor***********************/
    setOperator(state: any, value: IRiskDefinitionFormulaElement) {
        state.riskDefinition.formula_operator = value;
    },
    removeOperator(state: IState) {
        state.riskDefinition.formula_operator = null;
    },
    addXElement(state: IState, params: { element: IRiskDefinitionFormulaElement; index: number }) {
        if (!state.riskDefinition.x_formula_elements) {
            state.riskDefinition.x_formula_elements = [];
        }
        state.riskDefinition.x_formula_elements.splice(params.index, 0, params.element);
    },
    addYElement(state: IState, params: { element: IRiskDefinitionFormulaElement; index: number }) {
        if (!state.riskDefinition.y_formula_elements) {
            state.riskDefinition.y_formula_elements = [];
        }
        state.riskDefinition.y_formula_elements.splice(params.index, 0, params.element);
    },
    removeXElementAtIndex(state: IState, index: number) {
        if (state.riskDefinition.x_formula_elements) state.riskDefinition.x_formula_elements.splice(index, 1);
    },
    removeYElementAtIndex(state: IState, index: number) {
        if (state.riskDefinition.y_formula_elements) state.riskDefinition.y_formula_elements.splice(index, 1);
    },
    /*************************Matrix Editor***********************/
    updateSelectedComponentItemName(state: IState, name: string) {
        if (state.selectedComponentItem) state.selectedComponentItem.name = name;
    },
    updateSelectedComponentItemFactor(state: IState, factor: number) {
        if (state.selectedComponentItem) state.selectedComponentItem.factor = factor;
    },
    setVisualXComponent(state: IState, component: IRiskDefinitionComponent) {
        state.riskDefinition.visual_x_component = component;
    },
    setVisualYComponent(state: IState, component: IRiskDefinitionComponent) {
        state.riskDefinition.visual_y_component = component;
    },
    setVisualComponent(state: IState, axis: "x" | "y"): void {
        let component: IRiskDefinitionComponent | null = null;
        let variables: IRiskDefinitionFormulaElement[] | null = null;
        let name = "";
        let componentFormulaElements: IRiskDefinitionFormulaElement[] | null = null;
        let graduations = 5;
        if (axis === "x") {
            if (state.riskDefinition && state.riskDefinition.x_formula_elements && (state.riskDefinition.x_formula_elements as IRiskDefinitionFormulaElement[]).length > 0) {
                variables = (state.riskDefinition.x_formula_elements as IRiskDefinitionFormulaElement[]).filter((el) => el.type === FormulaElementType.Variable);
                name = FormulaService.getVisualComponentName(state.riskDefinition.x_formula_elements as IRiskDefinitionFormulaElement[]);
                componentFormulaElements = state.riskDefinition.x_formula_elements;
                graduations = state.xGraduations ? state.xGraduations : 5;
            } else {
                variables = null;
            }
        } else if (axis === "y") {
            if (state.riskDefinition && state.riskDefinition.y_formula_elements && (state.riskDefinition.y_formula_elements as IRiskDefinitionFormulaElement[]).length > 0) {
                variables = (state.riskDefinition.y_formula_elements as IRiskDefinitionFormulaElement[]).filter((el) => el.type === FormulaElementType.Variable);
                name = FormulaService.getVisualComponentName(state.riskDefinition.y_formula_elements as IRiskDefinitionFormulaElement[]);
                componentFormulaElements = state.riskDefinition.y_formula_elements;
                graduations = state.yGraduations ? state.yGraduations : 5;
            } else {
                variables = null;
            }
        }
        if (
            variables &&
            variables.length > 0 &&
            componentFormulaElements &&
            FormulaService.validate(componentFormulaElements).result &&
            FormulaService.calculateTest(componentFormulaElements)
        ) {
            if (variables.length === 1) {
                const foundComponent: IRiskDefinitionComponent | undefined = state.riskDefinition.risk_definition_components.find(
                    (c: IRiskDefinitionComponent) => c.name === (variables as IRiskDefinitionFormulaElement[])[0].value
                );
                component = foundComponent !== undefined ? (foundComponent as IRiskDefinitionComponent) : null;
            } else {
                const componentItems: IRiskDefinitionComponentItem[] = [];
                const minMaxScores: { min: number; max: number } = FormulaService.minMaxScores(
                    componentFormulaElements as IRiskDefinitionFormulaElement[],
                    state.riskDefinition.risk_definition_components
                );
                const newComponentId = -new Date().valueOf();
                for (let i = 0; i < graduations; i++) {
                    const step: number = (minMaxScores.max - minMaxScores.min) / graduations;
                    const fromScore: number = Math.round(minMaxScores.min + i * step);
                    const toScore: number = Math.round(minMaxScores.min + (i + 1) * step);
                    const name = fromScore.toFixed(0) + "-" + toScore.toFixed(0);
                    componentItems.push({
                        id: newComponentId - 1 - i,
                        name: name,
                        description: "",
                        define: "",
                        factor: toScore, //parseFloat((fromScore + (toScore - fromScore) / 2).toFixed(1)),
                        frequency: null,
                        frequency_unit: null,
                        risk_definition_component_id: newComponentId,
                        risk_definition_consequences: [],
                    });
                }
                const newComponent: IRiskDefinitionComponent = {
                    id: newComponentId,
                    name: name,
                    seq: 0,
                    sort: SortDirection.Ascending,
                    is_visual: true,
                    is_consequence: false,
                    risk_definition_id: state.riskDefinition.id,
                    risk_definition_component_items: componentItems,
                };
                component = newComponent;
                //add the component to the components array
                state.riskDefinition.risk_definition_components.push(component);
            }
        }
        if (axis === "x") {
            //if the existing component is a visual component then it should be deleted from the components list
            //otherwise it will become an orphan.
            if (state.riskDefinition.visual_x_component && state.riskDefinition.visual_x_component.is_visual) {
                if (state.riskDefinition.risk_definition_components) {
                    const foundIndex = state.riskDefinition.risk_definition_components.findIndex((c: IRiskDefinitionComponent) => c === state.riskDefinition.visual_x_component);
                    if (foundIndex) {
                        state.riskDefinition.risk_definition_components.splice(foundIndex, 1);
                    }
                }
            }
            Vue.set(state.riskDefinition, "visual_x_component", component);
        } else if (axis === "y") {
            //if the existing component is a visual component then it should be deleted from the components list
            //otherwise it will become an orphan.
            if (state.riskDefinition.visual_y_component && state.riskDefinition.visual_y_component.is_visual) {
                if (state.riskDefinition.risk_definition_components) {
                    const foundIndex = state.riskDefinition.risk_definition_components.findIndex((c: IRiskDefinitionComponent) => c === state.riskDefinition.visual_y_component);
                    if (foundIndex) {
                        state.riskDefinition.risk_definition_components.splice(foundIndex, 1);
                    }
                }
            }
            Vue.set(state.riskDefinition, "visual_y_component", component);
        }
    },
    sortAndNameVisualComponentItems(state: IState, params: { axis: "x" | "y"; minMaxScores: { min: number; max: number } }) {
        let component: IRiskDefinitionComponent | null = null;
        if (params.axis === "x") {
            component = state.riskDefinition.visual_x_component;
        } else if (params.axis === "y") {
            component = state.riskDefinition.visual_y_component;
        }
        if (component) {
            const items: IRiskDefinitionComponentItem[] | undefined = component?.risk_definition_component_items;
            if (items) {
                if (component.sort === SortDirection.Ascending) {
                    items.sort((a, b) => (a.factor || 0) - (b.factor || 0));
                    let prevItemHigh = "0";
                    for (let i = 0; i < items.length; i++) {
                        if (i === 0) {
                            //This is the smallest factor
                            items[i].description = "(" + params.minMaxScores.min.toString() + "-" + items[i].factor.toString() + ")";
                            prevItemHigh = items[i].factor.toString();
                        } else if (i === items.length - 1) {
                            //This is the largest factor
                            if (items[i].factor !== params.minMaxScores.max) {
                                items[i].factor = params.minMaxScores.max;
                            }
                            items[i].description = "(" + prevItemHigh + "-" + params.minMaxScores.max.toString() + ")";
                            prevItemHigh = items[i].factor.toString();
                        } else {
                            items[i].description = "(" + prevItemHigh + "-" + items[i].factor.toString() + ")";
                            prevItemHigh = items[i].factor.toString();
                        }
                    }
                } else if (component.sort === SortDirection.Descending) {
                    items.sort((a, b) => (b.factor || 0) - (a.factor || 0));
                    let prevItemHigh = "0";
                    for (let i = items.length - 1; i >= 0; i--) {
                        if (i === items.length - 1) {
                            //This is the largest factor
                            items[i].description = "(" + params.minMaxScores.min.toFixed(0) + "-" + items[i].factor.toString() + ")";
                            prevItemHigh = items[i].factor.toString();
                        } else if (i === 0) {
                            //This is the smallest factor
                            if (items[i].factor !== params.minMaxScores.max) {
                                items[i].factor = params.minMaxScores.max;
                            }
                            items[i].description = "(" + prevItemHigh + "-" + params.minMaxScores.max.toFixed(0) + ")";
                            prevItemHigh = items[i].factor.toString();
                        } else {
                            items[i].description = "(" + prevItemHigh + "-" + items[i].factor.toString() + ")";
                            prevItemHigh = items[i].factor.toString();
                        }
                    }
                }
            }
        }
    },
    setXGraduations(state: IState, value: number) {
        state.xGraduations = value;
    },
    setYGraduations(state: IState, value: number) {
        state.yGraduations = value;
    },
    setSelectedMatrixItem(state: IState, params: { xComponentItem: IRiskDefinitionComponentItem; yComponentItem: IRiskDefinitionComponentItem }) {
        let foundItem: IRiskDefinitionMatrixItem | undefined | null = null;
        if (state.riskDefinition.risk_definition_matrix_items) {
            foundItem = state.riskDefinition.risk_definition_matrix_items.find(
                (rdmi: IRiskDefinitionMatrixItem) =>
                    rdmi.risk_definition_x_component_item === params.xComponentItem && rdmi.risk_definition_y_component_item === params.yComponentItem
            );
        } else {
            state.riskDefinition.risk_definition_matrix_items = [];
        }
        if (!foundItem) {
            foundItem = {
                id: -new Date().valueOf(),
                risk_definition_x_component_item_id: params.xComponentItem.id,
                risk_definition_y_component_item_id: params.yComponentItem.id,
                risk_definition_x_component_item: params.xComponentItem,
                risk_definition_y_component_item: params.yComponentItem,
                score: null,
                risk_definition_criterion_id: null,
                risk_definition_criterion: null,
            };
            state.riskDefinition.risk_definition_matrix_items.push(foundItem);
        }
        state.selectedMatrixItem = foundItem;
    },
    updateSelectedMatrixItemValues(state: IState, params: { score: number; criterion: IRiskDefinitionCriterion }) {
        if (state.selectedMatrixItem) {
            Vue.set(state.selectedMatrixItem, "score", params.score);
            Vue.set(state.selectedMatrixItem, "risk_definition_criterion_id", params.criterion ? params.criterion.id : null);
            Vue.set(state.selectedMatrixItem, "risk_definition_criterion", params.criterion);
        }
    },

    /*************************Criteria Editor***********************/
    setSelectedCriterion(state: IState, item: IRiskDefinitionCriterion) {
        //set the selected item
        state.selectedCriterion = item;
    },
    addNewCriterion(state: IState, criterion: IRiskDefinitionCriterion) {
        state.riskDefinition.risk_definition_criteria.push(criterion);
    },
    updateSelectedCriterion(state: IState, workingItem: IRiskDefinitionCriterion) {
        //copy the working item into the selected item
        Object.assign(state.selectedCriterion!, workingItem);
    },
    deleteSelectedCriterion(state: IState) {
        if (state.selectedCriterion) {
            const index = state.riskDefinition.risk_definition_criteria.indexOf(state.selectedCriterion);
            if (index > -1) {
                state.riskDefinition.risk_definition_criteria.splice(index, 1);
            }
        }
    },
    updateCriteriaMaxScore(state: IState, params: { criterion: IRiskDefinitionCriterion; value: number }) {
        params.criterion.score_max = params.value;
    },

    /*************************Roles***********************/
    setRoles(state: IState, roles: IRole[]) {
        //set the selected item
        state.riskDefinition.risk_definition_roles = roles;
    },

    clearRiskDefinition(state: IState): void {
        state = {
            riskDefinition: {
                id: -new Date().valueOf(),
                name: "",
                description: null,
                license_id: null,
                is_template: false,
                is_default: false,
                incl_categories: false,
                layout_template_id: null,
                x_formula_elements: null,
                y_formula_elements: null,
                formula_operator: null,
                visual_x_component: null,
                visual_y_component: null,
                risk_definition_categories: [],
                risk_definition_components: [],
                risk_definition_matrix_items: [],
                risk_definition_criteria: [],
                risk_definition_roles: [],
            },
            //selectedComponent: null,
            selectedComponentItem: null,
            selectedCriterion: null,
            selectedCategory: null,
            selectedCategoryConsequence: null,
            selectedMatrixItem: null,
            xGraduations: null,
            yGraduations: null,
        }
    }
};

export const riskDefinition = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
