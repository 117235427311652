import { AxiosError, AxiosResponse } from "axios";

const formatError = (error: AxiosError | Error, message: string): string[] | string => {
	let msg: string[] | string = message;
	try {
		//if its an axios error then it will have a response property
		if (typeof error === "object" && error !== null) {
			if (error.hasOwnProperty("response")) {
				//this is an axios error
				const response: AxiosResponse<any> = (error as AxiosError).response as AxiosResponse<any>;
				if (response.status === 422) {
					//validation error - construct an array of error messages.
					msg = [];
					if (response.data && response.data.errors) {
						for (const err of Object.values(response.data.errors) as string[]) {
							for (const desc of err) {
								msg.push(desc);
							}
						}
					}
				} else if (response.status === 403) {
					if (response.data && response.data.message) {
						msg = response.data.message;
					} else {
						//unauthorised
						msg = "This action is unauthorised.";
					}
				}
			} else if (error.hasOwnProperty("message")) {
				//this is a standard javscript Error
				msg = error.message;
			}
		}
	} catch (e: any) { }
	return msg;
};

export { formatError };
