export enum ChartType {
	Line = "line",
	Area = "area",
    Bar = "bar",
    Radar = "radar",
    Histogram = "histogram",
    Scatter = "scatter",
    Pie = "pie",
    Donut = "donut",
    RadialBar = "radialbar",
    Bubble = "bubble",
    HeatMap = "heatmap",
    CandleStick = "candleStick",
    //RangeBarChart,
    Map = "map"
}

export enum ChartFieldType {
    Categorical,
    Numeric,
    DateTime,
    Custom,
    None,
}

export enum SeriesType {
    Categorical_None,
    Categorical_Categorical,
    Categorical_Numeric,
    Numeric_Numeric,
    DiscreteNumeric_Numeric,
    Other,
    None,
}
