import { FolderTypes } from "@/core/enums/LookupEnums";
import { IDirectoryItem } from "@/core/interfaces/DirectoryItemInterface";
import DirectoryService from "@/core/services/DirectoryService";
import store from "./index";

interface IState {
    directories: { [folderType: string]: IDirectoryItem[]; };
}

const state: IState = {
    directories: {},
};

const getters = {
    allDirectories: (state: IState) => {
        return state.directories;
    },
    directory: (state: IState) => (folderType: FolderTypes) => {
        if (state.directories.hasOwnProperty(folderType)) {
            return state.directories[folderType];
        } else {
            return null;
        }
    },
};

const actions = {
    async getDirectory({ commit }: any, folderType: FolderTypes): Promise<IDirectoryItem[]> {
        let result = await DirectoryService.getDirectory(folderType, "childLicenses");
        if (result.length === 0) {
            const licenseId = store.getters["auth/currentLicenseId"];
            if (licenseId) {
                result = [
                    {
                        id: "f" + (-licenseId).toString(),
                        license_id: licenseId,
                        name: "No folders",
                        personal: false,
                        selected: false,
                        open: false,
                        parent: null,
                    },
                ];
            } else {
                store.commit("common/errorMsg", "Invalid license. Please try again.");
            }
        }
        commit("setDirectory", { directory: result, folderType: folderType });
        return result;
    },
};

const mutations = {
    setDirectory(state: IState, payload: { directory: IDirectoryItem[], folderType: string; }) {
        state.directories[payload.folderType] = payload.directory;
    },
    clearDirectory(state: IState, key: string) {
        delete state.directories[key];
    },
};

export const directories = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
