import UserService, { PermissionService } from "./core/services/UserService";
import { Modules } from "./core/enums/LookupEnums";
import Router, { Route } from "vue-router";
import Vue from "vue";
import { globalVars } from "./core/helpers/GlobalVars";
import AppLayout from "@/core/components/layouts/AppLayout.vue"
import AppLayoutBlank from "@/core/components/layouts/AppLayoutBlank.vue"
import AppLayoutEventStaff from "@/core/components/layouts/AppLayoutEventStaff.vue"
import store from "@/store/index";


Vue.use(Router);

const router: Router = new Router({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		//*********DASHBOARD*/
		//{
		//	path: "/dashboard",
		//	//path: "/",
		//	name: "dashboard",
		//	//component: Home
		//	component: () => import(/* webpackChunkName: "dashboard" */ "./modules/Dashboard/views/Dashboard.vue"),
		//	meta: { public: false },
		//},

		//*********USER*/
		{
			path: "/home/:s1?/:s2?/:s3?/:s4?/:s5?/:s6?",
			name: "Home",
			component: () => import(/* webpackChunkName: "home", webpackPreload: true */ "./core/views/Home.vue"),
			meta: { public: false, "layout": AppLayout },
		},
		{
			path: "/login",
			name: "login",
			component: () => import(/* webpackChunkName: "login", webpackPreload: true */ "./core/views/user/Login.vue"),
			meta: { public: true, "layout": AppLayoutBlank },
		},
        {
			path: '/resetPassword/:token/:email',
			name: 'Reset Password',
			component: () => import(/* webpackChunkName: "login" */ './core/views/user/SetPassword.vue'),
			meta: { public: true, "layout": AppLayoutBlank },
            props: { newUser: false }
		},
        {
			path: '/setPassword/:token/:email',
			name: 'Set Password',
			component: () => import(/* webpackChunkName: "login" */ './core/views/user/SetPassword.vue'),
			meta: { public: true, "layout": AppLayoutBlank },
            props: { newUser: true }
		},
		{
			path: "/recoverPassword",
			name: "Recover Password",
			component: () => import(/* webpackChunkName: "login" */ "./core/views/user/RecoverPassword.vue"),
			meta: { public: true, "layout": AppLayoutBlank },

		},
		{
			path: "/manageNotifications",
			name: "Notifications",
			component: () => import(/* webpackChunkName: "user" */ "./core/views/user/ManageNotifications.vue"),
			meta: { public: false, "layout": AppLayout },
		},
		{
			path: "/event-staff-login/failed",
			name: "EventStaffLoginFailed",
			component: () => import(/* webpackChunkName: "event-staff", webpackPrefetch: true */ "./core/views/EventStaffLoginError.vue"),
			meta: { public: false, "layout": AppLayoutBlank },
		},
        {
			path: "/event-staff-login/not-active",
			name: "EventStaffLoginNotActive",
			component: () => import(/* webpackChunkName: "event-staff", webpackPrefetch: true */ "./core/views/EventStaffLoginError.vue"),
			meta: { public: false, "layout": AppLayoutBlank },
		},
        {
			path: "/event-staff-login/not-live",
			name: "EventStaffLoginNotLive",
			component: () => import(/* webpackChunkName: "event-staff", webpackPrefetch: true */ "./core/views/EventStaffLoginError.vue"),
			meta: { public: false, "layout": AppLayoutBlank },
		},
        {
        	path: "/event-staff-login/:token?",
			name: "EventStaffLogin",
			meta: { public: false, "layout": AppLayoutBlank },
		},
        {
			path: "/event-staff/home/:s1?/:s2?/:s3?",
			name: "EventStaffHome",
			component: () => import(/* webpackChunkName: "event-staff", webpackPrefetch: true */ "./modules/Issues/views/EventStaffHome.vue"),
			meta: { public: false, "layout": AppLayoutEventStaff },
		},

		//*********RS101*/
		{
			path: "/rs101/:page",
			name: "Rs101",
			component: () => import(/* webpackChunkName: "rs101", webpackPrefetch: true */ "./core/views/external/Rs101.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.ContractorManagement) || PermissionService.hasModule(Modules.RiskProfiling)) {
					next();
				} else {
					next("/home");
				}
			},
		},

		//*********SYSTEM SETTINGS*/
		{
			path: "/system-admin",
			name: "System Admin",
			component: () => import(/* webpackChunkName: "system" */ "./modules/SystemAdmin/views/SystemAdminHome.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.isSysAdmin) {
					next();
				} else {
					next("/home");
				}
			},
		},
		{
			path: "/system-admin/licenses",
			name: "Licenses",
			component: () => import(/* webpackChunkName: "system" */ "./modules/SystemAdmin/views/SystemAdminLicenses.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.isSysAdmin && PermissionService.hasPermission("SA-LI-MNG")) {
					next();
				} else {
					next("/home");
				}
			},
		},
		{
			path: "/system-admin/venues",
			name: "Venue Types",
			component: () => import(/* webpackChunkName: "system" */ "./modules/SystemAdmin/views/SystemAdminVenueTypes.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.isSysAdmin && PermissionService.hasPermission("SA-VT-MNG")) {
					next();
				} else {
					next("/home");
				}
			},
		},
		{
			path: "/system-admin/risk-search-manager",
			name: "Search Manager",
			component: () => import(/* webpackChunkName: "system" */ "./modules/RiskDataSearch/views/RiskDataSearchManager.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.isSysAdmin && PermissionService.hasPermission("SA-RD-MNG")) {
					next();
				} else {
					next("/home");
				}
			},
		},
		{
			path: "/system-admin/risk-data-review",
			name: "Search Review",
			component: () => import(/* webpackChunkName: "system" */ "./modules/RiskDataSearch/views/ReviewManager.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.isSysAdmin && PermissionService.hasPermission("SA-RD-MNG")) {
					next();
				} else {
					next("/home");
				}
			},
		},

		//*********LICENSE SETTINGS*
        {
			path: "/license-settings/rs101/:page?",
			name: "Rs101 Settings",
			component: () => import(/* webpackChunkName: "rs101", webpackPrefetch: true */ "./core/views/external/Rs101.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.ContractorManagement) || PermissionService.hasModule(Modules.RiskProfiling)) {
					next();
				} else {
					next("/home");
				}
			},
		},
		{
			path: "/license-settings/license/:s1?",
			name: "License Admin",
			component: () => import(/* webpackChunkName: "settings" */ "./core/views/licenseSettings/license/LicenseSettingsAdmin.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.isLicenseAdmin) {
					next();
				} else {
					next(false);
				}
			},
		},
        {
			path: "/license-settings/notifications",
			name: "License Notifications",
			component: () => import(/* webpackChunkName: "settings" */ "./core/views/licenseSettings/license/LicenseSettingsNotifications.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.isLicenseAdmin) {
					next();
				} else {
					next(false);
				}
			},
		},
		{
			path: "/license-settings/permissions/:s1?",
			name: "License Permissions",
			//component: AppSettingsLicensePermissions,
			component: () => import(/* webpackChunkName: "settings" */ "./core/views/licenseSettings/personnel/permissions/LicenseSettingsPermissions.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.LicenseAdmin) && PermissionService.isLicenseAdmin) {
					next();
				} else {
					next(false);
				}
			},
		},
		{
			path: "/license-settings/users",
			name: "Users and Permission Profiles",
			component: () => import(/* webpackChunkName: "settings" */ "./core/views/licenseSettings/personnel/LicenseSettingsUsers.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.LicenseAdmin) && PermissionService.isLicenseAdmin) {
					next();
				} else {
					next(false);
				}
			},
		},
		{
			path: "/license-settings/teams",
			name: "License Teams",
			component: () => import(/* webpackChunkName: "settings" */ "./core/views/licenseSettings/personnel/LicenseSettingsTeams.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.LicenseAdmin) && PermissionService.isLicenseAdmin) {
					next();
				} else {
					next(false);
				}
			},
		},
		{
			path: "/license-settings",
			name: "License Settings Dashboard",
			component: () => import(/* webpackChunkName: "settings" */ "./core/views/licenseSettings/LicenseSettingsHome.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.LicenseAdmin) && PermissionService.isLicenseAdmin) {
					next();
				} else {
					next(false);
				}
			},
		},
		{
			path: "/license-settings/layouts",
			name: "Layouts",
			component: () => import(/* webpackChunkName: "settings" */ "./core/views/licenseSettings/license/LicenseSettingsLayouts.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.LicenseAdmin) && PermissionService.isLicenseAdmin) {
					next();
				} else {
					next(false);
				}
			},
		},
		// Event Types - License Admin
		{
			path: "/license-settings/event-types",
			name: "Event Types",
			component: () => import(/* webpackChunkName: "settings" */ "./core/views/licenseSettings/license/LicenseSettingsEventTypes.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if ((PermissionService.hasModule(Modules.RiskAssessment) || PermissionService.hasModule(Modules.Issues)) && PermissionService.isLicenseAdmin) {
					next();
				} else {
					next(false);
				}
			},
		},
		// Task Priorities - License Admin
		{
			path: "/license-settings/tasks/:s1?",
			name: "Task Priorities",
			component: () => import(/* webpackChunkName: "settings" */ "./core/views/licenseSettings/modules/tasks/LicenseSettingsTasks.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.TaskManagement) && PermissionService.isLicenseAdmin) {
					next();
				} else {
					next(false);
				}
			},
		},
        {
			path: "/license-settings/location-settings/:s1?",
			name: "Locations",
			//component: AppSettingsLicenseLocations,
			component: () => import(/* webpackChunkName: "settings" */ "./core/views/licenseSettings/license/locations/LicenseSettingsLocations.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.LicenseAdmin) && PermissionService.isLicenseAdmin) {
					next();
				} else {
					next(false);
				}
			},
		},
		{
			path: "/license-settings/roles",
			name: "Roles",
			component: () => import(/* webpackChunkName: "settings" */ "./core/views/licenseSettings/personnel/LicenseSettingsRoles.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.LicenseAdmin) && PermissionService.isLicenseAdmin) {
					next();
				} else {
					next(false);
				}
			},
		},
		{
			path: "/license-settings/external-parties",
			name: "External Parties",
			component: () => import(/* webpackChunkName: "settings" */ "./core/views/licenseSettings/personnel/LicenseSettingsExternalParties.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.LicenseAdmin) && PermissionService.isLicenseAdmin) {
					next();
				} else {
					next(false);
				}
			},
		},
		{
			path: "/license-settings/external-party-groups",
			name: "External Party Groups",
			component: () => import(/* webpackChunkName: "settings" */ "./core/views/licenseSettings/personnel/LicenseSettingsExternalPartyGroups.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.LicenseAdmin) && PermissionService.isLicenseAdmin) {
					next();
				} else {
					next(false);
				}
			},
		},
		{
            path: "/license-settings/risks/:s1?/:s2?",
			name: "Risk Settings",
			component: () => import(/* webpackChunkName: "settings" */ "./core/views/licenseSettings/modules/risks/LicenseSettingsRisks.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.LicenseAdmin) && PermissionService.hasModule(Modules.RiskAssessment) && PermissionService.isLicenseAdmin) {
					next();
				} else {
					next(false);
				}
			},
		},
		{
            path: "/license-settings/issues/:s1?/:s2?",
			name: "Issue Settings",
			component: () => import(/* webpackChunkName: "settings" */ "./core/views/licenseSettings/modules/issues/LicenseSettingsIssues.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.LicenseAdmin) && PermissionService.hasModule(Modules.Issues) && PermissionService.isLicenseAdmin) {
					next();
				} else {
					next(false);
				}
			},
		},
		{
			path: "/license-settings/incidents/:s1?/:s2?",
			name: "Incident Reporting",
			component: () => import(/* webpackChunkName: "settings" */ "./core/views/licenseSettings/modules/incidents/LicenseSettingsIncidents.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.LicenseAdmin) && PermissionService.hasModule(Modules.Incidents) && PermissionService.isLicenseAdmin) {
					next();
				} else {
					next(false);
				}
			},
		},


		//*********TASKS*/
		{
			path: "/task-manager/:s1?/:s2?/:s3?",
			name: "Task Manager",
			component: () => import(/* webpackChunkName: "tasks", webpackPrefetch: true */ "./modules/Tasks/views/HomeTasks.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.TaskManagement)) {
					next();
				} else {
					next("/home");
				}
			},
		},
		//*********RISK ASSESSMENTS*/
		{
			path: "/risk-assessment/:s1?/:s2?/:s3?/:s4?/:s5?",
			name: "Risk Assessment",
			component: () => import(/* webpackChunkName: "risks", webpackPrefetch: true */ "./modules/RiskAssessments/views/HomeRisks.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.RiskAssessment)) {
					next();
				} else {
					next("/home");
				}
			},
		},
		//*********ISSUES*/
		{
			path: "/issue-manager/:s1?/:s2?/:s3?/:s4?/:s5?/:s6?",
			name: "Issue Manager",
			component: () => import(/* webpackChunkName: "issues", webpackPrefetch: true */ "./modules/Issues/views/HomeIssues.vue"),
			meta: { public: false, "layout": AppLayout },
			beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.Issues)) {
					next();
				} else {
					next("/home");
				}
			},
		},
		//*********INCIDENTS*/
        {
			path: "/incident-reporting/:s1?/:s2?/:s3?/:s4?/:s5?/:s6?",
			name: "incident-reporting",
			component: () => import(/* webpackChunkName: "incidents", webpackPrefetch: true */ "./modules/Incidents/views/HomeIncidents.vue"),
			meta: { public: false, "layout": AppLayout },
            beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				if (PermissionService.hasModule(Modules.Incidents)) {
					next();
				} else {
					next("/home");
				}
			},
		},

		/**  keep for later
		{
			path: "/testArea",
			name: "Test Area",
			component: TreeTable,
			meta: { public: false }
		},
		{
			path: "/riskDefinitions/:raid",
			props: true, //pass the parameter to the component as an input prop
			name: "Risk Definitions",
			component: RiskDefinitions,
			meta: { public: false }
		},
		{
			path: "/testArea",
			name: "Test Area",
			component: ReportBuilderTest,
			meta: { public: false }
		},
		**/
		/* {
			path: "/testArea",
			name: "Test Area",
			component: RiskAssessment,
			meta: { public: false }
		}, */

        {
            path: "/download/attachments/:generated_id",
            name: "Download",
            component: () => import("./core/views/Download.vue"),
            meta: { public: false, "layout": AppLayoutBlank },
            beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
                if (PermissionService.hasModule(Modules.Incidents)) {
                    next();
                } else {
                    next("/home");
                }
            },
        },
		//catch all redirect
		{
			path: "*",
			name: "unknown",
			component: () => import(/* webpackChunkName: "home", webpackPreload: true */ "./core/views/Home.vue"),
			meta: { public: false, "layout": AppLayout },
            beforeEnter: (to: Route, from: Route, next: (arg?: any) => void) => {
				next("/home");
			},
		},
	],
});

/**
 * Router guard.
 */
router.beforeEach(async (to: Route, from: Route, next: (arg?: any) => void) => {
	globalVars.pendingRoute = to;
	const isPublic = to.matched.some((record) => record.meta.public);
	//If public then anyone can see it
	if (isPublic) {
		next();
	} else {
		//check if the user is logged in - this will redirect to the login screen
		//via the axios interceptor if necessary
		await UserService.loggedIn(to);

        let userData = store.getters["auth/currentUserData"];
        if (userData && userData.extra && userData.extra.is_staff) {
            // event staff redirects
            if (to.name && to.name.slice(0, 10) != "EventStaff") {
                next("/event-staff/home");
            } else if (userData.event_staff_extra && !userData.event_staff_extra.active && to.name != "EventStaffLoginNotActive") {
                next("/event-staff-login/not-active");
            } else if (userData.event_staff_extra && !userData.event_staff_extra.live && to.name != "EventStaffLoginNotLive") {
                next("/event-staff-login/not-live");
            } else {
                next();
            }
        } else {
            next();
       }
	}
});

export default router;
