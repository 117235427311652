import Vue from "vue";
Vue.directive("click-outside", {
	bind: function (el: any, binding: any, vnode: any) {
		el.clickOutsideEvent = (event: Event) => {
			// here I check that click was outside the el and his childrens
			if (!(el == event.target || el.contains(event.target))) {
				// and if it did, call method provided in attribute value
				if (vnode && vnode.context && binding.expression) {
					vnode.context[binding.expression](event);
				}
			}
		};
		document.body.addEventListener("click", el.clickOutsideEvent);
	},

	unbind: function (el: any) {
		document.body.removeEventListener("click", el.clickOutsideEvent);
	},
});
