import { ChartType, SeriesType, ChartFieldType } from "../enums/ChartEnum";
import { IDdValue, IFieldDefinition } from "../interfaces/CustomFieldInterface";
import { IChart } from "../interfaces/ChartInterface";
import ChartHelper from "../helpers/ChartHelper";
import ChartConstants from "../helpers/ChartConstants";

export default abstract class ChartService {
    static getRecordsForDataPointSelection = (chart: IChart, dataPointIndex: number | null, seriesIndex: number | null) => {
        if (ChartHelper.getSeriesTypeForChartType(chart.type) == SeriesType.DiscreteNumeric_Numeric) {
            if (seriesIndex != null) {
                if (dataPointIndex == null) {
                    return (chart.data!.series![seriesIndex] as any).records!
                } else {
                    return chart.data!.records![dataPointIndex];
                }
            }
        } else {
            if (seriesIndex != null) {
                if (dataPointIndex == null) {
                    let result: any[] = [];
                    chart.data!.records![seriesIndex].forEach((dataPointArray: any[]) => {
                        dataPointArray.forEach((dp: unknown) => result.push(dp))
                    });
                    return result;
                } else {
                    if (chart.data!.records!.length <= seriesIndex) seriesIndex = 0;
                    return chart.data!.records![seriesIndex][dataPointIndex];
                }
            }
        }
        return [];
    }

    static getAxisOptions = (chartType: ChartType, fields: IFieldDefinition[], for_axis: "primary" | "secondary" | "grouping", with_custom: boolean = true) => {
        let options: IDdValue[] = [];
        const targetFieldType = ChartHelper.getChartFieldTypeForChartType(chartType, for_axis);
        let display_sequence_counter = 0;

        if(for_axis == "secondary" &&
            (ChartHelper.getSeriesTypeForChartType(chartType) == SeriesType.Categorical_Numeric
                || ChartHelper.getSeriesTypeForChartType(chartType) == SeriesType.DiscreteNumeric_Numeric
                || ChartHelper.getSeriesTypeForChartType(chartType) == SeriesType.Numeric_Numeric)) {
            options.push({
                value: ChartConstants.record_count_field.id,
                text: ChartConstants.record_count_field.label,
                display_sequence: display_sequence_counter++,
                data: { fieldType: ChartFieldType.Numeric, field_name: ChartConstants.record_count_field.name }
            });
        }
        if(for_axis == "grouping" &&
            (ChartHelper.getSeriesTypeForChartType(chartType) == SeriesType.Numeric_Numeric
                || ChartHelper.getSeriesTypeForChartType(chartType) == SeriesType.DiscreteNumeric_Numeric
                || chartType == ChartType.Map
                || chartType == ChartType.Bar)) {
            options.push({
                value: ChartConstants.none_grouping_field.id,
                text: ChartConstants.none_grouping_field.label,
                display_sequence: display_sequence_counter++,
                data: { fieldType: ChartFieldType.Categorical, field_name: ChartConstants.none_grouping_field.name }
            });
        }

        fields.forEach((field: IFieldDefinition) => {
            if (ChartHelper.fieldIsType(field, targetFieldType)) {
                options.push({
                    value: field.id,
                    text: field.label,
                    display_sequence: display_sequence_counter++,
                    data: { fieldType: targetFieldType, field_name: field.name }
                });
            }
            else if (with_custom && ChartHelper.fieldIsType(field, ChartFieldType.Custom)) {
                options.push({
                    value: field.id,
                    text: field.label,
                    display_sequence: display_sequence_counter++,
                    data: { fieldType: ChartFieldType.Custom, field_name: field.name }
                });
            }
        });

        // sort alphabetically
        options.sort((optA, optB) => {
            return optA.text.toLowerCase().localeCompare(optB.text.toLowerCase());
        });

        return options;
    }

    static getBucketSizeOptions = (fieldToBucket: IFieldDefinition) => {
        let options: IDdValue[] = [];
        let display_sequence_counter = 0;

        const is_date = (fieldToBucket.lku_field_type_id == "date" || fieldToBucket.lku_field_type_id == "datetime");
        let values = is_date ? [/*'minute',*/ 'hour', 'day', 'week', 'month', 'year'] : ['1', '5', '10', '15', '20', '25', '50', '100'];

        values.forEach((value) => options.push({
            value: value,
            text: value.charAt(0).toUpperCase() + value.substr(1), //to sentence case
            display_sequence: display_sequence_counter++,
        }));

        return options;
    }

    static getEmptyChart = (display_sequence: number) => {
        let chart: IChart = {
            display_sequence: display_sequence,
            type: null,
            heading: '',
            description: '',
            primary_axis_field_id: null,
            secondary_axis_field_id: null,
            grouping_field_id: null,
            grouping_layout: null,
            bucket_size: null,
            palette: "palette1",
            horizontal: false,
            width: 2,
            height: 2,
            lku_master_table_id: null,
        }
        return chart;
    }

    static formatCharts = (charts: IChart[], fields: {[key: string]: IFieldDefinition[]}) => {
        charts.map((chart) => ChartService.formatChart(chart, fields));
    }

    static formatChart = (chart: IChart, fields: {[key: string]: IFieldDefinition[]}) => {
        // set the index
        // if (chart.data && chart.data.options && chart.data.options.chart) {
        //     chart.data.options.chart.id = chart.display_sequence.toString();
        // }

        // set the field definitions -- done in front end to reduce DB fetches
        const chartTable = chart.lku_master_table_id;
        if (chartTable != null && Array.isArray(fields[chartTable])) {
            if (chart.primary_axis_field_id) {
                chart.primary_axis_field = fields[chartTable].find((item: IFieldDefinition)=>item.id == chart.primary_axis_field_id);
            }

            if (chart.secondary_axis_field_id && chart.secondary_axis_field_id > 0) {
                chart.secondary_axis_field = fields[chartTable].find((item: IFieldDefinition)=>item.id == chart.secondary_axis_field_id);
            }
            else {
                chart.secondary_axis_field_id = -1;
                chart.secondary_axis_field = ChartConstants.record_count_field;
            }

            if (chart.grouping_field_id && chart.grouping_field_id > 0) {
                chart.grouping_field = fields[chartTable].find((item: IFieldDefinition)=>item.id == chart.grouping_field_id);
            }
            else {
                chart.grouping_field_id = -1;
                chart.grouping_field = ChartConstants.none_grouping_field;
            }
        }
        return chart;
    }
}
