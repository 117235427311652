import { TableNames } from "../enums/LookupEnums";
import { IFieldDefinition } from "./CustomFieldInterface";
import { IFile } from "./FileInterface";
import { IReportFile } from "./ReportFileInterface";
import { IIncident } from "../../modules/Incidents/interfaces/IncidentInterface"
import { IIncidentSelection } from "@/modules/Incidents/interfaces/IncidentFilterInterface";
import { IChart } from "./ChartInterface";
import { IEvent } from "@/modules/Issues/interfaces/EventInterface";
import { IIssue } from "@/modules/Issues/interfaces/IssueInterface";
import { IRiskAssessment } from "@/modules/RiskAssessments/interfaces/RiskAssessmentInterface";
import { ISelectListItem } from "@/core/interfaces/SelectListItemInterface";
import { ICustomFieldDef } from "@/core/interfaces/CustomFieldInterface";
import { IRisk } from "@/modules/RiskAssessments/interfaces/RiskInterface";
import { IControl } from "@/modules/RiskAssessments/interfaces/ControlInterface";
import { IRiskDefinition } from "@/modules/RiskAssessments/interfaces/RiskDefinitionsInterface";
import { IIssueSelection } from "@/modules/Issues/interfaces/IssueSelectorInterface";


export enum ReportType {
    Any = -1,
    Unknown = 0,
    RiskAssessment = 1,
    IncidentReport = 2,
    EventReport = 3,
    MultipleRiskAssessment = 4,
    MultipleEvent = 5,
}

export enum ReportModuleSectionType {
    All = 0,
    Team = 1,
    "Issue Type" = 2,
}

export enum ModuleCode {
    CoverPage = "cover_page",
    Toc = "toc",
    AddPicture = "add_picture",
    PageBreakL = "page_break_l",
    PageBreakP = "page_break_p",
    Comments = "comments",
    Section = "section",
    RAImages = "ra_images",
    RAContext = "ra_context",
    RPProfile = "rp_profile",
    RARegister = "ra_register",
    RAAnalysis = "ra_analysis",
    RATeamMembers = "ra_team_members",
    RADefinitions = "ra_definitions",
    EVIssues = "ev_issue_list",
    EVTeamMembers = "ev_team_members",
    EVAnalysis = "ev_analysis",
    EVImages = "ev_images",
    EVINDetails = "ev_in_details",
    EVINRegister = "ev_in_register",
    EVINAnalysis = "ev_in_analysis",

    // -- Incident Reporting --
    IRSection = "ir_section",
    IRDetails = "ir_details",
    IRRegister = "ir_register",
    IRAnalysis = "ir_analysis"

    /**
     * IRStats
     * IRMatrix -- severity matrix
     *
     * -- Corrective Action Reporting --
     * CAList
     * CACharts
     */
}

export enum PageLayout {
    Portrait1 = 1,
    Landscape1 = 2,
    Portrait2 = 3,
    Landscape2 = 4,
    Portrait3 = 5,
    Landscape3 = 6,
    Portrait3a = 7,
    Landscape3a = 8,
    Portrait3b = 9,
    Landscape3b = 10,
    Portrait4 = 11,
    Landscape4 = 12,
}

export interface ILkuReportModuleSection {
    id: number;
    lku_report_type_id: ReportType;
    name: string;
    lku_report_modules: ILkuReportModule[];
}
export interface ILkuReportModule {
    id: number;
    name: string;
    code: ModuleCode;
    active: boolean;
    display_sequence: number;
    description: string | null;
    data?: any; // use for report modules of templates
}

export interface IReport {
    id: number;
    lku_report_type_id: ReportType;
    master_id: number | null;
    master_selection: IReportMasterSelection | null;
    folder_id: number | null;
    brand_image_file_id: number | null;
    brand_image_file?: IFile;
    name: string;
    is_template: boolean;
    save_as_template_name?: string | null;
    report_modules: IReportModule[];
    report_files: IReportFile[] | null;
    auto_generate: boolean;
    generate_interval: number;
    generate_interval_unit: "day" | "week" | "month" | "year" | null;
    notifiables: { type_id: string, name: string; }[] | null;
}

export interface IReportMasterSelection {
    filter_state: any;
    selected_ids: number[];
}

export interface IAdhocReport {
    name: string;
    selection: any,
    modules: { module_code: ModuleCode, data: any }[];
}

export interface IReportModule {
    id: number;
    lku_report_module_id: number;
    lku_report_module_code: ModuleCode;
    lku_report_module?: ILkuReportModule | null;
    report_id: number;
    report?: IReport | null;
    display_sequence: number;
    report_module_risk_option?: IReportModuleRiskOption;
    report_module_issue_option?: IReportModuleIssueOption;
    report_module_cover?: IReportModuleCover;
    report_module_heading?: IReportModuleHeading;
    report_module_description?: IReportModuleDescription;
    report_module_image?: IReportModuleImage;
    report_module_profile?: IReportModuleProfile;
    field_definition_report_modules?: { [key: string]: IFieldDefinitionReportModule[] };
    report_module_section?: IReportModuleSection;
    data?: unknown;
}

export interface IReportModuleRiskOption {
    id: number;
    report_module_id: number;
    report_module?: IReportModule;
    user_id: number | null;
    external_party_id: number | null;
    sort_by: number | null;
    sort_direction: "asc" | "desc";
    sort_by_1: number | null;
    sort_direction_1: "asc" | "desc";
    show_criteria: string[];
}

export interface IReportModuleIssueOption {
    id: number;
    report_module_id: number;
    report_module?: IReportModule;
    sort_by: number | null;
    sort_direction: "asc" | "desc";
    sort_by_1: number | null;
    sort_direction_1: "asc" | "desc";
    show_criteria: string[];
}

export interface IReportModuleImage {
    id: number;
    report_module_id: number;
    report_module?: IReportModule;
    file_id: number;
    file?: IFile;
}

export interface IReportModuleCover {
    id: number;
    report_module_id: number;
    report_module?: IReportModule;
    content: string;
    background_img_file_id: number | null;
}

export interface IReportModuleHeading {
    id: number;
    report_module_id: number;
    report_module?: IReportModule;
    heading: string;
}

export interface IReportModuleDescription {
    id: number;
    report_module_id: number;
    report_module?: IReportModule;
    description: string;
}

export interface IReportModuleProfile {
    id: number;
    report_module_id: number;
    report_module?: IReportModule;
    profile_id: number;
}

export interface IFieldDefinitionReportModule {
    field_definition_id: number;
    field_definition?: IFieldDefinition;
    lku_master_table_id?: TableNames;
    field_definition_name?: string;
    report_module_id: number;
    report_module?: IReportModule;
    display_sequence: number;
    label: string | undefined;
    selected: boolean;
    parent_field_definition_id: number | null;
}

export enum Category {
    IssueType = "issue_type",
    IssuePriority = "issue_priority",
    IssueLocation = "issue_location",
    Team = "team",
    Timeline = "timeline",
}

export enum Series {
    IssueCount = "issue_count",
    TaskCount = "task_count",
}

export type ChartType =
    | "line"
    | "area"
    | "bar"
    | "column"
    | "histogram"
    | "pie"
    | "donut"
    | "radialBar"
    | "rangeBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "candlestick"
    | "radar"
    | "polarArea";

export interface IReportModuleSection {
    id: number;
    report_module_id: number;
    report_module?: IReportModule;
    heading: string;
    introduction: string;
	selection: IIssueSelection;
    issues: IIssue[];
}

export interface IReportModuleEventSectionSubData {
    event: IEvent;
    incidents: IIncident[];
    issues: IIssue[];
    issueCriteriaOptions: ISelectListItem[];
}

export interface IReportModuleRiskAssessmentSectionSubData {
    risk_assessment: IRiskAssessment,
    risk_definition: IRiskDefinition,
    risks: IRisk[],
    controls: IControl[],
}

// custom analysis module data, common across RA, EV, IR, etc
export interface IReportModuleAnalysisData {
    charts: IChart[];
    lku_page_layout_id: PageLayout;
}

// common across all incident modules
interface IReportModuleIncidentData {
    incidents: IIncident[] | null;
	selection: null | IIncidentSelection;
    fields: { [key: string]: ICustomFieldDef };
}

export interface IReportModuleIncidentSectionData extends IReportModuleIncidentData {
    heading: string;
    introduction: string;
    orientation: string;
}

export interface IReportModuleIncidentDetailsData extends IReportModuleIncidentData {
    useOwnIncidents: boolean;
    pageBreakAfterEach: boolean;
    attachOnSepPage: boolean;
    fontSize: number;
}

export interface IReportModuleIncidentAnalysisData extends IReportModuleIncidentData, IReportModuleAnalysisData {
    useOwnIncidents: boolean;
}

