import { ILevel, ILocation, IVenue } from "@/core/interfaces/LocationInterface";
import LicenseService from "@/core/services/LicenseService";
import LocationService from "@/core/services/LocationService";
import { isFunction, isObject } from "lodash";
import Vue from "vue";
import { findLocationByCode, findLocationById } from "@/core/helpers/LocationHelper";
import { ISelectListItem } from "@/core/interfaces/SelectListItemInterface";
import { SelectListService } from "@/core/services/SelectListService";

interface IState {
	locations: ILocation[];
	venues: IVenue[];
	/* eventLocations: { [key: string]: ILocation[] };
	eventVenues: { [key: string]: IVenue[] }; */
	locationCategories: ISelectListItem[];
}

const state: IState = {
	locations: [],
	venues: [],
	/* eventLocations: {},
	eventVenues: {}, */
	locationCategories: [],
};

const getters = {
	locations: (state: IState): ILocation[] => {
		return state.locations;
	},
	venues: (state: IState): IVenue[] => {
		return state.venues;
	},
	/* eventLocations: (state: IState) => (eventId: number): ILocation[] => {
		if (state.eventLocations.hasOwnProperty(eventId.toString())) {
			return state.eventLocations[eventId.toString()];
		} else {
			return [];
		}
	},
	eventVenues: (state: IState) => (venueId: number): IVenue[] => {
		if (state.eventVenues.hasOwnProperty(venueId.toString())) {
			return state.eventVenues[venueId.toString()];
		} else {
			return [];
		}
	}, */
	locationCategories: (state: IState): ISelectListItem[] => {
		return state.locationCategories;
	},
};

const actions = {
	async getLocations({ commit, state }: any, force: boolean): Promise<void> {
		try {
			if (force || state.locations.length === 0) {
				const locations: ILocation[] = await LicenseService.getLocations();
				commit("gotLocations", locations);
			}

		} catch (e: any) {
			commit("common/errorMsg", "Getting Locations failed, please try again.", { root: true });
			throw e;
		}
	},
	async getEventLocations({ commit, state }: any, params: { eventId: number; force: boolean }): Promise<void> {
		try {
			if (params.force || state.locations.length === 0) {
				const locations: ILocation[] = await LocationService.getLocationsForEvent(params.eventId);
				//commit("gotEventLocations", { eventId: params.eventId, locations: locations });
				commit("gotLocations", locations);
			}

		} catch (e: any) {
			commit("common/errorMsg", "Getting Event Locations failed, please try again.", { root: true });
			throw e;
		}
	},
	async getLocationCategories({ commit, state }: any, force: boolean): Promise<void> {
		try {
			if (force || state.locationCategories.length === 0) {
				const locationCategories: ISelectListItem[] = await SelectListService.getLocationCategories();
				commit("gotLocationCategories", locationCategories);
			}

		} catch (e: any) {
			commit("common/errorMsg", "Getting Location Categories failed, please try again.", { root: true });
			throw e;
		}
	},
	/**
	 * Sets the selectedLocation for the locationId in the Venue and returns the IVenue and ILocation for the location.
	 */
	setVenueLocation({ commit, state }: any, location: ILocation | number | string): { venue: IVenue | null, location: ILocation | undefined } {
		let venue: IVenue | null = null;
		let foundLoc: ILocation | undefined = undefined;
		if (isObject(location)) {
			foundLoc = location;
		} else if ((typeof location === 'number')) {
			foundLoc = state.locations.find((loc: ILocation) => loc.id === location);
		} else if (typeof location === 'string') {
			foundLoc = state.locations.find((loc: ILocation) => loc.code === location);
		}
		if (foundLoc) {
			if (foundLoc.lku_location_type_id === "VEN") {
				venue = foundLoc;
			} else {
				const foundParent1: ILocation | undefined = state.locations.find((loc: ILocation) => loc.id === foundLoc!.parent_id);
				if (foundParent1) {
					if (foundParent1.lku_location_type_id === "VEN") {
						venue = foundParent1;
					} else {
						const foundParent2: ILocation | undefined = state.locations.find((loc: ILocation) => loc.id === foundParent1.parent_id);
						if (foundParent2 && foundParent2.lku_location_type_id === "VEN") {
							venue = foundParent2;
						}
					}
				}
			}
			if (venue && foundLoc) {
				commit("setVenueLocation", { venue: venue, location: foundLoc });
			}
		}
		return { venue: venue, location: foundLoc };
	},
};

const mutations = {
	gotLocations(state: IState, locations: ILocation[]) {
		state.locations = locations;
		state.venues = state.locations.filter((loc: ILocation) => loc.lku_location_type_id === "VEN" && loc.file_id);
		//build venue levels and spaces.
		for (const venue of state.venues) {
			for (const lvl of state.locations.filter((lvl) => lvl.parent_id === venue.id)) {
				if (lvl.lku_location_type_id === "LVL") {
					let level: ILevel = lvl;
					for (const spc of state.locations.filter((spc) => spc.parent_id === level.id)) {
						if (spc.lku_location_type_id === "SPC") {
							if (!level.spaces) Vue.set(level, "spaces", []);
							level.spaces!.push(spc);
						}
					}
					if (!venue.levels) Vue.set(venue, "levels", []);
					venue.levels!.push(level);
				}
			}
		}
	},
	gotLocationCategories(state: IState, locationCategories: ISelectListItem[]) {
		state.locationCategories = locationCategories;
	},
	setVenueSvg(state: IState, values: { venueId: number, svg: SVGSVGElement }) {
		const foundVenue: IVenue | undefined = state.venues.find((ven: IVenue) => ven.id === values.venueId);
		if (foundVenue) {
			Vue.set(foundVenue, "svg", values.svg);
		}
	},
	resetVenuesLocations(state: IState) {
		state.venues = [];
		state.locations = [];
	},
	clearVenueLocations(state: IState) {
		for (const venue of state.venues) {
			Vue.set(venue, "selectedLocation", null);
		}
	},
	setVenueLocation(state: IState, values: { venue: IVenue | number | string, location: ILocation | number | string }) {
		let foundVenue: IVenue | undefined = undefined;
		let foundLocation: IVenue | undefined = undefined;
		if (isObject(values.venue)) {
			foundVenue = values.venue;
		} else {
			foundVenue = state.venues.find((ven: ILocation) => ven.id === values.venue);
		}
		if (foundVenue) {
			if (isObject(values.location)) {
				foundLocation = values.location;
			} else if ((typeof values.location === 'number')) {
				foundLocation = findLocationById(foundVenue, values.location);
			} else if (typeof values.location === 'string') {
				foundLocation = findLocationByCode(foundVenue, values.location);
			}
			if (foundLocation) {
				Vue.set(foundVenue, "selectedLocation", foundLocation);
			}
		}
	},
	updateLocation(state: IState, location: ILocation) {
		const foundLocation: ILocation | undefined = state.locations.find((loc: ILocation) => loc.id === location.id);
		if (foundLocation) {
			//update the item in the array			
			Object.assign(foundLocation, location);
		} else {
			//add to the array
			state.locations.push(location);
			//if its parent exists in any event locations, we should add it to the locations there and if applicable to the venue too
			if (location.parent_id) {
				//TODO if the parent has changed or if it is a new object we need to update the venue structure.
				/* for (const event in state.eventLocations) {
					const eventLocations: ILocation[] = state.eventLocations[event];
					const parent: ILocation | undefined = eventLocations.find((loc: ILocation) => loc.id === location.parent_id)
					if (parent) {
						eventLocations.push(location);
						if (parent.lku_location_type_id === "VEN" && location.lku_location_type_id === "LVL") {
							if (!(parent as IVenue).levels) Vue.set(parent, "levels", []);
							(parent as IVenue).levels?.push(location);
						} else if (parent.lku_location_type_id === "LVL" && location.lku_location_type_id === "SPC") {
							if (!(parent as ILevel).spaces) Vue.set(parent, "spaces", []);
							(parent as ILevel).spaces?.push(location);
						}
					}
				} */
			}
		}
	},
	updateLocationGeocode(state: IState, values: { location: ILocation | number, geocode: google.maps.LatLng | google.maps.LatLngLiteral | null }) {
		let foundLocation: IVenue | undefined = undefined;
		if (isObject(values.location)) {
			foundLocation = values.location;
		} else {
			foundLocation = state.locations.find((loc: ILocation) => loc.id === values.location);
		}
		if (foundLocation) {
			if (values.geocode) {
				foundLocation.lat = isFunction(values.geocode.lat) ? values.geocode.lat() : values.geocode.lat;
				foundLocation.long = isFunction(values.geocode.lng) ? values.geocode.lng() : values.geocode.lng;
			} else {
				foundLocation.lat = null;
				foundLocation.long = null;
			}
		}
	},
	updateLocationFile(state: IState, values: { location: ILocation | number, fileId: number | null }) {
		let foundLocation: IVenue | undefined = undefined;
		if (isObject(values.location)) {
			foundLocation = values.location;
		} else {
			foundLocation = state.locations.find((loc: ILocation) => loc.id === values.location);
		}
		if (foundLocation) {
			if (values.fileId) {
				foundLocation.file_id = values.fileId;
			} else {
				foundLocation.file_id = null;
			}
		}
	},

};

export const location = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};