export enum FieldCategories {
	Custom = "custom",
	Mandatory = "sys_mand",
	Optional = "sys_opt",
}

export enum FieldTypes {
	Boolean = "boolean",
	Decimal = "decimal",
	Integer = "integer",
	String = "string",
    OptionedString = "o_string",
    Email = "email",
	Text = "text",
	Date = "date",
	DateTime = "datetime",
	DropdownFd = "dd_fd",
	DropdownAddFd = "dda_fd",
	DropdownSys = "dd_sys",
	DropdownMultiFd = "ddm_fd",
	DropdownMultiAddFd = "ddma_fd",
	DropdownMultiSys = "ddm_sys",
	Currency = "currency",
	HierarchicalDdFd = "hdd_fd",
	HierarchicalDdAddFd = "hdda_fd",
	HierarchicalDdSys = "hdd_sys",
	HierarchicalDdMultiFd = "hddm_fd",
	HierarchicalDdMultiAddFd = "hddma_fd",
	HierarchicalDdMultiSys = "hddm_sys",
	Custom = "custom",
	Table = "table",
}

export enum LayoutItemTypes {
	Field = "field",
	Heading = "heading",
	Line = "line",
}

export enum FolderTypes {
	RaRa = "ra-ra",
	IsIs = "is-is",
	IsEv = "is-ev",
	InIn = "in-in",
}

export enum Modules {
	Incidents = "IN",
	Issues = "IS",
	RiskAssessment = "RA",
	RiskProfiling = "RP",
	ContractorManagement = "CM",
	TaskManagement = "TK",
	LicenseAdmin = "LA",
	SystemAdmin = "SA",
	Dashboard = "DA",
	Mapping = "MP",
	EventStaff = "ES",
}

export enum ModulesNames {
	IN = "Incidents",
	IS = "Issues",
	RA = "Risk Assessments",
	RP = "Risk Profiling",
	CM = "Contractor Management",
	TK = "Task Management",
	LA = "License Admin",
	SA = "System Admin",
	DA = "Dashboard",
	MP = "Mapping",
	ES = "Event Staff",
}

export enum TableNames {
	RiskAssessments = "risk_assessments",
	Incidents = "incidents",
	CorrectiveActions = "corrective_actions",
	People = "people",
	IncidentInvestigations = "incident_investigations",
	Licenses = "licenses",
	Issues = "issues",
	Risks = "risks",
	Controls = "controls",
	Tasks = "tasks",
	Events = "events",
}

export enum ModelTypes {
	RiskAssessment = "App\\Model\\RiskAssessment",
	Incident = "App\\Model\\Incident",
	CorrectiveAction = "App\\Model\\CorrectiveAction",
	Investigation = "App\\Model\\IncidentInvestigation",
	Issue = "App\\Model\\Issue",
	Risk = "App\\Model\\Risk",
	Control = "App\\Model\\Control",
	Task = "App\\Model\\Task",
	Event = "App\\Model\\Event",
}

export enum DateFormats {
	us = "MMM D YYYY",
	default = "D MMM YYYY",
}
export enum TimeFormats {
	ampm = "h:mm a",
	default = "HH:mm",
}

export enum FileTypes {
	Image = 0,
	PDF = 1,
	WordDoc = 2,
	Excel = 3,
	CSV = 4,
	Video = 5,
	Audio = 6,
	Archive = 7,
	Other = 8,
	None = 9,
}

export enum AccessPermissionType {
	None = 0,
	Read = 1,
	ReadWrite = 2,
	ReadWriteDelete = 3,
}

export enum Rs101Pages {
	cm_matrix = "reviews_/index",
	cm_settings = "contractorSetup_/index",
	cm_library = "folders_/index",
	rp_profile = "crowdprofile",
	rp_settings = "crowdprofile/settings"
}
