<template>
	<div id="app-layout-blank"><slot /></div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({ name: "app-layout-blank" })
export default class AppLayoutBlank extends Vue {}
</script>
