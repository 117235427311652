import { ISelectListItem } from "@/core/interfaces/SelectListItemInterface";
import ITeam from "@/core/interfaces/TeamInterface";
import { SelectListService } from "@/core/services/SelectListService";
import { TeamService } from "@/core/services/TeamService";
import { IPerson, } from "@/modules/Incidents/interfaces/PersonInterface";
import PersonService from "@/modules/Incidents/services/PersonService";

interface IState {
    people: IPerson[];
    users: ISelectListItem[];
    teams: ITeam[];
}

const state: IState = {
    people: [],
    users: [],
    teams: [],
};

const getters = {
    people: (state: IState) => {
		return state.people;
	},
	users: (state: IState) => {
		return state.users;
	},
    teams: (state: IState) => {
		return state.teams;
	},
};

const actions = {
    async getPeople({ commit }: any): Promise<IPerson[]> {
        let people = await PersonService.getPeople();
        commit("gotPeople", people);
        return people;
    },
    async getUsers({ commit, state }: any): Promise<ISelectListItem[]> {
        let users = await SelectListService.getUsers(5);
        commit("gotUsers", users);
        return users;
    },
    async getTeams({ commit, state }: any): Promise<ITeam[]> {
        let teams = await TeamService.getTeams();
        commit("gotTeams", teams);
        return teams;
    },
    async refresh({ commit, dispatch }: any): Promise<void> {
        Promise.all([
            dispatch("gotPeople"),
            dispatch("gotUsers"),
            dispatch("gotTeams"),
        ]);
    },
};

const mutations = {
    gotPeople(state: IState, people: IPerson[]) {
		state.people = people;
	},
    gotUsers(state: IState, users: ISelectListItem[]) {
		state.users = users;
	},
    gotTeams(state: IState, teams: ITeam[]) {
		state.teams = teams;
	},
};

export const incidents = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
