import { IEvent } from "@/modules/Issues/interfaces/EventInterface";
import { IIssue } from "@/modules/Issues/interfaces/IssueInterface";
import { IRelatedRecords } from "../interfaces/RelatedRecordInterface";

export default class RelatedRecordHelper {
	static getEmptyRelatedRecords(): IRelatedRecords {
		return {
			events: [],
			risk_assessments: [],
			incidents: [],
		}
	}

	static getRelatedRecordsForEvent(event: IEvent): IRelatedRecords {
        return RelatedRecordHelper.getRelatedRecordsForEvents([event]);
    }

    static getRelatedRecordsForEvents(events: IEvent[]): IRelatedRecords {
        let result = this.getEmptyRelatedRecords();
        result.events = [
            ...events.map(event => {
                return {
                    id: event.id,
                    text: event.description,
                    issues: [],
                    data: {
                        incident_notifiables: event.event_definition && event.event_definition.incident_notifiables_select_list ? event.event_definition.incident_notifiables_select_list : []
                    }
                };
            })
        ];
        return result;
    }

	static getRelatedRecordsForEventId(eventId: number): IRelatedRecords {
		return this.getRelatedRecordsForEvent({ id: eventId, description: "Event" } as IEvent);
	}

	static getRelatedRecordsForIssue(issue: IIssue, event: IEvent | null): IRelatedRecords {
		let result = this.getEmptyRelatedRecords();

		if (event != null) {
			result.events.push({
				id: event.id,
				text: event.description,
				issues: [{
					id: issue.id,
					text: issue.description,
				}],
			});
		} else {
			result.events.push({
				id: issue.event_id,
				text: "Event",
				issues: [{
					id: issue.id,
					text: issue.description,
				}],
			});
		}
		return result;
	}
}
