<template>
    <b-modal lazy scrollable :id='id' hide-footer hide-header body-class='p-0' size='md' @shown='loadData'>
        <b-card class='mx-auto w-100' no-body>
            <b-card-header header-bg-variant='light'>
                <i class='rm-menu-h-icon'>
                    <font-awesome-icon icon='user'></font-awesome-icon>
                </i>
                <span class='px-2'>User Settings</span>
            </b-card-header>
            <b-card-body>
                <b-form>
                    <div class='form-group row'>
                        <label for='timezone-input' class='col-md-4 col-form-label text-md-right'>Timezone</label>
                        <div class='col-md-6'>
                            <b-form-select v-model='timezone' :options='timezones' :disabled='timezonesLoading'></b-form-select>
                        </div>
                    </div>
                    <div class='form-group row'>
                        <label for='mobile-input' class='col-md-4 col-form-label text-md-right'>Mobile Number</label>
                        <div class='col-md-6'>
                            <b-form-input
                                id='mobile-input'
                                type='text'
                                v-model='mobile'
                                description='+ Country code and number all as one string eg +61987654321'
                                invalid-feedback='Please enter a valid number as in the example below.'
                                :state='mobstate'
                            ></b-form-input>
                        </div>
                    </div>
                    <div class='form-group row mb-0'>
                        <div class='col-md-8 offset-md-4'>
                            <div>
                                <b-button ref='rmResetPasswordBtn' type='button' variant='primary' @click='saveClicked' :disabled='!changed'>Save</b-button>
                                <b-button variant='link' @click='closeClicked'>{{changed ? 'Cancel' : 'Close'}}</b-button>
                            </div>
                        </div>
                    </div>
                </b-form>
            </b-card-body>
        </b-card>
    </b-modal>
</template>

<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SystemService } from '@/modules/SystemAdmin/services/SystemService';
import { IUserData } from '@/core/interfaces/UserDataInterface';
import UserService from '@/core/services/UserService';

@Component
export default class ChangeProfile extends Vue {
    @Prop({ type: String, default: 'change-profile-modal'}) readonly id!: string;

    originalUserSettings: IUserData | null = null;
    currentTimezone: string | null = null;
    timezoneChanged: boolean = false;
    timezonesLoading: boolean = false;
    timezones: any[] = [];
    currentMobile: string | null = null;
    mobileChanged: boolean = false;

	// Computed Properties
	get mobstate(): boolean | null {
        if (this.mobileChanged && this.mobile) {
            const regex: RegExp = /^\+[1-9]\d{6,14}$/;
			return regex.test(this.mobile);
        } else {
            return null;
        }
	}
    get timezone(): string | null {
        return this.currentTimezone;
    }
    set timezone(value: string | null) {
        this.timezoneChanged = value != this.originalUserSettings?.timezone as string | null;;
        this.currentTimezone = value;
    }
    get mobile(): string | null {
        return this.currentMobile;
    }
    set mobile(value: string | null) {
        this.mobileChanged = value != this.originalUserSettings?.mobile as string | null;;
        this.currentMobile = value;
    }
    get changed(): boolean {
        return this.timezoneChanged || this.mobileChanged;
    }

    loadData() {
        this.timezonesLoading = true;
		SystemService.getTimezones()
			.then((results: any[]) => this.timezones = results)
			.catch((error) => this.$store.commit('common/errorMsg', 'Failed to get timezones from database, please try again.'))
			.finally(() => this.timezonesLoading = false);

		this.originalUserSettings = this.$store.getters['auth/currentUserData'];
        if (this.originalUserSettings != null) {
            this.timezone = this.originalUserSettings.timezone;
		    this.mobile = this.originalUserSettings.mobile;
        }
    }

    async saveClicked(): Promise<void> {
        if (this.mobstate === false) {
			this.$store.commit('common/errorMsg', 'Please enter a valid mobile number.');
		} else if (this.timezone == null) {
            this.$store.commit('common/errorMsg', 'Must select a timezone');
        } else {
            this.$store.commit('common/isWorkingStack', true);
            return UserService.updateUserProfile(this.timezone!, this.mobile!)
            .then((response) => {
                this.originalUserSettings!.timezone = this.timezone;
                this.originalUserSettings!.mobile = this.mobile;
                this.$store.commit('auth/currentUserData', this.originalUserSettings);
                this.$store.commit('common/infoMsg', 'Successfully updated settings');
                this.$bvModal.hide(this.id);
            })
            .catch(() => {
                this.$store.commit('common/errorMsg', 'Error updating settings');
            })
            .finally(() => {
                this.$store.commit('common/isWorkingStack', false);
            });
        }
    }
    clearMessages() {
        this.$store.commit('auth/clearErrorMessage');
		this.$store.commit('auth/clearInfoMessage');
        this.$store.commit('common/errorMsg', null);
		this.$store.commit('common/infoMsg', null);
    }
    closeClicked(): void {
        this.clearMessages();
		this.$bvModal.hide(this.id);
	}

}
</script>
