import UserService from "./core/services/UserService";
import axios from "axios";
import { globalVars } from "./core/helpers/GlobalVars";
import router from "./router";
import store from "./store";

axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.request.status === 401 || error.request.status === 419) {
			//if its unuthorised (401) or CSRF token problem (419) it means your session has probably timed out or you dont have permission to access that route
			//log the user out locally.
			UserService.logoutLocal();
			//If its an attempt to login to the staff portal and it failed then display an error page
			if (globalVars.pendingRoute && globalVars.pendingRoute.name && ["EventStaffHome", "EventStaffLogin", "EventStaffLoginFailed", "EventStaffLoginNotActive", "EventStaffLoginNotLive"].includes(globalVars.pendingRoute.name)) {
				//capture the token if present
				if(globalVars.pendingRoute.name === "EventStaffLogin" && globalVars.pendingRoute.params.token) store.commit("auth/token", globalVars.pendingRoute.params.token);
				if(router.currentRoute.path == "/event-staff-login/failed") {
					router.push("/event-staff-login/not-active");
				 } else {
					router.push("/event-staff-login/failed");
				 }
			} else if (!(router.currentRoute && router.currentRoute.path === "/login") && !(globalVars.pendingRoute && globalVars.pendingRoute.path === "/login")) {
				//go to the login page
				//make sure were not calling login multiple times
				const redirectPath: string | null = globalVars.pendingRoute ? globalVars.pendingRoute.fullPath : "/";
				router.push({
					path: "/login",
					query: { redirect: redirectPath },
				});
			}
			//resolve the promise, weve handled the error.
			return Promise.resolve();
		}
		// If error was not 401 just reject as is
		else {
			//throw error;
			return Promise.reject(error);
		}
	}
);
