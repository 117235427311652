import { ILkuReportModuleSection, IReport, ModuleCode, ReportType } from "../interfaces/ReportInterface";

import ApiService from "./ApiService";
import { AxiosResponse } from "axios";

export default abstract class ReportService {
    static getEmptyReport(): IReport {
        return {
            id: -new Date().valueOf(),
            lku_report_type_id: ReportType.Unknown,
            master_id: null,
            folder_id: null,
            brand_image_file_id: null,
            name: "",
            is_template: false,
            report_modules: [],
            report_files: [],
            master_selection: {
                filter_state: null,
                selected_ids: [],
            },
            auto_generate: false,
            generate_interval: 0,
            generate_interval_unit: "week",
            notifiables: [],
        };
    }

    static getNewReport(type: ReportType, name: string, template: boolean, master_id: number | null): IReport {
        let report = this.getEmptyReport();
        report.lku_report_type_id = type;
        report.name = name;
        report.is_template = template;
        report.master_id = master_id;
        return report;
    }

    static async getLkuModuleSections(reportTypeId: ReportType | undefined | null): Promise<ILkuReportModuleSection[]> {
        const resource: string = "/api/report_module_sections/" + (reportTypeId ? reportTypeId?.toString() : "-99");
        try {
            const response: AxiosResponse<any> = await ApiService.get(resource);
            if (response.data.data) {
                //response.data should an array of ILkuReportModuleSection object.
                return response.data.data;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            throw e;
        }
    }

    static async getReports(reportTypeId: ReportType | undefined | null, masterId: number | undefined | null, folderId: number | undefined | null): Promise<IReport[]> {
        const resource: string =
            "/api/reports/" + (reportTypeId ? reportTypeId?.toString() : "-99") + "/" + (masterId ? masterId?.toString() : "-99") + "/" + (folderId ? folderId?.toString() : "-99");
        try {
            const response: AxiosResponse<any> = await ApiService.get(resource);
            if (response.data.data) {
                //response.data should a single IReport object with fieldname/value properties representing the new team.
                return response.data.data;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            throw e;
        }
    }

    static async getReportById(reportId: number): Promise<IReport> {
        const resource: string = "/api/report/" + reportId.toString();
        try {
            const response: AxiosResponse<any> = await ApiService.get(resource);
            if (response.data.data) {
                //response.data should a single IReport object with fieldname/value properties representing the new team.
                return response.data.data;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            throw e;
        }
    }

    static async saveReport(report: IReport): Promise<IReport> {
        let resource = "";
        let response: AxiosResponse<any>;
        let toReturn: IReport | null = null;
        try {
            if (report.id != null && report.id > 0) {
                //--- update ---
                resource = "/api/report/" + report.id.toString();
                response = await ApiService.put(resource, report);
                toReturn = response.data.data; //get a fresh copy from the database as it contains related records
            } else {
                // --- add new ---
                resource = "/api/report";
                response = await ApiService.post(resource, report);
                toReturn = response.data.data; // when creating a new record we get the full object back
            }
            if (toReturn) {
                return toReturn;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            throw e;
        }
    }

    /**
     * Stores a new report and marks it as deleted. This is used when using the Print funcion of the report builder
     * @param report 
     * @returns the new report Id
     */
    static async storeDeleteReport(report: IReport): Promise<string> {
        let resource = "";
        let response: AxiosResponse<any>;
        let toReturn: IReport | null = null;
        try {            
            // --- add new ---
            resource = "/api/reportdelete";
            response = await ApiService.post(resource, report);
            toReturn = response.data; // should return the new report id
            if (response.data && response.data.id) {
                return response.data.id;
            } else {
                throw new Error("Invalid Response");
            }            
        } catch (e: any) {
            throw e;
        }
    }

    static async deleteReport(reportId: number): Promise<void> {
        try {
            const resource = "/api/report/" + reportId.toString();
            await ApiService.delete(resource);
        } catch (e: any) {
            let msg = "Delete report layout failed.";
            if (e.response) {
                msg = e.response.data.message;
            } else if (e.message) {
                msg = e.message;
            }
            throw new Error(msg);
        }
    }


    /**
     * Returns an Axios Response so that response headers are available
     * @param reportId
     * @param type
     */
    static async getPdfReport(reportId: number, type: "inline" | "download", save: boolean): Promise<AxiosResponse<any>> {
        const resource: string = "/api/pdfreport/" + reportId.toString() + "?t=" + (type === "inline" ? "i" : "d") + "&s=" + (save ? "true" : "false");
        try {
            const response: AxiosResponse<any> = await ApiService.get(resource, { responseType: "arraybuffer" });
            if (response.data) {
                //response data should be a pdf file as a binary
                //let blob = new Blob([response.data], { type: "application/pdf" });
                //return blob;
                return response;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            throw e;
        }
    }

    /**
     * Returns an Axios Response so that response headers are available
     * @param report
     * @param type
     */
    static async previewPdfReport(report: IReport, type: "inline" | "download" = "download"): Promise<AxiosResponse<any>> {
        const resource: string = "/api/adhocpdfreport?t=" + (type === "inline" ? "i" : "d") + "&s=false";
        try {
            const response: AxiosResponse<any> = await ApiService.post(resource, report, { responseType: "arraybuffer" });
            if (response.data) {
                return response;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            throw e;
        }
    }

    /**
     * Returns an Axios Response so that response headers are available
     * @param reportId
     * @param type
     */
    static async getWordReportToken(reportId: number): Promise<{ token: string; }> {
        const resource: string = "/api/wordreport/" + reportId.toString();
        try {
            const response: AxiosResponse<any> = await ApiService.get(resource);
            if (response.data) {
                //response data should be a pdf file as a binary
                //let blob = new Blob([response.data], { type: "application/pdf" });
                //return blob;
                return response.data;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            throw e;
        }
    }

    static async deleteReportFile(reportId: number): Promise<void> {
        try {
            const resource = "/api/reportfile/" + reportId.toString();
            await ApiService.delete(resource);
        } catch (e: any) {
            let msg = "Delete report failed.";
            if (e.response) {
                msg = e.response.data.message;
            } else if (e.message) {
                msg = e.message;
            }
            throw new Error(msg);
        }
    }

    static async getReportTemplates(reportTypeId: number): Promise<IReport[]> {
        const resource: string = "/api/reporttemplates/" + (reportTypeId ? reportTypeId?.toString() : "-99");
        try {
            const response: AxiosResponse<any> = await ApiService.get(resource);
            if (response.data.data) {
                //response.data should an array of IReport objects.
                return response.data.data;
            } else {
                throw new Error("Invalid Response");
            }
        } catch (e: any) {
            throw e;
        }
    }
}
