import { AxiosRequestConfig, AxiosResponse } from "axios";

import ApiService from "../../../core/services/ApiService";
import { IEvent, IMultipleEventDashboardData, IMultipleEventReportsData } from "../interfaces/EventInterface";
import { serialize } from "@/core/helpers/SerializeHelper";
import { IEventFilter } from "../interfaces/EventFilterInterface";

export default abstract class EventService {
	static async getEvents(folderId: number | string | null): Promise<any[]> {
		const resource: string = "/api/events/" + folderId?.toString();
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data && response.data) {
				//response.data should an array of objects representing events.
				return response.data.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get events failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async getEventSiblings(eventId: number): Promise<any[]> {
		if (eventId && eventId > 0) {
			const resource: string = "/api/events/siblings/" + eventId.toString();
			try {
				const response: AxiosResponse<any> = await ApiService.get(resource);
				if (response.data && response.data) {
					//response.data should an array of objects representing events.
					return response.data.data;
				} else {
					throw new Error("Invalid Response");
				}
			} catch (e: any) {
				let msg = "Get events failed.";
				if (e.response) {
					msg = e.response.data.message;
				} else if (e.message) {
					msg = e.message;
				}
				throw new Error(msg);
			}
		} else {
			throw new Error("Folder not specified");
		}
	}

	static async getActiveEventsForUser(): Promise<IEvent[]> {
		const resource = "/api/events/active/user";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data && response.data) {
				//response.data should an array of objects representing events.
				return response.data.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get events failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

    static async getEventsForFilter(filter: IEventFilter): Promise<IEvent[]> {
        const resource = "/api/events/filter" + "?" + serialize(filter);
        const response: AxiosResponse<any> = await ApiService.get(resource);
        if (response.data) {
            return response.data;
        } else {
            throw new Error("Invalid Response");
        }
    }

    static async getEventDashboardData(eventIds: number[]): Promise<IMultipleEventDashboardData> {
        const resource = '/api/events/data/dashboard?' + serialize(eventIds);
        const response: AxiosResponse<any> = await ApiService.get(resource);
        if (response.data) {
            return response.data;
        } else {
            throw new Error("Invalid Response");
        }
    }

    static async getEventReportsData(eventIds: number[]): Promise<IMultipleEventReportsData> {
        const resource = '/api/events/data/reports?' + serialize(eventIds);
        const response: AxiosResponse<any> = await ApiService.get(resource);
        if (response.data) {
            return response.data;
        } else {
            throw new Error("Invalid Response");
        }
    }

	static async deleteEvent(id: number): Promise<void> {
		const resource: string = "/api/event/" + id.toString();
		try {
			await ApiService.delete(resource);
		} catch (e: any) {
			let msg = "Delete Event failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	// --------- Download revent as Excel ------------------

	static async downloadEvent(evId: number): Promise<any> {
		const resource: string = "/api/export/events/" + evId.toString();

		// options to handle binary
		const config: AxiosRequestConfig = {
			responseType: "blob",
		};

		try {
			const response: AxiosResponse<any> = await ApiService.get(resource, config);
			if (response.data) {
				const data = response.data;

				return data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "download event failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async getEventById(eventId: number): Promise<IEvent> {
		if (eventId && eventId > 0) {
			const resource: string = "/api/event/" + eventId.toString();
			try {
				const response: AxiosResponse<any> = await ApiService.get(resource);
				if (response.data && response.data.data) {
					//response.data should an objects representing an event.
					return response.data.data;
				} else {
					throw new Error("Invalid Response");
				}
			} catch (e: any) {
				let msg = "Get events failed.";
				if (e.response) {
					msg = e.response.data.message;
				} else if (e.message) {
					msg = e.message;
				}
				throw new Error(msg);
			}
		} else {
			throw new Error("Event not specified");
		}
	}

	static async saveEvent(event: IEvent): Promise<IEvent> {
		let resource = "";
		let response: AxiosResponse<any>;
		try {
			if (event.id > 0) {
				//--- update ---
				resource = "/api/event/" + event.id.toString();
				response = await ApiService.put(resource, event);
			} else {
				// --- add new ---
				resource = "/api/event";
				response = await ApiService.post(resource, event);
			}
			if (response.data.data) {
				return response.data.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			throw e;
		}
	}

	/**
	 * Allocates events to a folder
	 * @param eventIds
	 * @param folderId
	 *
	 * @return number of events moved
	 */
	static async setEventsFolder(eventIds: number[], folderId: number | string): Promise<number> {
		if (eventIds && eventIds.length > 0) {
			try {
				const resource = "/api/events/folder/" + folderId.toString();
				const response: AxiosResponse<number> = await ApiService.put(resource, eventIds);
				return response.data; //should return the number of items moved.
			} catch (e: any) {
				throw e;
			}
		} else {
			throw new Error("No Event specified.");
		}
	}
}
