import { AxiosResponse } from "axios";
import { ILocation } from "../interfaces/LocationInterface";
import ApiService from "./ApiService";


export default abstract class LocationService {
	static async getLocationsForEvent(eventId: number): Promise<ILocation[]> {
		const resource: string = "/api/locations/event/" + eventId.toString();
		const response: AxiosResponse<{ data: ILocation[] }> = await ApiService.get(resource);
		if (response.data && response.data.data) {
			//response.data should an array of ILocation objects representing each record with fieldname/value properties.
			return response.data.data;
		} else {
			throw new Error("Invalid Response");
		}
	}

	static async getLocationCandidates(latlng: DOMPoint, eventId: number | null): Promise<{ id: number, levelCode: string, levelName: string, spaceCode: string, spaceName: string }[]> {
		//const data = { "lat": latlng.y, "lng": latlng.x, "eventId": eventId };
		const resource: string = "/api/location/candidates?lat=" + latlng.y + "&lng=" + latlng.x + "&eid=" + (eventId ? eventId?.toString() : "");
		const response: AxiosResponse<{ id: number, levelCode: string, levelName: string, spaceCode: string, spaceName: string }[]> = await ApiService.get(resource);
		if (response.data) {
			//response.data should an array of ILocation objects representing each record with fieldname/value properties.
			return response.data;
		} else {
			throw new Error("Invalid Response");
		}
	}
}
