import ApiService from "./ApiService";
import { AxiosResponse } from "axios";
//import CustomFieldModel from "../models/CustomFieldModel";
import { ICustomFieldDef } from "../interfaces/CustomFieldInterface";

export default abstract class CustomFieldService {
	static async getCustomFields(tableId: string, layoutTemplateId: number | null | undefined, params: any | null | undefined): Promise<ICustomFieldDef> {
		let layoutResourceIdString = "";
		if (layoutTemplateId && layoutTemplateId > 0) {
			layoutResourceIdString = "/" + layoutTemplateId.toString();
		}
		let queryString = "";
		if (params && params instanceof Object && !Array.isArray(params)) {
			for (const [key, value] of Object.entries(params)) {
				if (queryString) {
					queryString += "&";
				}
				queryString += key + "=" + value;
			}
		}
		const resource: string = "/api/field_definitions/" + tableId.trim().toLowerCase() + (layoutResourceIdString ? layoutResourceIdString : "") + (queryString ? "?" + queryString : "");
		try {
			const response: AxiosResponse<ICustomFieldDef> = await ApiService.get(resource);
			if (response.data) {
				//response.data should be an object conforming to a ICustomFieldDef interface.
				return response.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get custom fields failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}
}
