export enum ScoringType {
	ConsequenceOnly = 1,
	Standard = 2,
	Custom = 3,
}

export enum FormulaElementType {
	Variable = 0,
	Number = 1,
	Operator = 2,
}
