import { DateFormats, Modules, TimeFormats } from "../core/enums/LookupEnums";
import { IUserDataLicense, ILogo, IModule, ISetting, IUserData, PermissionCode } from "@/core/interfaces/UserDataInterface";
import { authErrorMessages, authInfoMessages, authLoginSteps, recoverPasswordInfoMessages } from "./authConstants";

import { INotificationStatus } from "../core/helpers/NotificationHelper";
import { ISelectListItem, IHierarchicalSelectListItem } from "../core/interfaces/SelectListItemInterface";
import LoginModel from "../core/models/LoginModel";
import { UserService } from "../core/services/UserService";
import router from "../router";
import store from "@/store/index";
import { cloneDeep } from "lodash";
import { formatError } from "@/core/helpers/ErrorHelper";

interface IState {
	authenticating: boolean;
	userData: IUserData | null;
	errorMessage: string | null;
	infoMessage: string | null;
	refreshTokenPromise: Promise<any> | null;
	loginModel: LoginModel;
	loginStep: authLoginSteps;
	currentLicenseId: number;
	loggedIn: boolean;
	notificationStatus: INotificationStatus | null;
	token: string | null;
}

const state: IState = {
	authenticating: false,
	userData: null, //Holds an IUserData object
	errorMessage: "",
	infoMessage: "",
	refreshTokenPromise: null, // Holds the promise of the refresh token
	loginModel: new LoginModel(null, null, null, null, null),
	loginStep: authLoginSteps.STEP_START,
	currentLicenseId: 0,
	loggedIn: true, //Assume you are logged in to start.  If any api calls return 401 this will be updated.
	notificationStatus: null,
	token: null,
};

const getters = {
	loggedIn: (state: any): boolean => {
		return state.loggedIn;
	},
	errorMessage: (state: any) => {
		return state.errorMessage;
	},
	infoMessage: (state: any) => {
		return state.infoMessage;
	},
	authenticating: (state: any) => {
		return state.authenticating;
	},
	loginStep: (state: any) => {
		return state.loginStep;
	},
	loginModel: (state: any) => {
		return state.loginModel;
	},
    email: (state: any) => {
        return state.loginModel.email;
    },
    license: (state: any) => {
        return state.loginModel.license;
    },
    password: (state: any) => {
        return state.loginModel.password;
    },
    newPassword: (state: any) => {
        return state.loginModel.newPassword;
    },
    confirmPassword: (state: any) => {
        return state.loginModel.confirmPassword;
    },
    token: (state: any) : string | null => {
		return state.token ? state.token : null;
	},
    licensesList: (state: any): ISelectListItem[] => {
        if (state.userData) {
            return (state.userData as IUserData).extra.available_licenses.map((lic) => {
                return { text: lic.name, value: lic.id.toString(), display_sequence: 0, group: "" };
            });
        } else {
            return [];
        }
	},
    hierarchicalLicensesList: (state: any): ISelectListItem[] => {
        if (state.userData) {
            const licenses = (state.userData as IUserData).extra.available_licenses;

            const isTopLevelLicense = (license: IUserDataLicense): boolean => {
                return license.parent_id == null || !licenses.find(parent_license => parent_license.id == license.parent_id);
            };
            const topLevelLicenses = licenses.filter(isTopLevelLicense);

            const format = (license: IUserDataLicense, licenses: IUserDataLicense[]): IHierarchicalSelectListItem => {
                return {
                    text: license.name,
                    value: license.id.toString(),
                    display_sequence: 0,
                    selectable: true,
                    children: licenses
                        .filter(lic => lic.parent_id == license.id) // children licenses
                        .map(lic => format(lic, licenses)) // recursive call
                        .sort((a, b) => a.text.localeCompare(b.text)) // sort alphabetically
                };
            };
            return topLevelLicenses
                .map(tll => format(tll, licenses))
                .sort((a, b) => {
                    if (Number(a.value) == 1) { // system license
                        return 1;
                    } else if (Number(b.value) == 1) { // system license
                        return 1;
                    } else {
                        return a.text.localeCompare(b.text); // alphabetically
                    }
                });
        } else {
            return [];
        }
    },
	currentLicenseId: (state: any): number | null => {
		return state.currentLicenseId;
	},
	currentLicenseName: (state: any): string | null => {
		if (state.userData) {
			const foundLicense: IUserDataLicense | undefined = (state.userData as IUserData).extra.available_licenses.find((license) => license.id === (state.currentLicenseId as number));
			if (foundLicense) {
				return foundLicense.name;
			} else {
				return "";
			}
		} else {
			return "";
		}
	},
	currentUserData: (state: any): IUserData | null => {
		return state.userData as IUserData;
	},
	hasPermission: (state: IState) => (permissionCode: PermissionCode): boolean => {
		if (state.userData && state.userData.extra && state.userData.extra.permissions) {
			return state.userData.extra.permissions.some((p: PermissionCode) => p === permissionCode);
		} else {
			return false;
		}
	},
	inPermissions: (state: IState) => (permissionCodes: PermissionCode[]): boolean => {
		if (state.userData && state.userData.extra && state.userData.extra.permissions) {
			return state.userData.extra.permissions.some((p: PermissionCode) => permissionCodes.includes(p));
		} else {
			return false;
		}
	},
	hasModule: (state: IState) => (moduleCode: Modules): boolean => {
		if (state.userData && state.userData.extra && state.userData.extra.modules) {
			return state.userData.extra.modules.some((m: IModule) => m.id === moduleCode);
		} else {
			return false;
		}
	},
	notificationStatus: (state: IState): INotificationStatus | null => {
		return state.notificationStatus;
	},
	currentLicense: (state: IState): IUserDataLicense | undefined => {
		if (
			state.userData &&
			state.userData.extra &&
			state.userData.extra.current_license_id &&
			state.userData.extra.available_licenses &&
			Array.isArray(state.userData.extra.available_licenses) &&
			state.userData.extra.available_licenses.length > 0
		) {
			return state.userData.extra.available_licenses.find((l: IUserDataLicense) => l.id === state.userData!.extra.current_license_id);
		} else {
			return undefined;
		}
	},
	// ------- License Settings Here - App Name First ---------
	settingsAppName: (state: any): string | null => {
		if (state.userData && state.userData.extra && state.userData.extra.settings) {
			const setting: ISetting | undefined = (state.userData as IUserData).extra.settings.find((s) => s.id === "LIC_APP_NAME");
			if (setting) {
				return setting.value;
			} else {
				return "";
			}
		} else {
			return "";
		}
	},
	settingsDateFormat: (state: any): string | null => {
		if (state.userData && state.userData.extra && state.userData.extra.settings) {
			const setting: ISetting | undefined = (state.userData as IUserData).extra.settings.find((s) => s.id === "LIC_DATE_FMT");
			if (setting) {
				// We get a value like 'us' or 'default' and need to look up its format in enums
				const value = setting.value as keyof typeof DateFormats;  // https://www.typescriptlang.org/docs/handbook/enums.html#enums-at-compile-time
				let format = DateFormats[value] ? DateFormats[value] : "D MMM YYYY";
				return format;
			} else {
				return "";
			}
		} else {
			return "";
		}
	},
	settingsTimeFormat: (state: any): string | null => {
		if (state.userData && state.userData.extra && state.userData.extra.settings) {
			const setting: ISetting | undefined = (state.userData as IUserData).extra.settings.find((s) => s.id === "LIC_TIME_FMT");
			if (setting) {
				// @todo there is a better way of getting enums
				const value = setting.value;
				let format = "HH:mm";

				if (value == "default") {
					format = TimeFormats.default;
				}

				if (value == "ampm") {
					format = TimeFormats.ampm;
				}
				return format;
			} else {
				return "";
			}
		} else {
			return "";
		}
	},
	settingsMidnightFormat: (state: any): boolean | null => {
		if (state.userData && state.userData.extra && state.userData.extra.settings) {
			const setting: ISetting | undefined = (state.userData as IUserData).extra.settings.find((s) => s.id === "LIC_MIDNT_FMT");
			
			if (setting) {
				// @todo there is a better way of getting enums
				const value = setting.value;
				let format = false;

				if (value == "default") {
					format = false;
				}

				if (value == "24hr") {
					format = true;
				}
				return format;
			} else {
				return false;
			}
		} else {
			return false;
		}
	},
	settingsColourScheme: (state: any): string | null => {
		if (state.userData && state.userData.extra && state.userData.extra.settings) {
			const setting: ISetting | undefined = (state.userData as IUserData).extra.settings.find((s) => s.id === "LIC_COL_SCH");
			if (setting) {
				return setting.value;
			} else {
				return "";
			}
		} else {
			return "";
		}
	},
	settingsSessionTime: (state: any): string | null => {
		if (state.userData && state.userData.extra && state.userData.extra.settings) {
			const setting: ISetting | undefined = (state.userData as IUserData).extra.settings.find((s) => s.id === "LIC_SESSION");
			if (setting) {
				return setting.value;
			} else {
				return "";
			}
		} else {
			return "";
		}
	},

	settingsBrandImage: (state: any): ILogo | null => {
		if (state.userData && state.userData.extra) {
			const logo: ILogo | undefined = (state.userData as IUserData).extra.logo;
			if (logo) {
				return logo;
			} else {
				return null;
			}
		} else {
			return null;
		}
	},

	settingsRiskSourceData: (state: any): string | null => {
		if (state.userData && state.userData.extra) {
			const rsd: string | undefined = (state.userData as IUserData).extra.risk_source_data;
			if (rsd) {
				return rsd;
			} else {
				return null;
			}
		} else {
			return null;
		}
	},

	settingsHasRelatedLicenses: (state: any): boolean | null => {
		if (state.userData && state.userData.extra) {
			const hrl: boolean | undefined = (state.userData as IUserData).extra.has_related_licenses;
			if (hrl) {
				return hrl;
			} else {
				return null;
			}
		} else {
			return null;
		}
	},

	settingsHasWebPush: (state: any): boolean => {
		let result = false;
		if (state.userData && state.userData.extra && state.userData.extra.settings) {
			const hwp: ISetting | undefined = (state.userData as IUserData).extra.settings.find((s) => s.id === "LIC_NOT_UWP");
			if (hwp && hwp.value == "true") {
				result = true;
			}
		}
		return result;
	},
};

const actions = {
	start: function ({ commit }: any) {
		commit("setAuthStart");
	},
	next: async function ({ commit, state, dispatch }: any) {
		commit("clearErrorMessage");
		//sataus must be STEP_SEL_LIC for this to do anything
		if (state.loginStep === authLoginSteps.STEP_SEL_LIC) {
			//check if the current license id has changed
			const before: number = (state.userData as IUserData).extra.current_license_id;
			const after: number = state.currentLicenseId;
			if (before === after) {
				//no need to change anything just login
				commit("setStep", authLoginSteps.STEP_LOGGED_IN);
				//get the redirect path
				const redirectPath: any = router.currentRoute?.query?.redirect ? router.currentRoute.query.redirect : "/home";
                router.push(redirectPath).catch(() => { });
			} else {
				dispatch("switchLicenseTo", state.currentLicenseId);
			}
		} else {
			commit("setErrorMessage", authErrorMessages.ERR_LOGIN_FAIL);
		}
		// whether changed or not - set the brand image and last login id in local storage
		const logo: ILogo | undefined = (state.userData as IUserData).extra.logo;
		localStorage.setItem("brandLogo", JSON.stringify(logo));
		const lastLicenseId: number | undefined = (state.userData as IUserData).extra.current_license_id;
		localStorage.setItem("lastLicenseId", JSON.stringify(lastLicenseId));
	},
	login: async function ({ commit, state }: any): Promise<any> {
		commit('setAuthenticating');
		commit('clearErrorMessage');
        if (!state.loginModel.email && !state.loginModel.password) {
            commit('setErrorMessage', authErrorMessages.ERR_INVALID_CREDENTIALS);
            return;
        } else if (!state.loginModel.password) {
            commit('setErrorMessage', authErrorMessages.ERR_INVALID_PWD);
            return;
        }
        store.commit('common/isWorkingStack', true);
        UserService.login(state.loginModel.email, state.loginModel.password)
            .then((userData: IUserData) => {
                const licenses: IUserDataLicense[] = userData.extra.available_licenses;
                if (licenses.length === 0) {
                    // no licenses available
                    commit('setStep', authLoginSteps.STEP_NO_LIC);
                } else if (licenses.length > 1) {
                    // have to select a license
                    commit('setStep', authLoginSteps.STEP_SEL_LIC);
                } else if (userData.extra.current_license_id !== licenses[0].id) {
                    // the current license doesn't match the logged in license
                    commit('setErrorMessage', authErrorMessages.ERR_LOGIN_FAIL);
                } else {
                    // we are logged in
                    commit('setStep', authLoginSteps.STEP_LOGGED_IN);
                    commit('clearLoginModel');
                    const redirectPath: any = router.currentRoute?.query?.redirect ? router.currentRoute.query.redirect : '/home';
                    router.push(redirectPath).catch(() => { });
                }
                commit('loginSuccess');
            })
            .catch((e) => {
                commit('loginError');
            })
            .finally(() => {
                store.commit('common/isWorkingStack', false);
            });
	},
	async logout() {
		store.commit("common/isWorkingStack", true);
		UserService.logout()
			.then(() => router.push("/login"))
			.finally(() => store.commit("common/isWorkingStack", false));
	},
	switchLicense({ commit, state }: any) {
		router.push("/login");
		commit("setStep", authLoginSteps.STEP_SEL_LIC);
	},
	switchLicenseTo({ commit, state }: any, licenseId: number) {
		store.commit("common/isWorkingStack", true);
		UserService.updateCurrentLicense(licenseId)
			.then(() => {
				commit("setStep", authLoginSteps.STEP_LOGGED_IN);
				const redirectPath: any = router.currentRoute?.query?.redirect ? router.currentRoute.query.redirect : "/home";
                router.push(redirectPath).catch(() => { });
			})
			.catch((e: any) => {
				commit("setErrorMessage", authErrorMessages.ERR_LOGIN_FAIL);

			})
			.finally(() => {
				store.commit("common/isWorkingStack", false);
			});
	},
	async recoverPassword({ commit, state }: any) {
		commit('clearErrorMessage');
		commit('clearInfoMessage');
		if (state.loginModel.email && /^\S{1,30}@\S{1,30}\.\S{1,30}$/.test(state.loginModel.email)) {
            const email: string = cloneDeep(state.loginModel.email);
            store.commit('common/isWorkingStack', true);
            return UserService.recoverPassword(email)
                .then((result: any) => {
                    commit('setInfoMessage', recoverPasswordInfoMessages.EMAIL_SENT(email));
                    commit('clearLoginModel');
                })
                .catch((e) => {
                    let msg = formatError(e, 'Error sending reset email, contact your license administrator if this error persists.');
                    if (Array.isArray(msg)) {
                        msg = msg[0];
                    }
                    commit('setInfoMessage', msg);
                })
                .finally(() => {
                    store.commit('common/isWorkingStack', false);
                });
		} else if (state.loginStep != authLoginSteps.STEP_RECOVER_PWD) {
            commit('setStep', authLoginSteps.STEP_RECOVER_PWD);
        } else {
            commit('setInfoMessage', authErrorMessages.ERR_INVALID_EMAIL);
        }
        return Promise.resolve();
	},
	async resetPassword({ commit, state, dispatch }: any) {
		commit('clearErrorMessage');
		commit('clearInfoMessage');
		//Ensure the password is populated
		if (state.loginModel.newPassword) {
			//check the validity of the new password
			const passwordValid: string | boolean = checkPasswordValidity(state.loginModel.newPassword);
            const passwordsMatch: string | boolean = checkMatchingPasswords(state.loginModel.newPassword, state.loginModel.confirmPassword);
			if (passwordValid !== true) {
                commit('setErrorMessage', passwordValid);
            } else if (passwordsMatch !== true) {
                commit('setErrorMessage', passwordsMatch);
			} else {
                store.commit('common/isWorkingStack', true);
                await UserService.resetPassword(state.token, state.loginModel.email, state.loginModel.newPassword, state.loginModel.confirmPassword)
                    .then((result: any) => {
                        router.push('/login');
                        commit('setInfoMessage', authInfoMessages.INFO_PASSWORD_CHANGED + "\nSigning you in now.");
                        commit('password', state.loginModel.newPassword);
                        return dispatch('login');
                    }).catch((e) => {
                        if (e.response?.data?.errors?.email?.length > 0) {
                            commit('setErrorMessage', e.response.data.errors.email[0]);
                        } else if (e.response?.data?.errors?.password?.length > 0) {
                            commit('setErrorMessage', e.response.data.errors.password[0]);
                        } else {
                            commit('setErrorMessage', 'Unknown error updating password. Contact you license administrator if this problem persists.');
                        }
                    })
                    .finally(() => {
                        store.commit('common/isWorkingStack', false);
                    });
			}
		} else {
			commit("setErrorMessage", authErrorMessages.ERR_INVALID_PWD);
		}
	},

	async changePassword({ commit, state }: any): Promise<boolean> {
		commit('clearErrorMessage');
		commit('clearInfoMessage');
        const passwordValid: string | boolean = checkPasswordValidity(state.loginModel.newPassword);
        const passwordsMatch: string | boolean = checkMatchingPasswords(state.loginModel.newPassword, state.loginModel.confirmPassword);
        if (passwordValid !== true) {
            commit('setErrorMessage', passwordValid);
            return false;
        } else if (passwordsMatch !== true) {
            commit('setErrorMessage', passwordsMatch);
            return false;
        } else {
            store.commit('common/isWorkingStack', true);
            return UserService.changePassword(state.loginModel.password, state.loginModel.newPassword, state.loginModel.confirmPassword)
                .then((result: any) => {
                    store.commit('common/infoMsg', authInfoMessages.INFO_PASSWORD_CHANGED);
                    commit('clearLoginModel');
                    return true;
                }).catch((e) => {
                    store.commit('common/errorMsg', formatError(e, 'Unknown error updating password. Contact you license administrator if this problem persists.'));
                    return false;
                })
                .finally(() => {
                    store.commit('common/isWorkingStack', false);
                });
        }
	},

	updateUserData() {
		UserService.updateUserData();
	},
};

const mutations = {
	setAuthStart(state: any) {
		state.authenticating = false;
		state.authData = null; //Holds an IAuthData object
		state.userData = null; //Holds an IUserData object
		state.errorMessage = "";
		state.infoMessage = "";
		state.refreshTokenPromise = null; // Holds the promise of the refresh token
		state.loginModel = new LoginModel(null, null, null, null, null);
		state.loginStep = authLoginSteps.STEP_START;
	},
	setAuthenticating(state: any) {
		state.authenticating = true;
	},
	loginSuccess(state: any) {
		state.authenticating = false;
	},
	clearLoginModel(state: any) {
		state.loginModel = new LoginModel(null, null, null, null, null);
	},
	setUserData(state: any, userData: IUserData) {
		state.userData = userData;
		state.currentLicenseId = userData.extra.current_license_id;
        // add the brand image and last login id in local storage
        const logo: ILogo = userData.extra.logo;
        localStorage.setItem("brandLogo", JSON.stringify(logo));
        const lastLicenseId: number | undefined = userData.extra.current_license_id;
        localStorage.setItem("lastLicenseId", JSON.stringify(lastLicenseId));
	},
	loginError(state: any) {
		state.authenticating = false;
		state.errorMessage = authErrorMessages.ERR_LOGIN_FAIL;
	},
	setErrorMessage(state: any, errorMessage: string) {
		state.errorMessage = errorMessage;
	},
	clearErrorMessage(state: any) {
		state.errorMessage = "";
	},
	setInfoMessage(state: any, infoMessage: string) {
		state.infoMessage = infoMessage;
	},
	clearInfoMessage(state: any) {
		state.infoMessage = "";
	},
	setStep(state: any, step: number) {
		state.loginStep = step;
	},
	email(state: any, value: string) {
		state.loginModel.email = value;
	},
    license(state: any, value: number) {
		state.loginModel.license = value;
	},
    password(state: any, value: string) {
		state.loginModel.password = value;
	},
    newPassword(state: any, value: string) {
		state.loginModel.newPassword = value;
	},
    confirmPassword(state: any, value: string) {
		state.loginModel.confirmPassword = value;
	},
	updateCurLicense(state: any, value: string) {
		state.currentLicenseId = parseInt(value);
	},
	syncCurLicense() {
		(state.userData as IUserData).extra.current_license_id = state.currentLicenseId;
	},
	setLicenses(state: any, licenses: IUserDataLicense[]) {
		state.licensesList = licenses;
	},
	setPermissions(state: any, permissions: any[]) {
		state.permissionsList = permissions;
	},
	refreshTokenPromise(state: any, promise: Promise<any>) {
		state.refreshTokenPromise = promise;
	},
	setLoggedIn(state: IState, value: boolean) {
		state.loggedIn = value;
	},
	setNotificationStatus(state: IState, value: INotificationStatus | null) {
		state.notificationStatus = value;
	},
	token(state: IState, value: string | null) {
		state.token = value;
	},
	setNotificationsSupported(state: IState, value: boolean) {
		if (state.notificationStatus) {
			state.notificationStatus.notificationsSupported = value;
		}
	},
	setNotificationsBlocked(state: IState, value: boolean) {
		if (state.notificationStatus) {
			state.notificationStatus.notificationsBlocked = value;
		}
	},
	setNotificationsEnabled(state: IState, value: boolean) {
		if (state.notificationStatus) {
			state.notificationStatus.notificationsEnabled = value;
		}
	},
	setServiceWorkerRegistation(state: IState, value: ServiceWorkerRegistration | null) {
		if (state.notificationStatus) {
			state.notificationStatus.serviceWorkerRegistation = value;
		}
	},
	setPushSubscription(state: IState, value: PushSubscription | null) {
		if (state.notificationStatus) {
			state.notificationStatus.pushSubscription = value;
		}
	},

	/* showNotificationMessage(state: IState, value: boolean) {
		state.showNotificationMessage = value;
	} */
};

//Utiltiy functions
function checkPasswordValidity(newPassword: string): string | boolean {
	if (newPassword.length >= 8) {
		return true;
	} else {
		return "Password length must be at least 8 characters.";
	}
}
function checkMatchingPasswords(newPassword: string, confirmPassword: string) {
	if (newPassword === confirmPassword) {
		return true;
	} else {
		return authErrorMessages.ERR_PWDS_DIFFER;
	}
}

export const auth = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
