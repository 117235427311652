import { IEventDefinition, IEventDefinitionTemplate } from "../interfaces/EventInterface";

import ApiService from "../../../core/services/ApiService";
import { AxiosResponse } from "axios";

export default abstract class EventDefinitionService {
	static async getEventDefTemplates(): Promise<IEventDefinitionTemplate[]> {
		const resource = "/api/event_definitions";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data && response.data.data) {
				//response.data should an array of objects representing events.
				return response.data.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get event definitions failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async getEventDefById(eventDefinitionId: number): Promise<IEventDefinition> {
		if (eventDefinitionId && eventDefinitionId > 0) {
			const resource: string = "/api/event_definition/" + eventDefinitionId.toString();
			try {
				const response: AxiosResponse<any> = await ApiService.get(resource);
				if (response.data && response.data.data) {
					//response.data should an objects representing an event.
					return response.data.data;
				} else {
					throw new Error("Invalid Response");
				}
			} catch (e: any) {
				let msg = "Get events failed.";
				if (e.response) {
					msg = e.response.data.message;
				} else if (e.message) {
					msg = e.message;
				}
				throw new Error(msg);
			}
		} else {
			throw new Error("Event not specified");
		}
	}

	static async getDefaultEventDef(): Promise<IEventDefinition> {
		const resource = "/api/event_definition/default";
		try {
			const response: AxiosResponse<any> = await ApiService.get(resource);
			if (response.data && response.data.data) {
				//response.data should an objects representing an event definition.
				return response.data.data;
			} else {
				throw new Error("Invalid Response");
			}
		} catch (e: any) {
			let msg = "Get event definition failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}

	static async saveEventDefinition(eventDefinition: IEventDefinition): Promise<IEventDefinition> {
		if (eventDefinition) {
			let resource = "";
			let response: AxiosResponse<any>;
			let toReturn: IEventDefinition | null = null;
			const eventDefinitionId: number = eventDefinition.id;
			try {
				if (eventDefinitionId != null && eventDefinitionId > 0) {
					//--- update ---
					resource = "/api/event_definition/" + eventDefinitionId.toString();
					response = await ApiService.put(resource, eventDefinition);
					toReturn = response.data.data; // retrun an event object generated by a Laravel API resource which wraps it in an extra data object
				} else {
					// --- add new ---
					resource = "/api/event_definition";
					response = await ApiService.post(resource, eventDefinition);
					toReturn = response.data.data; //  retrun an event definition object
				}

				if (toReturn) {
					return toReturn;
				} else {
					throw new Error("Invalid Response");
				}
			} catch (e: any) {
				let msg = "Save event definition failed.";
				if (e.response) {
					msg = e.response.data.message;
				} else if (e.message) {
					msg = e.message;
				}
				throw new Error(msg);
			}
		} else {
			throw new Error("Event Definition not specified");
		}
	}

	static async deleteEventDef(id: number): Promise<void> {
		const resource: string = "/api/event_definition/" + id.toString();
		try {
			await ApiService.delete(resource);
		} catch (e: any) {
			let msg = "Delete Event Definition failed.";
			if (e.response) {
				msg = e.response.data.message;
			} else if (e.message) {
				msg = e.message;
			}
			throw new Error(msg);
		}
	}
}
