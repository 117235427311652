import { IHierarchicalSelectListItem, ISelectListItem } from "../interfaces/SelectListItemInterface";

import { IDdValue } from "../interfaces/CustomFieldInterface";
import { threadId } from "worker_threads";

const HierarchicalSelectListHelper = {
	/**Recursively finds an item by searching the children in a Hierarchical select list item
	 * @parameter f - a function to compare the items agaist
	 * @parameter item - the Hierarchical select list item to search.	*/

	findItemRecursive(
		f: (item: IHierarchicalSelectListItem | ISelectListItem | IDdValue) => boolean,
		item: IHierarchicalSelectListItem | ISelectListItem | IDdValue
	): IHierarchicalSelectListItem | ISelectListItem | IDdValue | null {
		//check if the item is an object
		if (item && Object(item) === item) {
			if (f(item) === true) {
				return item;
			} else if (
				Object.prototype.hasOwnProperty.call(item, "children") &&
				(item as IHierarchicalSelectListItem | IDdValue).children &&
				((item as IHierarchicalSelectListItem | IDdValue).children as IHierarchicalSelectListItem[] | IDdValue[]).length > 0
			) {
				for (const childItem of (item as IHierarchicalSelectListItem | IDdValue).children as IHierarchicalSelectListItem[] | IDdValue[]) {
					const res: IHierarchicalSelectListItem | ISelectListItem | IDdValue | null = this.findItemRecursive(f, childItem);
					if (res !== null) {
						return res;
					}
				}
			}
		}
		return null;
	},

	/**Recursively finds an item an array of Hierarchical select list items
	 * @parameter f - a function to compare the items agaist
	 * @parameter item2 - An array of Hierarchical select list items to search.	*/
	findItem(
		f: (item: IHierarchicalSelectListItem | ISelectListItem | IDdValue) => boolean,
		items: IHierarchicalSelectListItem[] | ISelectListItem[] | IDdValue[]
	): IHierarchicalSelectListItem | ISelectListItem | IDdValue | undefined {
		for (const item of items) {
			const foundItem = this.findItemRecursive(f, item);
			if (foundItem) return foundItem;
		}
		return undefined;
	},

	/**
	 * Returns a new flattened array of items by copying all child items recusively to the top level.
	 * Children are inserted directly after their parents.
	 * Children remain intact in each item
	 * @param items
	 */
	flattenArray(items: IHierarchicalSelectListItem[] | IDdValue[]): (IHierarchicalSelectListItem | IDdValue)[] {
		let result: (IHierarchicalSelectListItem | IDdValue)[] = [];
		const addChildren: (item: IHierarchicalSelectListItem | IDdValue) => void = (item: IHierarchicalSelectListItem | IDdValue): void => {
			if (item.children && item.children.length > 0) {
				for (const child of item.children) {
					result.push(child);
					addChildren(child);
				}
			}
		};
		for (const item of items) {
			result.push(item);
			addChildren(item);
		}
		return result;
	},
	/**
	 * Builds a hierarchical select list from a plain list of objects
	 * @param source
	 * @param valueFieldName
	 * @param textFieldName
	 * @param parentFieldName
	 * @param excludeItemAndDescVal -- the value of the value field name in the list to exclude including its descendants.
	 * @returns
	 */
	buildHierarchical(source: { [key: string]: any }[], valueFieldName: string, textFieldName: string, parentFieldName: string, excludeItemAndDescVal: string | null = null): IHierarchicalSelectListItem[] {
		const addChildren = (item: IHierarchicalSelectListItem) => {
			let index = 1;
			for (const childObj of source.filter((o: { [key: string]: any }) => o[parentFieldName] && o[parentFieldName].toString() === item.value)) {
				if (excludeItemAndDescVal === null || childObj[valueFieldName].toString() !== excludeItemAndDescVal) {
					let childItem: IHierarchicalSelectListItem = {
						value: childObj[valueFieldName].toString(),
						text: childObj[textFieldName].toString(),
						display_sequence: index,
						selectable: true,
						children: [],
						selected: false,
					}
					addChildren(childItem);
					index++;
					if (!item.children) item.children = [];
					item.children.push(childItem);
				}
			}
		};
		let result: IHierarchicalSelectListItem[] = [];
		const rootObjects: { [key: string]: any }[] = source.filter((o: { [key: string]: any }) => !Boolean(o[parentFieldName]));
		let index = 1;
		for (const obj of rootObjects) {
			if (excludeItemAndDescVal === null || obj[valueFieldName].toString() !== excludeItemAndDescVal) {
				let item: IHierarchicalSelectListItem = {
					value: obj[valueFieldName].toString(),
					text: obj[textFieldName].toString(),
					display_sequence: index,
					selectable: true,
					children: [],
					selected: false,
				}
				addChildren(item);
				index++;
				result.push(item);
			}
		}

		return result;
	},

    /**
	 * Returns a new flattened array of item's children's values
	 * @param item
	 */
    flattenedChildrenValues(item: IHierarchicalSelectListItem): any[] {
        const getValues = (item: IHierarchicalSelectListItem, result: any[]) => {
            if (item.hasOwnProperty("children") && (item as IHierarchicalSelectListItem).children != null) {
                (item as IHierarchicalSelectListItem).children!.forEach((child: IHierarchicalSelectListItem) => {
                    result.push(child.value);
                    getValues(child, result);
                });
            }
        }
        let result: any[] = [];
        getValues(item, result);
        return result;
    },

     /**
	 * Returns a boolean value indicating whether an object has a populated children array attribute
     * Primarily used to determine if a list item is hierarchical
	 * @param item
	 */
    itemHasChildren(item: any): boolean {
        return item.hasOwnProperty("children") && item.children != null && Array.isArray(item.children) && item.children.length > 0;
    }
};

export { HierarchicalSelectListHelper };
